<template>
	<div class="p-d-flex p-flex-column">
		<slot :classes="{'p-invalid':errors && errors.length}"></slot>
		<span v-if="errors && errors.length" class="p-error">{{ errors[0].message }}</span>
	</div>
</template>

<script>
export default {
	name: "FormInput",
	props: {
		errors: Array
	}
}
</script>

<style scoped>

</style>
