export class CssGrid<T extends object> {
    
    static max_height = 24;
    
    readonly width: number;
    readonly cells: Cell<T>[];
    private readonly grid: any[];
    
    constructor(arg: constrArg<T>) {
        if ("data" in arg && arg.data) {
            if (typeof arg.data === "string")
                arg.data = JSON.parse(arg.data);
            
            const data = arg.data as Serialized<T>;
            
            this.width = data.width;
            this.grid = [];
            this.cells = [];
            
            let maxH = 0;
            for (const item of data.items) {
                maxH = Math.max(maxH, item.rect.h + item.rect.y);
                if (item.data)
                    this.add(item.data, item.rect);
            }
            
            this.fill({x: 1, y: 1, w: data.width, h: maxH});
            
            return;
        }
        
        arg = arg as constCreate;
        if (!arg.initSize)
            arg.initSize = 60;
        
        this.width = arg.width;
        this.grid = new Array(arg.initSize / arg.width);
        this.cells = [];
        
        this.fill({x: 1, y: 1, w: arg.width, h: arg.initSize / arg.width});
    }
    
    serialize(): Serialized<T> {
        const data: Serialized<T> = {
            width: this.width,
            items: []
        };
        for (let i = this.cells.length - 1; i >= 0; i--) {
            if (!this.cells[i]?.data)
                continue;
            
            data.items.push(this.cells[i]);
        }
        return JSON.parse(JSON.stringify(data));
    }
    
    defrag() {
        //TODO remove unused height
        
        let removed = 0;
        loop1:
            for (let i = this.cells.length - 1; i >= 0; i--) {
                if (!this.cells[i]) {
                    removed++;
                    continue;
                }
                
                for (let j = 0; j < i; j++) {
                    if (this.cells[j])
                        continue;
                    
                    this.cells[j] = this.cells[i];
                    for (let k = 0; k < this.grid.length; k++) {
                        if (this.grid[k] === i)
                            this.grid[k] = j;
                    }
                    removed++;
                    
                    continue loop1;
                }
                
                break;
            }
        
        this.cells.length -= removed;
    }
    
    fill({x, y, w, h}: Coord & Size) {
        for (let j = y; j < y + h; j++) {
            for (let k = x; k < x + w; k++) {
                if (!this.cells[this.grid[this.gridIndex(k, j)]]) {
                    this.grid[this.gridIndex(k, j)] = this.cells.length;
                    this.cells.push({
                        data: false,
                        rect: {
                            x: k,
                            y: j,
                            w: 1,
                            h: 1
                        },
                        gridArea: [j, k, j + 1, k + 1].join(" / ")
                    });
                }
            }
        }
    }
    
    check({x, y, w, h}: Coord & Size, index: number = -1) {
        for (let j = y; j < y + h; j++) {
            for (let k = x; k < x + w; k++) {
                const cellIndex = this.grid[this.gridIndex(k, j)]
                if (!Number.isInteger(cellIndex))
                    continue;
                if (this.cells[cellIndex].data && cellIndex !== index)
                    return false;
            }
        }
        return true;
    }
    
    move(index: number, {x, y, w, h}: Coord & Size) {
        const cell = this.cells[index];
        
        if (y + h > CssGrid.max_height || x + w > this.width + 1 || y < 1 || x < 1)
            return false;
        
        if (cell.rect.x === x && cell.rect.y === y && cell.rect.w === w && cell.rect.h === h)
            return false;
        
        if (!this.check({x, y, w, h}, index))
            return false;
        
        this.unregister(cell.rect);
        this.fill(cell.rect);
        
        cell.rect.x = x;
        cell.rect.y = y;
        cell.rect.w = w;
        cell.rect.h = h;
        this.updateGridArea(cell);
        
        let gapsIndex = this.unregister(cell.rect);
        for (const i of gapsIndex) {
            this.cells[i] = undefined;
        }
        
        this.register(cell, index);
        
        this.cleanState();
        if (this.cells.length > 1000)
            this.defrag();
        return true;
    }
    
    add(data: T, {x, y, w, h}: Coord & Size) {
        if (y + h > CssGrid.max_height || x + w > this.width)
            throw new Error();
        
        if (!this.check({x, y, w, h}))
            throw new Error();//TODO
        const cell = {
            data,
            rect: {
                x,
                y,
                w,
                h
            },
            gridArea: [y, x, y + h, x + w].join(" / ")
        };
        let gapsIndex = this.unregister(cell.rect);
        for (const i of gapsIndex) {
            this.cells[i] = undefined;
        }
        this.register(cell, this.cells.length);
        this.cells.push(cell);
    }
    
    remove(index) {
        const cell = this.cells[index];
        this.unregister(cell.rect);
        this.fill(cell.rect);
        this.cells[index] = undefined;
    }
    
    private unregister(rect: Coord & Size) {
        const cells = {};
        for (let j = rect.y; j < rect.y + rect.h; j++) {
            for (let k = rect.x; k < rect.x + rect.w; k++) {
                cells[this.grid[this.gridIndex(k, j)]] = true;
                this.grid[this.gridIndex(k, j)] = false;
            }
        }
        return Object.keys(cells);
    }
    
    private register({rect}: Cell<T>, index: number) {
        for (let j = rect.y; j < rect.y + rect.h; j++) {
            for (let k = rect.x; k < rect.x + rect.w; k++) {
                this.grid[this.gridIndex(k, j)] = index;
            }
        }
    }
    
    private updateGridArea(cell: Cell<T>) {
        cell.gridArea = [cell.rect.y, cell.rect.x, cell.rect.y + cell.rect.h, cell.rect.x + cell.rect.w].join(" / ");
    }
    
    private cleanState() {
        this.fill({
            x: 1,
            y: 1,
            w: this.width,
            h: Math.ceil(this.grid.length / this.width)
        })
    }
    
    gridIndex(x, y) {
        return (y - 1) * this.width + x - 1;
    }
}

export interface Cell<T> {
    data: T | false,
    gridArea: string,
    rect: Coord & Size
}

export interface Coord {
    x: number,
    y: number,
}

export interface Size {
    w: number,
    h: number
}

interface Serialized<T> {
    width: number,
    items: Cell<T>[]
}

export interface constDeserialize<T> {
    data: Serialized<T> | string
}

export interface constCreate {
    width: number,
    initSize?: number
}

export type constrArg<T> = constDeserialize<T> | constCreate;
