<template>
    <Toolbar class="p-mb-4" v-if="!useAsComponent">
      <template #left>
        <h1 style="margin: auto">{{ $t('shipping') }} - {{ $tc('shipping_addons',2) }}</h1>
      </template>
      <template #right>
        <Button class="p-button-success p-mr-2" :label="$t('save')" icon="pi pi-save" @click="update" autofocus/>
        <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus/>
      </template>
    </Toolbar>
  <DataTable :value="shippingaddons" key="value" v-if="shippingaddons.length" class="p-mb-3">
    <Column :header="$t('sku')">
      <template #body="{data}">
        <InputText type="text" v-model="data.sku"/>
      </template>
    </Column>
    <Column :header="$t('description')">
      <template #body="{data}">
        <div class="p-row">
          <div class="p-field p-col-12 p-p-0"><InputText type="text" :placeholder="$t('description')+' (fr)'" v-model="data.description_fr"/></div>
          <div class="p-field p-col-12 p-p-0 p-mb-0"><InputText type="text" :placeholder="$t('description')+' (en)'" v-model="data.description_en"/></div>
        </div>
      </template>
    </Column>
    <Column :header="$t('price')">
      <template #body="{data}">
        <InputText type="text" v-model="data.prix"/>
      </template>
    </Column>
    <Column header="Contact">
      <template #body="{data}">
        <Checkbox :value="1" :binary="true" v-model="data.contact" class="p-mr-3"/>
      </template>
    </Column>
    <Column header="">
      <template #body="{data,index}">
        <span class="p-button p-button-danger p-d-inline" @click="destroy(index)"><i class="pi pi-trash"></i></span>
      </template>
    </Column>
  </DataTable>
  <div class="p-row p-inputtext ">
    <div class="p-row">
      <div class="p-col-auto">
        <Button @click="add()" class="p-button p-component p-button-secondary">{{ $t('add')}}</Button>
      </div>
    </div>
  </div>
  <Toolbar class="p-mt-4" v-if="!useAsComponent">
    <template #left>
      <slot name="toolbar-left"></slot>
    </template>

    <template #right>
      <Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="update" autofocus/>
    </template>
  </Toolbar>
</template>

<script>

import DataTable from 'primevue/datatable';
import {CrudRepository} from "@radiumjs/client";
import {useStore} from "@/store";
import {useRadium} from "@radiumjs/vue";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";


export default {
  name:"ShippingAddons",
  emits: ["save", "update"],
  components: {
    DataTable,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
    Checkbox: require('primevue/checkbox').default,
    _GET
  },
  props:{
    useAsComponent: Boolean,
    items: Object
  },
  data() {
    let shippingaddons = [];
    if(this.useAsComponent&&this.items){
      shippingaddons = this.items;
    }
    return {
      shippingaddons:shippingaddons,
      id: null,
      settings:[]
    }
  },
  setup(props, {emit, slots}){
    const i18n = useI18n();
    const store = useStore();
    const radium = store.radium;
    _GET.setProvider(radium);

    return {};
  },

  methods:{
    repo(){
      if(!this.radium){
        this.radium = useRadium();
      }
      return new CrudRepository(this.radium, 'setting');
    },
    async getSettings(){
      await _GET.all('setting').then(response => {
        this.settings = response;
        this.getShippingAddons();
      });
    },
    getShippingAddons(){
      const shippingaddons = this.settings.filter(item => Object.keys(item).includes('shippingaddons'));
      if(shippingaddons.length==1){
        this.id = shippingaddons[0]['_id'];
        this.shippingaddons = shippingaddons[0]['shippingaddons'];
      }
    },
    add() {
      this.shippingaddons = [...this.shippingaddons, {}]
    },
    update(){
      let obj = {};
      obj['shippingaddons'] = this.shippingaddons;
      if(this.id!=null){
        _GET.update('setting',this.id,obj);
      }else{
        const response = _GET.create('setting',obj);
        console.log('add');
        console.log(response);
        if(response.insertedId){
          this.id = response.insertedId;
        }
      }
    },
    destroy(index){
      console.log('destroy '+index);
      const c = confirm($t('confirm_remove_item'));
      if(!c){
        return false;
      }
      this.shippingaddons.splice( index, 1 );
    },
  },
  mounted() {
    if(!this.$props.useAsComponent){
      this.getSettings();
    }
  }
}
</script>

<style>
td{
  vertical-align: top;
}
</style>
