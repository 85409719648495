<template>
	<slot v-if="form==='login'" v-bind="{login,errors,goToReset}">
	</slot>
	<slot v-else-if="form==='register'" name="register" v-bind="{register,errors}">
	</slot>
	<slot v-else name="reset" v-bind="{resetPassword,errors,goToLogin}">
	</slot>
</template>

<script>
import {useStore} from "@/store";
import {useToast} from "primevue/usetoast";
import {computed, ref} from "vue";
import {v} from '@/helpers/validation'
import {useRoute, useRouter} from "vue-router";
import moment from "moment";
import {useConfirm} from "primevue/useconfirm";
import {_GET} from "../../helpers/get";

export default {
	name: "LoginLayout",
	props: {
		email: String,
		password: String,
		passwordCheck: String
	},
	setup(props) {
		const store = useStore();
		const toast = useToast();
    const confirm = useConfirm();
		const route = useRoute();
		const router = useRouter();
    _GET.setProvider(store.radium);
		
		const form = computed({
			get() {
				switch (route.hash) {
					case "#register":
						return "register";
					case "#reset":
						return "reset";
					default:
						return "login"
				}
			},
			set(val) {
				router.replace({path: route.path, hash: '#' + val}).then(() => {
					errors.value = [];
				});
			}
		});
		
		////
		
		const email = computed(() => props.email);
		const password = computed(() => props.password);
		const passwordCheck = computed(() => props.passwordCheck);
		const errors = ref({});
		
		const validate = v.compile({
			email: {type: "email"},
			password: {type: "string", min: 10}
		});
		
		async function login(check = true) {
			if (check) {
				errors.value = [];
				const validated = await validate({
					email: email.value,
					password: password.value
				});
				
				if (validated !== true) {
					validated.forEach(v => {
						errors.value[v.field] = errors.value[v.field] || [];
						errors.value[v.field].push(v);
					});
					return;
				}
			}
			
			try {
				const response = await store.radium.auth(email.value || undefined, password.value || undefined);
				if (!response)
					return;


				const token = await store.radium.token();
				//store.auth.loggedIn.value = false;//TODO move to callback in radiumjs
				store.auth.user_info.value = token;
        const enabled = await store.permissions.get();
        console.log('enabled : '+enabled);

        if(!enabled){
          store.users.detail = {};
          await store.radium.logout();
          store.auth.loggedIn.value = false;
          toast.add({
            severity: 'error',
            life: 5000,
            summary: "Compte inactif",
            detail: "Votre compte n'est pas actif. Veuillez contacter Mat-Tech pour le réactiver."
          });
        }else{
          store.auth.loggedIn.value = true;
          store.session.lastLogin = store.auth.user_info.value.iat;
          await _GET.get(store.auth.user_info.value.key,store.auth.user_info.value.id).then(response => {
            response.password = '';
            store.users.detail = response;
          });
          if(check){
            await _GET.create('userLogs',{user:store.auth.user_info.value.id,'login':moment.now(),type:store.auth.user_info.value.key});
          }
        }

        console.log(store.auth);

			} catch (error) {
				if (!check)
					return;
				console.log(error);
				switch (error?.response?.status) {
					case 404:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "404",
							detail: "Veuillez vérifier votre courriel ou mot de passe."
						});
						break;
					case 422:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "422",
							detail: "Une erreur est survenue."
						});
						break;
					default:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "Erreur Inconnu",
							detail: error
						});
				}
			}
		}
		
		async function register() {
			errors.value = [];
			const validated = await validate({
				email: "tmp@tmp.tmp",
				password: password.value
			});
			if (validated !== true) {
				validated.forEach(v => {
					errors.value[v.field] = errors.value[v.field] || [];
					errors.value[v.field].push(v);
				});
				return;
			}
			if (passwordCheck.value !== password.value) {
				errors.value["passwordCheck"] = [
					{message: "Ce champ n'est pas pareil au mot de passe"}
				];
				return;
			}
			
			try {
				const response = await store.radium.api("changePassword", {
					newPassword: password.value,
					resetToken: route.query.token
				});
				if (!response)
					return toast.add({
						severity: 'error',
						life: 5000,
						summary: "Erreur Inconnu",
						detail: "Le serveur a retourner une reponse vide"
					});
				
				toast.add({
					severity: 'success',
					life: 5000,
					summary: "Votre mot de pass a été changer",
					detail: "Vous pouvez dorénavant vous connecter."
				});
				
				form.value = "login";
			} catch (error) {
				console.log(error);
				switch (error?.response?.status) {
					case 404:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "404",
							detail: "Le token utiliser n'est pas bon"
						});
						break;
					case 422:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "422",
							detail: "Une erreur est survenue."
						});
						break;
					default:
						toast.add({
							severity: 'error',
							life: 5000,
							summary: "Erreur Inconnu",
							detail: error
						});
				}
			}
		}
		
		async function resetPassword() {
			try {
				await store.radium.api("resetPassword", {
					email: props.email
				});
				toast.add({
					life: 5000,
					summary: "Email envoyé!",
					detail: "Regarder dans votre boite courriel pour le lien qui vous permettera de changer votre mot de pass."
				});
			} catch (e) {
				toast.add({
					life: 5000,
					summary: "Une erreur est survenue",
					detail: e,
					severity: 'error'
				});
			}
		}
		
		///////
		
		login(false);
		
		return {
			login, errors, form, register, resetPassword,
			goToReset() {
				form.value = 'reset';
			},
			goToLogin() {
				form.value = 'login';
			}
		};
	}
}
</script>

<style scoped>

</style>
