import {Store, useStore} from './index';
import {_GET} from "@/helpers/get";

export class Permissions {
    
    private store: Store;
    private permissions: [];
    private _filters: [];
    
    constructor(store: Store) {
        this.store = store;
        this._filters = [];
        _GET.setProvider(store.radium);
    }

    async get(){
        //store.auth.user_info.value.permissions = [];
        let permission = '';
        let user = null;
        if(this.store.auth.user_info.value.key=='resellerContact'){//distributeurs
            user = await _GET.get('resellerContact',this.store.auth.user_info.value.id).then(response => {return response;});
            permission = '62e834f47785a463949c52e5';
        }else{
            user = await _GET.get('admin',this.store.auth.user_info.value.id).then(response => {return response;});
            if(user['categorie']){
                permission = user['categorie'];
            }
        }
        if(user&&Object.keys(user).includes('status')){
            this.store.auth.user_info.value.status = user.status;
            if(user.status===-1||user.status==='Inactive'){
                //disable access
                return false;
            }
        }
        if(permission){
            const category = await _GET.get('userCategories', permission);
            if(category&&category['permissions']){
                this.permissions = category['permissions'];
                this.store.auth.user_info.value.permissions = this.permissions;
                console.log(this.permissions);
                return true;
            }
        }
        this.permissions = [];
        this.store.auth.user_info.value.permissions = [];
        return true;
    }

    has(permission){
        console.log('has permission : '+permission);
        if(permission&&this.store.auth.user_info.value.permissions.length){
            console.log(this.store.auth.user_info.value.permissions.includes(permission));
            return this.store.auth.user_info.value.permissions.includes(permission);
        }
        return true;
    }

    own(){
        console.log('own');
        if(this.store.auth.user_info&&this.store.auth.user_info.value.key!='admin'){
            this._filters['creator'] = {
                matchMode:'equals',
                value:this.store.auth.user_info.value.id
            };
        }
        console.log(this.store.auth.user_info.value);
        console.log(this._filters);
    }

    filters(){
        this.own();
        return this._filters;
    }

    current(){
        if(this.store.router.currentRoute.value.name){
            return String(this.store.router.currentRoute.value.name);
        }
        return 'default';
    }

    list(){
        if(this.store.router.currentRoute.value.meta&&this.store.router.currentRoute.value.meta.permission){
            // @ts-ignore
            const routes = this.store.router.currentRoute.value.meta.permission.split('.');
            routes.splice(-1);
            return this.store.permissions.has(routes.join('.')+'.list');
        }
        return true;
    }
    add(){
        if(this.store.router.currentRoute.value.meta&&this.store.router.currentRoute.value.meta.permission){
            // @ts-ignore
            const routes = this.store.router.currentRoute.value.meta.permission.split('.');
            routes.splice(-1);
            return this.store.permissions.has(routes.join('.')+'.add');
        }
        return true;
    }
    edit(){
        if(this.store.router.currentRoute.value.meta&&this.store.router.currentRoute.value.meta.permission){
            // @ts-ignore
            const routes = this.store.router.currentRoute.value.meta.permission.split('.');
            routes.splice(-1);
            return this.store.permissions.has(routes.join('.')+'.edit');
        }
        return true;
    }
    delete(){
        if(this.store.router.currentRoute.value.meta&&this.store.router.currentRoute.value.meta.permission){
            // @ts-ignore
            const routes = this.store.router.currentRoute.value.meta.permission.split('.');
            routes.splice(-1);
            return this.store.permissions.has(routes.join('.')+'.delete');
        }
        return true;
    }

    nfor(types){
        return !types.includes(this.store.auth.user_info.value.key);
    }

    for(types){
        return types.includes(this.store.auth.user_info.value.key);
    }

    isAdmin(){
        return this.for(['admin']);
    }

    isReseller(){
        return this.for(['resellerContact']);
    }

}
