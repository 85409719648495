<template>
	<div class="p-shadow-3 p-p-2" style="min-height: 100%">
		<h1>
			{{ item.username }}
		</h1>
		<hr>
		<span>{{ item.email }}</span>
	</div>
</template>

<script>

import {useStore} from "@/store";
import {CrudRepository} from "@radiumjs/client";

export default {
	name: "MyProfile",
	components: {InputText: require('primevue/inputtext').default},
	setup() {
		const store = useStore();
		const radium = store.radium;
		const {item, refresh} = radium.objectReactive(new CrudRepository(radium, store.auth.user_info.value.key), store.auth.user_info.value.id);
		refresh();
		
		return {
			auth: store.auth.user_info,
			item
		}
	}
}
</script>
