
import {computed, ComputedRef, defineComponent, inject, ref} from "vue";
import {SingleLayout} from "@radiumjs/vue";
import SkuBuilder from "@/components/Sku/SkuBuilder.vue";
import SkuSelector from "@/components/Sku/SkuSelector.vue";
import FormRadium from "@/components/FormPrime/FormRadium.vue";
import {FilterMatchMode} from "primevue/api";
import {SkuHelper} from "@/helpers/product";

export default defineComponent({
	name: "SkuList",
	components: {
		SingleLayout,
		SkuBuilder,
		SkuSelector,
		FormRadium,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Dialog: require('primevue/dialog').default,
		DataTable: require('primevue/datatable').default,
		Column: require('primevue/column').default,
		InputText: require('primevue/inputtext').default,
		Dropdown: require('primevue/dropdown').default,
		Textarea: require('primevue/textarea').default,
	},
	props: {},
	emits: [],
	setup() {
		
		const helper = inject("productHelper") as ComputedRef<SkuHelper>;
		
		
		const allSkus = computed(() => {
			const allSkus = [];
			
			helper.value.skus.forEach((sku) => {
				
				allSkus.push({
					sku: sku.sku,
					options: sku.options.map((v, i) => {
						return helper.value.product.sku.parts[i].options[v]
					}),
					price: helper.value.views.prices.getRange(sku)
				});
				
			});
			
			return allSkus;
		});
		
		return {
			filters: ref({'sku': {value: null, matchMode: FilterMatchMode.CONTAINS},}),
			skus: allSkus,
			parts: helper.value.product.sku.parts,
			refresh() {
			}
		};
	}
});
