
import SkuPart from "./SkuPart.vue";
import {computed, defineComponent, provide, reactive, ref, watch} from "vue";
import {Product, SkuHelper} from "@/helpers/product";
import SkuSelectorConfiguration from "@/components/Sku/SkuSelectorConfigurator.vue";
import SkuList from "@/components/Sku/SkuList.vue";

export default defineComponent({
	name: "SkuBuilder",
	components: {
		SkuList,
		SkuSelectorConfiguration,
		SkuPart,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Listbox: require('primevue/listbox').default,
		InputText: require('primevue/inputtext').default,
		Dropdown: require('primevue/dropdown').default,
		Sidebar: require('primevue/sidebar').default,
		TabPanel: require('primevue/tabpanel').default,
		TabView: require('primevue/tabview').default,
		DataTable: require('primevue/datatable').default,
		Column: require('primevue/column').default,
		OrderList: require('primevue/orderlist').default,
		Dialog: require('primevue/dialog').default,
	},
	props: {
		modelValue: Object,
		product: Object,
		skus: Array
	},
	emits: ["update:modelValue", "update:skus"],
	setup(props, {emit: emitO, expose}) {
		function emit(event, ...args) {
			console.log(event, args);
			emitO(event, ...args);
		}
		
		const parts = computed(() => {
			return props.modelValue?.parts || props.product?.skuParts || [];
		});
		
		const helper = computed(() => {
			const product: Product = reactive(JSON.parse(JSON.stringify(props.product)));
			const helper = new SkuHelper(product);
			
			watch(product.sku, () => {
				emit("update:modelValue", product.sku);
			}, {
				deep: true
			});
			
			{//update skus list if needed
				const skus = helper.skus.map(s => s.sku)
				if (props.skus?.length === skus.length) {
					for (let i = 0; i < skus.length; i++) {
						if (skus[i] !== props.skus?.[i]) {
							emit("update:skus", skus);
							break;
						}
					}
				} else
					emit("update:skus", skus);
			}
			
			return helper;
		});
		
		provide('parts', parts);
		
		provide('productHelper', helper);
		
		expose({
			productHelper: helper
		});
		
		return {
			edit: ref(false),
			list: ref(false),
			
			parts,
			skuConfig: computed({
				get() {
					return props.modelValue
				},
				set(val) {
					emit('update:modelValue', val);
				}
			}),
			updatePart(newPart, i) {
				let newParts = [...parts.value];
				newParts[i] = newPart;
        newParts = newParts.filter(row => row.name!='');
        const np = props.modelValue;
        np.parts = newParts;
				emit('update:modelValue', np);
			},
			addPart() {
				emit('update:modelValue', {
					...props.modelValue, parts: [
						...parts.value,
						SkuPart.methods.newDatas()
					]
				});
			},
			destroy(i) {
				const newParts = [...parts.value];
				newParts.splice(i, 1);
				emit('update:modelValue', {...props.modelValue, parts: newParts});
			},
			reorder(dragIndex, dropIndex) {
				dropIndex = Math.min(parts.value.length, Math.max(dropIndex, 0));
				
				const a = parts.value[dragIndex];
				
				const before = parts.value.slice(0, dropIndex);
				const after = parts.value.slice(dropIndex, parts.value.length);
				
				//5 < 4 = after
				//4 < 5 = befor
				if (dragIndex < dropIndex)
					before.splice(dragIndex, 1);
				else
					after.splice(dragIndex - dropIndex, 1);
				
				emit('update:modelValue', {
					...props.modelValue, parts: [
						...before,
						a,
						...after
					]
				});
			},
		};
	}
})
