import {SKU, SkuHelper, SkuPartOption} from "@/helpers/product";
import {Module, ModuleView} from "@/helpers/modules/module";
import {defineComponent, DefineComponent, ref, watch, watchEffect, WritableComputedRef} from "vue";
import {default as InputField} from "primevue/inputnumber";

export class Weights extends Module<{}, WeightModuleView> {
    
    defaultConfig() {
        return {};
    }
    
    get info() {
        return {
            label: "Poids"
        };
    }
    
    optionator(helper, optionData: WritableComputedRef<SkuPartOption>): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    const used = ref(null);
                    const weight = ref(null);
                    const weightLinear = ref(null);
                    
                    watchEffect(() => {
                        used.value = optionData.value?.vars?.weight?.type === "static";
                        weight.value = parseFloat(optionData.value?.vars?.weight?.type === "static" ? optionData.value?.vars?.weight?.value : '0');
                        weightLinear.value = parseFloat(optionData.value?.vars?.weightLinear?.type === "static" ? optionData.value?.vars?.weightLinear?.value : '0');
                    })
                    
                    watch([used, weight, weightLinear], () => {
                        if (used.value) {
                            optionData.value = {
                                ...optionData.value,
                                vars: {
                                    ...optionData.value.vars,
                                    weight: {type: "static", value: weight.value},
                                    weightLinear: {type: "static", value: weightLinear.value},
                                }
                            };
                        } else {
                            const vars = {
                                ...optionData.value.vars
                            };
                            delete vars.weight;
                            delete vars.weightLinear;
                            optionData.value = {
                                ...optionData.value,
                                vars
                            };
                        }
                    });
                    
                    const InputField = require("primevue/inputnumber").default
                    const Checkbox = require("primevue/checkbox").default
                    return () => {
                        let sections = [];
                        
                        if (used.value) {
                            sections.push(
                                <div className="p-field p-d-flex p-align-center p-mt-3">
                                    <label className="p-m-0 p-r-2">Weight</label>
                                    <InputField modelValue={weight.value} onUpdate:modelValue={e => weight.value = e} mode="decimal" minFractionDigits={2}/>
                                </div>
                            );
                            if (helper.value.views.sizes.isActive) {
                                sections.push(
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Weight Linear</label>
                                        <InputField modelValue={weightLinear.value} onUpdate:modelValue={e => weightLinear.value = e} mode="decimal" minFractionDigits={2}/>
                                    </div>
                                );
                            }
                        }
                        
                        return (
                            <div>
                                <div className="p-field-checkbox p-m-0">
                                    <Checkbox modelValue={used.value} onUpdate:modelValue={e => used.value = e} binary={true}/>
                                    <label>{used.value == null ? 'N/A' : used.value ? "Active" : "Inactif"}</label>
                                </div>
                                <div className="p-m-2 p-p-1 p-shadow-3">
                                    {sections}
                                </div>
                            </div>
                        )
                    }
                }
            })
        };
    }
    
    view(helper: SkuHelper, config): WeightModuleView {
        return new WeightModuleView(helper, config);
    }
    
}

class WeightModuleView extends ModuleView<{}> {
    constructor(helper, config) {
        super(helper, config);
    }
    
    getInfos(sku: SKU): Record<string, string> {
        return {weight: String(this.getWeight(sku))};
    }
    
    getRange(sku: SKU): [number, number] {
        const totals = this.getWeightStat(sku);
        return [totals.min, totals.max];
    }
    
    getWeight(sku: SKU): number {
        return this.getWeightStat(sku).real;
    }
    
    getWeightStat(sku: SKU) {
        const ops = {
            min: [],
            max: [],
            real: []
        }
        
        sku.options.forEach((oi, pi) => {
            const option = this.helper.product.sku.parts[pi].options[oi];
            const weight = option?.vars?.weight
            const weightLinear = option?.vars?.weightLinear;
            
            if (weight?.type === "static") {
                ops.min.push(() => parseFloat(weight?.value || '0'));
                ops.max.push(() => parseFloat(weight?.value || '0'));
                ops.real.push(() => parseFloat(weight?.value || '0'));
            }
            
            if (this.helper.views.sizes.config.active && weightLinear?.type === "static") {
                const sizes = this.helper.views.sizes.getSizes(sku);
                
                ops.min.push(() => sizes.length.min / 12 * parseFloat(weightLinear?.value || '0'));
                ops.max.push(() => sizes.length.max / 12 * parseFloat(weightLinear?.value || '0'));
                ops.real.push(() => parseFloat(this.helper.views.sizes.length.value) / 12 * parseFloat(weightLinear?.value || '0'));
            }
        });
        
        
        const result = {
            min: 0,
            max: 0,
            real: 0
        }
        Object.keys(ops).forEach(op => {
            result[op] = ops[op].reduce((a, v) => a + v(), 0);
        });
        return result;
    }
}
