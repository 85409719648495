import {SKU, SkuHelper, SkuPart, SkuPartOption} from "@/helpers/product";
import {DefineComponent, Ref, WritableComputedRef} from "vue";
import {CheckerFunctionError} from "fastest-validator";

export abstract class Module<T extends { active?: boolean }, V extends ModuleView<T> = ModuleView<T>> {
    
    constructor() {
    }
    
    abstract defaultConfig(): T;
    
    abstract get info(): { label: string };
    
    view(helper: SkuHelper, config: T): V {
        return new ModuleView(helper, config) as V;
    }
    
    configurator(view: Ref<V>): { component: DefineComponent } | undefined {
        return undefined;
    }
    
    optionator(view: Ref<SkuHelper>, optionData: WritableComputedRef<SkuPartOption>): { component: DefineComponent } | undefined {
        return undefined;
    }
    
    partator(view: Ref<SkuHelper>, partData: WritableComputedRef<SkuPart>): { component: DefineComponent } | undefined {
        return undefined;
    }
}

export class ModuleView<T extends { active?: boolean }> {
    protected helper: SkuHelper;
    public config: T;
    
    constructor(helper: SkuHelper, config: T) {
        this.helper = helper;
        this.config = config;
    }
    
    set isActive(v: boolean) {
        this.config.active = v;
    }
    
    get isActive(): boolean {
        return this.config.active;
    }
    
    getInfos(sku: SKU): Record<string, string> {
        return {}
    }
    
    testSKU(sku: SKU): boolean {
        return true;
    }
    
    validate(errors: CheckerFunctionError[]) {
    
    }
    
    selector(): { done: Ref<boolean>, init: Ref<boolean>, filter: (sku: SKU) => boolean, component: DefineComponent } | undefined {
        return undefined;
    }
}
