
import {computed, inject, ref, watchEffect} from "vue";

export default {
	name: "SkuConditions",
	components: {
		Button: require('primevue/button').default,
		Tree: require('primevue/tree').default,
	},
	props: {
		modelValue: Object,
		selfName: String
	},
	emits: ["update:modelValue"],
	setup(props, {emit}) {
		const modelValue = computed(() => {
			console.log(props.modelValue)
			return Array.isArray(props.modelValue) ? props.modelValue : [];
		});
		const parts = inject("parts", ref([]))
		
		const expandedKeys = ref({});
		const nodes = ref([]);
		const map = ref({});
		const conditions = ref({});
		
		watchEffect(() => {
			nodes.value = [];
			map.value = {};
			conditions.value = {};
			
			for (const val of modelValue.value) {
				const key = val.part + "-=-" + val.option;
				conditions.value[key] = {checked: true};
			}
			
			for (let i = 0; i < parts.value.length; i++) {
				const part = parts.value[i];
				if (part.name === props.selfName)
					continue;
				
				const curNode = [];
				let allSelected = true;
				conditions.value[i] = {};
				
				for (let io = 0; io < part.options.length; io++) {
					const option = part.options[io];
					const key = i + "-=-" + io;
					
					if (!conditions.value[key])
						allSelected = false;
					else
						conditions.value[i].partialChecked = true;
					
					map.value[key] = {part: i, option: io};
					curNode.push({
						key,
						label: "[" + option.name + "] " + (option.label_fr?option.label_fr:(option.label?option.label:'')),
						selectable: true
					});
				}
				
				if (allSelected)
					conditions.value[i] = {checked: true, partialChecked: false};
				
				nodes.value.push({
					key: i,
					label: part.name,
					selectable: true,
					children: curNode
				});
			}
		});
		
		return {
			log: console.log,
			nodes,
			conditions: computed({
				get() {
					return conditions.value;
				},
				set(val) {
					emit('update:modelValue', Object.keys(val).map(v => map.value[v]).filter(v => !!v));
				}
			}),
			
			expandedKeys,
			expandAll() {
				for (let node of nodes.value) {
					expandedKeys.value[node.key] = true;
				}
				
				expandedKeys.value = {...expandedKeys.value};
			},
			collapseAll() {
				expandedKeys.value = {};
			},
		};
	}
}
