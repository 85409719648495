import {Store, useStore} from './index';
import Dialog from 'primevue/dialog';
import moment from "moment";
import {defineComponent} from "vue";
import component from "*.vue";

export class Session {
    
    private store: Store;
    public lastLogin: number;//milliseconds
    readonly expiration: number;
    public timer: number;
    public close_timer: number;
    private app: any
    
    constructor(store: Store) {
        this.store = store;
        this.timer = 0;
        this.close_timer = 0;
        this.expiration = (60 * 60 * 2);//2 hours
        //this.expiration = (45);//45 sec
        this.confirm();
        this.start();

    }

    start(){
        const self = this;
        this.stop();
        //@ts-ignore
        this.timer = setTimeout(function(){self.check();},(60 * 5 * 1000));//milliseconds
    }

    stop(){
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    async check(){
        if(this.expired()){
            this.closing();
            /*if(this.app.$confirm) {
                const confirm = this.app.$confirm;
                confirm.require({
                    message: 'Votre session fermera dans 60 secondes. Voulez-vous poursuivre votre session.',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.cancel_closing();
                        if (this.store.auth.loggedIn.value) {
                            this.lastLogin = (moment.now() / 1000);
                            this.start();
                            return true;
                        }
                    },
                    reject: () => {
                        this.close();
                    }
                });
            }*/
            const c = confirm('Votre session fermera dans 60 secondes. Voulez-vous poursuivre votre session.');
            if(c){
                this.cancel_closing();
                if(this.store.auth.loggedIn.value){
                    this.lastLogin = (moment.now()/1000);
                    this.start();
                    return true;
                }
            }
            this.close();
        }else{
            this.start();
        }
    }

    expired(){
        console.log('lastLogin : '+this.lastLogin);
        console.log('expiration : '+(this.lastLogin+this.expiration));

        if(this.lastLogin){
            let now = moment.now();
            now = Math.ceil(now/1000);
            console.log('now : '+(this.lastLogin+this.expiration)+'<'+now+' ('+(this.lastLogin+this.expiration<now)+')');
            if(this.lastLogin+this.expiration>now){
                return false;
            }
        }
        return true;
    }

    closing(){
        const self = this;
        this.cancel_closing();
        //@ts-ignore
        this.close_timer = setTimeout(function(){self.close();},60000);//milliseconds
    }

    cancel_closing(){
        if(this.close_timer){
            clearTimeout(this.close_timer);
        }
    }

    close(){
        console.log('close session');
        this.store.radium.logout();
        this.store.auth.loggedIn.value = false;
    }

    confirm(){
        return {
            component: defineComponent({
                setup() {
                    console.log('setup session');
                }
            })
        };
    }
    
}
