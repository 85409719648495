
import {defineComponent, reactive, ref, triggerRef, onMounted, watch} from "vue";
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {CrudRepository} from "@radiumjs/client";
import Validator from "fastest-validator";
import {useRadium} from "@radiumjs/vue";
import {useToast} from "primevue/usetoast";
import moment from 'moment';

let v = new Validator({
	useNewCustomCheckerFunction: true,
	messages: {
		stringMin: "Le champ '{field}' doit avoir au moins {expected} caractères de long",
		string: "Veuillez vérifier ce champ",
		number: "Ce champ doit être de type numérique (pas de virgule, mais un point)"
	}
});

export default defineComponent({
	name: "SingleLayout",
	components: {
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		TabView: require('primevue/tabview').default,
		TabPanel: require('primevue/tabpanel').default,
		ProgressSpinner: require('primevue/progressspinner').default,
	},
	props: {
		service: String,
		schema: Object,
		returnOnSave: {
      type: String,
      default: ''
    },
    backButtonRoute: {
      type: String,
      default: ''
    } ,
		singleRouteName: String,
    backButton: {
      type: Boolean,
      default: true
    } ,
    saveButton: {
      type: Boolean,
      default: true
    },
    afterSave: null
  },
	emits: ["create","update","itemUpdated"],
	setup(props, {emit}) {
		const radium = useRadium();
		const route = useRoute();
		const router = useRouter();
		const repo = new CrudRepository(radium, props.service as string);
    const toast = useToast();
		//const error = ref(false);//TODO handle 404/500 and other

		const errors = ref<any>({});
		const saveCount = ref(0);
		const validate = v.compile(props.schema || {});
		const {item, id, loading, save: iSave, refresh: objRefresh, update, isModified} = radium.objectReactive(repo, false);

		onBeforeRouteLeave((to, from, next) => {
			if (isModified.value)
				if (window.confirm('Voulez-vous vraiment partir ? Vous avez des changements non enregistrés!'))
					next(); else
					next(false);
			else
				next();
		});

		const open = (_id: string | false) => {
			errors.value = {};
			id.value = _id;
			objRefresh();
			if (id.value === false) {
				emit('create', update);
			}
		}

		const save = async () => {
			saveCount.value++;
			const oldId = id.value;
			errors.value = {};
			let errs = validate(item.value);
			if (!Array.isArray(errs)) {
				await iSave();
        if(props.afterSave){
          await props.afterSave({id:id.value,item:item.value}).then(()=>{
            console.log('afterSave done');
          });
        }

        console.log(oldId, id.value);
        if (props.returnOnSave!=='') {
          router.push({name: props.returnOnSave});
        }else if (id.value !== oldId) {
          console.log(route.name);
          router.push({
            name: route.name || undefined,
            params: {
              id: id.value || undefined
            }});
          /*await router.replace({
            name: route.name || undefined,
            params: {
              id: id.value || undefined
            }
          });*/
        }

			} else {
				errs.forEach(v => {
					errors.value[v.field] = errors.value[v.field] || [];
					errors.value[v.field].push(v);
				});
				console.log(errors.value);
				triggerRef(errors);
			}
		}

		open(route.params.id as string || false);

    const single = reactive({
      item, id, refresh: objRefresh, validate, errors, save, update, close
    });

    watch(() => item.value, () => {
      emit('itemUpdated',item.value);
    }, {
      deep: true,
    });
    emit('itemUpdated',item.value);

		return {
			isModified,
			loading,
			saveCount,
			single,
      _update: update,
			toolbar: reactive({
				id,
        item,
				open(_id) {
					if (props.singleRouteName) {
						const newRoute = {
							name: props.singleRouteName,
							params:{}
						};
						if (_id)
							newRoute.params = {id: _id};
						router.push(newRoute);
					} else
						throw new Error();
				}
			}),
		};
	},
  methods:{
    update(op,k,v,set = false){
      console.log('update : '+k+' -> '+v);
      if(typeof(v)==='object'){
        console.log(v);
      }
      this._update(op, k, v);
      if(set){
        this.set(k,v);
      }
    },
    set(k,v){
      console.log('set item : '+k+' -> '+v);
      this.single.item[k] = v;
    }
  }
})
