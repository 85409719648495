<template>
	<div class="p-d-flex" v-if="unit==='ftin'">
		<div class="p-inputgroup" style="width: 6rem">
			<InputNumber v-model="pi" @focus="$event.target.select()"/>
			<span class="p-inputgroup-addon">'</span>
		</div>
		<div class="p-inputgroup" style="width: 6rem">
			<InputNumber v-model="po" mode="decimal" @focus="$event.target.select()"/>
			<span class="p-inputgroup-addon">"</span>
		</div>
	</div>
  <div class="p-d-flex" v-else-if="unit==='in'">
    <div class="p-inputgroup" style="width: 6rem">
      <InputNumber v-model="po" mode="decimal" @focus="$event.target.select()"/>
      <span class="p-inputgroup-addon">"</span>
    </div>
  </div>
  <div class="p-d-flex" v-else-if="unit==='ft'">
    <div class="p-inputgroup" style="width: 6rem">
      <InputText v-model="pi" @focus="$event.target.select()"/>
      <span class="p-inputgroup-addon">'</span>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {getMeasureUnit} from '../../helpers/mesures.js';

export default {
	name: "Length",
	components: {
		InputNumber: require('primevue/inputnumber').default,
    InputText: require('primevue/inputtext').default
	},
	props: ["min", "max", "modelValue","unit"],
  data(){},
	setup(props, {emit}) {
		const pi = computed({
			get() {
        let v = props.modelValue / 12;
        if(unit.value==='ft'){
          if(String(v).length>3){
            v = v.toFixed(2);
          }
        }else if(unit.value==='in'){
          v = 0;
        }else{
          v = Math.floor(v);
        }
				return v
			},
			set(val) {
        let v = val * 12;
        if(unit.value==='ftin'){
          v = val * 12 + (po.value || 0);
        }
				emit("update:modelValue", v);
			}
		});
		const po = computed({
			get() {
        let v = props.modelValue;
        if(unit.value==='ftin'){
          v = v % 12;
        }else if(unit.value==='ft'){
          v = 0;
        }
				return v
			},
			set(val) {
        let v = val;
        if(unit.value==='ftin'){
          v = (pi.value || 0) * 12 + val;
        }
				emit("update:modelValue", v);
			}
		});

    const unit = computed({
      get() {
        console.log(props.unit || localStorage.unit);
        return props.unit || localStorage.unit
      }
    });
		
		return {unit, pi, po};
	},
  methods:{
    convertUnit(n){
      if(this.unit === 'ftin'){
        return Math.floor(n / 12)+`' `+(n % 12)+`"`;
      }else if(this.unit === 'ft'){
        const _n = (n / 12);
        return _n.toFixed(2)+`'`;
      }
      return (n)+`"`;
    }
  }
}
</script>

<style scoped>

</style>
