import {Module, ModuleView} from "@/helpers/modules/module";
import {SkuHelper, SkuPart} from "@/helpers/product";
import {defineComponent, DefineComponent, h, Ref, WritableComputedRef} from "vue";

interface Config {

}

export class Hider extends Module<Config> {
    defaultConfig(): Config {
        return {};
    }
    
    get info(): { label: string } {
        return {label: "Fourbe"};
    }
    
    view(helper: SkuHelper, config: Config) {
        return new HiderView(helper, config);
    }
    
    partator(view: Ref<SkuHelper>, partData: WritableComputedRef<SkuPart>): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    return () => h(require("./HideIfPossible.vue").default, {
                        modelValue: !!partData.value.hiddenIfPossible,
                        "onUpdate:modelValue"(e) {
                            partData.value = {
                                ...partData.value,
                                hiddenIfPossible: !!e
                            };
                        }
                    })
                }
            })
        };
    }
}

class HiderView extends ModuleView<Config> {
    
    public shouldHideIfPossible(partIndex: number): boolean {
        return !!this.helper.product.sku.parts[partIndex].hiddenIfPossible;
    }
    
}
