<template>
	<TableLayout service="submission" single-route-name="submissions.edit" v-model:hidden-columns="hiddenColumns" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions" v-if="settings.length>0">
		<template #toolbar-left>
			<h1 style="margin: auto">{{ $tc('submission',2) }}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button :label="$t('add')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.add()"/>
		</template>
		
		<template #default="{open}">
			<Column field="qt_nb" sortable>
        <template #header>
          {{ $t('soumission_nb') }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>
      <Column field="status">
        <template #header></template>
        <template #body="{data}">
          <span :class="getStatut(data).css">{{ getStatut(data).name }}</span>
        </template>
        <!--<template #filter="{filterModel,filterCallback}">
          <Dropdown v-model="filterModel.value" :options="statusOptions" optionLabel="label" optionValue="value" placeholder="Tout" class="p-column-filter" :showClear="true" @change="setSatutFilter(filterCallback)"/>
        </template>-->
      </Column>
      <Column field="status">
        <template #header>
          {{ $tc('statut',1) }}
        </template>
        <template #body="{data}">
          <span class="badge" :style="'background-color:'+getSingleQuoteStatusColor(data.quotestatus)">{{ getSingleQuoteStatus(data.quotestatus) }}</span>
        </template>
        <!--<template #filter="{filterModel,filterCallback}">
          <Dropdown v-model="filterModel.value" :options="statusOptions" optionLabel="label" optionValue="value" placeholder="Tout" class="p-column-filter" :showClear="true" @change="setSatutFilter(filterCallback)"/>
        </template>-->
      </Column>
      <Column >
        <template #header>
          {{ $tc('distributeur',1) }}
        </template>
        <template #body="slotProps">
          {{ getResellerName(slotProps.data.reseller_id) }}
        </template>
      </Column>
      <Column >
        <template #header>
          {{ $tc('client',1) }}
        </template>
        <template #body="slotProps">
          {{ getClientName(slotProps.data.client_id) }}
        </template>
      </Column>
			<Column field="nom_projet">
        <template #header>
          {{ $t('nom_projet') }}
        </template>
      </Column>
      <Column field="nom_contact">
        <template #header>
          {{ $t('nom_contact') }}
        </template>
      </Column>
		</template>
	</TableLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref, inject} from "vue";
import * as moment from "moment";
import {CrudRepository} from "@radiumjs/client";
import {provinces} from "../../helpers/provinces";
import {useStore} from "@/store";
import {_GET} from "../../helpers/get";
import {$t} from "../../i18n";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
	name: "Submission",
	components: {
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
    _GET
	},
  data() {
    return {
      resellers:[],
      clients:[],
      settings:[],
      column_filters: {
        qt_nb: 'string'
      }
    }
  },
	setup(props, {emit}) {
    const store = useStore();
    _GET.setProvider(store.radium);
    const enabled_actions = [];
    const own_filters = store.permissions.filters();

    const statusOptions = ref([
      {
        name: $t('submission_expired'),
        code: 'expired',
        css: 'badge badge-danger'
      },
      {
        name: $t('submission_sent'),
        code: 'sent',
        css: 'badge badge-success'
      },
      {
        name: $t('submission_no-expiration'),
        code: 'no-expiration',
        css: 'badge badge-warning'
      },
      {
        name: $t('submission_active'),
        code: 'active',
        css: 'badge badge-primary'
      }
    ]);

    const statusMap = computed(() => {
      return statusOptions.value.reduce((a, v) => {
        a[v.code] = {name: v.name, css: v.css};
        return a
      }, {});
    });

    const getStatut = (data) => {
      let status = 'active';
      if(data&&data.sent){
        status = 'sent';
      }
      if(data&&data.expired_at){
        if(moment(String(data.expired_at)).isBefore(moment().format('YYYY-MM-DD'))){
          status = 'expired';
        }
      }else if(data&&!data.expired_at){
        status = 'no-expiration';
      }
      console.log('getStatut '+status);
      console.log(statusMap.value[status]);
      return statusMap.value[status];
    }

    let filters = {
      'qt_nb': {value: null, matchMode: FilterMatchMode.CONTAINS},
      /*'status': {operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }]}*/
    };
    filters = {...filters,...own_filters};

    const actions = [];

    /*const statusOptions = ref([
      {value:"active",label:$t('submission_active')},
      {value:"sent",label:$t('submission_sent')},
      {value:"expired",label:$t('submission_expired')},
      {value:"no-expiration",label:$t('submission_no-expiration')}
    ]);*/

    const setSatutFilter = (filterCallback) => {
      if(filters.status.value==='expired'){
        console.log('get expired : '+moment().format('YYY-MM-DD'));
        filters.expired_at = {value: moment().format('YYY-MM-DD'), matchMode: FilterMatchMode.DATE_IS};
      }
      filterCallback();
    }

		return {
      setSatutFilter,
      getStatut,
      statusOptions,
      statusMap,
      actions,
      enabled_actions,
      filters,
			hiddenColumns: ref([
				//'_id',
			]),
			tabs: {
				info: "Infos",//custom
				notable: true,//preMade
				timeData: true,//preMade
			},
			displayDate(e) {
				return e && moment.utc(e).local().format('D MMM YY - HH:mm')
			}
		};
	},
  methods:{
    getResellerName(_id){
      if(!_id){
        return '-';
      }
      if(this.resellers&&this.resellers[_id]){
        return this.resellers[_id];
      }

      try {
        const user = _GET.get('reseller', _id).then((response) => {
          this.resellers[_id] = response.customer_name;
          return this.resellers[_id];
        });
      }catch(e){
        this.resellers[_id] = '-';
      }
      return this.resellers[_id];
    },
    getClientName(_id){
      if(!_id){
        return '-';
      }
      if(this.clients&&this.clients[_id]){
        return this.clients[_id];
      }
      try {
        const user = _GET.get('client', _id).then((response) => {
          this.clients[_id] = response.name;
          return this.clients[_id];
        });
      }catch(e){
        this.clients[_id] = '-';
      }
      return this.clients[_id];
    },
    getSingleQuoteStatus(sku){
      if(!this.quotestatus){
        return '-';
      }
      let status = this.quotestatus.filter(item => item.sku==sku);
      if(status.length==1){
        return status[0].description_fr;
      }
      return '-';
    },
    getSingleQuoteStatusColor(sku){
      if(!this.quotestatus){
        return '#efefef';
      }
      let status = this.quotestatus.filter(item => item.sku==sku);
      console.log(sku);
      console.log(this.quotestatus);
      if(status.length==1){
        return '#'+status[0].color;
      }
      return '#efefef';
    },
    getQuoteStatus(){
      const quotestatus = this.settings.filter(item => Object.keys(item).includes('quotestatus'));
      console.log(quotestatus);
      if(quotestatus.length==1){
        this.quotestatus = quotestatus[0]['quotestatus'];
      }
    },
    async getSettings(){
      await _GET.all('setting').then(response => {
        this.settings = response;
      });
      this.getQuoteStatus();
    },
  },
  mounted() {
    this.getSettings();
  }
}
</script>

<style scoped>
.statut-expired{
  background-color: #ffcdd2;
  color: #c63737;
}
.statut-no-expiration{
  background-color: #ffd8b2;
  color: #805b36;
}
.statut-sent{
  background-color: #c8e6c9;
  color: #256029;
}
.statut-active{
  background-color: #b3e5fc;
  color: #23547b;
}
</style>
