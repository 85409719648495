<template>
  <div class="p-p-2 p-shadow-5 p-d-flex p-flex-column" style="min-height: 100%">
    <Toolbar class="p-mb-4">
      <template #left>
        <slot name="toolbar-left" v-bind="toolbar"></slot>
      </template>

      <template #right>
        <slot name="toolbar-right" v-bind="toolbar"></slot>
        <Button type="button" icon="pi pi-cog" @click="toggleSetting"/>

        <OverlayPanel ref="op">
          <MultiSelect v-model="selectedColumn" :options="columns" optionLabel="brand" placeholder="Select Brands">
            <template #value>
              Colonne visible
            </template>
            <template #option="{option}">
              <component v-if="option.children?.header" :is="option.children?.header"/>
              {{ option.props?.header }}
            </template>
          </MultiSelect>
          <slot name="settings" v-bind="toolbar"></slot>
        </OverlayPanel>
      </template>
    </Toolbar>

    <div class="p-p-2 p-d-flex" style="height: 100%;flex-grow: 1">
      <div style="flex-grow: 1;">
        <DataTable v-bind="opt"
                   :value="datatable.items"
                   v-model:filters="datatable.filters"
                   v-model:first="datatable.args.first"
                   v-model:rows="datatable.args.rows"
                   :reorderableColumns="true"
                   :totalRecords="datatable.totalCount"
                   @sort="datatable.onSort($event)"
                   :loading="datatable.loading"
                   :globalFilterFields="column_filters"
                   filterDisplay="row"
        >
          <template #empty>
            {{ $t('empty_list') }}
          </template>
          <template #loading>
            {{ $t('loading_list') }}
          </template>

          <component v-for="col of selectedColumnOrdered" :is="col"/>

          <Column v-if="!hideActions">
            <template #header>
              Actions
              <Button icon="pi pi-refresh" class="p-button-rounded p-button-sm p-button-text" @click="datatable.refresh();"/>
            </template>
            <template #body="{data}">
              <actions-button :data="data" :actions="actions" :disabled_actions="disabled_actions" @cmd:edit="datatable.open(data._id)" @cmd:delete="destroy(data._id)"></actions-button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {CrudRepository} from "@radiumjs/client";
import {computed, defineComponent, reactive, ref, unref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useRadium} from "@radiumjs/vue";
import {useStore} from "@/store";
import ActionsButton from "./ActionsButton.vue";
import {FilterMatchMode,FilterOperator} from 'primevue/api';


export default {
  name: "TableLayout",
  components: {
    // TableLayout,
    // FormInput,
    // FormDialog,
    ActionsButton,
    DataTable: require('primevue/datatable').default,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    TabView: require('primevue/tabview').default,
    TabPanel: require('primevue/tabpanel').default,
    ProgressSpinner: require('primevue/progressspinner').default,
    MultiSelect: require('primevue/multiselect').default,
    OverlayPanel: require('primevue/overlaypanel').default
  },
  props: {
    actions: Array,
    hideActions: Boolean,
    disabled_actions: Array,
    service: String,
    datatableOpt: Object,
    singleRouteName: String,
    hiddenColumns: Array,
    filters: Object,
    openParams: Object,
    search: Boolean,
    column_filters: Array,
    first: Number,
    parent: String
  },
  data(){
    const store = useStore();
    const routeName = store.permissions.current();
    console.log('routeName : '+routeName);
    return {
      routeName
    };
  },
  setup(props, {slots, emit}) {
    const store = useStore();
    const radium = useRadium();
    const router = useRouter();
    const toast = useToast();
    const route = useRoute();
    const repo = new CrudRepository(radium, props.service)
    const error = ref(false);//TODO handle 404/500 and other
    const routeName = store.permissions.current();
    const hideActions = props.hideActions || false;

    const column_filters = props.column_filters || [];
    console.log('column_filters');
    console.log(column_filters);

    const hasSearch = () => {
      if(typeof(props.search)==='boolean'){
        return props.search;
      }
      return true;
    };

    //lists
    const total_args = {rows:1000,first:0};
    const default_args = {rows:20,first:0};
    //pagination par route
    if(localStorage.pagination){
      const paginations = JSON.parse(localStorage.pagination);
      if(Object.keys(paginations).includes(routeName)){
        const pagination = paginations[routeName];
        if (pagination.rows) {
          default_args.rows = parseInt(pagination.rows);
        }else{
          pagination.rows = default_args.rows;
        }
        if (pagination.first) {
          default_args.first = parseInt(pagination.first);
        }
      }
    }
    if(props.first){
      default_args.first = props.first;
    }

    const {totalCount, refresh: totalRefresh} = radium.listReactive(repo,total_args);
    totalRefresh().catch((e) => {
      toast.add({
        life: 5000,
        summary: "Une erreur est survenu",
        detail: e?.message,
        severity: "error"
      });
    });

    const {items, args, loading: lList, refresh, filters} = radium.listReactive(repo,default_args);
    Object.assign(filters.value,props.filters||{}); //merge with user selected filter
    if(props.parent){
      Object.assign(filters.value,{parent:{value:props.parent, matchMode: FilterMatchMode.EQUALS}});
    }

    refresh().catch((e) => {
      toast.add({
        life: 5000,
        summary: "Une erreur est survenu",
        detail: e?.message,
        severity: "error"
      });
    });


    //const f:PrimeFilters = {'global': {value: null, matchMode: FilterMatchMode.CONTAINS, operator:FilterOperator.OR}};
    //filters.value = {...filters,...f};
    console.log('filters');
    console.log(filters);

    watch(() => filters.value, () => {
      refresh().catch((e) => {
        toast.add({
          life: 5000,
          summary: "Une erreur est survenu",
          detail: e?.message,
          severity: "error"
        });
      });
    }, {
      deep: true,
    });
    const onSort = (e) => {
      //@ts-ignore //TODO why
      args.multiSortMeta = unref(e.multiSortMeta);
    };

    /*onActivated(() => {
      refresh().catch((e) => {
        toast.add({
          life: 5000,
          summary: "Une erreur est survenu",
          detail: JSON.stringify(e),
          severity: "error"
        });
      });
    });*/

    function open(_id, argSup = false) {
      if (props.singleRouteName) {
        const newRoute = {
          name: props.singleRouteName,
          params: {}
        };
        if (_id)
          Object.assign(newRoute.params, {id: _id});
        if (props.openParams)
          Object.assign(newRoute.params, props.openParams)
        if (argSup)
          Object.assign(newRoute.params, argSup)
        router.push(newRoute);
      } else
        throw new Error();
    }

    const destroy = async (_id) => {
      await repo.delete(_id);
      await refresh();
    };

    const columns = slots.default(reactive({
      totalCount, items, args, filters, refresh, onSort, open, loading: lList
    }));

    const _hiddenColumns = ref([]);

    const selectedColumn = computed({
      get() {
        return columns.filter(c => !(props.hiddenColumns || _hiddenColumns.value).includes((c.props?.columnKey || c.props?.field)));
      },
      set(cols) {
        _hiddenColumns.value = columns.filter(c => !cols.includes(c)).map(c => c.props?.columnKey || c.props?.field);
        emit('update:hiddenColumns', _hiddenColumns.value);
      }
    });
    const selectedColumnOrdered = computed(() => {
      return columns.filter(col => {
        for (const sel of selectedColumn.value) {
          if (col === sel)
            return true;
        }
        return false
      })
    });
    const op = ref();
    watch(selectedColumn, console.log);

    const search = (event) => {
      console.log('search');
      console.log(event);
      filters.value = event;
    }

    const datatable = reactive({
      totalCount, items, args, filters, refresh, onSort, open, loading: lList
    });

    return {
      hideActions,
      routeName,
      column_filters,
      hasSearch,
      search,
      destroy,
      datatable,
      toolbar: reactive({
        open
      }),
      opt: {
        rowHover: true,
        lazy: true,
        paginator: true,
        autoLayout: true,
        rowsPerPageOptions: [10, 20, 50],
        paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        currentPageReportTemplate: "{first} à {last} de {totalRecords} entrées",
        removableSort: true,
        sortMode: "multiple",
        responsiveLayout: "stack",
        filterDisplay: "menu",
        ...props.datatableOpt,
      },
      selectedColumnOrdered,
      selectedColumn,
      columns,
      op,
      toggleSetting($event) {
        op.value.toggle($event);
      }
    };
  },
  methods:{
    rows(val){
      const key = 'rows';
      let paginations = {}
      if(localStorage.pagination){
        paginations = JSON.parse(localStorage.pagination);
        if(Object.keys(paginations).includes(this.routeName)){
          paginations[this.routeName][key] = val;
        }else{
          paginations[this.routeName] = {}
          paginations[this.routeName][key] = val;
        }
        console.log(paginations[this.routeName]);
      }
      localStorage.pagination = JSON.stringify(paginations);
    },
    first(val){
      const key = 'first';
      let paginations = {}
      if(localStorage.pagination){
        paginations = JSON.parse(localStorage.pagination);
        if(Object.keys(paginations).includes(this.routeName)){
          paginations[this.routeName][key] = val;
        }else{
          paginations[this.routeName] = {}
          paginations[this.routeName][key] = val;
        }
        console.log(paginations[this.routeName]);
      }
      localStorage.pagination = JSON.stringify(paginations);
    }
  },
  watch: {
    'datatable.args.rows': 'rows',
    'datatable.args.first': 'first',
  }
}
</script>

<style scoped>
::v-deep(.p-datatable .p-column-filter-menu-button){
  display: none !important;
}
</style>
