<template>
  <div class="p-row p-inputtext">
    <div :class="$props.class?$props.class:'p-d-flex p-flex-column'">
      <div class="form-note p-inputtext">
        <div class="btns">
          <Button v-if="!new_note" @click="new_note = true" icon="pi pi-plus" :label="$t('add_note')" class="btn-add p-button-sm p-py-1 p-px-2"/>
          <Button v-else @click="new_note = false" icon="pi pi-times" class="btn-close p-button-sm p-button-icon"/>
        </div>

        <div v-if="new_note">
          <h4>{{ $t('new_note') }}</h4>
          <p>{{ $t('author') }} : {{ complete_name }}</p>
          <FormInput>
            <div class="p-d-flex p-justify-around">
              <Editor style="width: 100%" v-model="note" editorStyle="height: 50vh"/>
            </div>
          </FormInput>
          <div class="p-mt-2">
            <Button :label="$t('add')" @click="add"></Button>
          </div>
        </div>
      </div>

      <div class="notes p-mt-3 p-inputtext">
        <div class="note p-py-2" v-for="note in formatted_notes">
          <div class="p-d-flex p-justify-between">
            <div class="p-d-flex p-align-center">
              <strong class="p-mr-3">{{ note.created_by }}</strong><small class="p-text-light">{{ note.created_at }}</small>
            </div>
            <Button class="p-button-sm p-button-danger p-button-text" icon="pi pi-trash" @click="delete_note(note.original_created_at)"></Button>
          </div>
          <div v-html="note.html"></div>
        </div>
        <h5 v-if="formatted_notes.length===0" class="p-text-center">
          {{ $t('empty_note') }}
        </h5>
      </div>
    </div>
    <slot name="note"></slot>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import FormInput from "@/components/FormPrime/FormInput";
import {useStore} from "@/store/index.ts";
import {useConfirm} from "primevue/useconfirm";
import * as moment from "moment";
import {clean} from "@/helpers/html";

export default {
	name: "LmNotes",
	components: {
		FormInput,
    Author: require('@/components/Author/Author').default,
		Button: require('primevue/button').default,
		Editor: require('primevue/editor').default,
    Calendar: require('primevue/calendar').default,
    Dropdown: require('primevue/dropdown').default,
		ConfirmDialog: require('primevue/confirmdialog').default,
	},
	props: {
		"modelValue": Object,
    "class": String
	},
	setup(props, {emit}) {
		const confirm = useConfirm();
		const store = useStore();
		
		const new_note = ref(false);
		
		const complete_name = computed(() => {
      return '';
			//return store.users.detail.username;
		});
		const formatted_notes = computed(() => {
			if (!props.modelValue)
				return [];
			return props.modelValue.map((i) => {
				return {
          author: i.author || {},
					text: i.text,
					html: clean(i.text),
					created_by: i.created_by,
					original_created_at: i.created_at,
					created_at: i.created_at ? moment.utc(i.created_at).local().format("Do MMMM YYYY, h:mm a") : ""
				};
			});
		});
		
		const note = ref("");
		
		async function add() {
			const add_note = {
        author:{type:store.auth.user_info.value.key,id:store.auth.user_info.value.id,name:complete_name.value},
				text: note.value,
				created_by: complete_name.value,
				created_at: moment().utc().format()
			};
      console.log(store.users);
			
			const notes = props.modelValue ? [...props.modelValue] : [];
			notes.unshift(add_note);
			
			emit("update:modelValue", notes);
			
			note.value = '';
			new_note.value = false;
		}
		
		async function delete_note(created_at) {
			confirm.require({
				message: 'Êtes-vous certain de vouloir supprimer cet élément?',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					const notes = props.modelValue.filter((note) => {
						return note.created_at !== created_at;
					});
					emit("update:modelValue", notes);
				},
				reject: () => {
				}
			});
		}
		
		return {complete_name, note, formatted_notes, new_note, add, delete_note};
	},
  methods:{
    setAuthor(event){
      this.author.value = event;
    }
  }
}
</script>
<style lang="scss" scoped>
.form-note{
  position: relative;
}
.form-note .btn-close{
  position: absolute;
  right: 0.5em;
}
.notes {
	border-top: 1px solid #ced4da;
	max-height: 500px;
	overflow: auto;
}

.note {
	border-bottom: 1px solid #ced4da;
	position: relative;
}

.note > > > img {
	max-width: 100%;
}

.delete {
	position: absolute;
	top: 10px;
	right: 0;
}

::v-deep(.p-scrollpanel) {
	.p-scrollpanel-wrapper {
		border-right: 9px solid var(--surface-b);
	}
	
	.p-scrollpanel-bar {
		background-color: var(--primary-color);
		opacity: 1;
		transition: background-color .2s;
		
		&:hover {
			background-color: #007ad9;
		}
	}
}
</style>
