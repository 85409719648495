<template>
	<div class="p-d-flex">
    <Dropdown v-model="selected"
              :options="options"
              optionLabel="name"
              optionValue="unit"
    />
	</div>
</template>

<script>
import {computed} from "vue";
import {getDefaultMeasureUnit,setMeasureUnit} from '../../helpers/mesures.js';
import {useI18n} from "vue-i18n";

export default {
	name: "LengthUnits",
	components: {
		Dropdown: require('primevue/dropdown').default
	},
	props: ["min", "max", "modelValue"],
  data(){},
	setup(props, {emit}) {
    const i18n = useI18n();

    const options = [
      {name:i18n.t('unit_ftin'),unit:'ftin'},
      {name:i18n.t('unit_ft'),unit:'ft'},
      {name:i18n.t('unit_in'),unit:'in'}
    ];

    const selected = computed({
      get() {
        console.log('localStorage.unit');
        console.log(localStorage.unit);
        return props.modelValue || localStorage.unit;
      },
      set(val) {
        localStorage.unit = val;
        emit("update:modelValue", val);
      }
    });
		
		return {selected,options};
	}
}
</script>

<style scoped>

</style>
