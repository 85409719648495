<template>
	<SingleLayout service="resellerContact" :schema="schema" :return-on-save="true">
		<template #toolbar-left="{id}">
			<h1 style="margin: auto">{{$tc('contact',1)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$t('information')}}</span>
				</template>
				
				<template #info>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.name" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.name" @change="update('$set','name',$event.target.value)" :class="classes"/>
									<label>{{$t('nom')}}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.main_email" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="fal fa-envelope"></i>
									<InputText type="email" v-model="item.main_email" @change="update('$set','main_email',$event.target.value)" :class="classes"/>
									<label>{{$t('main_email')}}</label>
								</span>
							</FormInput>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.main_phone" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.main_phone" @change="update('$set','main_phone',$event.target.value)" :class="classes"/>
									<label>{{$t('telephone')}}</label>
								</span>
							</FormInput>
						</div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
                  <Dropdown v-model="item.shipping"
                            @change="update('$set','shipping',$event.value)"
                            :options="resellerShippings"
                            optionLabel="shipto_location_name"
                            optionValue="_id"
                            :class="classes"/>
									<label>{{$tc('adresse',1)}}</label>
								</span>
              </FormInput>
            </div>
            <div class="p-field p-col-6">
              <FormInput class="p-mt-3" :errors="errors?.categorie" v-slot="classes">
                <span class="p-float-label">
                  <Dropdown v-model="item.categorie"
                            :options="contactCategories"
                            optionLabel="name_fr"
                            optionValue="_id"
                            id="contactCategoriesDropdown"
                            :show-clear="true"
                            @change="update('$set','categorie',$event.value)"
                  />
                  <label>{{$tc('departement',1)}}</label>
                </span>
              </FormInput>
            </div>
					</div>
				
				</template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {provinces} from "@/helpers/provinces";
import {useRoute} from "vue-router";
import {useStore} from "@/store";
import {_GET} from "../../helpers/get";
import {FilterMatchMode,FilterOperator} from 'primevue/api';


export default {
	name: 'EditResellerContact',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
		InputMask: require('primevue/inputmask').default,
	},
  data(){
    return {
      resellerShippings: [],
      contactCategories: []
    }
  },
	setup(props, {emit}) {
    const store = useStore();
		const route = useRoute();
    _GET.setProvider(store.radium);

    const filters = {
      parent: {value:route.params.reseller,matchMode: FilterMatchMode.EQUALS}
    };


		const statusOptions = ref([
			{
				name: "Actif",
				code: 1,
				color: '#198754'
			},
			{
				name: "Inactif",
				code: -1,
				color: '#d20000',
			}
		]);
		return {
      filters,
			provinces,
			schema: {
				name: {type: "string", min: 3, max: 255},
				main_phone: {type: "string", optional: true},
				main_email: {type: "email", optional: true},
        shipping: {type: "string", optional: true},
        categorie: {type: "string", optional: true},
				parent: {type: "string", default: route.params.reseller}
			},
			tabs: {
				info: "Infos",
				user: true,
				notable: true
			},
			statusOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = {name: v.name, color: v.color};
					return a
				}, {});
			}),

			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
		}
	},
  methods:{
    async getResellerShipping(){
      this.resellerShippings = await _GET.all('resellerShipping',{},this.filters);
    },
    async getContactCategories(){
      this.contactCategories = await _GET.all('resellerContactCategories');
    }
  },
  mounted() {
    this.getResellerShipping();
    this.getContactCategories();
  }
}
</script>
