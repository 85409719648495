import {App, inject} from "vue";
import {Router} from "vue-router";
import {Users} from "./users";
import {Auth} from "./auth";
import {Permissions} from "./permissions";
import {Dashboard} from "@/store/dashboard";
import moment from "moment";
import {RadiumVue} from "@radiumjs/vue";
import {Session} from "@/store/session";


const injectKey = "mySuperStore123";

export class Store {
    readonly router: Router;
    
    readonly radium: RadiumVue;
    
    readonly dashboard: Dashboard;
    readonly users: Users;
    readonly auth: Auth;
    readonly permissions: Permissions;
    readonly session: Session;
    
    readonly settings = {
        lang: navigator.language
    };
    
    constructor(router: Router, org: string) {
        let api_url = getCookie("api_url");
        
        if (process.env.NODE_ENV == 'production') {
            if (!api_url)
                api_url = location.origin;
        } else {
            api_url = prompt("Enter server API URL:", api_url) ?? api_url;
            setCookie("api_url", api_url, 60 * 60 * 24 * 256);
        }
        
        this.radium = new RadiumVue("", api_url);
        this.router = router;
        
        this.dashboard = new Dashboard(this);
        this.users = new Users(this);
        this.auth = new Auth(this);
        this.permissions = new Permissions(this);
        this.session = new Session(this);
        
        moment.locale(this.settings.lang)
        
        //TODO read settings from localStorage and when logged from user settings
    }
    
    install(app: App) {
        app.provide(injectKey, this);
        app.config.globalProperties.$store = this;
    }
}

export function useStore(): Store {
    return <Store>inject(injectKey);
}

function setCookie(cname, cvalue, exSec) {
    const d = new Date();
    d.setTime(d.getTime() + (exSec * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}
