<template>
	<TabView>
		<TabPanel v-for="(tabName,tabKey) in tabs" :key="tabKey">
			<template #header>
				<slot :name="tabKey+'-header'">
					<template v-if="tabKey==='user'">
						<i class="pi pi-user p-pr-1"></i>
						<span>{{$t('access')}}</span>
					</template>
					<template v-else-if="tabKey==='notable'">
						<i class="pi pi-book p-pr-1"></i>
						<span>{{$tc('note',2)}}</span>
					</template>
					<template v-else-if="tabKey==='timeData'">
						<i class="pi pi-clock p-pr-1"></i>
						<span>{{$t('timer')}}</span>
					</template>
					<span v-else>{{ tabName }}</span>
				</slot>
			</template>
			<slot :name="tabKey">
				<lm-user v-model="item" v-if="tabKey==='user'" @change="$emit('update', $event)">
          <template #userfields>
            <slot name="userfields"></slot>
          </template>
        </lm-user>
				<lm-notes v-model="item.lm_notes" v-if="tabKey==='notable'" @update:modelValue="$emit('update', ['$set', 'lm_notes', $event])" :class="$props.noteClass">
          <template #note>
            <slot name="note"></slot>
          </template>
        </lm-notes>
				<TimeData :item="item" v-if="tabKey==='timeData'"/>
			</slot>
		</TabPanel>
	</TabView>
	
	<Toolbar class="p-mt-4">
		<template #left>
			<slot name="toolbar-left"></slot>
		</template>
		
		<template #right>
			<Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="$emit('save')" autofocus/>
		</template>
	</Toolbar>
</template>

<script>
import Validator from "fastest-validator";
import TimeData from "@/components/FormPrime/TimeData";
import LmNotes from "@/components/Lm-Notes/Lm-Notes";
import LmUser from "@/components/FormPrime/LmUser";
import {useI18n} from "vue-i18n";




export default {
	name: "FormRadium",
	props: {
		tabs: Object,
		item: Object,
		errors: Object,
    noteClass: String
	},
	emits: ["save", "update"],
	components: {
		LmUser,
		LmNotes,
		TimeData,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		TabView: require('primevue/tabview').default,
		TabPanel: require('primevue/tabpanel').default,
		Toolbar: require('primevue/toolbar').default,
		ProgressSpinner: require('primevue/progressspinner').default,
	},
	setup(props, {emit, slots}) {
    /*const i18n = useI18n();
    let v = new Validator({
      messages: {
        stringMin: i18n.t('message_form_min'),
        string: i18n.t('message_form_fill'),
        number: i18n.t('message_form_number')
      }
    });
    return {v};*/
    return {};
	}
}
</script>

<style scoped>

</style>
