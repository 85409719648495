import {Store} from './index';

export class Users {
    
    private store: Store;
    public detail: {};
    
    constructor(store: Store) {
        this.store = store;
    }

    get(f){
        if(this.detail[f]){
            return this.detail[f];
        }
        return '';
    }
}
