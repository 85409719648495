<template>
  <TableLayout service="userCategories" single-route-name="users.categories.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
    <template #toolbar-left>
      <h1 style="margin: auto">{{$tc('user_categorie',2)}}</h1>
    </template>
    <template #toolbar-right="{open}">
      <Button :label="$t('add')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.add()"/>
      <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'users.list'})" autofocus/>
    </template>


    <template #default="{open}">
      <Column field="name" sortable>
        <template #header>
            {{$t('nom')}}
        </template>
        <template #body="{data}">
          {{ _t('name',data) }}
        </template>
      </Column>
    </template>
  </TableLayout>
</template>

<script>
import FormRadium from "@/components/FormPrime/FormRadium";
import FormInput from "@/components/FormPrime/FormInput";
import FormDialog from "@/components/FormPrime/FormDialog";
import {CrudRepository} from "@radiumjs/client";
import {translatedString} from "../../i18n";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
  name: 'UserCategories',
  components: {
    FormRadium,
    TableLayout,
    FormInput,
    FormDialog,
    DataTable: require('primevue/datatable').default,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
  },
  data(){
    return {
      column_filters: {}
    };
  },
  setup(props, {emit}) {
    const enabled_actions = [];
    const actions = [];
    let filters = {};
    return {
      enabled_actions,
      actions,
      filters
    };
  },
  methods:{
    _t(f,n){
      return translatedString(f,n);
    }
  }
}
</script>
