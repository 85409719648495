<template>
	<SingleLayout service="productCategories" :schema="schema"  :return-on-save="true">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('product_categorie',2)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{$t('information')}}</span>
				</template>
				<template #info>
					<div class="p-field p-mt-4 p-col-12">
						<div class="p-float-label">
							<InputText id="name" type="text" v-model="item.name" @change="update('$set','name',$event.target.value)"/>
							<label for="name">{{$t('nom')}}</label>
						</div>
					</div>
				</template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";

export default {
	name: 'EditProductCategorie',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
	setup(props, {emit}) {
		
		return {
			tabs: {
				info: true
			}
		};
	}
}
</script>
