import {createI18n} from "vue-i18n";
import { languages, defaultLocale } from './locales/index.js';
import {reactive} from "vue";

const messages = Object.assign(languages);

const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "fr",
    fallbackLocale: "en",
    messages: messages
});

export default i18n;

export function translatedString(key,parent){
    const l = i18n.global.locale;
    if(parent&&parent[key+'_'+l]){
        return parent[key+'_'+l];
    }else if(parent&&parent[key]){
        return parent[key];
    }
    return '';
}
export function translatedFieldName(key){
    const l = i18n.global.locale;
    return key+'_'+l;
}
export function getLocale(){
    return i18n.global.locale;
}
export function switchLocale(){
    console.log('switchLocale from '+getLocale());
    if(getLocale()=='fr'){
        i18n.global.locale = 'en';
    }else if(getLocale()=='en'){
        i18n.global.locale = 'fr';
    }
}

export function $t(str){
    return i18n.global.tc(str);
}
export function $tc(str,index){
    return i18n.global.tc(str,index);
}