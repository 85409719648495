<template>
  <Toolbar class="p-mb-4">
    <template #left>
      <h1 style="margin: auto">{{ $t('termes_conditions') }}</h1>
    </template>
    <template #right>
      <Button class="p-button-success p-mr-2" :label="$t('save')" icon="pi pi-save" @click="save" autofocus v-if="needUpdate()"/>
      <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus v-if="!needUpdate()"/>
    </template>
  </Toolbar>
  <DataTable :value="items" v-if="items.length">
    <Column field="label" header="Fichier">
      <template #body="{data}">
        <InputText id="realname" type="hidden" v-model="data.realname"/>
        {{ data.realname }}
      </template>
    </Column>
    <Column field="titre_fr">
      <template #body="{data,index}">
        <div class="p-row"><InputText class="w-100" type="text" v-model="data.titre_fr" placeholder="Titre (fr)" @change="updateItem(index)" /></div>
      </template>
    </Column>
    <Column field="titre_en">
      <template #body="{data,index}">
        <div class="p-row"><InputText class="w-100" type="text" v-model="data.titre_en" placeholder="Titre (en)" @change="updateItem(index)" /></div>
      </template>
    </Column>
    <Column>
      <template #body="{data,index}">
        <span class="p-button p-button-danger p-d-inline" @click="removeItem(index)"><i class="pi pi-trash"></i></span>
      </template>
    </Column>
  </DataTable>
  <Upload upload_dir="settings" @update:modelValue="addFiles($event)"></Upload>
  <Toolbar class="p-mt-4">
    <template #left>
      <slot name="toolbar-left"></slot>
    </template>

    <template #right>
      <Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="save" autofocus v-if="needUpdate()"/>
    </template>
  </Toolbar>
</template>

<script>
import SkuSelector from "@/components/Sku/SkuSelector";
import {useStore} from "@/store";
import {useConfirm} from "primevue/useconfirm";
import {SingleLayout, useRadium} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import FormInput from "@/components/FormPrime/FormInput";
import {CrudRepository} from "@radiumjs/client";
import Calendar from 'primevue/calendar';
import moment from 'moment';
import Upload from "@/components/Upload/Upload.vue";
import {computed} from "vue";
import {_GET} from "../../helpers/get";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";


export default {
  name: "Termes",
  components: {
    FormInput,
    SkuSelector,
    SingleLayout,
    FormRadium,
    moment,
    Upload,
    DataTable: require("primevue/datatable").default,
    DataView: require("primevue/dataview").default,
    Galleria: require("primevue/galleria").default,
    DataViewLayoutOptions: require("primevue/dataviewlayoutoptions").default,
    Column: require("primevue/column").default,
    Steps: require("primevue/steps").default,
    Dropdown: require("primevue/dropdown").default,
    Toolbar: require("primevue/toolbar").default,
    Rating: require("primevue/rating").default,
    Button: require("primevue/button").default,
    Sidebar: require("primevue/sidebar").default,
    InputText: require("primevue/inputtext").default,
    Calendar,
    _GET
  },
  props:{
    setting: String
  },
  data(){
    return {
      items: [],
      _id: null,
      hasChanged: false,
    };
  },
  emits: ["update", "save"],
  setup(props, {emit, slots}) {
    const radium = useStore().radium;
    _GET.setProvider(radium);

    return {};
  },
  methods:{
    async getItem(){
      const results = await _GET.all('setting').then(response => {return response;});
      const id = 'termes';
      let items = [];
      for (let i = 0; i < results.length; i++) {
        if(Object.keys(results[i]).includes(id)){
          items = results[i][id];
          this.id = results[i]['_id'];
          break;
        }
      }
      console.log(id);
      console.log(items);
      if(items.length>0){
        this.items = items;
      }
      return this.items;
    },
    updateItem(index){
      console.log(this.items);
      this.hasChanged = true;
    },
    removeItem(index){
      this.items.splice(index,1);
      this.hasChanged = true;
    },
    needUpdate(){
      return this.hasChanged;
    },
    save(){
      if(this.id){
        _GET.update('setting',this.id,'termes',this.items)
      }else{
        _GET.create('setting','termes',this.items);
      }
      this.hasChanged = false;
    },
    addFiles(event){
      for(let i=0;i<event.length;i++){
        let v = event[i];
        this.items.push(v);
      }
      this.hasChanged = true;
    }
  },
  mounted() {
    this.getItem();
  }
}
</script>

<style lang="scss" scoped>

</style>

