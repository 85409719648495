import {Store} from "@/store/index";
import {CssGrid} from "@/helpers/grid";

export class Dashboard {
    readonly widgetsType = {
        MenuButton: require('@/components/Dashboard/widget/MenuButton.vue'),
        Graph: require('@/components/Dashboard/widget/Graph.vue'),
    }
    
    readonly grid: CssGrid<any>
    
    constructor(store: Store) {
        const data = localStorage.getItem("dash");
        if (data) {
            try {
                this.grid = new CssGrid<any>({data: data});
            } catch (e) {
                console.error(e);
            }
        }
        if (!this.grid)
            this.grid = new CssGrid<any>({width: 12});
    }
    
    saveDash() {
        const data = this.grid.serialize();
        localStorage.setItem("dash", JSON.stringify(data));
    }
}

