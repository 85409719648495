import moment from 'moment';

export class _DATE {

	constructor() {}

	static format(date,today=false){
		if (date) {
			console.log(date);
			let new_date = moment(String(date)).format('YYYY-MM-DD');
			console.log(new_date);
			return new_date;
		}
		if (today) {
			return moment().format('YYYY-MM-DD');
		}
	}
}

export function dateFormat(date,today=false){
	return _DATE.format(date,today);
}