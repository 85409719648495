export default {
  "distributeur": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reseller"]), _normalize(["Resellers"])])},
  "client": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Customer"]), _normalize(["Customers"])])},
  "adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "adresses": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Address"]), _normalize(["Addresses"])])},
  "adresse_ligne_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
  "adresse_ligne_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])},
  "adresse_ligne_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 3"])},
  "adresse_ligne_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 4"])},
  "ville": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["City"]), _normalize(["Cities"])])},
  "province": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Province"]), _normalize(["Provinces"])])},
  "code_postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
  "expired_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid till"])},
  "type_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote type"])},
  "types_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote types"])},
  "statuts_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote statuses"])},
  "statut_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote status"])},
  "nom_cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS contact name"])},
  "entreprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "escompte": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Discount"]), _normalize(["Discounts"])])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing email"])},
  "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "billing_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use for billing"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
  "shipping_address": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Shipping address"]), _normalize(["Shipping addresses"])])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "main_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main email"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "statut": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Status"]), _normalize(["Statuses"])])},
  "account_nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account #"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation email"])},
  "invoice_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing email"])},
  "nom_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact name"])},
  "nom_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat Tech rep name"])},
  "nom_projet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])},
  "delai_production_jour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production delay (day)"])},
  "delai_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production delay"])},
  "soumission_nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote number"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
  "document": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Document"]), _normalize(["Documents"])])},
  "file": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["File"]), _normalize(["Files"])])},
  "add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a file"])},
  "file_toupload": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["File to upload"]), _normalize(["Files to upload"])])},
  "error_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred while loading."])},
  "confirm_remove_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the file?"])},
  "confirm_remove_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this item?"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "weblink": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["URL"]), _normalize(["URLs"])])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "add_to_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to quote"])},
  "submission": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Quote"]), _normalize(["Quotes"])])},
  "tps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPS"])},
  "tvq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVQ"])},
  "tvh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVH"])},
  "poids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "msrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSRP"])},
  "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
  "option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Option"]), _normalize(["Options"])])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "color": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Color"]), _normalize(["Colors"])])},
  "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Product"]), _normalize(["Products"])])},
  "product_option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Product option"]), _normalize(["Product options"])])},
  "product_option_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update product option"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizes"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "choose_corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose corners"])},
  "choose_sides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose sides"])},
  "sketch": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Draft"]), _normalize(["Drafts"])])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "shipping_addons": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Accessory fee"]), _normalize(["Accessory fees"])])},
  "shipping_carrier": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Carrier"]), _normalize(["Carriers"])])},
  "message_need_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must set a contact name and a phone number."])},
  "label": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Label"]), _normalize(["Labels"])])},
  "lb": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["lb"]), _normalize(["lbs"])])},
  "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
  "unit_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inches"])},
  "unit_ftin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feet/inches"])},
  "unit_ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feet"])},
  "menu_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "add_empty_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add empty product"])},
  "empty_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty product"])},
  "po": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inches"])},
  "price_wdiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price after discount"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
  "submission_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote for"])},
  "client_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client type"])},
  "message_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount must be between 0 and 100 separated by '/'. ie: 40/15"])},
  "message_note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a note"])},
  "message_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products added to quote"])},
  "message_form_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the errors in the form."])},
  "message_form_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Field ", _interpolate(_named("field")), " must be at least ", _interpolate(_named("expected")), " characters long"])},
  "message_form_fill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check this field"])},
  "message_form_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be of numeric type (no comma, but a point)"])},
  "message_success_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change made successfully!"])},
  "message_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email or password."])},
  "message_need_saving": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must save before you can select one or more ", _interpolate(_named("type")), "."])},
  "message_need_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a customer."])},
  "message_need_reseller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a distributor."])},
  "message_need_pos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a branch."])},
  "message_select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an .xlsx file."])},
  "notify_escompte_overwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual discount. Priority over global discount."])},
  "notify_price_overwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom price."])},
  "notify_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each tag must be separated by a space."])},
  "notify_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid values"])},
  "notify_delai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder (day)"])},
  "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["Leads"])])},
  "event": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Event"]), _normalize(["Events"])])},
  "administrator": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrator"]), _normalize(["Administrators"])])},
  "myaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
  "setting": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Setting"]), _normalize(["Settings"])])},
  "taxe": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tax"]), _normalize(["Taxes"])])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "save_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving completed!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "note": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Note"]), _normalize(["Notes"])])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
  "coordonnees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address details"])},
  "actif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "inactif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "product_categorie": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Product category"]), _normalize(["Product categories"])])},
  "new_categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category"])},
  "back_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to products"])},
  "new_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New option"])},
  "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["Users"])])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify a user"])},
  "choix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Choices -"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "seller": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Seller"]), _normalize(["Sellers"])])},
  "employe": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employee"]), _normalize(["Employees"])])},
  "action_undoable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is irreversible."])},
  "contact": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact"]), _normalize(["Contacts"])])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "reset_my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset my password"])},
  "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Sent"])},
  "sub_option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sub-option"]), _normalize(["Sub-options"])])},
  "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable"])},
  "add_sub_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a sub-option"])},
  "notice_sub_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must check the Sub-option box in the Information tab to be able to configure sub-options."])},
  "optiontype_contour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat side"])},
  "optiontype_coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat corner"])},
  "optiontype_unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per mat (unit)"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francais"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
  "msrp_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit msrp"])},
  "linear_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear price"])},
  "msrp_linear_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear msrp"])},
  "many_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On how many sides"])},
  "many_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On how many ends"])},
  "many_corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On how many corners"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "duplicated_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicated product"])},
  "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reason"]), _normalize(["Reasons"])])},
  "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
  "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad"])},
  "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
  "awaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "edit_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change my password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "vendor": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vendor"]), _normalize(["Vendors"])])},
  "menu_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu_distributeur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resellers"])},
  "menu_distributeur_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a reseller"])},
  "menu_distributeur_contact_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of contacts"])},
  "menu_distributeur_contact_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a contact"])},
  "menu_distributeur_contact_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete a contact"])},
  "menu_distributeur_contact_category_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of departments"])},
  "menu_distributeur_contact_category_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a department"])},
  "menu_distributeur_shipping_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of addresses"])},
  "menu_distributeur_shipping_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit an address"])},
  "menu_distributeur_shipping_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete an address"])},
  "menu_distributeur_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify a reseller"])},
  "menu_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "menu_product_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
  "menu_product_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "menu_product_option_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product Options"])},
  "menu_product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "menu_product_category_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product categories"])},
  "menu_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "menu_lead_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Lead"])},
  "menu_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "menu_event_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Event"])},
  "menu_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submissions"])},
  "menu_submission_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Submission"])},
  "menu_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "menu_user_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])},
  "menu_user_categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])},
  "menu_user_categorie_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a department"])},
  "menu_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
  "menu_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "menu_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "menu_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "menu_setting_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "menu_taxe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
  "menu_taxe_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit taxes"])},
  "menu_shipping_addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping - Addons Charges"])},
  "menu_shipping_addons_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Addons"])},
  "menu_shipping_carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping - Carriers"])},
  "menu_shipping_carrier_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Carriers"])},
  "menu_mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
  "menu_mailchimp_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing Lists"])},
  "menu_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "menu_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "menu_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "menu_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "menu_setting_invoicemessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages for submissions"])},
  "menu_options_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Option"])},
  "menu_categories_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Category"])},
  "menu_contact_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Contact"])},
  "menu_shipping_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address"])},
  "menu_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "billed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill to"])},
  "shipped_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship to"])},
  "send_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Submission"])},
  "invoice_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Message"])},
  "category": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Category"]), _normalize(["Categories"])])},
  "departement": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Department"]), _normalize(["Departments"])])},
  "user_categorie": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Department"]), _normalize(["Departments"])])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "import_product_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Update"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weeks"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "tag": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag"]), _normalize(["Tags"])])},
  "termes_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
  "user_log": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Visit"]), _normalize(["Visits"])])},
  "user_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "available_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for Mat Tech"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not available"])},
  "image": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Image"]), _normalize(["Images"])])},
  "messages_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select automatic messages"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent on"])},
  "notification_will_be_send_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reminder will be sent on"])},
  "notification_sent_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reminder was sent on"])},
  "notification_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while calling back."])},
  "loading_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading. Please wait."])},
  "empty_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items."])},
  "submission_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "submission_no-expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No expiration"])},
  "submission_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "submission_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
  "select_adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an address"])},
  "image_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Type"])},
  "voir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
  "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrer"])},
  "parent_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrer Type"])},
  "enduser": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["End User"]), _normalize(["End Users"])])},
  "menu_enduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Users"])},
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "new_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New note"])},
  "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note"])},
  "empty_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No note"])},
  "type_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact type"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
  "general": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["General"]), _normalize(["Generals"])])},
  "generale": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["General"]), _normalize(["Generals"])])},
  "succursale": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Branch"]), _normalize(["Branches"])])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Type"]), _normalize(["Types"])])},
  "quote_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote regular"])},
  "quote_special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Quote"])},
  "quote_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price request"])}
}