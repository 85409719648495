<template>
  <Toolbar class="p-mb-4">
    <template #left>
      <h1 style="margin: auto">{{ $t('menu_setting_invoicemessages') }}</h1>
    </template>
    <template #right>
      <Button class="p-button-success p-mr-2" :label="$t('save')" icon="pi pi-save" @click="save" autofocus v-if="needUpdate()"/>
      <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus v-if="!needUpdate()"/>
    </template>
  </Toolbar>

  <div class="p-field mb-3" v-if="items.length">
    <div class="p-row p-inputtext">
  <DataTable :value="items">
    <Column field="label" header="Nom">
      <template #body="{data}">
        {{ data.name }}
      </template>
    </Column>
    <Column>
      <template #body="{data,index}">
        <span class="p-button p-button-success p-d-inline mr-1" @click="setUpdate(index)"><i class="pi pi-pencil"></i></span>
        <span class="p-button p-button-danger p-d-inline" @click="removeItem(index)"><i class="pi pi-trash"></i></span>
      </template>
    </Column>
  </DataTable>
    </div>
  </div>

  <div class="p-field">
    <div class="p-row p-inputtext form-add">
      <div class="p-row">
        <div class="col">
          <h4 class="p-text-normal p-mt-0 p-mb-2">Ajouter un message</h4>
        </div>
      </div>
      <div class="p-row">
      <div class="col">
        <InputText type="hidden" v-model="i"/>
        <InputText type="hidden" v-model="item.reference"/>
        <p class="p-text-normal p-mt-0 p-mb-2">Nom</p>
        <InputText type="text" v-model="item.name"/>
      </div>
    </div>
      <div class="p-row">
        <div class="col-12 col-lg-6">
          <FormInput class="p-mt-3" v-slot="classes">
            <p class="p-text-normal p-mt-0 p-mb-2">Message (fr)</p>
            <Editor v-model="item.message_fr" editorStyle="height: 320px"/>
          </FormInput>
        </div>
        <div class="col-12 col-lg-6">
          <FormInput class="p-mt-3" v-slot="classes">
            <p class="p-text-normal p-mt-0 p-mb-2">Message (en)</p>
            <Editor v-model="item.message_en" editorStyle="height: 320px"/>
          </FormInput>
        </div>
      </div>
        <div class="p-row mt-3">
          <div class="col"><Button class="p-px-3" :label="$t('add')" icon="pi pi-check" @click="updateItem()"/></div>
        </div>
    </div>
  </div>

  <Toolbar class="p-mt-4">
    <template #left>
      <slot name="toolbar-left"></slot>
    </template>

    <template #right>
      <Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="save" autofocus v-if="needUpdate()"/>
    </template>
  </Toolbar>
</template>

<script>
import SkuSelector from "@/components/Sku/SkuSelector";
import {useStore} from "@/store";
import {useConfirm} from "primevue/useconfirm";
import {SingleLayout, useRadium} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import FormInput from "@/components/FormPrime/FormInput";
import {CrudRepository} from "@radiumjs/client";
import Calendar from 'primevue/calendar';
import moment from 'moment';
import Upload from "@/components/Upload/Upload.vue";
import {computed} from "vue";
import {_GET} from "../../helpers/get";
import {useRoute} from "vue-router";
import {useToast} from "primevue/usetoast";


export default {
  name: "Termes",
  components: {
    FormInput,
    SkuSelector,
    SingleLayout,
    FormRadium,
    moment,
    Upload,
    DataTable: require("primevue/datatable").default,
    DataView: require("primevue/dataview").default,
    Galleria: require("primevue/galleria").default,
    DataViewLayoutOptions: require("primevue/dataviewlayoutoptions").default,
    Column: require("primevue/column").default,
    Steps: require("primevue/steps").default,
    Dropdown: require("primevue/dropdown").default,
    Toolbar: require("primevue/toolbar").default,
    Rating: require("primevue/rating").default,
    Button: require("primevue/button").default,
    Sidebar: require("primevue/sidebar").default,
    InputText: require("primevue/inputtext").default,
    Editor: require('primevue/editor').default,
    Calendar,
    _GET
  },
  props:{
    setting: String
  },
  data(){
    return {
      items: [],
      _id: null,
      hasChanged: false,
      i:null,
      item:{
        reference:'',
        message_fr:'',
        message_en:''
      }
    };
  },
  emits: ["update", "save"],
  setup(props, {emit, slots}) {
    const toast = useToast();
    const radium = useStore().radium;
    _GET.setProvider(radium);

    return {toast};
  },
  methods:{
    async getItem(){
      const results = await _GET.all('setting').then(response => {return response;});
      const id = 'invoicemessages';
      let items = [];
      for (let i = 0; i < results.length; i++) {
        if(Object.keys(results[i]).includes(id)){
          items = results[i][id];
          this.id = results[i]['_id'];
          break;
        }
      }
      console.log(id);
      console.log(items);
      if(items.length>0){
        this.items = items;
      }
      return this.items;
    },
    updateItem(){
      if(this.i===null){
        //new
        this.item.reference = this.items.length+1;
        this.items.push(this.item);
      }else{
        this.items[this.i] = this.item;
      }
      this.item = {};
      this.i = null;
      this.hasChanged = true;
    },
    removeItem(index){
      if(this.i!==null&&this.i===index){
        this.item = {};
        this.i = null;
      }
      this.items.splice(index,1);
      this.hasChanged = true;
    },
    needUpdate(){
      return this.hasChanged;
    },
    save(){
      let action = {};
      if(this.id){
        action = _GET.update('setting',this.id,'invoicemessages',this.items);
        if(action.modifiedCount){
          this.toast.add({
            life: 5000,
            summary: "Sauvegarde réussie",
            severity: "success"
          });
        }else{
          this.toast.add({
            life: 5000,
            summary: "Une erreur est survenu",
            severity: "error"
          });
        }
      }else{
        action = _GET.create('setting','invoicemessages',this.items);
        if(action.insertedId){
          this._id = action.insertedId;
          this.toast.add({
            life: 5000,
            summary: "Sauvegarde réussie",
            severity: "success"
          });
        }else{
          this.toast.add({
            life: 5000,
            summary: "Une erreur est survenu",
            severity: "error"
          });
        }
      }
      this.hasChanged = false;
    },
    setUpdate(index){
      this.item = this.items[index];
      this.i = index;
    }
  },
  mounted() {
    this.getItem();
  }
}
</script>

<style lang="scss" scoped>
.form-add{
  background-color: #f8f9fa;
}
</style>

