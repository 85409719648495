<template>
	<TableLayout service="event" single-route-name="events.edit" v-model:hidden-columns="hiddenColumns" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('event',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button label="Nouveau" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)"/>
		</template>
		
		<template #default="{open}">
			<Column field="title" sortable>
        <template #header>
          {{ $t('titre') }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>

      <Column field="start_date" sortable>
        <template #header>
          {{ $t('start_at') }}
        </template>
        <template #body="{data}">
          {{ formatDate(data.start_date) }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Calendar v-model="filterModel.value" dateFormat="yy-mm-dd" placeholder="yy-mm-dd" @date-select="filterCallback()" />
        </template>
      </Column>

			<Column field="due_date" sortable>
        <template #header>
          {{ $t('expired_at') }}
        </template>
        <template #body="{data}">
					{{ formatDate(data.due_date) }}
				</template>
			</Column>

      <Column field="status">
        <template #header>
          {{ $tc('statut',1) }}
        </template>
        <template #body="{data}">
          {{ statusMap[data.status] }}
        </template>
      </Column>
		</template>
	</TableLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import * as moment from "moment";
import {useStore} from "@/store";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
	name: 'Events',
	components: {
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
  data(){
    return {
      column_filters: {
        title: 'string',
        description: 'string',
        //start_date: 'date'
      }
    };
  },
	setup(props, {emit}) {
    const store = useStore();
    const enabled_actions = [];
    const own_filters = store.permissions.filters();

    let filters = {
      'title': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'start_date': {value: null, matchMode: FilterMatchMode.DATE_IS}
    };
    filters = {...filters,...own_filters};

    const actions = [];

    const statusOptions = ref([
			{
				name: "En attente",
				code: -1
			},
			{
				name: "Priorité",
				code: 2
			},
			{
				name: "Commencé",
				code: 1
			},
			{
				name: "Terminé",
				code: 0
			}
		]);
		return {
      actions,
      enabled_actions,
      filters,
			hiddenColumns: ref([
				'_id',
			]),
			schema: {
				status: {type: "number"},
				title: {type: "string", min: 3, max: 255},
				description: {type: "string", optional: true},
				//client_id: {type: "string", optional: true, default: useRoute().params.client_id},
				//assigned_users: {type: "array", optional: true},
				due_date: {type: "string", optional: true}
			},
			tabs: {
				info: "Infos",//custom
				notable: true,//preMade
				timeData: true,//preMade
			},
			statusOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = v.name;
					return a
				}, {});
			}),
			due_date_formatted: computed(() => {
				return moment.utc(this.item.due_date).local().toDate();
			}),
			formatDate(e) {
				return e && moment.utc(e).local().format('D MMM YY - HH:mm')
			}
		};
	}
}
</script>
