export default {
  "distributeur": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Distributeur"]), _normalize(["Distributeurs"])])},
  "client": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Client"]), _normalize(["Clients"])])},
  "adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "adresses": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adresse"]), _normalize(["Adresses"])])},
  "adresse_ligne_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 1"])},
  "adresse_ligne_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 2"])},
  "adresse_ligne_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 3"])},
  "adresse_ligne_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse ligne 4"])},
  "ville": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ville"]), _normalize(["Villes"])])},
  "province": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Province"]), _normalize(["Provinces"])])},
  "code_postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le"])},
  "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le"])},
  "expired_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide jusqu'au"])},
  "type_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de soumission"])},
  "types_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de soumission"])},
  "statuts_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuts de soumission"])},
  "statut_soumission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la soumission"])},
  "nom_cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la personne CS"])},
  "entreprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "escompte": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Escompte"]), _normalize(["Escomptes"])])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Web"])},
  "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de facturation"])},
  "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
  "billing_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser pour la facturation"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
  "shipping_address": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adresse de livraison"]), _normalize(["Adresses de livraison"])])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "main_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel principal"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "statut": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Statut"]), _normalize(["Statuts"])])},
  "account_nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de compte"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
  "confirmation_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de confirmation"])},
  "invoice_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de facturation"])},
  "nom_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du contact"])},
  "nom_rep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du représentant Mat Tech"])},
  "nom_projet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du projet"])},
  "delai_production_jour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de production (jour)"])},
  "delai_production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de production"])},
  "soumission_nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de soumission"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser"])},
  "upload_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléverser le fichier"])},
  "document": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Document"]), _normalize(["Documents"])])},
  "file": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fichier"]), _normalize(["Fichiers"])])},
  "add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un fichier"])},
  "file_toupload": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Fichier à téléverser"]), _normalize(["Fichiers à téléverser"])])},
  "error_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du chargement"])},
  "confirm_remove_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous supprimer ce fichier?"])},
  "confirm_remove_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous supprimer cet élément?"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "weblink": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lien Internet"]), _normalize(["Liens Internet"])])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien"])},
  "add_to_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à la soumission"])},
  "submission": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Soumission"]), _normalize(["Soumissions"])])},
  "tps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPS"])},
  "tvq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVQ"])},
  "tvh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVH"])},
  "poids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "msrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSRP"])},
  "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté"])},
  "option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Option"]), _normalize(["Options"])])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "color": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Couleur"]), _normalize(["Couleurs"])])},
  "product": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Produit"]), _normalize(["Produits"])])},
  "product_option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Option de produit"]), _normalize(["Option de produits"])])},
  "product_option_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'option de produit"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])},
  "choose_corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les coins applicables"])},
  "choose_sides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les côtés applicables"])},
  "sketch": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Esquisse"]), _normalize(["Esquisses"])])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "shipping_addons": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Frais accessoire"]), _normalize(["Frais accessoires"])])},
  "shipping_carrier": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Transporteur"]), _normalize(["Transporteurs"])])},
  "message_need_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez fournir un Nom de contact et un numéro de téléphone."])},
  "label": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Étiquette"]), _normalize(["Étiquettes"])])},
  "lb": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["lb"]), _normalize(["lbs"])])},
  "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
  "unit_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pouces"])},
  "unit_ftin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pieds/pouces"])},
  "unit_ft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pieds"])},
  "menu_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "add_empty_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un produit vierge"])},
  "empty_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit vierge"])},
  "po": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pouces"])},
  "price_wdiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix après escompte"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommaire"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])},
  "submission_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumission pour"])},
  "client_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de client"])},
  "message_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le discount doit être des nombres de 0 à 100 séparés par '/'. ex: 40/15"])},
  "message_note_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez une note"])},
  "message_product_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits ajouté à la soumission"])},
  "message_form_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier les erreurs dans le formulaire."])},
  "message_form_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ '", _interpolate(_named("field")), "' doit avoir au moins ", _interpolate(_named("expected")), " caractères de long"])},
  "message_form_fill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier ce champ"])},
  "message_form_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être de type numérique (pas de virgule, mais un point)"])},
  "message_success_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification effectuée avec succès!"])},
  "message_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel ou mot de passe."])},
  "message_need_saving": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez sauvegarder avant de pouvoir sélectionner un ou plusieurs ", _interpolate(_named("type")), "."])},
  "message_need_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un client."])},
  "message_need_reseller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un distributeur."])},
  "message_need_pos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner une succursale."])},
  "message_select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un fichier .xlsx."])},
  "notify_escompte_overwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escompte individuelle. Priorité sur l'escompte globale."])},
  "notify_price_overwrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix personnalisé."])},
  "notify_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque tag doit être séparé par un espace."])},
  "notify_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs invalides"])},
  "notify_delai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel (jour)"])},
  "lead": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Lead"]), _normalize(["Leads"])])},
  "event": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Événement"]), _normalize(["Événements"])])},
  "administrator": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Administrateur"]), _normalize(["Administrateurs"])])},
  "myaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
  "setting": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Configuration"]), _normalize(["Configurations"])])},
  "taxe": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Taxe"]), _normalize(["Taxes"])])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "save_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarde effectué!"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue!"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horodatage"])},
  "note": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Note"]), _normalize(["Notes"])])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
  "coordonnees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
  "actif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "inactif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "product_categorie": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Catégorie de produit"]), _normalize(["Catégories de produit"])])},
  "new_categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle catégorie"])},
  "back_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux produits"])},
  "new_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle option"])},
  "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilisateur"]), _normalize(["Utilisateurs"])])},
  "add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "edit_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un utilisateur"])},
  "choix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Choix -"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "seller": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vendeur"]), _normalize(["Vendeurs"])])},
  "employe": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employé"]), _normalize(["Employés"])])},
  "action_undoable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action est irréversible."])},
  "contact": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contact"]), _normalize(["Contacts"])])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
  "reset_my_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mon mot de passe"])},
  "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel Envoyé"])},
  "sub_option": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sous-option"]), _normalize(["Sous-options"])])},
  "applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable"])},
  "add_sub_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une sous-option"])},
  "notice_sub_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez cocher la case Sous-option dans l'onglet Information pour pouvoir configurer des sous-options."])},
  "optiontype_contour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contour du tapis"])},
  "optiontype_coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin du tapis"])},
  "optiontype_unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par tapis (unitaire)"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francais"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire"])},
  "msrp_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Msrp unitaire"])},
  "linear_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix linéaire"])},
  "msrp_linear_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Msrp linéaire"])},
  "many_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur combien de côté"])},
  "many_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur combien d'extrémité"])},
  "many_corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur combien de coin"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
  "duplicated_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit dupliqué"])},
  "reason": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Raison"]), _normalize(["Raisons"])])},
  "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon"])},
  "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais"])},
  "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
  "awaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
  "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencé"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublier?"])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregistrer"])},
  "edit_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "vendor": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Vendeur"]), _normalize(["Vendeurs"])])},
  "menu_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "menu_distributeur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeurs"])},
  "menu_distributeur_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un distributeur"])},
  "menu_distributeur_contact_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des contacts"])},
  "menu_distributeur_contact_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un contact"])},
  "menu_distributeur_contact_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un contact"])},
  "menu_distributeur_contact_category_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des départements"])},
  "menu_distributeur_contact_category_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un département"])},
  "menu_distributeur_shipping_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des adresses"])},
  "menu_distributeur_shipping_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une adresse"])},
  "menu_distributeur_shipping_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer une adresse"])},
  "menu_distributeur_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un distributeur"])},
  "menu_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
  "menu_product_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un produit"])},
  "menu_product_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "menu_product_option_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les options d'un produit"])},
  "menu_product_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
  "menu_product_category_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les catégories d'un produit"])},
  "menu_lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "menu_lead_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un lead"])},
  "menu_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
  "menu_event_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un événement"])},
  "menu_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumissions"])},
  "menu_submission_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un soumission"])},
  "menu_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "menu_user_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un utilisateur"])},
  "menu_user_categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départements"])},
  "menu_user_categorie_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un département"])},
  "menu_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateurs"])},
  "menu_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profile"])},
  "menu_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "menu_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "menu_setting_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générale"])},
  "menu_taxe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
  "menu_taxe_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les taxes"])},
  "menu_shipping_addons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison - Frais accessoires"])},
  "menu_shipping_addons_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les frais accessoires"])},
  "menu_shipping_carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison - Transporteurs"])},
  "menu_shipping_carrier_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les transporteurs"])},
  "menu_mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
  "menu_mailchimp_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes d'envoi"])},
  "menu_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
  "menu_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "menu_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "menu_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "menu_setting_invoicemessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages pour soumissions"])},
  "menu_options_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une option"])},
  "menu_categories_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une catégorie"])},
  "menu_contact_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])},
  "menu_shipping_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse"])},
  "menu_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importation"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "billed_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturer à"])},
  "shipped_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à"])},
  "send_submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la soumission"])},
  "invoice_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message du courriel"])},
  "category": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Catégorie"]), _normalize(["Catégories"])])},
  "departement": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Département"]), _normalize(["Départements"])])},
  "user_categorie": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Département"]), _normalize(["Départements"])])},
  "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "import_product_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise a jour des prix"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semaines"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soustotal"])},
  "tag": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tag"]), _normalize(["Tags"])])},
  "termes_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
  "user_log": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Visite"]), _normalize(["Visites"])])},
  "user_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
  "available_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible pour Mat Tech"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible"])},
  "image": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Image"]), _normalize(["Images"])])},
  "messages_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix des messages automatiques"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé le"])},
  "notification_will_be_send_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rappel sera envoyé le"])},
  "notification_sent_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rappel a été envoyé le"])},
  "notification_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du rappel."])},
  "loading_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours. Veuillez patienter."])},
  "empty_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élément."])},
  "submission_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expirée"])},
  "submission_no-expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune expiration"])},
  "submission_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "submission_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyée"])},
  "select_adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une adresse"])},
  "image_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'image"])},
  "voir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
  "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référent"])},
  "parent_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de référent"])},
  "enduser": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilisateur final"]), _normalize(["Utilisateurs finaux"])])},
  "menu_enduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs finaux"])},
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "new_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle note"])},
  "add_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une note"])},
  "empty_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note"])},
  "type_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contact"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provenance"])},
  "general": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Général"]), _normalize(["Généraux"])])},
  "generale": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Générale"]), _normalize(["Générales"])])},
  "succursale": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Succursale"]), _normalize(["Succursales"])])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])},
  "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Type"]), _normalize(["Types"])])},
  "quote_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote régulière"])},
  "quote_special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote spéciale"])},
  "quote_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de prix"])}
}