import {useStore} from "@/store";
import {_GET} from "@/helpers/get";
import {User} from "@/helpers/interface";

export class Permissions{

    user_id:null
    user:User
    permission_id:string
    private category: unknown;
    private permissions: any;

    constructor(){
        //const pr = this.getPermissions();
    }

    setUserId(){
        const store = useStore();
        console.log('user id :'+store.auth.user_info.value.id);
        if(store.auth.user_info.value.id){
            this.user_id = store.auth.user_info.value.id;
        }
    }

    async getUser(id){
        if(!this.user_id){
            this.setUserId();
        }
        if(this.user_id){
            this.user = await _GET.get('admin', this.user_id) as User;
        }
    }

    async getPermissions(){
        console.log('getPermissions');
        const store = useStore();
        if(!this.permission_id&&store.auth.user_info.value.key){
            //store.auth.user_info.value.permissions = [];
            let permission = '';
            if(store.auth.user_info.value.key=='vendor'){//distributeurs
                permission = '62e834f47785a463949c52e5';
            }else{
                console.log(store.auth.user_info.value);
                const user = await _GET.get('admin',store.auth.user_info.value.id).then(response => {return response;});
                console.log(user);
                if(user['categorie']){
                    permission = user['categorie'];
                }
            }
            this.permission_id = permission;
            this.category = await _GET.get('userCategories', this.permission_id);
            if(this.category&&this.category['permissions']){
                this.permissions = this.category['permissions'];
                store.auth.user_info.value.permissions = this.permissions;
                console.log(this.permissions);
            }
        }
    }

    hasPermission(perm){
        if(!this.permissions){
            this.getPermissions();
        }
        if(this.permissions){
            return this.permissions.includes(perm);
        }
        return false;
    }

}