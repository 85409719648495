import {CalendarOptions} from "@fullcalendar/core";
import moment from "moment";
import {CrudRepository, ListReturn, RadiumClient} from "@radiumjs/client";


interface EventRaw {
    _id: string
    //2021-08-31T11:57:33.896Z
    created_at: string
    creator: string
    //2021-08-31T11:57:33.896Z
    modified_at: string
    
    description?: string
    //2021-08-11T11:54:12.000Z
    start_date?: string
    //2021-08-11T11:54:12.000Z
    end_date?: string | false
    status: number
    title?: string,
    url?: string
}

interface EventInput {
    start: Date | string | number | number[];
    end?: Date | string | number | number[];
    date?: Date | string | number | number[];
    allDay?: boolean;
    id: string;
    groupId?: string;
    title: string;
    url?: string;
}

export class Events {
    private radium: RadiumClient;
    private crud: CrudRepository<EventRaw>;
    
    constructor(radium: RadiumClient) {
        this.radium = radium;
        this.crud = new CrudRepository(radium, "event");
    }
    
    fetch(): CalendarOptions['events'] {
        return async (
            arg: { start: Date, end: Date, startStr: string, endStr: string, timeZone: string },
            successCallback: (events: EventInput[]) => void,
            failureCallback: (error) => void
        ): Promise<EventInput[]> => {
            const result = await this.get(arg);//TODO if we dont get all result
            
            const eventsRaw = result.items;
            const events: EventInput[] = [];
            
            for (const eventRaw of eventsRaw) {
                const event: EventInput = {
                    start: moment(eventRaw.start_date).local().toDate(),
                    id: eventRaw._id,
                    title: eventRaw.title
                };
                
                if (eventRaw.end_date)//TODO endDate
                    event.end = moment(eventRaw.end_date).local().toDate();
                
                if (eventRaw.url)
                    event.url = eventRaw.url;
                else
                    event.url = "/event/edit/" + eventRaw._id
                
                events.push(event);
            }
            
            console.log(events);
            return events;
        }
    }
    
    async get(arg: { start: Date, end: Date, startStr: string, endStr: string, timeZone: string }, start: number = 0): Promise<ListReturn<EventRaw>> {
        return (await this.radium.api<ListReturn<EventRaw>>("event.list", {
            filter: {
                $or: [
                    {
                        start_date: {
                            $gte: moment(arg.start).toISOString(),
                            $lte: moment(arg.end).toISOString()
                        }
                    },
                    {
                        end_date: {
                            $gte: moment(arg.start).toISOString(),
                            $lte: moment(arg.end).toISOString()
                        }
                    }
                ]
            }
        })).data;
    }
}
