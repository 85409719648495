<template>
	<div class="widget">
		<div class="widget-header" v-if="edit" @mousedown="startDrag" @contextmenu="openMenu">
		
		</div>
		<div class="widget-body">
			<slot>
			
			</slot>
		</div>
		<ContextMenu ref="menuRef" :model="menuItems"/>
		<Dialog header="Header" v-model:visible="settingsDisplay">
			<component :is="settings" :args="cell.data.args" @update:args="$emit('update:args',$event)">
			</component>
		</Dialog>
	</div>
</template>

<script>
import {computed, ref, watchEffect} from "vue";

export default {
	name: "Widget",
	props: ['edit', 'cell', 'meta'],
	components: {
		Dialog: require('primevue/dialog').default,
		ContextMenu: require('primevue/contextmenu').default,
	},
	setup(props, {emit}) {
		const positions = {
			x: 0,
			y: 0,
			dx: 0,
			dy: 0,
			nx: 0,
			ny: 0,
		};
		
		const startDrag = (event) => {
			event.preventDefault()
			positions.x = event.clientX
			positions.y = event.clientY
			document.addEventListener('mousemove', elementDrag);
			document.addEventListener('mouseup', endDrag);
		}
		
		const elementDrag = (event) => {
			event.preventDefault()
			positions.dx = positions.x - event.clientX
			positions.dy = positions.y - event.clientY
			positions.nx = event.clientX
			positions.ny = event.clientY
			emit("dragged", positions);
		}
		
		const endDrag = () => {
			document.removeEventListener('mousemove', elementDrag)
			document.removeEventListener('mouseup', endDrag)
		}
		
		////
		
		const settingsDisplay = ref(false);
		const settings = computed(() => {
			return props.meta.settings
		});
		
		////
		
		const menuItems = ref([]);
		const menuRef = ref();
		
		watchEffect(() => {
			const sizes = props.meta.sizes.map(s => {
				return {
					label: s.w + "x" + s.h,
					command: () => {
						emit("resize", s);
					}
				}
			});
			menuItems.value = [
				{
					label: "Sizes",
					items: sizes
				}
			];
			
			if (props.meta.settings)
				menuItems.value.push({
					label: "Settings",
					command: () => {
						settingsDisplay.value = true;
					}
				});
			
			menuItems.value.push({
				separator: true
			});
			
			menuItems.value.push({
				label: "Delete",
				command: () => {
					emit("delete");
				}
			});
		});
		
		return {
			settingsDisplay,
			menuRef,
			settings,
			startDrag,
			openMenu($event) {
				if (!props.edit)
					return;
				menuRef.value.show($event);
			},
			menuItems
		};
	}
}
</script>

<style scoped>
.widget {
	display: flex;
	flex-direction: column;
	position: relative;
}

.widget-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 2rem;
	height: 2rem;
	background-color: #42b983;
	z-index: 10000;
	border-radius: 12%;
}

.widget-body {
	height: 100%;
	display: flex;
}
</style>
