<template>
  <div v-if="resellers&&$store.permissions.isAdmin()" >
    <div class="p-field">
      <FormInput class="p-mt-3" v-slot="classes">
                <span class="p-float-label">
                  <Dropdown v-model="type"
                            @change="update();getParentOptions();"
                            :options="parentTypes"
                            optionLabel="name"
                            optionValue="code"
                            :showClear="true"
                  />
                  <label>{{ $t('parent_type') }}</label>
                </span>
      </FormInput>
    </div>
    <div class="p-field">
      <FormInput class="p-mt-3" v-slot="classes">
                <span class="p-float-label">
                  <Dropdown v-model="id"
                            @change="update();"
                            :options="parentOptions"
                            optionLabel="name"
                            optionValue="code"
                            :showClear="true"
                  />
                  <label>{{ $t('referent') }}</label>
                </span>
      </FormInput>
    </div>
  </div>
  <div v-else>
    {{ name }}
  </div>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {computed, ref} from "vue";
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";

export default {
	name: 'Author',
	components: {
		FormInput,
		FormDialog,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
		InputMask: require('primevue/inputmask').default,
	},
  props:{
    modelValue: Object
  },
  data(){},
  emits: ['change'],
	setup(props, {emit}) {
    const i18n = useI18n();
    const store = useStore();
		const radium = store.radium;

    const parentTypes = ref([
      {
        name: "Mat Tech",
        code: 'admin',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('menu_distributeur'),
        code: 'reseller',
        css: 'badge badge-info'
      }
    ]);

    const parentOptions = ref([]);



    const type = ref(props.modelValue?.type || store.auth.user_info.value.key);
    const id = ref(props.modelValue?.id || store.auth.user_info.value.id);
    const users = ref([]);
    const resellers = ref([]);

    const _id = computed(() => {
      console.log(id.value);
      console.log(users);
      if(id.value&&Object.keys(users.value).includes(id.value)){
        return id.value;
      }
      return '';
    });

    const name = computed(() => {
      if(id.value&&Object.keys(users.value).includes(id.value)){
        if(type.value==='admin'){
          return users.value[id.value].username;
        }
        const p = Object.keys(resellers.value).includes(users.value[id.value].parent) ? resellers.value[users.value[id.value].parent].customer_name+' - ' : '';
        return p+users.value[id.value].shipto_location_name+' - '+users.value[id.value].shipto_city;
      }
      return '';
    });

    const parent = computed(() => {
      if(id.value&&Object.keys(users.value).includes(id.value)){
        if(Object.keys(resellers.value).includes(users.value[id.value].parent)){
          return users.value[id.value].parent;
        }
      }
      return 0;
    });

		return {
      type,
      id,
      name,
      users,
      resellers,
      parentTypes,
      parentOptions,
      update(){
        emit('change',{type:type.value,id:_id.value,name:name.value,parent:parent.value});
      }
		}
	},

  methods:{
    async getParentOptions(){
      this.parentOptions.value = [];
      this.users.value = [];

      let service = this.type;
      if(this.type!=='admin'){
        service = 'resellerShipping';
      }

      await _GET.all(service).then(response => {
            this.parentOptions = [];
              response.forEach(item => {
                if (this.type === 'admin') {
                  this.parentOptions.push({name: item.username, code: item._id});
                } else {
                  const p = Object.keys(this.resellers).includes(item.parent) ? this.resellers[item.parent].customer_name + ' - ' : '';
                  this.parentOptions.push({name: p + item.shipto_location_name+' - '+item.shipto_city, code: item._id});
                }
                this.users[item._id] = item;
              });
              if(!Object.keys(this.users).includes(this.id)){
                this.id = '';
              }
      });
    },
    async getResellers(){
      await _GET.all('reseller').then(response => {
          response.forEach(item => {
            this.resellers[item._id] = item;
          });
          if(this.type){
            this.getParentOptions(this.type);
          }
      });
    }
  },
  mounted() {
    this.getResellers();
  }
}
</script>
