import {SKU, SkuHelper, SkuPartOption} from "@/helpers/product";
import {Module, ModuleView} from "@/helpers/modules/module";
import {computed, defineComponent, DefineComponent, h, Ref, ref, watch, watchEffect, WritableComputedRef} from "vue";
import {isObject} from "lodash";
import Length, {default as LengthField} from '@/components/Inputs/Length.vue';
import LengthUnits from '@/components/Inputs/LengthUnits.vue';
import Button from 'primevue/button';
import {default as Size} from "@/helpers/modules/size/size.vue";
import {getDefaultMeasureUnit,setMeasureUnit,getMeasureUnit} from '@/helpers/mesures.js';

interface SizeConfig {
    active: boolean
}

export class Sizes extends Module<SizeConfig, SizeView> {
    
    defaultConfig() {
        return {active:false};
    }
    
    get info() {
        return {
            label: "Dimension"
        };
    }
    
    view(helper: SkuHelper, config): SizeView {
        return new SizeView(helper, config);
    }
    
    optionator(helper, optionData: WritableComputedRef<SkuPartOption>): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    const standard = ref(null);
                    const w = ref(null);
                    const l = ref(null);
                    const w2 = ref(null);
                    const l2 = ref(null);
                    
                    watchEffect(() => {
                        const type = optionData.value?.vars?.width?.type;
                        standard.value = type === "static" || (type === "range" ? false : null);
                        w.value = optionData.value?.vars?.width?.type === "static" ? optionData.value?.vars?.width?.value : optionData.value?.vars?.width?.min || 0;
                        l.value = optionData.value?.vars?.length?.type === "static" ? optionData.value?.vars?.length?.value : optionData.value?.vars?.length?.min || 0;
                        w2.value = optionData.value?.vars?.width?.type === "static" ? optionData.value?.vars?.width?.value : optionData.value?.vars?.width?.max || 0;
                        l2.value = optionData.value?.vars?.length?.type === "static" ? optionData.value?.vars?.length?.value : optionData.value?.vars?.length?.max || 0;
                    })
                    
                    watch([standard, w, l, w2, l2], () => {
                        if (standard.value === null) {
                            const vars = {...optionData.value.vars}
                            delete vars.width;
                            delete vars.length;
                            optionData.value = {
                                ...optionData.value,
                                vars
                            }
                        } else if (standard.value === true) {
                            optionData.value = {
                                ...optionData.value,
                                vars: {
                                    ...optionData.value.vars,
                                    width: {
                                        type: "static",
                                        value: w.value
                                    },
                                    length: {
                                        type: "static",
                                        value: l.value
                                    }
                                }
                            }
                        } else {
                            optionData.value = {
                                ...optionData.value,
                                vars: {
                                    ...optionData.value.vars,
                                    width: {
                                        type: "range",
                                        min: w.value,
                                        max: w2.value
                                    },
                                    length: {
                                        type: "range",
                                        min: l.value,
                                        max: l2.value
                                    }
                                }
                            }
                        }
                    });
                    
                    const LengthField = require("../../../components/Inputs/Length").default
                    const LengthUnits = require("../../../components/Inputs/LengthUnits").default
                    const TriStateCheckbox = require("primevue/tristatecheckbox").default
                    const SelectButton = require("primevue/selectbutton").default
                    return () => {
                        let sections = [];

                        if (standard.value !== null) {
                            sections.push(
                                <div className="p-col-12 p-md-4 p-lg-3 p-m-2 p-p-1 p-shadow-3">
                                    <p><b>{standard.value ? "Valeur" : "Min"}</b></p>
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Width</label>
                                        <LengthField modelValue={w.value} onUpdate:modelValue={e => w.value = e}/>
                                    </div>
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Length</label>
                                        <LengthField modelValue={l.value} onUpdate:modelValue={e => l.value = e}/>
                                    </div>
                                </div>
                            );
                        }
                        if (standard.value === false) {
                            sections.push(
                                <div className="p-col-12 p-md-4 p-lg-3 p-m-2 p-p-1 p-shadow-3">
                                    <p><b>Max</b></p>
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Width</label>
                                        <LengthField modelValue={w2.value} onUpdate:modelValue={e => w2.value = e}/>
                                    </div>
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Length</label>
                                        <LengthField modelValue={l2.value} onUpdate:modelValue={e => l2.value = e}/>
                                    </div>
                                </div>
                            );
                        }

                        const opts = [
                            {name: "Inactif", value: null},
                            {name: "Standard", value: true},
                            {name: "Custom", value: false},
                        ];
                        return (
                            <div>
                                <div className="p-field-checkbox p-m-0">
                                    <SelectButton modelValue={standard.value} onUpdate:modelValue={e => standard.value = e} options={opts} optionLabel="name" optionValue="value"/>
                                </div>
                                <div class="p-row">
                                    {sections}
                                </div>
                            </div>
                        )
                    }
                }
            })
        };
    }
    
    configurator(view: Ref<ModuleView<SizeConfig>>): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    
                    const rand = Math.random();
                    const Size = require("./size.vue").default;
                    return () => {
                        return (<Size modelValue={view.value.config}>{rand}</Size>)
                    }
                }
            })
        };
    }
    
}

class SizeView extends ModuleView<SizeConfig> {
    
    public width = ref("");
    public length = ref("");
    public unit = ref("");
    
    getInfos(sku: SKU): Record<string, string> {
        const sizes = this.getSizes(sku);
        return {
            width: sizes.width.min === sizes.width.max ? sizes.width.min.toString() : this.width.value,
            length: sizes.length.min === sizes.length.max ? sizes.length.min.toString() : this.length.value,
            unit: this.unit.value,
        };
    }
    
    getSizes(sku: SKU): { width: { min: number, max: number }; length: { min: number, max: number } } {
        function minmax(a, v, t) {
            a[t].min = Math.min(a[t].min, parseFloat(v[t].type === "static" ? v[t].value : v[t].min) || 0);
            a[t].max = Math.max(a[t].max, parseFloat(v[t].type === "static" ? v[t].value : v[t].max) || 0);
        }
        
        return sku.options.map((o, i) => {
            return {
                width: this.helper.product.sku.parts[i].options[o].vars?.width,
                length: this.helper.product.sku.parts[i].options[o].vars?.length
            }
        }).reduce((a, v) => {
            
            if (v.width)
                minmax(a, v, "width")
            
            if (v.length && isObject(v.length))
                minmax(a, v, "length")
            
            return a;
        }, {
            width: {min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER},
            length: {min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER}
        });
    }

    convertUnit(n){
        const unit = this.unit.value || localStorage.unit;
        if(unit == 'ftin'){
            return Math.floor(n / 12)+`' `+(n % 12)+`"`;
        }else if(unit == 'ft'){
            const _n = (n / 12);
            return _n.toFixed(2)+`'`;
        }
        return (n)+`"`;
    }
    
    selector(): { done: Ref<boolean>, init: Ref<boolean>, filter: (sku: SKU) => boolean; component: DefineComponent } | undefined {
        const skus = this._computeSku();
        
        const suggestedSkus = computed(() => {
            const w = parseFloat(this.width.value);
            const l = parseFloat(this.length.value);
            return skus.filter(s => {
                return s.width[0] === s.width[1] && s.length[0] === s.length[1];
                // add to remove standard if same as input
                // && !((s.width[0] === w && s.length[0] === l) || (s.width[0] === l && s.length[0] === w));
            }).sort((a, b) => {
                const diff = Math.max(Math.abs(a.width[0] - w), Math.abs(a.length[0] - l));
                const diff2 = Math.max(Math.abs(a.width[0] - l), Math.abs(a.length[0] - w));
                const diff3 = Math.max(Math.abs(b.width[0] - w), Math.abs(b.length[0] - l));
                const diff4 = Math.max(Math.abs(b.width[0] - l), Math.abs(b.length[0] - w));
                return Math.min(diff, diff2) - Math.min(diff3, diff4);
            }).slice(0, 4);
        });
        
        const filteredSkus = computed(() => {
            const w = parseFloat(this.width.value);
            const l = parseFloat(this.length.value);
            
            const inRange = skus.filter(s => {
                const ir = SizeView._inRange([s.width, s.length], [w, l]);
                console.log(w+':'+l+' : '+ir);
                return ir;
            }).sort((a, b) => {
                const diff = ((a.width[1] - w) - (b.width[1] - w)) + ((a.length[1] - l) - (b.length[1] - l));
                const diff2 = ((a.width[1] - l) - (b.width[1] - l)) + ((a.length[1] - w) - (b.length[1] - w));
                return Math.max(diff, diff2);
            });
            
            const standard = inRange.filter(s => s.width[0] === s.width[1] && s.length[0] === s.length[1]);
            if (standard.length)
                return standard.flatMap(s => s.skus).map(s => s.sku);
            
            return inRange.length ? inRange[0].skus.map(s => s.sku) : [];
        });

        const done = ref(true);
        const init = ref(false);
        const view = this;
        return {
            done,
            init,
            filter(sku) {
                return filteredSkus.value.indexOf(sku.sku) !== -1;
            },
            component: defineComponent({
                setup() {
                    const debugs = computed(() => {
                        return h("table", {}, [
                            suggestedSkus.value.map(s => {
                                return h('tr', {}, [
                                    h('td', `${view.convertUnit(s.width[0])}`),
                                    h('td', `x`),
                                    h('td', `${view.convertUnit(s.length[0])}`),
                                    h('td', h(Button, {
                                        class: "p-ml-3",
                                        'onClick': () => {
                                            view.width.value = s.width[0].toString();
                                            view.length.value = s.length[0].toString();
                                        }
                                    }, () => 'Selectionner')),
                                ])
                            })
                        ]);
                        //return suggestedSkus.value.map(s => h('p', s.skus.map(s => s.sku).join(',') + ` w[${s.width[0]},${s.width[1]}] , l[${s.length[0]},${s.length[1]}]`));
                    });
                    
                    watchEffect(() => {
                        console.log('filteredSkus INIT ('+init.value+')');
                        if(view.width.value && view.length.value){
                            console.log('filteredSkus INIT');
                            init.value = true;
                        }
                        if(filteredSkus.value.length===0){
                            console.log('filteredSkus NONE');
                            done.value = false;
                        }else{
                            console.log('filteredSkus EXIST');
                            done.value = true;
                        }
                        console.log(view.width.value, view.length.value, suggestedSkus.value, filteredSkus.value);
                    });

                    
                    return () => {
                        console.log("render");
                        console.log(view.unit.value);
                        
                        const inputs = [
                            [h(LengthUnits, {modelValue:view.unit.value, 'onUpdate:modelValue': v => view.unit.value = v}), ""],
                            [h(Length, {modelValue: view.width.value, unit: view.unit.value, 'onUpdate:modelValue': v => view.width.value = v}), "Width"],
                            [h(Length, {modelValue: view.length.value, unit: view.unit.value, 'onUpdate:modelValue': v => view.length.value = v}), "Length"]
                        ].map(f => h('div', {class: "p-col-12"}, [f[1], f[0]]));
                        
                        return h('div', {class: "p-grid p-fluid"}, [...inputs, debugs.value])
                    };
                }
            })
        };
    }
    
    private static _inRange(b: [[number, number], [number, number]], s: [number, number]): boolean {
        if (b[0][0] <= s[0] && s[0] <= b[0][1] && b[1][0] <= s[1] && s[1] <= b[1][1])
            return true;
        return b[0][0] <= s[1] && s[1] <= b[0][1] && b[1][0] <= s[0] && s[0] <= b[1][1];
    }
    
    private _computeSku(): { width: [number, number], length: [number, number], skus: SKU[] }[] {
        return this.helper.skus.map(sku => {
            const vals = this.getSizes(sku);
            
            return {
                sku: sku,
                width: [vals.width.min, vals.width.max],
                length: [vals.length.min, vals.length.max],
            };
        }).reduce((a, v) => {
            for (const ae of a) {
                if (ae.width[0] !== v.width[0] || ae.width[1] !== v.width[1])
                    continue;
                if (ae.length[0] !== v.length[0] || ae.length[1] !== v.length[1])
                    continue;
                
                ae.skus.push(v.sku);
                
                return a;
            }
            
            a.push({
                width: v.width,
                length: v.length,
                skus: [v.sku]
            });
            
            return a;
        }, []);
    }
    
}
