import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Button, {
        type: "button",
        icon: "pi pi-plus",
        label: "Expand All",
        onClick: $setup.expandAll
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        type: "button",
        icon: "pi pi-minus",
        label: "Collapse All",
        onClick: $setup.collapseAll
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_Tree, {
      value: $setup.nodes,
      selectionMode: "checkbox",
      selectionKeys: $setup.conditions,
      "onUpdate:selectionKeys": _cache[0] || (_cache[0] = ($event: any) => (($setup.conditions) = $event)),
      expandedKeys: $setup.expandedKeys
    }, null, 8, ["value", "selectionKeys", "expandedKeys"])
  ]))
}