<template>
  	<TableLayout service="reseller" single-route-name="resellers.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('distributeur',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
      <Button :label="$tc('departement',2)" icon="pi pi-cog" class="p-button-success p-mr-2" @click="$router.push({name:'resellers.contact.categories.list'})" v-if="$store.permissions.has('resellers.contact.categories.list')"/>
			<Button :label="$t('new')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.add()"/>
		</template>
		
		<template #default="{open}">
			<Column field="name" sortable>
        <template #header="{data}">
          {{$t('nom')}}
        </template>
        <template #body="{data}">
					<router-link :to="{name: 'resellers.edit', params: {id: data._id}}"
					             v-slot="{ href, route, navigate, isActive, isExactActive }"
					             custom>
						<Button @click="navigate" :label="data.customer_name" class="p-button-text"/>
					</router-link>
				</template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
			</Column>
			<Column field="status">
        <template #header="{data}">
          {{$tc('statut',2)}}
        </template>
        <template #body="{data}">
          <span :class="statusMap[data.active_flag]?.css">{{ statusMap[data.active_flag]?.name }}</span>
				</template>
			</Column>
			<Column field="customer_billing_telephone" filterField="main_phone">
        <template #header="{data}">
          {{$t('telephone')}}
        </template>
        <template #filter="{filterModel,filterCallback}" v-if="filterModel">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>
			<Column field="address_line_1">
        <template #header="{data}">
          {{$t('adresse')}}
        </template>
				<template #body="{data}">
					{{ data.customer_billing_address_1 }}
					<br v-if="data.customer_billing_address_2">
					{{ data.customer_billing_address_2 }}
          <br v-if="data.customer_billing_address_3">
          {{ data.customer_billing_address_3 }}
          <br v-if="data.customer_billing_address_4">
          {{ data.customer_billing_address_4 }}
					<br>
					{{ data.customer_billing_city }}, {{ mappedProvinces[data.customer_billing_province] }}
					<br v-if="data.customer_billing_postal_code">
					{{ data.customer_billing_postal_code }}
				</template>
			</Column>
		</template>


	
	</TableLayout>


</template>

<script>
import TableLayout from "../Layouts/TableLayout";
import FormRadium from "@/components/FormPrime/FormRadium";
import {computed, ref, inject} from "vue";
import FormInput from "@/components/FormPrime/FormInput";
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import * as XLSX from "xlsx";

import {provinces} from "@/helpers/provinces";
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";
const ObjectID = require("bson-objectid");



export default {
	name: 'Resellers',
	components: {
		FormInput,
		FormRadium,
    TableLayout,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputMask: require('primevue/inputmask').default,
	},
  data(){
    return {
      column_filters: {
        name: 'string',
        main_phone: 'string'
      },
      file:null,
      toImport: [],
      importRow: null,
      processedResellers: [],
      existingResellers: [],
      existingResellersByID: [],
      existingResellerContacts: []
    };
  },
	setup(props, {emit}) {
    const store = useStore();
    _GET.setProvider(store.radium);
    const i18n = useI18n();
		const statusOptions = ref([
			{
				name: i18n.t('actif'),
				code: 'Active',
				css: 'badge badge-success'
			},
			{
				name: i18n.t('inactif'),
				code: 'Inactive',
        css: 'badge badge-danger'
			}
		]);
		const radium = useStore().radium;
    const enabled_actions = [];

    const actions = [];
    if(store.permissions.has('resellers.resetpassword')){
      actions.push({
        label: i18n.t('reset_password'),
        icon: "pi pi-envelope",
        command(data) {
          radium.api('resetPassword', {email: data.email}).then(() => {
            toast.add({
              severity: "success",
              summary: i18n.t('email_sent'),
              life: 5000
            });
          })
        }
      });
    }

    let filters = {
      'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
    };

		return {
      filters,
      store,
			actions,
      enabled_actions,
			tabs: {
				info: "Infos",
				notable: true,
				timeData: true,
			},
			statusOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = {name: v.name, css: v.css};
					return a
				}, {});
			}),
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
		}
	},
  methods:{
    async onChange(event) {
      const _self = this;
      this.file = event.target.files ? event.target.files[0] : null;
      //const f = await (await fetch("https://sheetjs.com/pres.xlsx")).arrayBuffer();
      const reader = new FileReader();
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        _self.toImport = XLSX.utils.sheet_to_json(worksheet);
        //console.log(this.toImport);
        //_self.ini_import();
      };
      reader.readAsArrayBuffer(this.file);

      /*const wb = read(reader);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      console.log(data);*/
    },
    addFiles(){
      document.getElementById('file').click();
    },
    async ini_import(){
      if(this.importRow===null){
        this.importRow = 0;
      }else if(typeof(this.importRow)=='string'){
        this.importRow = parseInt(this.importRow);
      }
      const self = this;
      await _GET.all('reseller', {rows:5000,first:0}).then(function(response){
        response.forEach(item => {
          self.existingResellers.push(item);
          self.existingResellersByID[item.customer_number] = item._id;
        });
      });
      await _GET.all('resellerContact', {rows:5000,first:0}).then(function(response){
        response.forEach(item => {
          self.existingResellerContacts.push(item);
        });
      });
      this.start_import();
    },
    async start_import(tO = 2000){
      console.log('start import : '+(this.importRow+1)+'/'+this.toImport.length);
      const self = this;
      setTimeout(function(){self.import();},tO);
    },
    async import(){
      const self = this;
      const insertOnly = false;
      let tO = 2000;

      if(this.toImport.length>0){
        const mapping_all = [];
        Object.keys(this.toImport[0]).forEach(k => {
          let _k = k.toLocaleLowerCase().replace(/(\-)/g,'');
          _k = _k.replace(/( )/g,'_');
          mapping_all[_k] = k;
        });

        const mapping_reseller = [];
        const mapping_contact = [];
        Object.keys(mapping_all).map(k => {
          //console.log(k.match(/shipto/g));
          if(k.match(/shipto/g)===null){
            mapping_reseller[k] = mapping_all[k];
          }else{
            mapping_contact[k] = mapping_all[k];
          }
        });

        const r = this.toImport[this.importRow];
        const user_id = parseFloat(r['Customer Number']).toFixed(0);
        let update_reseller = false;
        if(Object.keys(this.existingResellersByID).includes(user_id)){
          update_reseller = true;
        }
        console.log('update_reseller '+user_id+' : '+update_reseller);
        //setting reseller profil
        const reseller_profil = {};
        Object.keys(mapping_reseller).map(k => {
          reseller_profil[k] = r[mapping_reseller[k]];
        });
        //setting reseller contact
        const reseller_contact = {};
        Object.keys(mapping_contact).map(k => {
          reseller_contact[k] = r[mapping_contact[k]];
        });

        if(!this.processedResellers.includes(user_id)){
          if(update_reseller){
            if(!insertOnly){
              const updated = _GET.update('reseller',this.existingResellersByID[user_id], reseller_profil).then(response => {
                return response;
              });
              if(updated){
                this.processedResellers.push(user_id);
                //this.existingResellersByID[user_id] = reseller_profil;
              }
            }
          }else{
            //reseller_profil['_id'] = db_user_id;
            const created = await _GET.create('reseller',reseller_profil).then(response => {
              return response;
            });
            if(created){
              console.log('created '+user_id+' : '+created);
              this.processedResellers.push(user_id);
              this.existingResellersByID[user_id] = created;
            }
          }
        }

        if(this.existingResellersByID[user_id]){
          let update_contact = false;
          const contact = this.existingResellerContacts.filter(item => {
            if(item.parent==this.existingResellersByID[user_id]&&item.shipto_address_1==r['Ship-To Address 1']){
              return true;
            }
          });
          if(contact.length===1){
            update_contact = contact[0]._id;
          }

          reseller_contact.status = reseller_profil.active_flag;

          if(update_contact){
            if(!insertOnly) {
              const updated_contact = await _GET.update('resellerContact', update_contact, reseller_contact).then(response => {
                return response;
              });
            }else{
              tO = 500;
            }
          }else{
            reseller_contact.parent = this.existingResellersByID[user_id];
            //console.log(r['Primary Ship-To Flag']+' : '+(r['Ship-To E-Mail']||r['Customer Contact E-Mail']));
            if(r['Primary Ship-To Flag']==='Primary'&&(r['Ship-To E-Mail']||r['Customer Contact E-Mail'])){
              //add access to primary contact
              reseller_contact.username = r['Customer Name'];
              reseller_contact.email = r['Ship-To E-Mail'] || r['Customer Contact E-Mail'];
              reseller_contact.status = r['Active Flag'];
            }else{
              reseller_contact.status = 'Inactive';
            }
            const created_contact = await _GET.create('resellerContact',reseller_contact).then(response => {
              return response;
            });
          }
        }

        //return;
        this.importRow = this.importRow+1;
        if(this.importRow<this.toImport.length){
          this.start_import(tO);
        }else{
          console.log('import done');
        }

      }
    }
  }
}
</script>