<template>
    <Toolbar class="p-mb-4">
      <template #left>
        <h1 style="margin: auto">Importation</h1>
      </template>
      <template #right>
        <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus/>
      </template>
    </Toolbar>
  <DataTable :value="items" key="value">
    <Column>
      <template #body="{data}">
        <InputText id="label" type="hidden" v-model="data.label"/>
        {{ data.label }}
      </template>
    </Column>
    <Column>
      <template #body="{data}">
          <input type="file" :id="data.type" @change="onChange($event,data.type)" />
          <Button @click="addFiles(data.type)" class="p-button p-component p-button-secondary p-ml-2">{{ $t('add_file')}}</Button>
          <Button @click="ini_import(data.type)" class="p-button p-component p-button-secondary p-ml-2">{{ $t('import')}}</Button>
          <Button @click="ini_export(data.type)" class="p-button p-component p-button-secondary p-ml-2" v-if="data.export">{{ $t('export')}}</Button>
      </template>
    </Column>
    <Column>
    <template #body="{data}">
      <div class="progress">
        <div class="progress-bar" role="progressbar" aria-label="Basic example" :style="{width:sync[data.type].progress+'%'}" :aria-valuenow="sync[data.type].progress" aria-valuemin="0" aria-valuemax="100">{{ sync[data.type].progressText || sync[data.type].progress }}</div>
      </div>
    </template>
  </Column>
  </DataTable>
</template>

<script>

import DataTable from 'primevue/datatable';
import $router from "core-js/internals/internal-state";
import {CrudRepository} from "@radiumjs/client";
import {computed, defineComponent, reactive, ref, unref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useRadium} from "@radiumjs/vue";
import {provinces} from "../../helpers/provinces";

import {_GET} from "../../helpers/get";
import * as XLSX from "xlsx";
import {SkuHelper,Product} from "@/helpers/product";


export default {
  name:"Sync",
  emits: ["save", "update"],
  components: {
    DataTable,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
    ProgressBar: require('primevue/progressbar').default,
  },
  data() {
    return {
      items:[],
      sync: {
        reseller: {
          file:null,
          toImport:[],
          importRow:null,
          processed:[],
          existing:[],
          existingByID:[],
          existingContacts:[],
          progress:0
        },
        productPrice: {
          file:null,
          toImport:[],
          importRow:null,
          processed:[],
          progress:0,
          progressText:'',
          datas:[]
        }
      }
    }
  },
  setup(props, {emit, slots}){
    return {};
  },

  methods:{
    async onChange(event,type) {
      const _self = this;
      this.sync[type]['file'] = event.target.files ? event.target.files[0] : null;
      const reader = new FileReader();
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        _self.sync[type]['toImport'] = XLSX.utils.sheet_to_json(worksheet);
      };
      reader.readAsArrayBuffer(this.sync[type]['file']);
    },
    addFiles(type){
      document.getElementById(type).click();
    },
    ini_export(type){
      if(type==='productPrice'){
        this.ini_export_productPrice();
      }
    },
    ini_import(type){
      if(type==='reseller'){
        this.ini_import_reseller();
      }else if(type==='productPrice'){
        this.ini_import_productPrice();
      }
    },
    async ini_import_reseller(){
      if(this.sync.reseller.importRow===null){
        this.sync.reseller.importRow = 0;
      }else if(typeof(this.sync.reseller.importRow)=='string'){
        this.sync.reseller.importRow = parseInt(this.sync.reseller.importRow);
      }
      const self = this;
      await _GET.all('reseller', {rows:5000,first:0}).then(function(response){
        response.forEach(item => {
          self.sync.reseller.existing.push(item);
          self.sync.reseller.existingByID[item.customer_number] = item._id;
        });
      });

      await _GET.all('resellerContact', {rows:5000,first:0}).then(function(response){
        response.forEach(item => {
          self.sync.reseller.existingContacts.push(item);
        });
      });

      this.start_import_reseller();
    },
    start_import_reseller(tO = 2000){
      this.start_import_reseller_progress();
      const self = this;
      setTimeout(function(){self.import_reseller();},tO);
    },
    start_import_reseller_progress(){
      this.sync.reseller.progress = (this.sync.reseller.importRow+1)/this.sync.reseller.toImport.length;
    },
    async import_reseller(){
      const self = this;
      const insertOnly = false;
      let tO = 2000;

      if(this.sync.reseller.toImport.length>0){
        const mapping_all = [];
        Object.keys(this.sync.reseller.toImport[0]).forEach(k => {
          let _k = k.toLocaleLowerCase().replace(/(\-)/g,'');
          _k = _k.replace(/( )/g,'_');
          mapping_all[_k] = k;
        });

        const mapping_reseller = [];
        const mapping_contact = [];
        Object.keys(mapping_all).map(k => {
          //console.log(k.match(/shipto/g));
          if(k.match(/shipto/g)===null){
            mapping_reseller[k] = mapping_all[k];
          }else{
            mapping_contact[k] = mapping_all[k];
          }
        });

        const r = this.sync.reseller.toImport[this.importRow];
        const user_id = parseFloat(r['Customer Number']).toFixed(0);
        let update_reseller = false;
        if(Object.keys(this.sync.reseller.existingByID).includes(user_id)){
          update_reseller = true;
        }
        console.log('update_reseller '+user_id+' : '+update_reseller);
        //setting reseller profil
        const reseller_profil = {};
        Object.keys(mapping_reseller).map(k => {
          reseller_profil[k] = r[mapping_reseller[k]];
        });
        //setting reseller contact
        const reseller_contact = {};
        Object.keys(mapping_contact).map(k => {
          reseller_contact[k] = r[mapping_contact[k]];
        });

        if(!this.sync.reseller.processed.includes(user_id)){
          if(update_reseller){
            if(!insertOnly){
              const updated = _GET.update('reseller',this.sync.reseller.existingByID[user_id], reseller_profil).then(response => {
                return response;
              });
              if(updated){
                this.sync.reseller.processed.push(user_id);
                //this.existingResellersByID[user_id] = reseller_profil;
              }
            }
          }else{
            //reseller_profil['_id'] = db_user_id;
            const created = await _GET.create('reseller',reseller_profil).then(response => {
              return response;
            });
            if(created){
              console.log('created '+user_id+' : '+created);
              this.sync.reseller.processed.push(user_id);
              this.sync.reseller.existingByID[user_id] = created;
            }
          }
        }

        if(this.sync.reseller.existingByID[user_id]){
          let update_contact = false;
          const contact = this.sync.reseller.existingContacts.filter(item => {
            if(item.parent==this.sync.reseller.existingByID[user_id]&&item.shipto_address_1==r['Ship-To Address 1']){
              return true;
            }
          });
          if(contact.length===1){
            update_contact = contact[0]._id;
          }

          reseller_contact.status = reseller_profil.active_flag;

          if(update_contact){
            if(!insertOnly) {
              const updated_contact = await _GET.update('resellerContact', update_contact, reseller_contact).then(response => {
                return response;
              });
            }else{
              tO = 500;
            }
          }else{
            reseller_contact.parent = this.sync.reseller.existingByID[user_id];
            //console.log(r['Primary Ship-To Flag']+' : '+(r['Ship-To E-Mail']||r['Customer Contact E-Mail']));
            if(r['Primary Ship-To Flag']==='Primary'&&(r['Ship-To E-Mail']||r['Customer Contact E-Mail'])){
              //add access to primary contact
              reseller_contact.username = r['Customer Name'];
              reseller_contact.email = r['Ship-To E-Mail'] || r['Customer Contact E-Mail'];
              reseller_contact.status = r['Active Flag'];
            }else{
              reseller_contact.status = 'Inactive';
            }
            const created_contact = await _GET.create('resellerContact',reseller_contact).then(response => {
              return response;
            });
          }
        }

        //return;
        this.sync.reseller.importRow = this.sync.reseller.importRow+1;
        if(this.sync.reseller.importRow<this.sync.reseller.toImport.length){
          this.start_import_reseller(tO);
        }else{
          console.log('import done');
        }

      }
    },

    async ini_import_productPrice(){
      if(this.sync.productPrice.importRow===null){
        this.sync.productPrice.importRow = 0;
      }else if(typeof(this.sync.productPrice.importRow)=='string'){
        this.sync.productPrice.importRow = parseInt(this.sync.productPrice.importRow);
      }

      this.sync.productPrice.progressText = 'Recuperation des produits existants...';
      const self = this;
      await _GET.all('product').then(function(response){
        self.sync.productPrice.datas = response;
      });
      this.sync.productPrice.progressText = '';

      console.log(this.sync.productPrice.toImport);
      const products = [];
      let edits = [];
      for (let i = 0; i < this.sync.productPrice.toImport.length; i++) {
         const row = this.sync.productPrice.toImport[i];
         this.sync.productPrice.progress = (i)/this.sync.productPrice.toImport.length;

         if(row.Product){
           if(!Object.keys(products).includes(row.Product)){
             let id = null;
             let ex = self.sync.productPrice.datas.filter((item) => {
               return item.name==row.Product
             });
             if(ex.length===1){
               console.log('getting product '+row.Product);
               ex = ex[0];
               products[row.Product] = ex;
               edits[row.Product] = products[row.Product].skuParts;
             }
           }
           let parser = edits[row.Product];
           if(parser){
             parser.forEach((part,k) => {
               if(part.label==row.Part){
                 part.options.forEach((opt,kk) => {
                   if(opt.name==row.Option&&opt.vars){
                     opt.vars.forEach((v,kkk) => {
                       if(v.name==='price'){
                         let p = row.Price.replace(',','.');
                         if(v.value!=p){
                           edits[row.Product][k].options[kk].vars[kkk].value = p;
                         }
                       }
                     });
                   }
                 });
               }
             });
           }else{
             console.log('Cant reach skuParts '+row.Product);
           }

         }
      }
      console.log(edits);
      if(edits){
        let i = 0;
        for (const key in edits) {
          this.sync.productPrice.progress = (i+1)/edits.length;
          console.log('try update '+key);
          if(products[key]){
            let id = products[key]._id;
            if(id){
              let d = {skuParts: edits[key]};
              await _GET.update('product',id,d);
            }
          }
          i++;
        }
      }
      this.sync.productPrice.progress = 100;

    },

    async ini_export_productPrice(){
      const self = this;
      await _GET.all('product').then(function(response){
        self.sync.productPrice.datas = response;
      });

      let excel = XLSX.utils.book_new();
      let datas = [];
      for (let i = 0; i < self.sync.productPrice.datas.length; i++) {
        const row = self.sync.productPrice.datas[i];
        let product = new SkuHelper(row);
        //console.log(product);
        let opts = [];
        let skuPart_filter = [];
        product._skus.value.every(sku => {
          //let s = product.build(sku, {});
          //console.log(sku);
          sku?.options.forEach((oi, pi) => {
            let skuPart = row.skuParts[pi];
            if(skuPart){

              const option = skuPart.options[oi];
              if(option?.vars){
                //console.log(option?.vars);
                let price = option?.vars.filter(v => {
                  return v.name=='price';
                });
                if(price.length>0){
                  price = price[0];
                  //console.log(price);
                  if (price.type === "static") {
                    if(!Object.keys(opts).includes(skuPart.name)){
                      opts[skuPart.name] = [];
                    }
                    if(!Object.keys(opts[skuPart.name]).includes(option.name)){
                      opts[skuPart.name][option.name] = [skuPart.label,option.name,price.value.replace(',','.')];
                      datas.push([row.name,skuPart.label,option.name,price.value.replace(',','.')]);
                    }
                    //datas.push([sku.sku,skuPart.label,option.label,price.value.replace(',','.')]);
                  }
                }
              }
            }
          });
          return true;
        });
        //break;
      }

      if(datas.length>0){
        console.log(datas);
        datas.unshift(['Product','Part','Option','Price']);
        let sheet = XLSX.utils.aoa_to_sheet(datas);
        XLSX.utils.book_append_sheet(excel, sheet, "Sheet1");
        XLSX.writeFile(excel, 'Product_prices.xlsx');
      }

    },
  },
  mounted() {
    this.items = [
      {label:'Client',type:'reseller'},
      {label:'Prix produit',type:'productPrice',export:true}
    ];
  }
}
</script>
