<template>
	<div class="p-py-1">
		<SkuPartOption v-for="(opt,i) of options" :modelValue="opt" @update:modelValue="updateOption($event,i)" :part-name="partName" @destroy="destroy(i)">
		</SkuPartOption>
	</div>
</template>

<script>
import {computed} from "vue";
import SkuPartOption from "./SkuPartOption";

export default {
	name: "SkuPartOptions",
	components: {
		SkuPartOption
	},
	props: {
		modelValue: Object,
		partName: String
	},
	emits: ["update:modelValue"],
	setup(props, {emit}) {
		
		return {
			options: computed(() => {
        let opt = props.modelValue || [];
        if(opt.label){
          //fix for english label
          opt.label_en = opt.label;
          opt.label = '';
        }
				return opt;
			}),
			updateOption(newOpt, i) {
        console.log('updateOption');
        console.log(newOpt);
				const newOpts = [...props.modelValue];
				newOpts[i] = newOpt;
				emit('update:modelValue', newOpts);
			},
			destroy(i) {
				const newOpts = [...props.modelValue];
				newOpts.splice(i, 1);
				emit('update:modelValue', newOpts);
			}
		};
	}
}
</script>

<style scoped>

</style>
