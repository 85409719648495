import {Prices} from "@/helpers/modules/price/prices";
import {Condition} from "@/helpers/modules/condition/condition";
import {Indexer} from "@/helpers/indexer";
import {Sizes} from "@/helpers/modules/size/sizes";
import {CheckerFunctionError} from "fastest-validator";
import {computed, ComputedRef} from "vue";
import {Hider} from "@/helpers/modules/hidder/hider";
import {BuiltProduct} from "@/helpers/soumission";
import {Weights} from "@/helpers/modules/weight/weight";
import SkuPart from "@/components/Sku/SkuPart.vue";
import {translatedString} from "../i18n";

const modules = {
    prices: new Prices(),
    weight: new Weights(),
    sizes: new Sizes(),
    condition: new Condition(),
    hider: new Hider()
} as const;


export class SkuHelper {
    
    public readonly product: Product;
    
    private readonly _skus: ComputedRef<SKU[]>;
    private _filteredSkus: ComputedRef<SKU[]>;
    
    public readonly errors = [];
    public readonly views: { [key in keyof ModulesType]: ReturnType<ModulesType[key]['view']> };
    
    constructor(product: Product) {
        this.product = product;
        
        if (!product.sku) {
            product.sku = {
                modules: {},
                parts: [],
                config: {}
            };
        }

        // bogue sku.modules is undefined
        if(product.sku&&!product.sku.modules){
            product.sku.modules = {};
        }

        //
        if(!product.categories){
            product.categories = [];
        }

        //console.log('product:');
        //console.log(product);
        this.views = Object.entries(modules).reduce((a, v) => {
            if (!product.sku.modules[v[0]])
                product.sku.modules[v[0]] = v[1].defaultConfig();
            a[v[0]] = v[1].view(this, product.sku.modules[v[0]]);
            return a;
        }, {}) as { [key in keyof ModulesType]: ReturnType<ModulesType[key]['view']> };
        
        this._skus = computed(() => {
            const result = [];
            if (this.product.sku) {
                const indexes = new Indexer(this.product.sku.parts.map(part => (part.options?part.options.length:0)));
                do {
                    const opts = this.product.sku?.parts.map((part, i) => part.options[indexes.indexes[i]]);
                    result.push({
                        sku: opts.map(o => o.name).join(""),
                        options: [...indexes.indexes]
                    });
                } while (!indexes.incrementIndexes());
            }
            return result;
        })
        
        this._filteredSkus = computed(() => {
            return this._skus.value.filter(sku => !Object.values(this.views).filter(v => v.isActive).some(v => !v.testSKU(sku)))
        })
    }
    
    get skus() {
        return this._filteredSkus.value;
    }
    
    build(sku: SKU,params: {}): BuiltProduct {
        console.log('build');
        const info = Object.values(this.views).map(v => v.getInfos(sku)).reduce((a, v) => Object.assign(a, v), {})
        let options = sku.options.map((oI, pI) => {
            const arr = {};
            arr[this.product.sku.parts[pI].name] = this.product.sku.parts[pI].options[oI];
            return arr;
        });

        let files = this.product.files?this.product.files.filter((item: any) => item.soumission === true):[];
        let weblinks = this.product.weblinks?this.product.weblinks.filter((item: any) => item.soumission === true):[];
        let productOptions = params['productOptions']?params['productOptions']:[];
        productOptions = productOptions.filter((po: any) => po);

        const note: string = sku.options.map((oI, pI) => {
            if(this.product.sku.parts[pI].options[oI].soumission){
                return translatedString('note',this.product.sku.parts[pI].options[oI]);
            }
            return "";
        }).join("");

        const discount = '';

        return {
            name: this.product.name,
            sku: sku.options.map((oI, pI) => this.product.sku.parts[pI].options[oI].name).join(""),
            desc: sku.options.map((oI, pI) => this.product.sku.parts[pI].hiddenIfPossible ? false : this.product.sku.parts[pI].options[oI].label).filter(s => !!s).join("/"),
            info,
            options,
            files,
            weblinks,
            productOptions,
            note:note,
            tags:'',
            discount:discount,
            custom: false,
            price: this.views.prices.getPrice(sku),
            weight: this.views.weight.getWeight(sku),
            productOptions_price: this.views.prices.getProductOptionsPrice(sku,productOptions),
            prix_client: 0
        };
    }

    buildEmpty(params: {}): any {
        return {
            ...params, custom:true
        };
    }
    
    // getSkus(scope: {[k in keyof VarsFromModules]?: any}) {
    //     return  this._skus.filter(sku => !Object.values(this._modules).some(module => !module.testSKU(sku,scope)));
    // }
    
    moduleConfig<K extends keyof ModulesType>(key: K): ReturnType<ModulesType[K]['defaultConfig']> {
        return this.views[key].config as any;
    }
    
    validate(errors: CheckerFunctionError[]) {
        Object.values(this.views).forEach(m => m.validate(errors))
    }

    static get SkuPart(): { options: SkuPartOption[]; label: string; name: string; hiddenIfPossible: boolean }{
        return {
            label: '',
            name: '',
            options: <SkuPartOption[]>this.SkuPartOption,
            hiddenIfPossible: false
        };
    }

    static get SkuPartOption(): SkuPartOption[]{
        return [{
            label:'',
            label_fr:'',
            label_en:'',
            name:'',
            note:'',
            note_fr:'',
            note_en:'',
            soumission:false,
            vars:{}
        }];
    }
    
    ////////////////static helper
    
    static get modules(): ModulesType {
        return modules;
    }
}

export type ModulesType = typeof modules;

export interface SKU {
    sku: string,
    options: number[]
}

export interface Product {
    name: string
    sku: {
        parts: SkuPart[],
        config: {},
        modules: {
            [key in keyof ModulesType]?: ReturnType<ModulesType[key]['defaultConfig']>
        }
    }

    categories: []
    files:[]
    weblinks:[]
    productOptions:[]
    
    /**
     * @deprecated
     */
    skuParts?: SkuPart[]
    /**
     * @deprecated
     */
    price?: string
    prix_client?: string
}

export interface SkuPart {
    label: string
    name: string
    options: SkuPartOption[]
    
    hiddenIfPossible?: boolean
}

export interface SkuPartOption {
    label: string
    label_fr: string
    label_en: string
    note: string
    note_fr: string
    note_en: string
    name: string
    soumission: boolean
    conditions?: {
        part: number
        option: number
    }[],
    sizeFilter?: {//used in size module
        /**
         * undefined: dont care<br>
         * true: width need to be custom<br>
         * false: width need to be standard
         */
        ifWidthCustom?: boolean,
        /**
         * undefined: dont care<br>
         * true: width need to be custom<br>
         * false: width need to be standard
         */
        ifLengthCustom?: boolean
    },
    vars: {
        [name: string]: {
            type: "range"
            min: string
            max: string
        } | {
            type: "static"
            value: string
        }
    }
}

export interface VariableDef {
    help: string,
    kind: VarKind,
    label: string,
    static?: boolean | undefined
}

export enum VarKind {
    Date = "Date",
    Length = 'Length',
    Money = "Money"
}
