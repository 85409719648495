<template>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col">
			<label>Date de création
			</label>
			<Calendar v-model="created_at" :showTime="true" :showSeconds="true" :readonly="true" :manualInput="false"/>
		</div>
		<div class="p-field p-col">
			<label>Date de modification
			</label>
			<Calendar v-model="modified_at" :showTime="true" :showSeconds="true" :readonly="true" :manualInput="false"/>
		</div>
	</div>
</template>

<script>
import * as moment from "moment";

export default {
	name: "TimeData",
	components: {
		Calendar: require('primevue/calendar').default
	},
	props: {
		item: Object
	},
	computed: {
		created_at() {
			return moment.utc(this.item.created_at).local().toDate();
		},
		modified_at() {
			return moment.utc(this.item.modified_at).local().toDate();
		}
	}
}
</script>

<style scoped>

</style>
