
import {computed, defineComponent, ref, getCurrentInstance} from "vue";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import {BuiltProduct, discountStringToNumber, SoumissionRapide} from "@/helpers/soumission";
import {getMeasureString} from "@/helpers/mesures";
import Vue3Html2pdf from 'vue3-html2pdf'
import moment from "moment";
import Sommaire from "@/components/SoumissionRapide/Sommaire.vue";
import {useStore} from "@/store";
import FormInput from "@/components/FormPrime/FormInput.vue";
import {translatedFieldName, translatedString} from "@/i18n";
import {ShippingCarrier} from "@/helpers/interface";
import Termes from "../Settings/Termes.vue";
import Messages from "../Settings/Messages.vue";
import {bites_to} from '@/helpers/random';
import {_GET} from '@/helpers/get';
import {useRadium} from "@radiumjs/vue";



export default defineComponent({
	name: "SoumissionInvoice",
	components: {
    Vue3Html2pdf,
    Sommaire,
    FormInput,
		Column: require("primevue/column").default,
		DataTable: require("primevue/datatable").default,
		Button: require("primevue/button").default,
		Toolbar: require("primevue/toolbar").default,
		Sidebar: require("primevue/sidebar").default,
		InputText: require("primevue/inputtext").default,
		Textarea: require("primevue/textarea").default,
    Editor: require('primevue/editor').default,
    Dropdown: require("primevue/dropdown").default,
	},
	props: {
		modelValue: Object,
    taxes: Array,
    shippingAddons: Array
	},
  data(){
    return {
      custom_radium: null,
      toast: null,
      message:'',
      //shipping: {} as ShippingCarrier,
      messageOptions:[]
    }
  },
  emits: ["sent"],
	setup(props, {emit}) {
    const radium = useRadium();
    _GET.setProvider(radium);

    let sent = ref('');
    if(props.modelValue.sent){
      sent.value = props.modelValue.sent;
    }

    const selectedMessage = props.modelValue.selectedMessage || null;

    const sending = ref(false);

    const item = props.modelValue || {};
    const attachments = ref([]);

    if(item.files){
      item.files.forEach(file => {
        attachments.value.push(file);
      });
    }

    return {
      selectedMessage,
      sending,
      sent,
      item,
      attachments,
      discount: computed(() => discountStringToNumber(props.modelValue.discount)),
      updateSent(sent) {
        if (props.modelValue._id) {
          //direct save
          _GET.update('submission', props.modelValue._id, 'sent', sent);
        } else {
          emit("sent", sent);
        }
        sent.value = sent;
      }
    };
	},
  methods: {
    decimal(n){
      return n.toFixed(2);
    },
    getMeasureString(n,d){
      const unit = d.unit || 'ftin';
      if(unit == 'ftin'){
        return Math.floor(n / 12)+`' `+(n % 12)+`"`;
      }else if(unit == 'ft'){
        const _n = (n / 12);
        return _n.toFixed(2)+`'`;
      }
      return (n)+`"`;
    },
    now(){
      return moment().format('YYYY-MM-DD');
    },
    getShippingAddonName(shippingAddon){
      const field = translatedString('description',shippingAddon);
      return field;
    },
    getShippingName(shipping){
      if(this.item.carrier.name){
        return this.item.carrier.name;
      }
      if(this.item.carrier.description_fr){
        return this.item.carrier.description_fr;
      }
      return '';
    },
    getFileTitle(file){
      if(file['titre_'+this.$i18n.locale]){
        return file['titre_'+this.$i18n.locale];
      }
      return '';
    },
    getFileLink(file){
      if(file.name){
        return (process.env.VUE_APP_UPLOAD_SERVER?process.env.VUE_APP_UPLOAD_SERVER:'')+'/storages/'+file.dir+'/'+file.name;
      }
      return '';
    },
    getWeblinkTitle(weblink){
      if(weblink['titre_'+this.$i18n.locale]){
        return weblink['titre_'+this.$i18n.locale];
      }
      return '';
    },
    getWeblinkLink(weblink){
      if(weblink['link_'+this.$i18n.locale]){
        return weblink['link_'+this.$i18n.locale];
      }
      return '';
    },
    getOptionName(product, optionName){
      if(product.options){
        for(let i in product.options){
          const option = product.options[i];
          if(option&&option[optionName]){
            if(option[optionName]['label_'+this.$i18n.locale]){
              return option[optionName]['label_'+this.$i18n.locale];
            }
            return option[optionName]['label'];
          }
        }
      }
      return '';
    },
    displayProductOptionInfoDetail(productOption){
      let description = '';
      if(productOption.unique){
        description = productOption.unique;
      }
      return  description;
    },
    get_discount(data){
      let discount = this.discount;
      if(data.product.discount){
        discount = discountStringToNumber(data.product.discount);
      }
      return discount;
    },
    get_price(data){
      const option_msrp = data.product.productOptions_price&&data.product.productOptions_price.msrp ? data.product.productOptions_price.msrp : 0;
      let price = (Number(data.product.price)+option_msrp);
      if(this.item.billed_to||this.item.for==='custom'){
        let p = Number(data?.product?.prix_client);
        if(p){
          price = p;
        }
      }
      return price;
    },
    price(data){
      let discount = this.get_discount(data);
      let price = this.get_price(data);
      return this.decimal(price * data.qty * discount);
    },
    billing_detail(){
      let info = [];
      const billing = this.item.billing || {};
      if(billing.name){
        info.push(billing.name);
      }
      if(billing.address_1){
        info.push(billing.address_1);
      }
      if(billing.address_2){
        info.push(billing.address_2);
      }
      let city = [];
      if(billing.city){
        city.push(billing.city);
      }
      if(billing.province){
        city.push(billing.province);
      }
      if(billing.postal_code){
        city.push(billing.postal_code);
      }
      if(city){
        info.push(city.join(' '));
      }
      if(billing.telephone){
        info.push(billing.telephone);
      }
      if(billing.email){
        info.push(billing.email);
      }
      if(this.item.nom_contact){
        info.push('<hr>');
        info.push(this.$tc('contact',1)+' : '+this.item.nom_contact);
      }
      return info.join('<br>');
    },
    shipping_detail(){
      let info = [];
      let shipping = this.item.shipping || {};
      if(!shipping){
        shipping = this.item.billing || {};
      }
      if(shipping.name){
        info.push(shipping.name);
      }
      if(shipping.address_1){
        info.push(shipping.address_1);
      }
      if(shipping.address_2){
        info.push(shipping.address_2);
      }
      let city = [];
      if(shipping.city){
        city.push(shipping.city);
      }
      if(shipping.province){
        city.push(shipping.province);
      }
      if(shipping.postal_code){
        city.push(shipping.postal_code);
      }
      if(city){
        info.push(city.join(' '));
      }
      if(shipping.telephone){
        info.push(shipping.telephone);
      }
      if(shipping.email){
        info.push(shipping.email);
      }
      if(this.item.nom_contact){
        info.push('<hr>');
        info.push(this.$tc('contact',1)+' : '+this.item.nom_contact);
      }
      return info.join('<br>');
    },
    header_detail(){
      let info = [];
      if(this.item.account_nb){
        info.push(this.$t('account')+' : '+this.item.account_nb);
      }
      if(info.length){
        return '<h5>'+info.join('<br>')+'</h5>';
      }
      return '';
    },
    notes(){
      let info = [];
      const modelValue = this.item;
      if(modelValue.nom_projet){
        info.push(this.$t('nom_projet')+' : '+modelValue.nom_projet);
      }
      if(modelValue.delai_production){
        info.push(this.$t('delai_production')+' : '+modelValue.delai_production);
      }
      if(modelValue.expired_at){
        info.push(this.$t('expired_at')+' : '+modelValue.expired_at);
      }
      if(info.length){
        return '<span>'+info.join('<br>')+'</span>';
      }
      return '';
    },
    createSoumission(){
      //@ts-ignore
      this.$refs.invoice.generatePdf();
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      let invoice = await html2pdf().set(options).from(pdfContent).toPdf().output().then( (data) => {
        //console.log(data);
        //console.log(btoa(data));
        this.send(data);
        return data
      })
    },
    async send(data){
      const email = this.item.billing?.email;
      if(!email){
        alert('Erreur : Vous devez associé un courriel à la soumission.');
      }

      this.sending = true;

      let formData = new FormData();
      formData.append('pdf', btoa(data));
      formData.append('filename', 'qt_'+this.item.qt_nb);
      formData.append('dir', 'soumissions/'+this.item._id);
      formData.append('to_email', email);
      formData.append('to_name', this.item.billing?.name);
      formData.append('sujet', "Soumission "+this.item.qt_nb);
      formData.append('message', this.message);
      formData.append('i18n', this.$i18n.locale);

      for (let i = 0; i<this.attachments.length; i++){
        formData.append('attachments['+i+']', JSON.stringify(this.attachments[i]));
      }


      const r = await this.custom_radium.api(
            "POST mailer.php",
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(response => {
          if(response.data&&response.data.statut=='success') {
            //envoie du fichier
            if(response.data.file){
              /*const uploaded_url = process.env.VUE_APP_UPLOAD_SERVER;
              const absolute_file_url = uploaded_url+response.data.file;
              const response = sendMail({
                'to' : [{email:email,name:this.reseller.name}],
                'message' : this.message,
                'attachment' : [{absolute_file_url}]
              });*/
            }

            this.sent = moment().format();
            this.updateSent(this.sent);

            this.toast.add({
              summary: 'La soumission a été envoyée',
              detail: '',
              severity: "info",
              life: 5000
            });

            this.sending = false;


          }else if(response.data&&response.data.statut=='success') {
            this.toast.add({
              summary: 'La soumission n\'a pas été envoyée',
              detail: '',
              severity: "danger",
              life: 5000
            });

            this.sending = false;
          }
        });
      console.log('after promise');
      console.log(r);
    },
    setMessage(){
      console.log(this.messageOptions);
      const msg = this.messageOptions.filter(r => r.reference==this.selectedMessage);
      this.message = translatedString('message',msg[0]);
    }
  },
  created(){
    this.custom_radium = useStore().radium;
    this.toast = useToast();
  },
  mounted() {
    Termes.methods.getItem().then(response => {
      console.log('invoice mounted');
      console.log(response);
      response.forEach(file => {
        this.attachments.push(file);
      });
      console.log(this.attachments);
      return response;
    });
    Messages.methods.getItem().then(response => {
      response.forEach(el => {
        this.messageOptions.push(el);
      });
      return response;
    });

  }
});
