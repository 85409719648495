<template>
  <div class="p-field" v-if="inputs">
    <div class="p-row p-inputtext">
      <div class="p-col-12 p-row">
        <h2>{{ title }}</h2>
      </div>
      <div v-for="(input, key) in inputs" class="p-col-12 p-row p-align-center">
        <slot></slot>
        <div class="p-field p-col-4">
          <span class="p-button p-button-danger p-d-inline" @click="removeInput(key)"><i class="pi pi-trash"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div class="p-field">
    <div class="p-row p-inputtext">
      <div class="p-row">
        <div class="p-col-auto">
          <Button @click="addInput()" class="p-button p-component p-button-secondary">{{ $t('add')}}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store/index.ts";
import {RadiumVue} from "@radiumjs/vue";
import {RadiumClient} from "@radiumjs/client";

export default {
  data(){
    return {
      inputs:[]
    }
  },
  props: {
    title: String,
    modelValue: Array
  },
  emits: ["update:modelValue"],
  setup(props, {emit: emitO, expose}) {
    function emit(event, ...args) {
      console.log(event, args);
      emitO(event, ...args);
    }
    return {

    };
  },
  created(){},
  methods: {
    addInput(){
      this.inputs.push({});
      this.saveInputs();
    },
    removeInput( key ){
      const c = confirm(this.$t('confirm_remove_item'));
      if(!c){
        return false;
      }
      this.inputs.splice( key, 1 );
      this.saveInputs();
    },
    saveInputs(){
      this.$emit('update:modelValue', this.inputs);
    }
  }
}
</script>

<style>

</style>
