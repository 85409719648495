export function discountStringToNumber(discount: string) {
    if(!discount){
        discount = '0';//default
    }
    return discount.split("/").map(v => (100 - parseInt(v, 10)) / 100).reduce((acc, cur) => {
        return acc * cur;
    })
}

export interface BuiltProduct {
    name: string
    options: object
    productOptions: object
    note: string
    tags: string
    discount: any
    files: object
    weblinks: object
    sku: string
    desc: string
    info: Record<string, string>
    price: number
    prix_client: number
    weight: number
    custom: boolean
    productOptions_price: {
        msrp: number,
        prix: number
    }
}

export interface SoumissionRapide {
    _id
    po: string
    soumission_type: string
    client_type: string
    discount: string
    account_nb: string
    qt_nb: string
    nom_cs: string
    nom_rep: string
    nom_projet: string
    delai: string
    billing: {
        province: string
    }
    shipping: {
        sku: string
        name: string
        price: number
    }
    shippingaddons: []
    expired_at: string
    client: string
    products: {
        product: BuiltProduct,
        qty: number,
        note: string
    }[]
}
