<template>
	<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
      <FormInput class="p-mt-3" :errors="errors?.username" v-slot="classes">
				<span class="p-float-label">
					<InputText type="text" v-model="item.username" :class="classes" @change="$emit('change',['$set','username',$event.target.value])"/>
					<label>{{$t('nom')}}</label>
				</span>
      </FormInput>
    </div>
		<div class="p-field p-col-6">
			<FormInput class="p-mt-3" :errors="errors?.email" v-slot="classes">
				<span class="p-float-label">
					<InputText type="email" v-model="item.email" :class="classes" @change="$emit('change',['$set','email',$event.target.value])"/>
					<label>{{$t('email')}}</label>
				</span>
			</FormInput>
		</div>
    <div class="p-field p-col-6">
      <FormInput class="p-mt-3" :errors="errors?.status" v-slot="classes">
				<span class="p-float-label">
					<Dropdown v-model="item.status"
                    :options="statusOptions"
                    optionLabel="name"
                    optionValue="code"
                    id="statusUserDropdown"
                    @change="$emit('change',['$set','status',$event.value])"
          />
					<label>{{$tc('statut',1)}} {{ status }}</label>
				</span>
      </FormInput>
    </div>
    <slot name="userfields"></slot>
	</div>
</template>

<script>
import FormInput from "@/components/FormPrime/FormInput";
import {computed, ref} from "vue";

export default {
	name: "LmUser",
	components: {
		FormInput,
		Button: require('primevue/button').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		InputMask: require('primevue/inputmask').default,
	},
	props: {
		errors: Object,
		modelValue: Object
	},
	emits: ["change"],
	setup(props, {emit}) {

    const item = props.modelValue;
		
		const statusOptions = ref([
			{
				name: "Actif",
				code: 1,
				color: '#198754'
			},
			{
				name: "Inactif",
				code: -1,
				color: '#d20000',
			}
		]);

		
		return {item, statusOptions};
	}
}
</script>

<style scoped>

</style>
