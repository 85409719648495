
import {useConfirm} from "primevue/useconfirm";
import {computed, ComputedRef, inject, ref} from "vue";
import {ModulesType, SkuHelper, SkuPartOption} from "@/helpers/product";

type Modules = {
	[name in keyof ModulesType]: {
		module: ModulesType[name],
		optionator: ReturnType<ModulesType[name]['optionator']>
	}
}

export default {
	name: "SkuPartOption",
	components: {
		Panel: require('primevue/panel').default,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Listbox: require('primevue/listbox').default,
		InputText: require('primevue/inputtext').default,
		Textarea: require('primevue/textarea').default,
		Dropdown: require('primevue/dropdown').default,
		Sidebar: require('primevue/sidebar').default,
    Checkbox: require('primevue/checkbox').default,
	},
	props: {
		modelValue: Object,
		partName: String,
		index: Number
	},
	emits: ["update:modelValue", "destroy"],
	setup(props, {emit}) {
		const confirm = useConfirm();
		const helper = inject("productHelper") as ComputedRef<SkuHelper>;
		
		const edit = ref(false);
		const optionData = computed<SkuPartOption>({
			get() {
				return props.modelValue
			},
			set(data) {
				emit('update:modelValue', data);
			}
		});
		
		const modules: Modules = Object.entries(SkuHelper.modules).reduce((a, v) => {
			a[v[0]] = {
				module: v[1],
				optionator: v[1].optionator(helper, optionData)
			};
			return a;
		}, {} as Modules);
		
		return {
			edit,
			modules: computed(() => {
				return Object.entries(modules).filter(([k, m]) => helper.value?.views[k].isActive && m.optionator).map(v => v[1]);
			}),
			label_en: computed({
				get() {
					return props.modelValue.label_en || props.modelValue.label || ""
				},
				set(label_en) {
					emit('update:modelValue', {
						...props.modelValue,
						label_en,label:''
					});
				}
			}),
      label_fr: computed({
        get() {
          return props.modelValue.label_fr || ""
        },
        set(label_fr) {
          emit('update:modelValue', {
            ...props.modelValue,
            label_fr
          });
        }
      }),
			note_fr: computed({
				get() {
					return props.modelValue.note_fr || ""
				},
				set(note_fr) {
					emit('update:modelValue', {
						...props.modelValue,
						note_fr
					});
				}
			}),
      note_en: computed({
        get() {
          return props.modelValue.note_en || props.modelValue.note || ""
        },
        set(note_en) {
          emit('update:modelValue', {
            ...props.modelValue,
            note_en,note:''
          });
        }
      }),
      soumission: computed({
        get() {
          return props.modelValue.soumission || 0
        },
        set(soumission) {
          emit('update:modelValue', {
            ...props.modelValue,
            soumission
          });
        }
      }),
			name: computed({
				get() {
					return props.modelValue.name
				},
				set(name) {
					emit('update:modelValue', {
						...props.modelValue,
						name
					});
				}
			}),
			variables: computed({
				get() {
					return props.modelValue.vars || {}
				},
				set(vars) {
					emit('update:modelValue', {
						...props.modelValue,
						vars
					});
				}
			}),
			conditions: computed({
				get() {
					return props.modelValue.conditions
				},
				set(conditions) {
					emit('update:modelValue', {
						...props.modelValue,
						conditions
					});
				}
			}),
			destroy($event) {
				confirm.require({
					target: $event.currentTarget,
					message: 'Êtes-vous sure de vouloir supprimer cette partie?',
					icon: 'pi pi-exclamation-triangle',
					acceptClass: 'p-button-danger',
					accept: () => {
						edit.value = false;
						emit('destroy');
					},
					reject: () => {
					}
				});
			}
		}
	}
}
