<template>
  <Toolbar class="p-mb-4">
    <template #left>
      <h1 style="margin: auto">{{$tc('user_log',2)}}</h1>
    </template>
    <template #right>
      <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.back" autofocus/>
    </template>
  </Toolbar>
  <DataTable :value="items" v-if="items.length">
    <Column>
      <template #header>
        {{ $t('user_type') }}
      </template>
      <template #body="{data}">
        {{ userTypes[data.type] }}
      </template>
    </Column>
    <Column>
      <template #header>
        {{ $t('nom') }}
      </template>
      <template #body="{data}">
        {{ user(data.user,'username') }}
      </template>
    </Column>
    <Column>
      <template #header>
        {{ $t('email') }}
      </template>
      <template #body="{data}">
        {{ user(data.user,'email') }}
      </template>
    </Column>
    <Column>
      <template #body="{data}">
        {{ $filters.date(data.login,true) }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import {useStore} from "@/store";
import {t} from "../../i18n";
import {_GET} from "../../helpers/get";

export default {
  name: 'UserCategories',
  components: {
    DataTable: require('primevue/datatable').default,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
  },
  data(){
    return {
      users: [],
      items: []
    };
  },
  setup(props, {emit}) {
    const store = useStore();
    _GET.setProvider(store.radium);

    const userTypes = {
      resellerContact: 'Distributeur',
      admin: 'Utilisateur',
      '':'-'
    }

    return {
      userTypes,
      t
    };
  },
  methods:{
    async getItems(){
      const self = this;
      await _GET.all('userLogs',{sortField:'login',sortOrder:-1}).then(response => {
        self.items = response;
      });
    },
    async getUsers(){
      const self = this;
      await _GET.all('admin').then(response => {
        response.forEach(item => {
          self.users.push(item);
        });
      });
      await _GET.all('resellerContact').then(response => {
        response.forEach(item => {
          self.users.push(item);
        });
      });
      console.log(this.users);
    },
    user(id, key){
      console.log(id);
      const user = this.users.filter(user => user._id==id);
      console.log(user);
      if(user.length>0){
        return user[0][key] ? user[0][key] : '-';
      }
      return {};
    }
  },
  mounted(){
    this.getUsers();
    this.getItems();
  }

}
</script>
