<template>
  <div class="p-field">
    <div class="p-row p-inputtext">
  <div class="p-row">
      <template v-if="files_toupload.length===1&&single"></template>
      <div class="p-col-auto" v-else>
        <input type="file" id="files" multiple @change="handleFileUpload( $event )"/>
        <Button @click="addFiles()" class="p-button p-component p-button-secondary">{{ $t('add_file')}}</Button>
      </div>
    </div>
    <div class="p-row">
      <div v-if="files_toupload.length" class="p-col-12 p-lg-6">
        <div class="p-row p-align-center">
          <div class="p-col-auto">
            <h2>{{ $tc('file_toupload',2)}}</h2>
          </div>
          <div class="p-col-auto">
            <Button @click="submitFile()" class="p-ml-3 p-button p-component p-button-primary">{{ $t('upload_file')}}</Button>
          </div>
        </div>
        <div class="p-row">
          <div v-for="(file, key) in files_toupload" class="p-col-12 p-row">
            <div class="p-col-10">{{ file.name }}</div>
            <div class="p-col-2"><span class="p-button p-button-danger p-d-inline" @click="removeFile( key )"><i class="pi pi-trash"></i></span></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store/index.ts";
import {RadiumVue} from "@radiumjs/vue";
import {RadiumClient} from "@radiumjs/client";
import axios from "axios";

export default {
  data(){
    return {
      files: '',
      files_toupload: [],
      custom_radium: null
    }
  },
  props: {
    upload_dir: String,
    modelValue: Object,
    single: Boolean
  },
  emits: ["update:modelValue"],
  setup(props, {emit: emitO, expose}) {
    function emit(event, ...args) {
      console.log(event, args);
      emitO(event, ...args);
    }
    const single = props.single || false;
    const upload_server = process.env.VUE_APP_UPLOAD_SERVER ? process.env.VUE_APP_UPLOAD_SERVER : '' ;
    return {single,upload_server};
  },
  created(){
    this.custom_radium = useStore().radium;
    return;
    const upload_server = process.env.VUE_APP_UPLOAD_SERVER ? process.env.VUE_APP_UPLOAD_SERVER : '' ;
    console.log('upload url : '+upload_server);
    console.log(process.env);
    if(upload_server){
      this.custom_radium = new RadiumClient("", upload_server);
    }else{
      this.custom_radium = useStore().radium;
    }
  },
  methods: {
    addFiles(){
      document.getElementById('files').click();
    },
    handleFileUpload(event){
      let uploadedFiles = event.target.files;
      for( var i = 0; i < uploadedFiles.length; i++ ){
        this.files_toupload.push( uploadedFiles[i] );
      }
    },
    removeFile( key ){
      this.files_toupload.splice( key, 1 );
    },
    async submitFile(){
      let files = [];
      let formData = new FormData();
      for( let i = 0; i < this.files_toupload.length; i++ ){
        let file = this.files_toupload[i];
        formData.append('files[' + i + ']', file);
      }
      formData.append('dir', this.upload_dir);
      //test
      //this.$emit('update:modelValue', this.files_toupload);
      //this.files_toupload = [];

      const response = await axios.post(
          this.upload_server+"/upload.php",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response => {
        console.log(response);
        this.files_toupload = [];
        if(response.data&&response.data.statut==='success'){
          this.$emit('update:modelValue', response.data.uploaded);
        }else{
          alert(this.$t('error_upload'));
        }
      });

      /*this.custom_radium.api(
          "POST "+this.upload_server+"/upload.php",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response => {
          console.log(response);
          this.files_toupload = [];
          if(response.data&&response.data.statut=='success'){
            this.$emit('update:modelValue', response.data.uploaded);
          }else{
            alert(this.$t('error_upload'));
          }
      });*/
    }
  }
}
</script>

<style>
input[type="file"]{
  position: absolute;
  left: -99999em;
}
</style>
