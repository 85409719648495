
import {computed, defineComponent, h, ref} from 'vue';
import FormRadium from "../FormPrime/FormRadium.vue";
import {Product, SKU, SkuHelper, SkuPart} from "@/helpers/product";
import {useRadium} from "@radiumjs/vue";
import {CrudRepository} from "@radiumjs/client";
import {translatedString} from "@/i18n";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "SkuSelector",
	components: {
		FormRadium,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Listbox: require('primevue/listbox').default,
		InputText: require('primevue/inputtext').default,
		Dropdown: require('primevue/dropdown').default,
    Checkbox: require('primevue/checkbox').default,
    RadioButton: require('primevue/radiobutton').default,
    SkuSelectorProductOption: require('@/components/Sku/SkuSelectorProductOption').default,
	},
	props: {
		product: Object
	},
	emits: ["add", "cancel"],
  data() {
    return {
      productOptions:[],
      availableProductOptions:[]
    }
  },
	setup(props, {emit}) {
    const i18n = useI18n();
		const helper = new SkuHelper(props.product as Product);
		const parts: SkuPart[] = helper.product.sku.parts;

    const selectedProductOptions = [];

		const selectors = Object.entries(helper.views).map(([, module]) => {
			return module.isActive ? module.selector() : undefined;
		}).filter(s => !!s);
		const instances = selectors.map(s => h(s.component));
		const instancesIndex = ref(0);

    console.log(helper.views);
		
		const skus = computed(() => {
			return helper.skus.filter(sku => !selectors.some(selector => !selector.filter(sku)));
		});

    console.log('selectors');
    console.log(selectors);


		const selected = ref<{ [index: number]: number | undefined }>({});
    const defaultselected = ref<{ [index: string]: string | undefined }>({});
		const choices = computed(() => {
			const choices: number[][] = new Array(parts.length).fill(undefined).map(() => {
				return []
			});
			
			for (const sku of skus.value) {
				sku.options.forEach((skuOption, index) => {
					if (!choices[index].includes(skuOption))
						choices[index].push(skuOption);
				});
			}

      console.log('helper.views');
      console.log(helper.views);

			
			return choices.map((options, index) => {
        console.log('choices.map : ' + index);
        console.log('options.length : ' + options.length);
        if (options.length === 1) {
          selected.value[index] = options[0];
					if (helper.views.hider.shouldHideIfPossible(index))
						return;
				}
        console.log('selected.value : ' + selected.value[index]);
        console.log('parts');
        console.log(parts[index]);

        const part = parts[index];
        let size_options = [];
        let size_valueLabel = '';
        if(part.name=='size'&&typeof(selected.value[index])!='undefined'){
          let view = helper.views.sizes;
          size_options = options.map(o => ({
            //@ts-ignore
            label:  view.convertUnit(parts[index].options[o].vars.width.value)+' X '+view.convertUnit(parts[index].options[o].vars.length.value),
            index: o
          }));
          //@ts-ignore
          size_valueLabel = view.convertUnit(parts[index].options[selected.value[index]].vars.width.value)+' X '+view.convertUnit(parts[index].options[selected.value[index]].vars.length.value);

          //check if custom
          const option = part.options[selected.value[index]];
          let sm = false;//Width = max
          let sw = false;//Width between min/max
          if(option.vars&&option.vars.width&&option.vars.width.type=='range'){
            if(view.width.value==option.vars.width.max){
              sm = true;
            }else if(view.width.value>=option.vars.width.min&&view.width.value<=option.vars.width.max){
              sw = true;
            }
          }
          if(sw){
            defaultselected.value['custom'] = 'SW';
          }else if(sm){
            defaultselected.value['custom'] = 'SM';
          }

          if(option.vars&&option.vars.width&&option.vars.width.type=='range'){
            if(view.width.value==option.vars.width.max){
              sm = true;
            }else if(view.width.value>=option.vars.width.min&&view.width.value<=option.vars.width.max){
              sw = true;
            }
          }

          //label = view.convertUnit(view.width.value)+' X '+view.convertUnit(view.length.value);
        }

        //chech defaultselected
        if(defaultselected.value[part.name]&&!selected.value[index]){
          let s: number = -1;
          part.options.map((item,item_i) => {
            if(item.name==defaultselected.value[part.name]){
              s = item_i;
            }
          });
          console.log('defaultselected');
          console.log(defaultselected.value[part.name]);
          console.log(s);
          if(s>=0){
            selected.value[index] = s;
          }
        }

        const view_options = options.map(o => ({
              label:  translatedString('label',parts[index].options[o]) || parts[index].options[o]?.name,
              index: o
            }));
        const valueLabel = translatedString('label',parts[index].options[selected.value[index]]);

        console.log('options');
        console.log(view_options);
				return {
					canCancel: options.length > 1,
					label: translatedString('label',parts[index]),
					part: index,
					options: size_options.length ? size_options : view_options,
					valueLabel: size_valueLabel || valueLabel,
					value: selected.value[index]
				};
			}).filter(e => !!e);
		});
		
		const canAdd = computed(() => {
			for (let i = 0; i < parts.length; i++) {
				if (selected.value[i] === undefined)
					return false;
			}
			return true;
		});

    const canNextInstance = computed(() => {
      return selectors[instancesIndex.value].done.value;
    })
		
		return {
			instances,
			instance: computed(() => {
				return instances[instancesIndex.value];
			}),
      canNextInstance,
      isInvalidValue: computed(() => {
        console.log(selectors[instancesIndex.value].init.value+' : '+canNextInstance.value);
        if(selectors[instancesIndex.value].init.value&&!canNextInstance.value){
          return true;
        }
        return false;
      }),
      invalidMessage: computed(() => {
        return i18n.t('notify_invalid');
      }),
			nextInstance() {
				instancesIndex.value++
			},

      selectedProductOptions,
			
			choices,
			selected,
			choose(opt, i) {
        console.log('choose');
        console.log(opt);
        console.log(i);
				if (opt === undefined)
					delete selected.value[i]
				else
					selected.value[i] = opt;
			},
			
			notes: computed(() => {
				return Object.entries(selected.value).map(([i, s]) => {
					const option = parts[i].options[s];
					return {
						label: parts[i].label,
						text: option.note
					};
				}).filter(n => !!n.text);
			}),
			
			canAdd,
			reset() {
				instancesIndex.value = 0;
				selected.value = {};
			},
      updateSelectedProductOption(index,option){
        console.log('updateSelectedProductOption '+index);
        console.log(option);
        selectedProductOptions[index] = option;
      },
			add() {
        console.log('add');
				const sku = Object.values(selected.value).reduce((a, oI, pI) => {
          a.sku += parts[pI].options[oI].name;
					a.options[pI] = oI;
          console.log(parts[pI].options[oI].name+' : '+pI+' : '+oI);
          return a;
				}, {sku: "", options: []} as SKU);

        const params = {
          productOptions: selectedProductOptions
        };
        console.log('params');
        console.log(params);
				const product = helper.build(sku,params);

        console.log(product);
				emit('add', product);
			}
		};
	},
  methods:{
    async getGlobalOptions(){
      const radium = useRadium();
      const crude = new CrudRepository(radium, 'productOptions');
      const cats = await crude.list({},{rows: 50,first: 0}).then(function (response) {
        return response.items;
      });
      this.productOptions = cats;
      this.setAvailableProductOptions();
    },
    setAvailableProductOptions(){
      this.availableProductOptions = this.productOptions.filter(item => this.$props.product.productOptions.includes(item._id));
      console.log(this.availableProductOptions);
    },
    getOptionName(option){
      if(!option){
        return '';
      }
      if(option['name_'+this.$i18n.locale]){
        return option['name_'+this.$i18n.locale];
      }
      return option.name;
    },
    chooseProductOptions(id){
      const exists = this.selectedProductOptions.filter(item => item.id===id);
      console.log('exists');
      console.log(exists);
      if(exists.length){
        const _new = [];
        for(let i in this.selectedProductOptions){
          if(i != id){
            _new[i] = this.selectedProductOptions[i];
          }
        }
        this.selectedProductOptions = _new;
        return false;
      }else{
        this.selectedProductOptions[id] = {id:id};
        return true;
      }
    }
  },
  mounted() {
    this.getGlobalOptions();
  }
});
