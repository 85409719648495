import './assets/css/all.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
/*
import {createI18n,useI18n} from "vue-i18n";
import { languages, defaultLocale } from './locales/index.js';
const messages = Object.assign(languages);
// VueI18n instance
const i18nOptions = {
    locale: defaultLocale, // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages
};
const i18n = createI18n(i18nOptions);
*/
import {App, createApp} from "vue";
import {keyRadiumVue} from "@radiumjs/vue";
import router from './router';
import {Store} from '@/store';
import i18n from "./i18n";
import moment from "moment";
const app: App = create(require('./App.vue').default);

const store = new Store(router, 'mattech');



setup(app, router, store, i18n);

mount(app);

export function create(App) {
    return createApp(App);
}

export function setup(app: App, router, store, i18n) {
    router.beforeEach(async (to, from, next) => {
        //console.log('check route permission : '+to.meta.permission);
        //console.log(store.permissions.has(to.meta.permission));
        const has = store.permissions.has(to.meta.permission);
        if(has){
            console.log('continue');
            next();
        }else{
            console.log('forbidden route');
            next({path:""});
        }
    });

    app.use(router);
    app.use(store);
    app.use(i18n);
    app.provide(keyRadiumVue, store.radium);
    app.use(require('primevue/config').default);
    app.use(require('primevue/toastservice').default);
    app.use(require('primevue/confirmationservice').default);
    app.mixin({
        methods:{
            translatedString(key,parent){
                const l = this.$i18n.locale;
                if(parent&&parent[key+'_'+l]){
                    return parent[key+'_'+l];
                }else if(parent&&parent[key]){
                    return parent[key];
                }
                return '';
            },
            translatedFieldName(key){
                const l = this.$i18n.locale;
                return key+'_'+l;
            },
            $money(val: any) {
                if(val){
                    let n: any = parseFloat(val);
                    n = isNaN(n) ? '0' : n;
                    return n.toFixed(2) + " $";
                }
                return '0.00 $';
            },
            _t(key: any) {
                return this.$t(key);
            },
            _tc(key: any, index: number) {
                return this.$tc(key,index);
            }
        }
    });
    
    app.directive('badge', require('primevue/badgedirective').default);
    app.directive('ripple', require('primevue/ripple').default);
    app.directive('tooltip', require('primevue/tooltip').default);

    /*app.config.globalProperties.$permissions = {
        list: function(){
            if(router.currentRoute.value.meta&&router.currentRoute.value.meta.permission){
                const routes = router.currentRoute.value.meta.permission.split('.');
                routes.splice(-1);
                return store.permissions.has(routes.join('.')+'.list');
            }
            return true;
        },
        add: function(){
            if(router.currentRoute.value.meta&&router.currentRoute.value.meta.permission){
                const routes = router.currentRoute.value.meta.permission.split('.');
                routes.splice(-1);
                return store.permissions.has(routes.join('.')+'.add');
            }
            return true;
        },
        edit: function(){
            if(router.currentRoute.value.meta&&router.currentRoute.value.meta.permission){
                const routes = router.currentRoute.value.meta.permission.split('.');
                routes.splice(-1);
                return store.permissions.has(routes.join('.')+'.edit');
            }
            return true;
        },
        delete: function(){
            if(router.currentRoute.value.meta&&router.currentRoute.value.meta.permission){
                const routes = router.currentRoute.value.meta.permission.split('.');
                routes.splice(-1);
                return store.permissions.has(routes.join('.')+'.delete');
            }
            return true;
        },
        has: function(r){
            return store.permissions.has(r);
        }
    };
    app.provide('permissions', app.config.globalProperties.$permissions);*/
    
    app.config.globalProperties.$icons = require('@/helpers/icons').icons;
    app.config.globalProperties.$filters = {
        bytes: function (data, to) {
            const const_term = 1024;

            // Convert the values and concatenate
            // the appropriate unit
            if (to === "KB") {
                return (data / const_term).toFixed(3) + "KB";
            } else if (to === "MB") {
                return (data / const_term ** 2).toFixed(3) + "MB";
            } else if (to === "GB") {
                return (data / const_term ** 3).toFixed(3) + "GB";
            } else if (to === "TB") {
                return (data / const_term ** 4).toFixed(3) + "TB";
            } else {
                return "Please pass valid option";
            }
        },
        date: function(d,time){//d = 2022-07-29T14:27:04-04:00
            const _date = moment(d);
            let format = 'YYYY-MM-DD';
            if(time){
                format += ' HH:mm';
            }
            return _date.format(format);
        }
    }

    app.config.globalProperties.$superadmin = () => {
        const sa = ['62168a8a2de203344715e2b1'];
        return sa.includes(store.auth.user_info.value.id);
    }

}

export function mount(app) {
    app.mount('#app');
}
