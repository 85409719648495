<template>
	<TableLayout service="lead" single-route-name="leads.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('lead',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button label="Nouveau" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)"/>
		</template>
		
		<template #default="{open}">
			<Column field="status" sortable :header="$tc('statut',2)"></Column>
			<Column field="name" sortable :header="$t('nom')"></Column>
			<Column field="contact" sortable :header="$tc('contact',1)"></Column>
			<Column field="raison" sortable :header="$tc('reason',1)"></Column>
			<Column field="_id" sortable header="ID"></Column>
		</template>
	</TableLayout>
</template>

<script>
import FormRadium from "@/components/FormPrime/FormRadium";
import FormInput from "@/components/FormPrime/FormInput";
import FormDialog from "@/components/FormPrime/FormDialog";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
	name: 'Leads',
	components: {
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
  data(){
    return {
      column_filters: {}
    };
  },
	setup(props, {emit}) {
    const enabled_actions = [];
    const actions = [];
    let filters = {};
		return {
      enabled_actions,
      actions,
      filters
    };
	}
}
</script>
