export const provinces = [
	{value: 'QC', label: "Québec"},
	{value: 'ON', label: "Ontario"},
	{value: 'AB', label: "Alberta"},
	{value: 'BC', label: "Colombie-Britannique"},
	{value: 'PE', label: "Île-du-Prince-Édouard"},
	{value: 'MB', label: "Manitoba"},
	{value: 'NB', label: "Nouveau-Brunswick"},
	{value: 'NS', label: "Nouvelle-Écosse"},
	{value: 'NU', label: "Nunavut"},
	{value: 'SK', label: "Saskatchewan"},
	{value: 'NL', label: "Terre-Neuve-et-Labrador"},
	{value: 'NT', label: "Territoires du Nord-Ouest"},
	{value: 'YK', label: "Yukon"},
];
