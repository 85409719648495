
import {computed, defineComponent} from "vue";

export default defineComponent({
	name: "HideIfPossible",
	components: {
		Checkbox: require("primevue/checkbox").default
	},
	props: ["modelValue"],
	setup(props, {emit}) {
		return {
			value: computed({
				get() {
					return props.modelValue;
				},
				set(val) {
					emit("update:modelValue", val);
				}
			})
		}
	}
})
