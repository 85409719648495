<template>
    <Toolbar class="p-mb-4">
      <template #left>
        <h1 style="margin: auto">{{ $t('shipping') }} - {{ $tc('shipping_carrier',2) }}</h1>
      </template>
      <template #right>
        <Button class="p-button-success p-mr-2" :label="$t('save')" icon="pi pi-save" @click="update" autofocus/>
        <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus/>
      </template>
    </Toolbar>
  <DataTable :value="shipping" key="value" v-if="shipping.length" class="p-mb-3">
    <Column :header="$t('sku')">
      <template #body="{data}">
        <InputText type="text" v-model="data.sku"/>
      </template>
    </Column>
    <Column :header="$t('description')">
      <template #body="{data}">
        <div class="p-row">
          <div class="p-field p-col-12 p-p-0"><InputText type="text" :placeholder="$t('description')+' (fr)'" v-model="data.description_fr"/></div>
        </div>
      </template>
    </Column>
    <Column header="">
      <template #body="{data,index}">
        <span class="p-button p-button-danger p-d-inline" @click="destroy(index)"><i class="pi pi-trash"></i></span>
      </template>
    </Column>
  </DataTable>
  <div class="p-row p-inputtext ">
    <div class="p-row">
      <div class="p-col-auto">
        <Button @click="add()" class="p-button p-component p-button-secondary">{{ $t('add')}}</Button>
      </div>
    </div>
  </div>
  <Toolbar class="p-mt-4">
    <template #left>
      <slot name="toolbar-left"></slot>
    </template>

    <template #right>
      <Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="update" autofocus/>
    </template>
  </Toolbar>
</template>

<script>

import DataTable from 'primevue/datatable';
import {CrudRepository} from "@radiumjs/client";
import {useRadium} from "@radiumjs/vue";


export default {
  name:"ShippingAddons",
  emits: ["save", "update"],
  components: {
    DataTable,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
    Checkbox: require('primevue/checkbox').default,
  },
  data() {
    return {
      shipping:[],
      id: null
    }
  },
  setup(props, {emit, slots}){
    return {};
  },

  methods:{
    repo(){
      if(!this.radium){
        this.radium = useRadium();
      }
      return new CrudRepository(this.radium, 'setting');
    },
    async getShipping(){
      const settings = await this.repo().list({},{rows: 50,first: 0}).then(function (response) {
        return response.items;
      });

      const shipping = settings.filter(item => Object.keys(item).includes('shipping'));
      console.log(shipping);
      if(shipping.length==1){
        this.id = shipping[0]['_id'];
        this.shipping = shipping[0]['shipping'];
      }
    },
    add() {
      this.shipping = [...this.shipping, {}]
    },
    update(){
      let obj = {};
      obj['shipping'] = this.shipping;
      if(this.id!=null){
        this.repo().update(this.id,{$set:obj});
      }else{
        const response = this.repo().create(obj);
        if(response.insertedId){
          this.id = response.insertedId;
        }
      }
    },
    destroy(index){
      console.log('destroy '+index);
      const c = confirm($t('confirm_remove_item'));
      if(!c){
        return false;
      }
      this.shipping.splice( index, 1 );
    },
  },
  mounted() {
    this.getShipping();
  }
}
</script>

<style>
td{
  vertical-align: top;
}
</style>
