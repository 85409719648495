<template>
  <div class="p-row p-col-12">
    <p>Sélectionnez les sections que vous voulez autoriser l'accès.</p>
  </div>
  <div class="p-row p-col-12 p-inputtext p-mb-2">
    <div class="p-col-12 field-checkbox p-d-flex">
      <Button label="Tout sélectionner" icon="pi pi-check" class="p-button-secondary p-mr-2" @click="checkAll"/>
    </div>
  </div>
  <div class="p-row p-col-12 p-inputtext p-mb-2" v-for="(item,ikey) of itemsComputed">
    <div class="p-col-12 field-checkbox p-d-flex">
      <Checkbox name="check" :value="item.permission" v-model="checked" class="p-mr-2" @change="update()" v-if="!item.items&&item.permission" /> {{item.label}}
    </div>
    <div class="p-col-12 field-checkbox p-d-flex p-ml-5" v-if="item.items" v-for="(child, ckey) of item.items">
      <Checkbox name="check" :value="child.permission" v-model="checked" class="p-mr-2" @change="update()" /> {{child.label}}
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import {useRouter} from "vue-router";
import {useStore} from "@/store";
import {ref} from "vue";
import {_GET} from "../../helpers/get";

export default {
	name: 'UserPermissions',
	components: {
    Checkbox,
    Button,
    _GET
	},
  props:{
    selected: Array
  },
  data(){
    return {items:[]};
  },
	setup(props, {emit}) {
    const radium = useStore().radium;
    _GET.setProvider(radium);

    let checked = ref([]);
    if(props.selected){
      props.selected.forEach(item => {
        checked.value.push(item);
      });
    }

    let permissions_addon = [
      {i18n:"menu_add",permission:"resellers.add",after:'resellers.list'},
      {i18n:"menu_delete",permission:"resellers.delete",after:'resellers.edit'},
      {i18n:"menu_distributeur_contact_list",permission:"resellers.contact.list",after:'resellers.edit'},
      {i18n:"menu_contact_add",permission:"resellers.contact.add",after:'resellers.edit'},
      {i18n:"menu_distributeur_contact_delete",permission:"resellers.contact.delete",after:'resellers.contact.edit'},

      {i18n:"menu_distributeur_shipping_list",permission:"resellers.shipping.list",after:'resellers.edit'},
      {i18n:"menu_shipping_add",permission:"resellers.shipping.add",after:'resellers.shipping.edit'},
      {i18n:"menu_distributeur_shipping_delete",permission:"resellers.shipping.delete",after:'resellers.shipping.edit'},

      {i18n:"escompte",permission:"submission.discount",after:'submissions.edit'},
      {i18n:"menu_options_add",permission:"products.options.add",after:'products.options.edit'},
      {i18n:"menu_categories_add",permission:"products.categories.add",after:'products.categories.edit'},

    ];

		return {
      checked,
      permissions_addon,
      update(){
        emit('update:modelValue',checked.value);
      }
    };
	},
  methods:{
    createMenu(){
      const router = useRouter();
      const store = useStore();
      let menuItems = [];

      router.options.routes.forEach(route => {
        const m = this._menu(route);
        if(m.label){
          menuItems.push(m);
          if(this.permissions_addon){
            const addons = this.permissions_addon.filter(i => {
              return i.after===m.permission;
            });
            addons.forEach(i => {
              const m = this._menu(i);
              if(m.permission){
                menuItems.push(m);
              }
            });
          }
        }
      });

      this.items = menuItems;

      console.log(menuItems);
      return menuItems;
    },
    _menu(item){//item = route
      if(item.i18n) {
        let args = {
          label: this.$t(item.i18n),//i18n key
          key: item.i18n,
          icon: item.meta?.icon
        };
        if (item.path) {
          args.to = item;
        }
        if (item.meta?.permission) {
          args.permission = item.meta.permission;
        }else{
          if(Object.keys(item).includes('permission')){
            args.permission = item.permission;
          }
        }
        if(item.children) {
          let children = [];
          item.children.forEach(child => {
            const m = this._menu(child);
            if(m.label){
              children.push(m);

              if(this.permissions_addon){
                const addons = this.permissions_addon.filter(i => {

                  return i.after===m.permission;
                });
                addons.forEach(i => {
                  const m = this._menu(i);
                  if(m.permission){
                    children.push(m);
                  }
                });
              }
            }
          });

          if(children.length){
            args.items = children;
            args.to = false;
          }
        }



        return args;
      }
      return {};
    },
    checkAll(){
      this.items.forEach(item => {
        if(item.items){
          item.items.forEach(child => {
            if(child.permission){
              this.checked.push(child.permission);
            }
          });
        }else if(item.permission){
          this.checked.push(item.permission);
        }
      });
      this.update();
    }
  },
  computed: {
    itemsComputed() {
      return this.items
    }
  },
  mounted(){
    this.createMenu();
  }
}
</script>
