
import {ComputedRef, defineComponent, inject, Ref, ref, watchEffect} from "vue";
import {ModulesType, SkuHelper} from "@/helpers/product";

type Modules = {
	[name in keyof ModulesType]: {
		module: ModulesType[name],
		configurator: ReturnType<ModulesType[name]['configurator']>,
		view: Ref<ReturnType<ModulesType[name]['view']>>
	}
}

export default defineComponent({
	name: "SkuSelectorConfiguration",
	components: {
		Panel: require("primevue/panel").default,
		Checkbox: require("primevue/checkbox").default,
	},
	props: ["modelValue"],
	emits: ["update:modelValue"],
	setup(props, {emit}) {
		const modules: Modules = Object.entries(SkuHelper.modules).reduce((a, v) => {
			const view = ref();
			a[v[0]] = {
				module: v[1],
				configurator: v[1].configurator(view),
				view
			};
			return a;
		}, {} as Modules)
		
		const helper = inject("productHelper") as ComputedRef<SkuHelper>;
		watchEffect(() => {
			Object.entries(modules).forEach(([k, m]) => {
				m.view.value = helper.value.views[k];
			});
		});
		
		return {
			modules: Object.values(modules)
		}
	}
});
