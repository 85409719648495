<template>
	<TableLayout service="admin" single-route-name="users.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('user',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button label="Nouveau" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)"  v-if="$store.permissions.add()"/>
      <Button :label="$tc('user_categorie',2)" icon="pi pi-cog" class="p-button-success p-mr-2" @click="$router.push({name:'users.categories.list'})" v-if="$store.permissions.has('users.categories.list')"/>
      <Button :label="$tc('user_log',2)" icon="pi pi-cog" class="p-button-success p-mr-2" @click="$router.push({name:'users.logs.list'})" v-if="$store.permissions.has('users.logs.list')"/>
		</template>
		
		<template #default="{open}">
			<Column field="username" sortable>
        <template #header>
          {{ $t('nom') }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
			</Column>
      <Column field="email" sortable>
        <template #header>
          {{ $t('email') }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>
      <Column sortable>
        <template #header>
          Type
        </template>
        <template #body="{data}">
          {{ typesMap[data.type] }}
        </template>
      </Column>
      <Column sortable>
        <template #header>
          {{ $tc('statut',1) }}
        </template>
        <template #body="{data}">
          {{ statusMap[data.status] }}
        </template>
      </Column>
		</template>
	</TableLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {useStore} from "@/store";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";


export default {
	name: 'Users',
	components: {
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
  data(){
    return {
      column_filters: {
        username: 'string',
        email: 'string'
      }
    };
  },
	setup(props, {emit}) {
		const radium = useStore().radium;
		const toast = useToast();
    const i18n = useI18n();
    const enabled_actions = [];

    let filters = {
      'username': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
    };
		
		const statusOptions = ref([
			{
				name: i18n.t('actif'),
				code: 1
			},
			{
				name: i18n.t('inactif'),
				code: 0
			}
		]);

    const typesOptions = ref([
      {
        name: "Administrateur",
        code: 1
      },
      {
        name: "Vendeur",
        code: 2
      }
    ]);

		return {
      enabled_actions,
      filters,
			actions: [
				{
					label: i18n.t('reset_password'),
					icon: "pi pi-envelope",
					command(data) {
						radium.api('resetPassword', {email: data.email}).then(() => {
							toast.add({
								severity: "success",
								summary: i18n.t('email_sent'),
								life: 5000
							});
						})
					}
				}
			],
			hiddenColumns: ref([
				'_id',
			]),
			tabs: {
				info: "Infos",//custom
				notable: true,//preMade
				timeData: true,//preMade
			},
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = v.name;
					return a
				}, {});
			}),
      typesMap: computed(() => {
        return typesOptions.value.reduce((a, v) => {
          a[v.code] = v.name;
          return a
        }, {});
      })
		};
	}
}
</script>
