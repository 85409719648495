<template>
  <DataTable :value="itemsComputed" class="p-mb-3 no-header" :rowHover="true">
    <Column field="label"></Column>
    <Column header="">
      <template #body="{data,index}">
        <Button @click="$router.push(data.to)" class="p-button p-component p-button-secondary">{{ $t('voir')}}</Button>
      </template>
    </Column>
  </DataTable>
</template>

<script>

import {useRouter} from "vue-router";
import {useStore} from "@/store";


export default {
  name:"ConfigDefault",
  emits: ["save", "update"],
  components: {
    DataTable: require('primevue/datatable').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
  },
  data(){
    return {
      items: []
    }
  },
  setup(props, {emit, slots}){
    return {};
  },
  methods:{
    createMenu(){
      const router = useRouter();
      const store = useStore();
      let menuItems = [];

      router.options.routes.forEach(route => {
        if(route.path==='/config'){//load only config
          if(route.children) {
            route.children.forEach((child,child_i)=> {
              let m = this._menu(child);
              if(m.label){
                menuItems.push(m);
              }
            });
          }
        }
      });

      this.items = menuItems;
      return menuItems;
    },
    _menu(item){//item = route
      const store = useStore();
      let hide = false;
      if(item.i18n) {
        let args = {
          label: this.$t(item.i18n),//i18n key
          key: item.i18n,
          icon: item.meta?.icon,
          hide: false
        };
        if (item.path) {
          args.to = item;
        }

        if(item.meta?.permission){
          if(!store.permissions.has(item.meta.permission)){
            args.to = false;
            args.hide = true;
          }
        }

        if((!args.items&&!args.to)||args.hide){
          return {};
        }

        return args;
      }
      return {};
    }
  },
  computed: {
    itemsComputed() {
      return this.items
    }
  },
  mounted(){
    this.createMenu();
  }
}
</script>

<style>
.no-header thead{
  display: none;
}
td{
  vertical-align: middle;
}
</style>
