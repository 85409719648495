import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "p-p-2 p-shadow-5 p-d-flex p-flex-column",
  style: {"min-height":"100%"}
}
const _hoisted_2 = {
  class: "p-p-2 p-d-flex",
  style: {"height":"100%","flex-grow":"1"}
}
const _hoisted_3 = {
  key: 1,
  style: {"flex-grow":"1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toolbar, { class: "p-mb-4" }, {
      left: _withCtx(() => [
        _renderSlot(_ctx.$slots, "toolbar-left", _normalizeProps(_guardReactiveProps(_ctx.toolbar)))
      ]),
      right: _withCtx(() => [
        _renderSlot(_ctx.$slots, "toolbar-right", _normalizeProps(_guardReactiveProps(_ctx.toolbar)), () => [
          (_ctx.$props.saveButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.$t('save'),
                icon: "pi pi-save",
                class: "p-button-success p-mr-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.single.save()))
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.$props.backButton&&!_ctx.$props.returnOnSave)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                label: _ctx.$t('back'),
                icon: "pi pi-arrow-left",
                class: "p-button-secondary p-mr-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$props.backButtonRoute ? _ctx.$router.push({name:_ctx.$props.backButtonRoute}) : _ctx.$router.back()))
              }, null, 8, ["label"]))
            : (_ctx.$props.returnOnSave)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 2,
                  label: _ctx.$t('back'),
                  icon: "pi pi-arrow-left",
                  class: "p-button-secondary p-mr-2",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({name:_ctx.$props.returnOnSave})))
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
            key: 0,
            class: "p-m-auto"
          }))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", _mergeProps(_ctx.single, { key: _ctx.saveCount }))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}