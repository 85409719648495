import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return ($setup.items)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Button, {
          type: "button",
          icon: "pi pi-list",
          onClick: $setup.toggle
        }, null, 8, ["onClick"]),
        _createVNode(_component_Menu, {
          ref: "menu",
          popup: true,
          model: $setup.items
        }, null, 8, ["model"])
      ]))
    : _createCommentVNode("", true)
}