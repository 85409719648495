export class Indexer {
    private readonly len: number;
    public readonly indexes: number[];
    private readonly maxes: number[];
    
    constructor(maxes: number[]) {
        this.len = maxes.length;
        this.maxes = maxes;
        this.indexes = new Array(this.len).fill(0, 0, this.len);
    }
    
    /**
     * @return if indexes is maxed
     */
    incrementIndexes(): void | true {
        for (let i = this.len - 1; i >= 0; i--) {
            this.indexes[i]++;
            
            if (this.indexes[i] < this.maxes[i])
                break;
            else if (i === 0)
                return true;//the end
            else
                this.indexes[i] = 0;
        }
    }
}
