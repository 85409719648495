<template>
	<SingleLayout service="productOptions" :schema="schema"  :return-on-save="false">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('product_option',2)}}</h1>
		</template>

		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{$t('information')}}</span>
				</template>
        <template #option-header>
          <span>{{$tc('sub_option',2)}}</span>
        </template>
				<template #info>
          <div class="p-row">
            <div class="p-col-12 p-md-6">
              <div class="p-field p-mt-4 p-col-12">
                <div class="p-float-label">
                  <InputText id="name" type="text" v-model="item.name" @change="update('$set','name',$event.target.value)"/>
                  <label for="name">{{$t('nom')}}</label>
                </div>
              </div>
              <div class="p-field p-mt-4 p-col-12">
                <div class="p-float-label">
                  <Dropdown id="optionType" v-model="item.optionType" :options="optionsTypes" optionLabel="name" @change="update('$set','optionType',$event.value)" />
                  <label :for="optionType">{{$t('applicable')}}</label>
                </div>
              </div>
              <div class="p-field  field-checkbox p-mt-4 p-col-12">
                  <Checkbox :id="sousOption" :value="1" :binary="true" v-model="item.sousOption" class="p-mr-3" @change="update('$set','sousOption',item.sousOption)"/>
                  <label :for="sousOption">{{$tc('sub_option',1)}}</label>
              </div>
            </div>
            <div class="p-col-12 p-md-6" v-if="!item.sousOption">
              <edit-product-sous-option :model-value="item.option?item.option:{}" :parent="item" @update:model-value="updateSingle(item,update,$event)"></edit-product-sous-option>
            </div>
          </div>
				</template>

        <template #option>
          <div class="p-row">
            <div class="p-row p-col-12" v-for="(key, index) in item.options"  v-if="item.sousOption">
              <edit-product-sous-option  :index="index" :model-value="key" :parent="item" @update:model-value="updateOption(item,$event,index,update)" @destroy="destroyOption(item,index)"></edit-product-sous-option>
            </div>

            <div class="p-row p-col-12" v-if="item.sousOption">
              <Button :label="$t('add_sub_option')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="addOption(item)"/>
            </div>
            <div class="p-row p-col-12" v-else>
              <div class="p-field p-mt-4 p-col-12">
                {{$t('notice_sub_option')}}
              </div>
            </div>
          </div>
        </template>
			</FormRadium>
		</template>

	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import EditProductSousOption from './EditProductSousOption';
import {computed, ComputedRef, defineComponent, inject, ref} from "vue";
import {useConfirm} from "primevue/useconfirm";
import {useI18n} from "vue-i18n";

export default {
	name: 'EditProductOption',
	components: {
    EditProductSousOption,
    SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
    Checkbox: require('primevue/checkbox').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,

	},
  props:{
    item: {
      option: {}
    }
  },
  data() {
    return {}
  },
	setup(props, {emit}) {
    const i18n = useI18n();
		return {
			tabs: {
				info: true,
        option: true
			},
      optionsTypes: [
        {name: i18n.t('optiontype_contour'), code: 'contour'},
        {name: i18n.t('optiontype_coin'), code: 'coin'},
        {name: i18n.t('optiontype_unique'), code: 'unique'},
      ]
		};
	},
  methods:{
    updateSingle(item, update, event) {
      console.log(event);
      if(!item.option){
        item.option = {};
      }
      item.option = {...item.option, ...event};
      update('$set','option',item.option);
    },
    addOption(item) {
      console.log(item);
      if(!item.options){
        item.options = [];
      }
      item.options = [...item.options, {}]
    },
    updateOption(item, newOpt, i, update) {
      const newOpts = [...item.options];
      newOpts[i] = newOpt;
      item.options = newOpts;
      update('$set','options',item.options);
    },
    destroyOption(item, i) {
      const newOpts = [...item.options];
      newOpts.splice(i, 1);
      item.options = newOpts;
    },
  }
}
</script>

<style scoped>
#sousOptions {
  display:none;
}
</style>
