import Validator from "fastest-validator";
import i18n from "../i18n";


export const v = new Validator({
	messages: {
		stringMin: i18n.global.t('message_form_min'),
		string: i18n.global.t('message_form_fill'),
		number: i18n.global.t('message_form_number')
	}
});
