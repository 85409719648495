<template>
	<Menubar :model="menu" class="p-my-4 p-p-3 p-shadow-3">
		<template #start>
			<img src="https://lithiummarketing.com/images/lithiummarketing/logo.png" class="p-mr-3" alt="">
		</template>
		<template #end>
			<Button icon="pi pi-th-large" @click="visibleFull = true"/>
			<Button class="p-ml-3" badge="5" badgeClass="p-badge-danger" icon="pi pi-bell" @click="visibleNotif = true" style="width: auto;padding: 0.5rem"/>
			<Button class="p-ml-3" icon="pi pi-power-off" @click="logout" :style="{'margin-left': '0 .5em'}"/>
		</template>
	</Menubar>
	<Dialog v-model:visible="visibleFull" :baseZIndex="1" :dismissableMask="true"
	        :modal="true" :maximizable="true" position="topright" :style="{'max-width': '1400px',width: '100%'}"
	>
		<template #header>
			<h3>Dashboard</h3>
		</template>
		<dashboard @closeDash="visibleFull = false"></dashboard>
	</Dialog>
	<Sidebar v-model:visible="visibleNotif" :baseZIndex="1000" position="right">
		<h3>Notification</h3>
	</Sidebar>
</template>
<script>
import {computed, ref, Ref, UnwrapRef} from 'vue';
import Dashboard from "@/components/Dashboard/Dashboard";

const menu = [
	{
		label: 'pages',
		icon: 'pi pi-fw pi-directions',
		items: [
			{
				label: 'Tâche',
				icon: 'pi pi-fw pi-calendar',
				to: '/tasks'
			}
		]
	}
];

export default {
	components: {
		Dashboard,
		Sidebar: require('primevue/sidebar').default,
		Dialog: require('primevue/dialog').default,
		Menubar: require('primevue/menubar').default,
		Button: require('primevue/button').default,
		Checkbox: require('primevue/checkbox').default,
	},
	setup() {
		/**
		 * @type {Ref<UnwrapRef<boolean|string>>}
		 */
		const visibleMenu = ref(false);
		
		// useRouter().beforeEach(()=>{
		// 	visibleMenu.value = false;
		// });
		
		return {
			menu: ref(menu),
			visibleFull: computed({
				get: () => visibleMenu.value === "Full",
				set: v => visibleMenu.value = v ? "Full" : false
			}),
			visibleNotif: computed({
				get: () => visibleMenu.value === "Notif",
				set: v => visibleMenu.value = v ? "Notif" : false
			}),
			dashboardEdit: ref(false)
		};
	},
	methods: {
		logout() {
			this.$store.dispatch('logOut').then(() => {
			
			});
		}
	},
	computed: {
		name() {
			const user = this.$store.state.user_info;
			return user?.first_name + ' ' + user?.last_name;
		}
	}
}
</script>
<style scoped>

</style>
