<template #option v-if="modelValue && modelValue.length">
  <div class="p-col-12 p-row p-border-b">
    <div v-for="item of modelValue" :key="value" class="field-checkbox p-md p-col-12">
      <Checkbox :id="item[value]" :name="group" :value="item[value]" class="p-mr-3" v-model="checked" @change="update(item[value])"/>
      <label :for="item[value]">{{item[name]}}</label>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue';
export default {
  name: 'CheckboxGroup',
	props: {
		modelValue: Array,
    selected: Array,
    group: String,
    name: String,
    value: String,
    response: String
	},
	components: {
    Checkbox: require('primevue/checkbox').default,
	},
	data() {
    return {}
	},
  setup(props, {emit}){
      let checked = ref([]);

      if(props.selected){
        props.selected.forEach(item => {
          if(typeof(item)!=='object'){
            console.log(item);
            checked.value.push(item);
          }

        });
      }

      let response = 'single';
      if(props.response){
        response = props.response;
      }

      return {
        checked,
        response,
        update(clicked){
          let event = checked.value;
          if(response==='array'){
            event = [checked.value,clicked];
          }
          emit('update:modelValue',event);
        }
      };
  },
	methods: {

	},
	computed: {

	}
}
</script>

<style scoped>
.field-checkbox::v-deep(.p-checkbox) {
  vertical-align: middle;
}
</style>
