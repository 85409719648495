export const icons = {
	"pi-times": "pi pi-times",
	"pi-bars": "pi pi-bars",
	"pi-cog": "pi pi-cog",
	"pi-circle-on": "pi pi-circle-on",
	"scale-active": "pi pi-circle-on scale-active",
	"pi-check": "pi pi-check",
	"pi-spinner": "pi pi-spinner",
	"pi-align-center": "pi pi-align-center",
	"pi-align-justify": "pi pi-align-justify",
	"pi-align-left": "pi pi-align-left",
	"pi-align-right": "pi pi-align-right",
	"pi-amazon": "pi pi-amazon",
	"pi-android": "pi pi-android",
	"pi-angle-double-down": "pi pi-angle-double-down",
	"pi-angle-double-left": "pi pi-angle-double-left",
	"pi-angle-double-right": "pi pi-angle-double-right",
	"pi-angle-double-up": "pi pi-angle-double-up",
	"pi-angle-down": "pi pi-angle-down",
	"pi-angle-left": "pi pi-angle-left",
	"pi-angle-right": "pi pi-angle-right",
	"pi-angle-up": "pi pi-angle-up",
	"pi-apple": "pi pi-apple",
	"pi-arrow-circle-down": "pi pi-arrow-circle-down",
	"pi-arrow-circle-left": "pi pi-arrow-circle-left",
	"pi-arrow-circle-right": "pi pi-arrow-circle-right",
	"pi-arrow-circle-up": "pi pi-arrow-circle-up",
	"pi-arrow-down": "pi pi-arrow-down",
	"pi-arrow-left": "pi pi-arrow-left",
	"pi-arrow-right": "pi pi-arrow-right",
	"pi-arrow-up": "pi pi-arrow-up",
	"pi-backward": "pi pi-backward",
	"pi-ban": "pi pi-ban",
	"pi-bell": "pi pi-bell",
	"pi-book": "pi pi-book",
	"pi-bookmark": "pi pi-bookmark",
	"pi-briefcase": "pi pi-briefcase",
	"pi-calendar": "pi pi-calendar",
	"pi-calendar-minus": "pi pi-calendar-minus",
	"pi-calendar-plus": "pi pi-calendar-plus",
	"pi-calendar-times": "pi pi-calendar-times",
	"pi-camera": "pi pi-camera",
	"pi-caret-down": "pi pi-caret-down",
	"pi-caret-left": "pi pi-caret-left",
	"pi-caret-right": "pi pi-caret-right",
	"pi-caret-up": "pi pi-caret-up",
	"pi-chart-bar": "pi pi-chart-bar",
	"pi-chart-line": "pi pi-chart-line",
	"pi-check-circle": "pi pi-check-circle",
	"pi-check-square": "pi pi-check-square",
	"pi-chevron-circle-down": "pi pi-chevron-circle-down",
	"pi-chevron-circle-left": "pi pi-chevron-circle-left",
	"pi-chevron-circle-right": "pi pi-chevron-circle-right",
	"pi-chevron-circle-up": "pi pi-chevron-circle-up",
	"pi-chevron-down": "pi pi-chevron-down",
	"pi-chevron-left": "pi pi-chevron-left",
	"pi-chevron-right": "pi pi-chevron-right",
	"pi-chevron-up": "pi pi-chevron-up",
	"pi-circle-off": "pi pi-circle-off",
	"pi-clock": "pi pi-clock",
	"pi-clone": "pi pi-clone",
	"pi-cloud": "pi pi-cloud",
	"pi-cloud-download": "pi pi-cloud-download",
	"pi-cloud-upload": "pi pi-cloud-upload",
	"pi-comment": "pi pi-comment",
	"pi-comments": "pi pi-comments",
	"pi-compass": "pi pi-compass",
	"pi-copy": "pi pi-copy",
	"pi-credit-card": "pi pi-credit-card",
	"pi-desktop": "pi pi-desktop",
	"pi-directions": "pi pi-directions",
	"pi-directions-alt": "pi pi-directions-alt",
	"pi-discord": "pi pi-discord",
	"pi-dollar": "pi pi-dollar",
	"pi-download": "pi pi-download",
	"pi-eject": "pi pi-eject",
	"pi-ellipsis-h": "pi pi-ellipsis-h",
	"pi-ellipsis-v": "pi pi-ellipsis-v",
	"pi-envelope": "pi pi-envelope",
	"pi-euro": "pi pi-euro",
	"pi-exclamation-circle": "pi pi-exclamation-circle",
	"pi-exclamation-triangle": "pi pi-exclamation-triangle",
	"pi-external-link": "pi pi-external-link",
	"pi-eye": "pi pi-eye",
	"pi-eye-slash": "pi pi-eye-slash",
	"pi-facebook": "pi pi-facebook",
	"pi-fast-backward": "pi pi-fast-backward",
	"pi-fast-forward": "pi pi-fast-forward",
	"pi-file": "pi pi-file",
	"pi-file-excel": "pi pi-file-excel",
	"pi-file-o": "pi pi-file-o",
	"pi-file-pdf": "pi pi-file-pdf",
	"pi-filter": "pi pi-filter",
	"pi-filter-slash": "pi pi-filter-slash",
	"pi-flag": "pi pi-flag",
	"pi-folder": "pi pi-folder",
	"pi-folder-open": "pi pi-folder-open",
	"pi-forward": "pi pi-forward",
	"pi-github": "pi pi-github",
	"pi-globe": "pi pi-globe",
	"pi-google": "pi pi-google",
	"pi-heart": "pi pi-heart",
	"pi-home": "pi pi-home",
	"pi-id-card": "pi pi-id-card",
	"pi-image": "pi pi-image",
	"pi-images": "pi pi-images",
	"pi-inbox": "pi pi-inbox",
	"pi-info": "pi pi-info",
	"pi-info-circle": "pi pi-info-circle",
	"pi-key": "pi pi-key",
	"pi-link": "pi pi-link",
	"pi-list": "pi pi-list",
	"pi-lock": "pi pi-lock",
	"pi-lock-open": "pi pi-lock-open",
	"pi-map": "pi pi-map",
	"pi-map-marker": "pi pi-map-marker",
	"pi-microsoft": "pi pi-microsoft",
	"pi-minus": "pi pi-minus",
	"pi-minus-circle": "pi pi-minus-circle",
	"pi-mobile": "pi pi-mobile",
	"pi-money-bill": "pi pi-money-bill",
	"pi-moon": "pi pi-moon",
	"pi-palette": "pi pi-palette",
	"pi-paperclip": "pi pi-paperclip",
	"pi-pause": "pi pi-pause",
	"pi-paypal": "pi pi-paypal",
	"pi-pencil": "pi pi-pencil",
	"pi-percentage": "pi pi-percentage",
	"pi-phone": "pi pi-phone",
	"pi-play": "pi pi-play",
	"pi-plus": "pi pi-plus",
	"pi-plus-circle": "pi pi-plus-circle",
	"pi-power-off": "pi pi-power-off",
	"pi-print": "pi pi-print",
	"pi-question": "pi pi-question",
	"pi-question-circle": "pi pi-question-circle",
	"pi-refresh": "pi pi-refresh",
	"pi-replay": "pi pi-replay",
	"pi-reply": "pi pi-reply",
	"pi-save": "pi pi-save",
	"pi-search": "pi pi-search",
	"pi-search-minus": "pi pi-search-minus",
	"pi-search-plus": "pi pi-search-plus",
	"pi-send": "pi pi-send",
	"pi-share-alt": "pi pi-share-alt",
	"pi-shield": "pi pi-shield",
	"pi-shopping-cart": "pi pi-shopping-cart",
	"pi-sign-in": "pi pi-sign-in",
	"pi-sign-out": "pi pi-sign-out",
	"pi-sitemap": "pi pi-sitemap",
	"pi-slack": "pi pi-slack",
	"pi-sliders-h": "pi pi-sliders-h",
	"pi-sliders-v": "pi pi-sliders-v",
	"pi-sort": "pi pi-sort",
	"pi-sort-alpha-down": "pi pi-sort-alpha-down",
	"pi-sort-alpha-down-alt": "pi pi-sort-alpha-down-alt",
	"pi-sort-alpha-up": "pi pi-sort-alpha-up",
	"pi-sort-alpha-up-alt": "pi pi-sort-alpha-up-alt",
	"pi-sort-alt": "pi pi-sort-alt",
	"pi-sort-amount-down": "pi pi-sort-amount-down",
	"pi-sort-amount-down-alt": "pi pi-sort-amount-down-alt",
	"pi-sort-amount-up": "pi pi-sort-amount-up",
	"pi-sort-amount-up-alt": "pi pi-sort-amount-up-alt",
	"pi-sort-down": "pi pi-sort-down",
	"pi-sort-numeric-down": "pi pi-sort-numeric-down",
	"pi-sort-numeric-down-alt": "pi pi-sort-numeric-down-alt",
	"pi-sort-numeric-up": "pi pi-sort-numeric-up",
	"pi-sort-numeric-up-alt": "pi pi-sort-numeric-up-alt",
	"pi-sort-up": "pi pi-sort-up",
	"pi-star": "pi pi-star",
	"pi-star-o": "pi pi-star-o",
	"pi-step-backward": "pi pi-step-backward",
	"pi-step-backward-alt": "pi pi-step-backward-alt",
	"pi-step-forward": "pi pi-step-forward",
	"pi-step-forward-alt": "pi pi-step-forward-alt",
	"pi-sun": "pi pi-sun",
	"pi-table": "pi pi-table",
	"pi-tablet": "pi pi-tablet",
	"pi-tag": "pi pi-tag",
	"pi-tags": "pi pi-tags",
	"pi-th-large": "pi pi-th-large",
	"pi-thumbs-down": "pi pi-thumbs-down",
	"pi-thumbs-up": "pi pi-thumbs-up",
	"pi-ticket": "pi pi-ticket",
	"pi-times-circle": "pi pi-times-circle",
	"pi-trash": "pi pi-trash",
	"pi-twitter": "pi pi-twitter",
	"pi-undo": "pi pi-undo",
	"pi-unlock": "pi pi-unlock",
	"pi-upload": "pi pi-upload",
	"pi-user": "pi pi-user",
	"pi-user-edit": "pi pi-user-edit",
	"pi-user-minus": "pi pi-user-minus",
	"pi-user-plus": "pi pi-user-plus",
	"pi-users": "pi pi-users",
	"pi-video": "pi pi-video",
	"pi-vimeo": "pi pi-vimeo",
	"pi-volume-down": "pi pi-volume-down",
	"pi-volume-off": "pi pi-volume-off",
	"pi-volume-up": "pi pi-volume-up",
	"pi-wallet": "pi pi-wallet",
	"pi-wifi": "pi pi-wifi",
	"pi-window-maximize": "pi pi-window-maximize",
	"pi-window-minimize": "pi pi-window-minimize",
	"pi-youtube": "pi pi-youtube",
	"fas-ad": "fas fa-ad",
	"far-ad": "far fa-ad",
	"fal-ad": "fal fa-ad",
	"fad-ad": "fad fa-ad",
	"fas-address-book": "fas fa-address-book",
	"far-address-book": "far fa-address-book",
	"fal-address-book": "fal fa-address-book",
	"fad-address-book": "fad fa-address-book",
	"fas-address-card": "fas fa-address-card",
	"far-address-card": "far fa-address-card",
	"fal-address-card": "fal fa-address-card",
	"fad-address-card": "fad fa-address-card",
	"fas-adjust": "fas fa-adjust",
	"far-adjust": "far fa-adjust",
	"fal-adjust": "fal fa-adjust",
	"fad-adjust": "fad fa-adjust",
	"fas-air-freshener": "fas fa-air-freshener",
	"far-air-freshener": "far fa-air-freshener",
	"fal-air-freshener": "fal fa-air-freshener",
	"fad-air-freshener": "fad fa-air-freshener",
	"fas-align-center": "fas fa-align-center",
	"far-align-center": "far fa-align-center",
	"fal-align-center": "fal fa-align-center",
	"fad-align-center": "fad fa-align-center",
	"fas-align-justify": "fas fa-align-justify",
	"far-align-justify": "far fa-align-justify",
	"fal-align-justify": "fal fa-align-justify",
	"fad-align-justify": "fad fa-align-justify",
	"fas-align-left": "fas fa-align-left",
	"far-align-left": "far fa-align-left",
	"fal-align-left": "fal fa-align-left",
	"fad-align-left": "fad fa-align-left",
	"fas-align-right": "fas fa-align-right",
	"far-align-right": "far fa-align-right",
	"fal-align-right": "fal fa-align-right",
	"fad-align-right": "fad fa-align-right",
	"fas-allergies": "fas fa-allergies",
	"far-allergies": "far fa-allergies",
	"fal-allergies": "fal fa-allergies",
	"fad-allergies": "fad fa-allergies",
	"fas-ambulance": "fas fa-ambulance",
	"far-ambulance": "far fa-ambulance",
	"fal-ambulance": "fal fa-ambulance",
	"fad-ambulance": "fad fa-ambulance",
	"fas-american-sign-language-interpreting": "fas fa-american-sign-language-interpreting",
	"far-american-sign-language-interpreting": "far fa-american-sign-language-interpreting",
	"fal-american-sign-language-interpreting": "fal fa-american-sign-language-interpreting",
	"fad-american-sign-language-interpreting": "fad fa-american-sign-language-interpreting",
	"fas-anchor": "fas fa-anchor",
	"far-anchor": "far fa-anchor",
	"fal-anchor": "fal fa-anchor",
	"fad-anchor": "fad fa-anchor",
	"fas-angle-double-down": "fas fa-angle-double-down",
	"far-angle-double-down": "far fa-angle-double-down",
	"fal-angle-double-down": "fal fa-angle-double-down",
	"fad-angle-double-down": "fad fa-angle-double-down",
	"fas-angle-double-left": "fas fa-angle-double-left",
	"far-angle-double-left": "far fa-angle-double-left",
	"fal-angle-double-left": "fal fa-angle-double-left",
	"fad-angle-double-left": "fad fa-angle-double-left",
	"fas-angle-double-right": "fas fa-angle-double-right",
	"far-angle-double-right": "far fa-angle-double-right",
	"fal-angle-double-right": "fal fa-angle-double-right",
	"fad-angle-double-right": "fad fa-angle-double-right",
	"fas-angle-double-up": "fas fa-angle-double-up",
	"far-angle-double-up": "far fa-angle-double-up",
	"fal-angle-double-up": "fal fa-angle-double-up",
	"fad-angle-double-up": "fad fa-angle-double-up",
	"fas-angle-down": "fas fa-angle-down",
	"far-angle-down": "far fa-angle-down",
	"fal-angle-down": "fal fa-angle-down",
	"fad-angle-down": "fad fa-angle-down",
	"fas-angle-left": "fas fa-angle-left",
	"far-angle-left": "far fa-angle-left",
	"fal-angle-left": "fal fa-angle-left",
	"fad-angle-left": "fad fa-angle-left",
	"fas-angle-right": "fas fa-angle-right",
	"far-angle-right": "far fa-angle-right",
	"fal-angle-right": "fal fa-angle-right",
	"fad-angle-right": "fad fa-angle-right",
	"fas-angle-up": "fas fa-angle-up",
	"far-angle-up": "far fa-angle-up",
	"fal-angle-up": "fal fa-angle-up",
	"fad-angle-up": "fad fa-angle-up",
	"fas-angry": "fas fa-angry",
	"far-angry": "far fa-angry",
	"fal-angry": "fal fa-angry",
	"fad-angry": "fad fa-angry",
	"fas-ankh": "fas fa-ankh",
	"far-ankh": "far fa-ankh",
	"fal-ankh": "fal fa-ankh",
	"fad-ankh": "fad fa-ankh",
	"fas-apple-alt": "fas fa-apple-alt",
	"far-apple-alt": "far fa-apple-alt",
	"fal-apple-alt": "fal fa-apple-alt",
	"fad-apple-alt": "fad fa-apple-alt",
	"fas-archive": "fas fa-archive",
	"far-archive": "far fa-archive",
	"fal-archive": "fal fa-archive",
	"fad-archive": "fad fa-archive",
	"fas-archway": "fas fa-archway",
	"far-archway": "far fa-archway",
	"fal-archway": "fal fa-archway",
	"fad-archway": "fad fa-archway",
	"fas-arrow-alt-circle-down": "fas fa-arrow-alt-circle-down",
	"far-arrow-alt-circle-down": "far fa-arrow-alt-circle-down",
	"fal-arrow-alt-circle-down": "fal fa-arrow-alt-circle-down",
	"fad-arrow-alt-circle-down": "fad fa-arrow-alt-circle-down",
	"fas-arrow-alt-circle-left": "fas fa-arrow-alt-circle-left",
	"far-arrow-alt-circle-left": "far fa-arrow-alt-circle-left",
	"fal-arrow-alt-circle-left": "fal fa-arrow-alt-circle-left",
	"fad-arrow-alt-circle-left": "fad fa-arrow-alt-circle-left",
	"fas-arrow-alt-circle-right": "fas fa-arrow-alt-circle-right",
	"far-arrow-alt-circle-right": "far fa-arrow-alt-circle-right",
	"fal-arrow-alt-circle-right": "fal fa-arrow-alt-circle-right",
	"fad-arrow-alt-circle-right": "fad fa-arrow-alt-circle-right",
	"fas-arrow-alt-circle-up": "fas fa-arrow-alt-circle-up",
	"far-arrow-alt-circle-up": "far fa-arrow-alt-circle-up",
	"fal-arrow-alt-circle-up": "fal fa-arrow-alt-circle-up",
	"fad-arrow-alt-circle-up": "fad fa-arrow-alt-circle-up",
	"fas-arrow-circle-down": "fas fa-arrow-circle-down",
	"far-arrow-circle-down": "far fa-arrow-circle-down",
	"fal-arrow-circle-down": "fal fa-arrow-circle-down",
	"fad-arrow-circle-down": "fad fa-arrow-circle-down",
	"fas-arrow-circle-left": "fas fa-arrow-circle-left",
	"far-arrow-circle-left": "far fa-arrow-circle-left",
	"fal-arrow-circle-left": "fal fa-arrow-circle-left",
	"fad-arrow-circle-left": "fad fa-arrow-circle-left",
	"fas-arrow-circle-right": "fas fa-arrow-circle-right",
	"far-arrow-circle-right": "far fa-arrow-circle-right",
	"fal-arrow-circle-right": "fal fa-arrow-circle-right",
	"fad-arrow-circle-right": "fad fa-arrow-circle-right",
	"fas-arrow-circle-up": "fas fa-arrow-circle-up",
	"far-arrow-circle-up": "far fa-arrow-circle-up",
	"fal-arrow-circle-up": "fal fa-arrow-circle-up",
	"fad-arrow-circle-up": "fad fa-arrow-circle-up",
	"fas-arrow-down": "fas fa-arrow-down",
	"far-arrow-down": "far fa-arrow-down",
	"fal-arrow-down": "fal fa-arrow-down",
	"fad-arrow-down": "fad fa-arrow-down",
	"fas-arrow-left": "fas fa-arrow-left",
	"far-arrow-left": "far fa-arrow-left",
	"fal-arrow-left": "fal fa-arrow-left",
	"fad-arrow-left": "fad fa-arrow-left",
	"fas-arrow-right": "fas fa-arrow-right",
	"far-arrow-right": "far fa-arrow-right",
	"fal-arrow-right": "fal fa-arrow-right",
	"fad-arrow-right": "fad fa-arrow-right",
	"fas-arrow-up": "fas fa-arrow-up",
	"far-arrow-up": "far fa-arrow-up",
	"fal-arrow-up": "fal fa-arrow-up",
	"fad-arrow-up": "fad fa-arrow-up",
	"fas-arrows-alt": "fas fa-arrows-alt",
	"far-arrows-alt": "far fa-arrows-alt",
	"fal-arrows-alt": "fal fa-arrows-alt",
	"fad-arrows-alt": "fad fa-arrows-alt",
	"fas-arrows-alt-h": "fas fa-arrows-alt-h",
	"far-arrows-alt-h": "far fa-arrows-alt-h",
	"fal-arrows-alt-h": "fal fa-arrows-alt-h",
	"fad-arrows-alt-h": "fad fa-arrows-alt-h",
	"fas-arrows-alt-v": "fas fa-arrows-alt-v",
	"far-arrows-alt-v": "far fa-arrows-alt-v",
	"fal-arrows-alt-v": "fal fa-arrows-alt-v",
	"fad-arrows-alt-v": "fad fa-arrows-alt-v",
	"fas-assistive-listening-systems": "fas fa-assistive-listening-systems",
	"far-assistive-listening-systems": "far fa-assistive-listening-systems",
	"fal-assistive-listening-systems": "fal fa-assistive-listening-systems",
	"fad-assistive-listening-systems": "fad fa-assistive-listening-systems",
	"fas-asterisk": "fas fa-asterisk",
	"far-asterisk": "far fa-asterisk",
	"fal-asterisk": "fal fa-asterisk",
	"fad-asterisk": "fad fa-asterisk",
	"fas-at": "fas fa-at",
	"far-at": "far fa-at",
	"fal-at": "fal fa-at",
	"fad-at": "fad fa-at",
	"fas-atlas": "fas fa-atlas",
	"far-atlas": "far fa-atlas",
	"fal-atlas": "fal fa-atlas",
	"fad-atlas": "fad fa-atlas",
	"fas-atom": "fas fa-atom",
	"far-atom": "far fa-atom",
	"fal-atom": "fal fa-atom",
	"fad-atom": "fad fa-atom",
	"fas-audio-description": "fas fa-audio-description",
	"far-audio-description": "far fa-audio-description",
	"fal-audio-description": "fal fa-audio-description",
	"fad-audio-description": "fad fa-audio-description",
	"fas-award": "fas fa-award",
	"far-award": "far fa-award",
	"fal-award": "fal fa-award",
	"fad-award": "fad fa-award",
	"fas-baby": "fas fa-baby",
	"far-baby": "far fa-baby",
	"fal-baby": "fal fa-baby",
	"fad-baby": "fad fa-baby",
	"fas-baby-carriage": "fas fa-baby-carriage",
	"far-baby-carriage": "far fa-baby-carriage",
	"fal-baby-carriage": "fal fa-baby-carriage",
	"fad-baby-carriage": "fad fa-baby-carriage",
	"fas-backspace": "fas fa-backspace",
	"far-backspace": "far fa-backspace",
	"fal-backspace": "fal fa-backspace",
	"fad-backspace": "fad fa-backspace",
	"fas-backward": "fas fa-backward",
	"far-backward": "far fa-backward",
	"fal-backward": "fal fa-backward",
	"fad-backward": "fad fa-backward",
	"fas-bacon": "fas fa-bacon",
	"far-bacon": "far fa-bacon",
	"fal-bacon": "fal fa-bacon",
	"fad-bacon": "fad fa-bacon",
	"fas-bacteria": "fas fa-bacteria",
	"far-bacteria": "far fa-bacteria",
	"fal-bacteria": "fal fa-bacteria",
	"fad-bacteria": "fad fa-bacteria",
	"fas-bacterium": "fas fa-bacterium",
	"far-bacterium": "far fa-bacterium",
	"fal-bacterium": "fal fa-bacterium",
	"fad-bacterium": "fad fa-bacterium",
	"fas-bahai": "fas fa-bahai",
	"far-bahai": "far fa-bahai",
	"fal-bahai": "fal fa-bahai",
	"fad-bahai": "fad fa-bahai",
	"fas-balance-scale": "fas fa-balance-scale",
	"far-balance-scale": "far fa-balance-scale",
	"fal-balance-scale": "fal fa-balance-scale",
	"fad-balance-scale": "fad fa-balance-scale",
	"fas-balance-scale-left": "fas fa-balance-scale-left",
	"far-balance-scale-left": "far fa-balance-scale-left",
	"fal-balance-scale-left": "fal fa-balance-scale-left",
	"fad-balance-scale-left": "fad fa-balance-scale-left",
	"fas-balance-scale-right": "fas fa-balance-scale-right",
	"far-balance-scale-right": "far fa-balance-scale-right",
	"fal-balance-scale-right": "fal fa-balance-scale-right",
	"fad-balance-scale-right": "fad fa-balance-scale-right",
	"fas-ban": "fas fa-ban",
	"far-ban": "far fa-ban",
	"fal-ban": "fal fa-ban",
	"fad-ban": "fad fa-ban",
	"fas-band-aid": "fas fa-band-aid",
	"far-band-aid": "far fa-band-aid",
	"fal-band-aid": "fal fa-band-aid",
	"fad-band-aid": "fad fa-band-aid",
	"fas-barcode": "fas fa-barcode",
	"far-barcode": "far fa-barcode",
	"fal-barcode": "fal fa-barcode",
	"fad-barcode": "fad fa-barcode",
	"fas-bars": "fas fa-bars",
	"far-bars": "far fa-bars",
	"fal-bars": "fal fa-bars",
	"fad-bars": "fad fa-bars",
	"fas-baseball-ball": "fas fa-baseball-ball",
	"far-baseball-ball": "far fa-baseball-ball",
	"fal-baseball-ball": "fal fa-baseball-ball",
	"fad-baseball-ball": "fad fa-baseball-ball",
	"fas-basketball-ball": "fas fa-basketball-ball",
	"far-basketball-ball": "far fa-basketball-ball",
	"fal-basketball-ball": "fal fa-basketball-ball",
	"fad-basketball-ball": "fad fa-basketball-ball",
	"fas-bath": "fas fa-bath",
	"far-bath": "far fa-bath",
	"fal-bath": "fal fa-bath",
	"fad-bath": "fad fa-bath",
	"fas-battery-empty": "fas fa-battery-empty",
	"far-battery-empty": "far fa-battery-empty",
	"fal-battery-empty": "fal fa-battery-empty",
	"fad-battery-empty": "fad fa-battery-empty",
	"fas-battery-full": "fas fa-battery-full",
	"far-battery-full": "far fa-battery-full",
	"fal-battery-full": "fal fa-battery-full",
	"fad-battery-full": "fad fa-battery-full",
	"fas-battery-half": "fas fa-battery-half",
	"far-battery-half": "far fa-battery-half",
	"fal-battery-half": "fal fa-battery-half",
	"fad-battery-half": "fad fa-battery-half",
	"fas-battery-quarter": "fas fa-battery-quarter",
	"far-battery-quarter": "far fa-battery-quarter",
	"fal-battery-quarter": "fal fa-battery-quarter",
	"fad-battery-quarter": "fad fa-battery-quarter",
	"fas-battery-three-quarters": "fas fa-battery-three-quarters",
	"far-battery-three-quarters": "far fa-battery-three-quarters",
	"fal-battery-three-quarters": "fal fa-battery-three-quarters",
	"fad-battery-three-quarters": "fad fa-battery-three-quarters",
	"fas-bed": "fas fa-bed",
	"far-bed": "far fa-bed",
	"fal-bed": "fal fa-bed",
	"fad-bed": "fad fa-bed",
	"fas-beer": "fas fa-beer",
	"far-beer": "far fa-beer",
	"fal-beer": "fal fa-beer",
	"fad-beer": "fad fa-beer",
	"fas-bell": "fas fa-bell",
	"far-bell": "far fa-bell",
	"fal-bell": "fal fa-bell",
	"fad-bell": "fad fa-bell",
	"fas-bell-slash": "fas fa-bell-slash",
	"far-bell-slash": "far fa-bell-slash",
	"fal-bell-slash": "fal fa-bell-slash",
	"fad-bell-slash": "fad fa-bell-slash",
	"fas-bezier-curve": "fas fa-bezier-curve",
	"far-bezier-curve": "far fa-bezier-curve",
	"fal-bezier-curve": "fal fa-bezier-curve",
	"fad-bezier-curve": "fad fa-bezier-curve",
	"fas-bible": "fas fa-bible",
	"far-bible": "far fa-bible",
	"fal-bible": "fal fa-bible",
	"fad-bible": "fad fa-bible",
	"fas-bicycle": "fas fa-bicycle",
	"far-bicycle": "far fa-bicycle",
	"fal-bicycle": "fal fa-bicycle",
	"fad-bicycle": "fad fa-bicycle",
	"fas-biking": "fas fa-biking",
	"far-biking": "far fa-biking",
	"fal-biking": "fal fa-biking",
	"fad-biking": "fad fa-biking",
	"fas-binoculars": "fas fa-binoculars",
	"far-binoculars": "far fa-binoculars",
	"fal-binoculars": "fal fa-binoculars",
	"fad-binoculars": "fad fa-binoculars",
	"fas-biohazard": "fas fa-biohazard",
	"far-biohazard": "far fa-biohazard",
	"fal-biohazard": "fal fa-biohazard",
	"fad-biohazard": "fad fa-biohazard",
	"fas-birthday-cake": "fas fa-birthday-cake",
	"far-birthday-cake": "far fa-birthday-cake",
	"fal-birthday-cake": "fal fa-birthday-cake",
	"fad-birthday-cake": "fad fa-birthday-cake",
	"fas-blender": "fas fa-blender",
	"far-blender": "far fa-blender",
	"fal-blender": "fal fa-blender",
	"fad-blender": "fad fa-blender",
	"fas-blender-phone": "fas fa-blender-phone",
	"far-blender-phone": "far fa-blender-phone",
	"fal-blender-phone": "fal fa-blender-phone",
	"fad-blender-phone": "fad fa-blender-phone",
	"fas-blind": "fas fa-blind",
	"far-blind": "far fa-blind",
	"fal-blind": "fal fa-blind",
	"fad-blind": "fad fa-blind",
	"fas-blog": "fas fa-blog",
	"far-blog": "far fa-blog",
	"fal-blog": "fal fa-blog",
	"fad-blog": "fad fa-blog",
	"fas-bold": "fas fa-bold",
	"far-bold": "far fa-bold",
	"fal-bold": "fal fa-bold",
	"fad-bold": "fad fa-bold",
	"fas-bolt": "fas fa-bolt",
	"far-bolt": "far fa-bolt",
	"fal-bolt": "fal fa-bolt",
	"fad-bolt": "fad fa-bolt",
	"fas-bomb": "fas fa-bomb",
	"far-bomb": "far fa-bomb",
	"fal-bomb": "fal fa-bomb",
	"fad-bomb": "fad fa-bomb",
	"fas-bone": "fas fa-bone",
	"far-bone": "far fa-bone",
	"fal-bone": "fal fa-bone",
	"fad-bone": "fad fa-bone",
	"fas-bong": "fas fa-bong",
	"far-bong": "far fa-bong",
	"fal-bong": "fal fa-bong",
	"fad-bong": "fad fa-bong",
	"fas-book": "fas fa-book",
	"far-book": "far fa-book",
	"fal-book": "fal fa-book",
	"fad-book": "fad fa-book",
	"fas-book-dead": "fas fa-book-dead",
	"far-book-dead": "far fa-book-dead",
	"fal-book-dead": "fal fa-book-dead",
	"fad-book-dead": "fad fa-book-dead",
	"fas-book-medical": "fas fa-book-medical",
	"far-book-medical": "far fa-book-medical",
	"fal-book-medical": "fal fa-book-medical",
	"fad-book-medical": "fad fa-book-medical",
	"fas-book-open": "fas fa-book-open",
	"far-book-open": "far fa-book-open",
	"fal-book-open": "fal fa-book-open",
	"fad-book-open": "fad fa-book-open",
	"fas-book-reader": "fas fa-book-reader",
	"far-book-reader": "far fa-book-reader",
	"fal-book-reader": "fal fa-book-reader",
	"fad-book-reader": "fad fa-book-reader",
	"fas-bookmark": "fas fa-bookmark",
	"far-bookmark": "far fa-bookmark",
	"fal-bookmark": "fal fa-bookmark",
	"fad-bookmark": "fad fa-bookmark",
	"fas-border-all": "fas fa-border-all",
	"far-border-all": "far fa-border-all",
	"fal-border-all": "fal fa-border-all",
	"fad-border-all": "fad fa-border-all",
	"fas-border-none": "fas fa-border-none",
	"far-border-none": "far fa-border-none",
	"fal-border-none": "fal fa-border-none",
	"fad-border-none": "fad fa-border-none",
	"fas-border-style": "fas fa-border-style",
	"far-border-style": "far fa-border-style",
	"fal-border-style": "fal fa-border-style",
	"fad-border-style": "fad fa-border-style",
	"fas-bowling-ball": "fas fa-bowling-ball",
	"far-bowling-ball": "far fa-bowling-ball",
	"fal-bowling-ball": "fal fa-bowling-ball",
	"fad-bowling-ball": "fad fa-bowling-ball",
	"fas-box": "fas fa-box",
	"far-box": "far fa-box",
	"fal-box": "fal fa-box",
	"fad-box": "fad fa-box",
	"fas-box-open": "fas fa-box-open",
	"far-box-open": "far fa-box-open",
	"fal-box-open": "fal fa-box-open",
	"fad-box-open": "fad fa-box-open",
	"fas-box-tissue": "fas fa-box-tissue",
	"far-box-tissue": "far fa-box-tissue",
	"fal-box-tissue": "fal fa-box-tissue",
	"fad-box-tissue": "fad fa-box-tissue",
	"fas-boxes": "fas fa-boxes",
	"far-boxes": "far fa-boxes",
	"fal-boxes": "fal fa-boxes",
	"fad-boxes": "fad fa-boxes",
	"fas-braille": "fas fa-braille",
	"far-braille": "far fa-braille",
	"fal-braille": "fal fa-braille",
	"fad-braille": "fad fa-braille",
	"fas-brain": "fas fa-brain",
	"far-brain": "far fa-brain",
	"fal-brain": "fal fa-brain",
	"fad-brain": "fad fa-brain",
	"fas-bread-slice": "fas fa-bread-slice",
	"far-bread-slice": "far fa-bread-slice",
	"fal-bread-slice": "fal fa-bread-slice",
	"fad-bread-slice": "fad fa-bread-slice",
	"fas-briefcase": "fas fa-briefcase",
	"far-briefcase": "far fa-briefcase",
	"fal-briefcase": "fal fa-briefcase",
	"fad-briefcase": "fad fa-briefcase",
	"fas-briefcase-medical": "fas fa-briefcase-medical",
	"far-briefcase-medical": "far fa-briefcase-medical",
	"fal-briefcase-medical": "fal fa-briefcase-medical",
	"fad-briefcase-medical": "fad fa-briefcase-medical",
	"fas-broadcast-tower": "fas fa-broadcast-tower",
	"far-broadcast-tower": "far fa-broadcast-tower",
	"fal-broadcast-tower": "fal fa-broadcast-tower",
	"fad-broadcast-tower": "fad fa-broadcast-tower",
	"fas-broom": "fas fa-broom",
	"far-broom": "far fa-broom",
	"fal-broom": "fal fa-broom",
	"fad-broom": "fad fa-broom",
	"fas-brush": "fas fa-brush",
	"far-brush": "far fa-brush",
	"fal-brush": "fal fa-brush",
	"fad-brush": "fad fa-brush",
	"fas-bug": "fas fa-bug",
	"far-bug": "far fa-bug",
	"fal-bug": "fal fa-bug",
	"fad-bug": "fad fa-bug",
	"fas-building": "fas fa-building",
	"far-building": "far fa-building",
	"fal-building": "fal fa-building",
	"fad-building": "fad fa-building",
	"fas-bullhorn": "fas fa-bullhorn",
	"far-bullhorn": "far fa-bullhorn",
	"fal-bullhorn": "fal fa-bullhorn",
	"fad-bullhorn": "fad fa-bullhorn",
	"fas-bullseye": "fas fa-bullseye",
	"far-bullseye": "far fa-bullseye",
	"fal-bullseye": "fal fa-bullseye",
	"fad-bullseye": "fad fa-bullseye",
	"fas-burn": "fas fa-burn",
	"far-burn": "far fa-burn",
	"fal-burn": "fal fa-burn",
	"fad-burn": "fad fa-burn",
	"fas-bus": "fas fa-bus",
	"far-bus": "far fa-bus",
	"fal-bus": "fal fa-bus",
	"fad-bus": "fad fa-bus",
	"fas-bus-alt": "fas fa-bus-alt",
	"far-bus-alt": "far fa-bus-alt",
	"fal-bus-alt": "fal fa-bus-alt",
	"fad-bus-alt": "fad fa-bus-alt",
	"fas-business-time": "fas fa-business-time",
	"far-business-time": "far fa-business-time",
	"fal-business-time": "fal fa-business-time",
	"fad-business-time": "fad fa-business-time",
	"fas-calculator": "fas fa-calculator",
	"far-calculator": "far fa-calculator",
	"fal-calculator": "fal fa-calculator",
	"fad-calculator": "fad fa-calculator",
	"fas-calendar": "fas fa-calendar",
	"far-calendar": "far fa-calendar",
	"fal-calendar": "fal fa-calendar",
	"fad-calendar": "fad fa-calendar",
	"fas-calendar-alt": "fas fa-calendar-alt",
	"far-calendar-alt": "far fa-calendar-alt",
	"fal-calendar-alt": "fal fa-calendar-alt",
	"fad-calendar-alt": "fad fa-calendar-alt",
	"fas-calendar-check": "fas fa-calendar-check",
	"far-calendar-check": "far fa-calendar-check",
	"fal-calendar-check": "fal fa-calendar-check",
	"fad-calendar-check": "fad fa-calendar-check",
	"fas-calendar-day": "fas fa-calendar-day",
	"far-calendar-day": "far fa-calendar-day",
	"fal-calendar-day": "fal fa-calendar-day",
	"fad-calendar-day": "fad fa-calendar-day",
	"fas-calendar-minus": "fas fa-calendar-minus",
	"far-calendar-minus": "far fa-calendar-minus",
	"fal-calendar-minus": "fal fa-calendar-minus",
	"fad-calendar-minus": "fad fa-calendar-minus",
	"fas-calendar-plus": "fas fa-calendar-plus",
	"far-calendar-plus": "far fa-calendar-plus",
	"fal-calendar-plus": "fal fa-calendar-plus",
	"fad-calendar-plus": "fad fa-calendar-plus",
	"fas-calendar-times": "fas fa-calendar-times",
	"far-calendar-times": "far fa-calendar-times",
	"fal-calendar-times": "fal fa-calendar-times",
	"fad-calendar-times": "fad fa-calendar-times",
	"fas-calendar-week": "fas fa-calendar-week",
	"far-calendar-week": "far fa-calendar-week",
	"fal-calendar-week": "fal fa-calendar-week",
	"fad-calendar-week": "fad fa-calendar-week",
	"fas-camera": "fas fa-camera",
	"far-camera": "far fa-camera",
	"fal-camera": "fal fa-camera",
	"fad-camera": "fad fa-camera",
	"fas-camera-retro": "fas fa-camera-retro",
	"far-camera-retro": "far fa-camera-retro",
	"fal-camera-retro": "fal fa-camera-retro",
	"fad-camera-retro": "fad fa-camera-retro",
	"fas-campground": "fas fa-campground",
	"far-campground": "far fa-campground",
	"fal-campground": "fal fa-campground",
	"fad-campground": "fad fa-campground",
	"fas-candy-cane": "fas fa-candy-cane",
	"far-candy-cane": "far fa-candy-cane",
	"fal-candy-cane": "fal fa-candy-cane",
	"fad-candy-cane": "fad fa-candy-cane",
	"fas-cannabis": "fas fa-cannabis",
	"far-cannabis": "far fa-cannabis",
	"fal-cannabis": "fal fa-cannabis",
	"fad-cannabis": "fad fa-cannabis",
	"fas-capsules": "fas fa-capsules",
	"far-capsules": "far fa-capsules",
	"fal-capsules": "fal fa-capsules",
	"fad-capsules": "fad fa-capsules",
	"fas-car": "fas fa-car",
	"far-car": "far fa-car",
	"fal-car": "fal fa-car",
	"fad-car": "fad fa-car",
	"fas-car-alt": "fas fa-car-alt",
	"far-car-alt": "far fa-car-alt",
	"fal-car-alt": "fal fa-car-alt",
	"fad-car-alt": "fad fa-car-alt",
	"fas-car-battery": "fas fa-car-battery",
	"far-car-battery": "far fa-car-battery",
	"fal-car-battery": "fal fa-car-battery",
	"fad-car-battery": "fad fa-car-battery",
	"fas-car-crash": "fas fa-car-crash",
	"far-car-crash": "far fa-car-crash",
	"fal-car-crash": "fal fa-car-crash",
	"fad-car-crash": "fad fa-car-crash",
	"fas-car-side": "fas fa-car-side",
	"far-car-side": "far fa-car-side",
	"fal-car-side": "fal fa-car-side",
	"fad-car-side": "fad fa-car-side",
	"fas-caravan": "fas fa-caravan",
	"far-caravan": "far fa-caravan",
	"fal-caravan": "fal fa-caravan",
	"fad-caravan": "fad fa-caravan",
	"fas-caret-down": "fas fa-caret-down",
	"far-caret-down": "far fa-caret-down",
	"fal-caret-down": "fal fa-caret-down",
	"fad-caret-down": "fad fa-caret-down",
	"fas-caret-left": "fas fa-caret-left",
	"far-caret-left": "far fa-caret-left",
	"fal-caret-left": "fal fa-caret-left",
	"fad-caret-left": "fad fa-caret-left",
	"fas-caret-right": "fas fa-caret-right",
	"far-caret-right": "far fa-caret-right",
	"fal-caret-right": "fal fa-caret-right",
	"fad-caret-right": "fad fa-caret-right",
	"fas-caret-square-down": "fas fa-caret-square-down",
	"far-caret-square-down": "far fa-caret-square-down",
	"fal-caret-square-down": "fal fa-caret-square-down",
	"fad-caret-square-down": "fad fa-caret-square-down",
	"fas-caret-square-left": "fas fa-caret-square-left",
	"far-caret-square-left": "far fa-caret-square-left",
	"fal-caret-square-left": "fal fa-caret-square-left",
	"fad-caret-square-left": "fad fa-caret-square-left",
	"fas-caret-square-right": "fas fa-caret-square-right",
	"far-caret-square-right": "far fa-caret-square-right",
	"fal-caret-square-right": "fal fa-caret-square-right",
	"fad-caret-square-right": "fad fa-caret-square-right",
	"fas-caret-square-up": "fas fa-caret-square-up",
	"far-caret-square-up": "far fa-caret-square-up",
	"fal-caret-square-up": "fal fa-caret-square-up",
	"fad-caret-square-up": "fad fa-caret-square-up",
	"fas-caret-up": "fas fa-caret-up",
	"far-caret-up": "far fa-caret-up",
	"fal-caret-up": "fal fa-caret-up",
	"fad-caret-up": "fad fa-caret-up",
	"fas-carrot": "fas fa-carrot",
	"far-carrot": "far fa-carrot",
	"fal-carrot": "fal fa-carrot",
	"fad-carrot": "fad fa-carrot",
	"fas-cart-arrow-down": "fas fa-cart-arrow-down",
	"far-cart-arrow-down": "far fa-cart-arrow-down",
	"fal-cart-arrow-down": "fal fa-cart-arrow-down",
	"fad-cart-arrow-down": "fad fa-cart-arrow-down",
	"fas-cart-plus": "fas fa-cart-plus",
	"far-cart-plus": "far fa-cart-plus",
	"fal-cart-plus": "fal fa-cart-plus",
	"fad-cart-plus": "fad fa-cart-plus",
	"fas-cash-register": "fas fa-cash-register",
	"far-cash-register": "far fa-cash-register",
	"fal-cash-register": "fal fa-cash-register",
	"fad-cash-register": "fad fa-cash-register",
	"fas-cat": "fas fa-cat",
	"far-cat": "far fa-cat",
	"fal-cat": "fal fa-cat",
	"fad-cat": "fad fa-cat",
	"fas-certificate": "fas fa-certificate",
	"far-certificate": "far fa-certificate",
	"fal-certificate": "fal fa-certificate",
	"fad-certificate": "fad fa-certificate",
	"fas-chair": "fas fa-chair",
	"far-chair": "far fa-chair",
	"fal-chair": "fal fa-chair",
	"fad-chair": "fad fa-chair",
	"fas-chalkboard": "fas fa-chalkboard",
	"far-chalkboard": "far fa-chalkboard",
	"fal-chalkboard": "fal fa-chalkboard",
	"fad-chalkboard": "fad fa-chalkboard",
	"fas-chalkboard-teacher": "fas fa-chalkboard-teacher",
	"far-chalkboard-teacher": "far fa-chalkboard-teacher",
	"fal-chalkboard-teacher": "fal fa-chalkboard-teacher",
	"fad-chalkboard-teacher": "fad fa-chalkboard-teacher",
	"fas-charging-station": "fas fa-charging-station",
	"far-charging-station": "far fa-charging-station",
	"fal-charging-station": "fal fa-charging-station",
	"fad-charging-station": "fad fa-charging-station",
	"fas-chart-area": "fas fa-chart-area",
	"far-chart-area": "far fa-chart-area",
	"fal-chart-area": "fal fa-chart-area",
	"fad-chart-area": "fad fa-chart-area",
	"fas-chart-bar": "fas fa-chart-bar",
	"far-chart-bar": "far fa-chart-bar",
	"fal-chart-bar": "fal fa-chart-bar",
	"fad-chart-bar": "fad fa-chart-bar",
	"fas-chart-line": "fas fa-chart-line",
	"far-chart-line": "far fa-chart-line",
	"fal-chart-line": "fal fa-chart-line",
	"fad-chart-line": "fad fa-chart-line",
	"fas-chart-pie": "fas fa-chart-pie",
	"far-chart-pie": "far fa-chart-pie",
	"fal-chart-pie": "fal fa-chart-pie",
	"fad-chart-pie": "fad fa-chart-pie",
	"fas-check": "fas fa-check",
	"far-check": "far fa-check",
	"fal-check": "fal fa-check",
	"fad-check": "fad fa-check",
	"fas-check-circle": "fas fa-check-circle",
	"far-check-circle": "far fa-check-circle",
	"fal-check-circle": "fal fa-check-circle",
	"fad-check-circle": "fad fa-check-circle",
	"fas-check-double": "fas fa-check-double",
	"far-check-double": "far fa-check-double",
	"fal-check-double": "fal fa-check-double",
	"fad-check-double": "fad fa-check-double",
	"fas-check-square": "fas fa-check-square",
	"far-check-square": "far fa-check-square",
	"fal-check-square": "fal fa-check-square",
	"fad-check-square": "fad fa-check-square",
	"fas-cheese": "fas fa-cheese",
	"far-cheese": "far fa-cheese",
	"fal-cheese": "fal fa-cheese",
	"fad-cheese": "fad fa-cheese",
	"fas-chess": "fas fa-chess",
	"far-chess": "far fa-chess",
	"fal-chess": "fal fa-chess",
	"fad-chess": "fad fa-chess",
	"fas-chess-bishop": "fas fa-chess-bishop",
	"far-chess-bishop": "far fa-chess-bishop",
	"fal-chess-bishop": "fal fa-chess-bishop",
	"fad-chess-bishop": "fad fa-chess-bishop",
	"fas-chess-board": "fas fa-chess-board",
	"far-chess-board": "far fa-chess-board",
	"fal-chess-board": "fal fa-chess-board",
	"fad-chess-board": "fad fa-chess-board",
	"fas-chess-king": "fas fa-chess-king",
	"far-chess-king": "far fa-chess-king",
	"fal-chess-king": "fal fa-chess-king",
	"fad-chess-king": "fad fa-chess-king",
	"fas-chess-knight": "fas fa-chess-knight",
	"far-chess-knight": "far fa-chess-knight",
	"fal-chess-knight": "fal fa-chess-knight",
	"fad-chess-knight": "fad fa-chess-knight",
	"fas-chess-pawn": "fas fa-chess-pawn",
	"far-chess-pawn": "far fa-chess-pawn",
	"fal-chess-pawn": "fal fa-chess-pawn",
	"fad-chess-pawn": "fad fa-chess-pawn",
	"fas-chess-queen": "fas fa-chess-queen",
	"far-chess-queen": "far fa-chess-queen",
	"fal-chess-queen": "fal fa-chess-queen",
	"fad-chess-queen": "fad fa-chess-queen",
	"fas-chess-rook": "fas fa-chess-rook",
	"far-chess-rook": "far fa-chess-rook",
	"fal-chess-rook": "fal fa-chess-rook",
	"fad-chess-rook": "fad fa-chess-rook",
	"fas-chevron-circle-down": "fas fa-chevron-circle-down",
	"far-chevron-circle-down": "far fa-chevron-circle-down",
	"fal-chevron-circle-down": "fal fa-chevron-circle-down",
	"fad-chevron-circle-down": "fad fa-chevron-circle-down",
	"fas-chevron-circle-left": "fas fa-chevron-circle-left",
	"far-chevron-circle-left": "far fa-chevron-circle-left",
	"fal-chevron-circle-left": "fal fa-chevron-circle-left",
	"fad-chevron-circle-left": "fad fa-chevron-circle-left",
	"fas-chevron-circle-right": "fas fa-chevron-circle-right",
	"far-chevron-circle-right": "far fa-chevron-circle-right",
	"fal-chevron-circle-right": "fal fa-chevron-circle-right",
	"fad-chevron-circle-right": "fad fa-chevron-circle-right",
	"fas-chevron-circle-up": "fas fa-chevron-circle-up",
	"far-chevron-circle-up": "far fa-chevron-circle-up",
	"fal-chevron-circle-up": "fal fa-chevron-circle-up",
	"fad-chevron-circle-up": "fad fa-chevron-circle-up",
	"fas-chevron-down": "fas fa-chevron-down",
	"far-chevron-down": "far fa-chevron-down",
	"fal-chevron-down": "fal fa-chevron-down",
	"fad-chevron-down": "fad fa-chevron-down",
	"fas-chevron-left": "fas fa-chevron-left",
	"far-chevron-left": "far fa-chevron-left",
	"fal-chevron-left": "fal fa-chevron-left",
	"fad-chevron-left": "fad fa-chevron-left",
	"fas-chevron-right": "fas fa-chevron-right",
	"far-chevron-right": "far fa-chevron-right",
	"fal-chevron-right": "fal fa-chevron-right",
	"fad-chevron-right": "fad fa-chevron-right",
	"fas-chevron-up": "fas fa-chevron-up",
	"far-chevron-up": "far fa-chevron-up",
	"fal-chevron-up": "fal fa-chevron-up",
	"fad-chevron-up": "fad fa-chevron-up",
	"fas-child": "fas fa-child",
	"far-child": "far fa-child",
	"fal-child": "fal fa-child",
	"fad-child": "fad fa-child",
	"fas-church": "fas fa-church",
	"far-church": "far fa-church",
	"fal-church": "fal fa-church",
	"fad-church": "fad fa-church",
	"fas-circle": "fas fa-circle",
	"far-circle": "far fa-circle",
	"fal-circle": "fal fa-circle",
	"fad-circle": "fad fa-circle",
	"fas-circle-notch": "fas fa-circle-notch",
	"far-circle-notch": "far fa-circle-notch",
	"fal-circle-notch": "fal fa-circle-notch",
	"fad-circle-notch": "fad fa-circle-notch",
	"fas-city": "fas fa-city",
	"far-city": "far fa-city",
	"fal-city": "fal fa-city",
	"fad-city": "fad fa-city",
	"fas-clinic-medical": "fas fa-clinic-medical",
	"far-clinic-medical": "far fa-clinic-medical",
	"fal-clinic-medical": "fal fa-clinic-medical",
	"fad-clinic-medical": "fad fa-clinic-medical",
	"fas-clipboard": "fas fa-clipboard",
	"far-clipboard": "far fa-clipboard",
	"fal-clipboard": "fal fa-clipboard",
	"fad-clipboard": "fad fa-clipboard",
	"fas-clipboard-check": "fas fa-clipboard-check",
	"far-clipboard-check": "far fa-clipboard-check",
	"fal-clipboard-check": "fal fa-clipboard-check",
	"fad-clipboard-check": "fad fa-clipboard-check",
	"fas-clipboard-list": "fas fa-clipboard-list",
	"far-clipboard-list": "far fa-clipboard-list",
	"fal-clipboard-list": "fal fa-clipboard-list",
	"fad-clipboard-list": "fad fa-clipboard-list",
	"fas-clock": "fas fa-clock",
	"far-clock": "far fa-clock",
	"fal-clock": "fal fa-clock",
	"fad-clock": "fad fa-clock",
	"fas-clone": "fas fa-clone",
	"far-clone": "far fa-clone",
	"fal-clone": "fal fa-clone",
	"fad-clone": "fad fa-clone",
	"fas-closed-captioning": "fas fa-closed-captioning",
	"far-closed-captioning": "far fa-closed-captioning",
	"fal-closed-captioning": "fal fa-closed-captioning",
	"fad-closed-captioning": "fad fa-closed-captioning",
	"fas-cloud": "fas fa-cloud",
	"far-cloud": "far fa-cloud",
	"fal-cloud": "fal fa-cloud",
	"fad-cloud": "fad fa-cloud",
	"fas-cloud-download-alt": "fas fa-cloud-download-alt",
	"far-cloud-download-alt": "far fa-cloud-download-alt",
	"fal-cloud-download-alt": "fal fa-cloud-download-alt",
	"fad-cloud-download-alt": "fad fa-cloud-download-alt",
	"fas-cloud-meatball": "fas fa-cloud-meatball",
	"far-cloud-meatball": "far fa-cloud-meatball",
	"fal-cloud-meatball": "fal fa-cloud-meatball",
	"fad-cloud-meatball": "fad fa-cloud-meatball",
	"fas-cloud-moon": "fas fa-cloud-moon",
	"far-cloud-moon": "far fa-cloud-moon",
	"fal-cloud-moon": "fal fa-cloud-moon",
	"fad-cloud-moon": "fad fa-cloud-moon",
	"fas-cloud-moon-rain": "fas fa-cloud-moon-rain",
	"far-cloud-moon-rain": "far fa-cloud-moon-rain",
	"fal-cloud-moon-rain": "fal fa-cloud-moon-rain",
	"fad-cloud-moon-rain": "fad fa-cloud-moon-rain",
	"fas-cloud-rain": "fas fa-cloud-rain",
	"far-cloud-rain": "far fa-cloud-rain",
	"fal-cloud-rain": "fal fa-cloud-rain",
	"fad-cloud-rain": "fad fa-cloud-rain",
	"fas-cloud-showers-heavy": "fas fa-cloud-showers-heavy",
	"far-cloud-showers-heavy": "far fa-cloud-showers-heavy",
	"fal-cloud-showers-heavy": "fal fa-cloud-showers-heavy",
	"fad-cloud-showers-heavy": "fad fa-cloud-showers-heavy",
	"fas-cloud-sun": "fas fa-cloud-sun",
	"far-cloud-sun": "far fa-cloud-sun",
	"fal-cloud-sun": "fal fa-cloud-sun",
	"fad-cloud-sun": "fad fa-cloud-sun",
	"fas-cloud-sun-rain": "fas fa-cloud-sun-rain",
	"far-cloud-sun-rain": "far fa-cloud-sun-rain",
	"fal-cloud-sun-rain": "fal fa-cloud-sun-rain",
	"fad-cloud-sun-rain": "fad fa-cloud-sun-rain",
	"fas-cloud-upload-alt": "fas fa-cloud-upload-alt",
	"far-cloud-upload-alt": "far fa-cloud-upload-alt",
	"fal-cloud-upload-alt": "fal fa-cloud-upload-alt",
	"fad-cloud-upload-alt": "fad fa-cloud-upload-alt",
	"fas-cocktail": "fas fa-cocktail",
	"far-cocktail": "far fa-cocktail",
	"fal-cocktail": "fal fa-cocktail",
	"fad-cocktail": "fad fa-cocktail",
	"fas-code": "fas fa-code",
	"far-code": "far fa-code",
	"fal-code": "fal fa-code",
	"fad-code": "fad fa-code",
	"fas-code-branch": "fas fa-code-branch",
	"far-code-branch": "far fa-code-branch",
	"fal-code-branch": "fal fa-code-branch",
	"fad-code-branch": "fad fa-code-branch",
	"fas-coffee": "fas fa-coffee",
	"far-coffee": "far fa-coffee",
	"fal-coffee": "fal fa-coffee",
	"fad-coffee": "fad fa-coffee",
	"fas-cog": "fas fa-cog",
	"far-cog": "far fa-cog",
	"fal-cog": "fal fa-cog",
	"fad-cog": "fad fa-cog",
	"fas-cogs": "fas fa-cogs",
	"far-cogs": "far fa-cogs",
	"fal-cogs": "fal fa-cogs",
	"fad-cogs": "fad fa-cogs",
	"fas-coins": "fas fa-coins",
	"far-coins": "far fa-coins",
	"fal-coins": "fal fa-coins",
	"fad-coins": "fad fa-coins",
	"fas-columns": "fas fa-columns",
	"far-columns": "far fa-columns",
	"fal-columns": "fal fa-columns",
	"fad-columns": "fad fa-columns",
	"fas-comment": "fas fa-comment",
	"far-comment": "far fa-comment",
	"fal-comment": "fal fa-comment",
	"fad-comment": "fad fa-comment",
	"fas-comment-alt": "fas fa-comment-alt",
	"far-comment-alt": "far fa-comment-alt",
	"fal-comment-alt": "fal fa-comment-alt",
	"fad-comment-alt": "fad fa-comment-alt",
	"fas-comment-dollar": "fas fa-comment-dollar",
	"far-comment-dollar": "far fa-comment-dollar",
	"fal-comment-dollar": "fal fa-comment-dollar",
	"fad-comment-dollar": "fad fa-comment-dollar",
	"fas-comment-dots": "fas fa-comment-dots",
	"far-comment-dots": "far fa-comment-dots",
	"fal-comment-dots": "fal fa-comment-dots",
	"fad-comment-dots": "fad fa-comment-dots",
	"fas-comment-medical": "fas fa-comment-medical",
	"far-comment-medical": "far fa-comment-medical",
	"fal-comment-medical": "fal fa-comment-medical",
	"fad-comment-medical": "fad fa-comment-medical",
	"fas-comment-slash": "fas fa-comment-slash",
	"far-comment-slash": "far fa-comment-slash",
	"fal-comment-slash": "fal fa-comment-slash",
	"fad-comment-slash": "fad fa-comment-slash",
	"fas-comments": "fas fa-comments",
	"far-comments": "far fa-comments",
	"fal-comments": "fal fa-comments",
	"fad-comments": "fad fa-comments",
	"fas-comments-dollar": "fas fa-comments-dollar",
	"far-comments-dollar": "far fa-comments-dollar",
	"fal-comments-dollar": "fal fa-comments-dollar",
	"fad-comments-dollar": "fad fa-comments-dollar",
	"fas-compact-disc": "fas fa-compact-disc",
	"far-compact-disc": "far fa-compact-disc",
	"fal-compact-disc": "fal fa-compact-disc",
	"fad-compact-disc": "fad fa-compact-disc",
	"fas-compass": "fas fa-compass",
	"far-compass": "far fa-compass",
	"fal-compass": "fal fa-compass",
	"fad-compass": "fad fa-compass",
	"fas-compress": "fas fa-compress",
	"far-compress": "far fa-compress",
	"fal-compress": "fal fa-compress",
	"fad-compress": "fad fa-compress",
	"fas-compress-alt": "fas fa-compress-alt",
	"far-compress-alt": "far fa-compress-alt",
	"fal-compress-alt": "fal fa-compress-alt",
	"fad-compress-alt": "fad fa-compress-alt",
	"fas-compress-arrows-alt": "fas fa-compress-arrows-alt",
	"far-compress-arrows-alt": "far fa-compress-arrows-alt",
	"fal-compress-arrows-alt": "fal fa-compress-arrows-alt",
	"fad-compress-arrows-alt": "fad fa-compress-arrows-alt",
	"fas-concierge-bell": "fas fa-concierge-bell",
	"far-concierge-bell": "far fa-concierge-bell",
	"fal-concierge-bell": "fal fa-concierge-bell",
	"fad-concierge-bell": "fad fa-concierge-bell",
	"fas-cookie": "fas fa-cookie",
	"far-cookie": "far fa-cookie",
	"fal-cookie": "fal fa-cookie",
	"fad-cookie": "fad fa-cookie",
	"fas-cookie-bite": "fas fa-cookie-bite",
	"far-cookie-bite": "far fa-cookie-bite",
	"fal-cookie-bite": "fal fa-cookie-bite",
	"fad-cookie-bite": "fad fa-cookie-bite",
	"fas-copy": "fas fa-copy",
	"far-copy": "far fa-copy",
	"fal-copy": "fal fa-copy",
	"fad-copy": "fad fa-copy",
	"fas-copyright": "fas fa-copyright",
	"far-copyright": "far fa-copyright",
	"fal-copyright": "fal fa-copyright",
	"fad-copyright": "fad fa-copyright",
	"fas-couch": "fas fa-couch",
	"far-couch": "far fa-couch",
	"fal-couch": "fal fa-couch",
	"fad-couch": "fad fa-couch",
	"fas-credit-card": "fas fa-credit-card",
	"far-credit-card": "far fa-credit-card",
	"fal-credit-card": "fal fa-credit-card",
	"fad-credit-card": "fad fa-credit-card",
	"fas-crop": "fas fa-crop",
	"far-crop": "far fa-crop",
	"fal-crop": "fal fa-crop",
	"fad-crop": "fad fa-crop",
	"fas-crop-alt": "fas fa-crop-alt",
	"far-crop-alt": "far fa-crop-alt",
	"fal-crop-alt": "fal fa-crop-alt",
	"fad-crop-alt": "fad fa-crop-alt",
	"fas-cross": "fas fa-cross",
	"far-cross": "far fa-cross",
	"fal-cross": "fal fa-cross",
	"fad-cross": "fad fa-cross",
	"fas-crosshairs": "fas fa-crosshairs",
	"far-crosshairs": "far fa-crosshairs",
	"fal-crosshairs": "fal fa-crosshairs",
	"fad-crosshairs": "fad fa-crosshairs",
	"fas-crow": "fas fa-crow",
	"far-crow": "far fa-crow",
	"fal-crow": "fal fa-crow",
	"fad-crow": "fad fa-crow",
	"fas-crown": "fas fa-crown",
	"far-crown": "far fa-crown",
	"fal-crown": "fal fa-crown",
	"fad-crown": "fad fa-crown",
	"fas-crutch": "fas fa-crutch",
	"far-crutch": "far fa-crutch",
	"fal-crutch": "fal fa-crutch",
	"fad-crutch": "fad fa-crutch",
	"fas-cube": "fas fa-cube",
	"far-cube": "far fa-cube",
	"fal-cube": "fal fa-cube",
	"fad-cube": "fad fa-cube",
	"fas-cubes": "fas fa-cubes",
	"far-cubes": "far fa-cubes",
	"fal-cubes": "fal fa-cubes",
	"fad-cubes": "fad fa-cubes",
	"fas-cut": "fas fa-cut",
	"far-cut": "far fa-cut",
	"fal-cut": "fal fa-cut",
	"fad-cut": "fad fa-cut",
	"fas-database": "fas fa-database",
	"far-database": "far fa-database",
	"fal-database": "fal fa-database",
	"fad-database": "fad fa-database",
	"fas-deaf": "fas fa-deaf",
	"far-deaf": "far fa-deaf",
	"fal-deaf": "fal fa-deaf",
	"fad-deaf": "fad fa-deaf",
	"fas-democrat": "fas fa-democrat",
	"far-democrat": "far fa-democrat",
	"fal-democrat": "fal fa-democrat",
	"fad-democrat": "fad fa-democrat",
	"fas-desktop": "fas fa-desktop",
	"far-desktop": "far fa-desktop",
	"fal-desktop": "fal fa-desktop",
	"fad-desktop": "fad fa-desktop",
	"fas-dharmachakra": "fas fa-dharmachakra",
	"far-dharmachakra": "far fa-dharmachakra",
	"fal-dharmachakra": "fal fa-dharmachakra",
	"fad-dharmachakra": "fad fa-dharmachakra",
	"fas-diagnoses": "fas fa-diagnoses",
	"far-diagnoses": "far fa-diagnoses",
	"fal-diagnoses": "fal fa-diagnoses",
	"fad-diagnoses": "fad fa-diagnoses",
	"fas-dice": "fas fa-dice",
	"far-dice": "far fa-dice",
	"fal-dice": "fal fa-dice",
	"fad-dice": "fad fa-dice",
	"fas-dice-d20": "fas fa-dice-d20",
	"far-dice-d20": "far fa-dice-d20",
	"fal-dice-d20": "fal fa-dice-d20",
	"fad-dice-d20": "fad fa-dice-d20",
	"fas-dice-d6": "fas fa-dice-d6",
	"far-dice-d6": "far fa-dice-d6",
	"fal-dice-d6": "fal fa-dice-d6",
	"fad-dice-d6": "fad fa-dice-d6",
	"fas-dice-five": "fas fa-dice-five",
	"far-dice-five": "far fa-dice-five",
	"fal-dice-five": "fal fa-dice-five",
	"fad-dice-five": "fad fa-dice-five",
	"fas-dice-four": "fas fa-dice-four",
	"far-dice-four": "far fa-dice-four",
	"fal-dice-four": "fal fa-dice-four",
	"fad-dice-four": "fad fa-dice-four",
	"fas-dice-one": "fas fa-dice-one",
	"far-dice-one": "far fa-dice-one",
	"fal-dice-one": "fal fa-dice-one",
	"fad-dice-one": "fad fa-dice-one",
	"fas-dice-six": "fas fa-dice-six",
	"far-dice-six": "far fa-dice-six",
	"fal-dice-six": "fal fa-dice-six",
	"fad-dice-six": "fad fa-dice-six",
	"fas-dice-three": "fas fa-dice-three",
	"far-dice-three": "far fa-dice-three",
	"fal-dice-three": "fal fa-dice-three",
	"fad-dice-three": "fad fa-dice-three",
	"fas-dice-two": "fas fa-dice-two",
	"far-dice-two": "far fa-dice-two",
	"fal-dice-two": "fal fa-dice-two",
	"fad-dice-two": "fad fa-dice-two",
	"fas-digital-tachograph": "fas fa-digital-tachograph",
	"far-digital-tachograph": "far fa-digital-tachograph",
	"fal-digital-tachograph": "fal fa-digital-tachograph",
	"fad-digital-tachograph": "fad fa-digital-tachograph",
	"fas-directions": "fas fa-directions",
	"far-directions": "far fa-directions",
	"fal-directions": "fal fa-directions",
	"fad-directions": "fad fa-directions",
	"fas-disease": "fas fa-disease",
	"far-disease": "far fa-disease",
	"fal-disease": "fal fa-disease",
	"fad-disease": "fad fa-disease",
	"fas-divide": "fas fa-divide",
	"far-divide": "far fa-divide",
	"fal-divide": "fal fa-divide",
	"fad-divide": "fad fa-divide",
	"fas-dizzy": "fas fa-dizzy",
	"far-dizzy": "far fa-dizzy",
	"fal-dizzy": "fal fa-dizzy",
	"fad-dizzy": "fad fa-dizzy",
	"fas-dna": "fas fa-dna",
	"far-dna": "far fa-dna",
	"fal-dna": "fal fa-dna",
	"fad-dna": "fad fa-dna",
	"fas-dog": "fas fa-dog",
	"far-dog": "far fa-dog",
	"fal-dog": "fal fa-dog",
	"fad-dog": "fad fa-dog",
	"fas-dollar-sign": "fas fa-dollar-sign",
	"far-dollar-sign": "far fa-dollar-sign",
	"fal-dollar-sign": "fal fa-dollar-sign",
	"fad-dollar-sign": "fad fa-dollar-sign",
	"fas-dolly": "fas fa-dolly",
	"far-dolly": "far fa-dolly",
	"fal-dolly": "fal fa-dolly",
	"fad-dolly": "fad fa-dolly",
	"fas-dolly-flatbed": "fas fa-dolly-flatbed",
	"far-dolly-flatbed": "far fa-dolly-flatbed",
	"fal-dolly-flatbed": "fal fa-dolly-flatbed",
	"fad-dolly-flatbed": "fad fa-dolly-flatbed",
	"fas-donate": "fas fa-donate",
	"far-donate": "far fa-donate",
	"fal-donate": "fal fa-donate",
	"fad-donate": "fad fa-donate",
	"fas-door-closed": "fas fa-door-closed",
	"far-door-closed": "far fa-door-closed",
	"fal-door-closed": "fal fa-door-closed",
	"fad-door-closed": "fad fa-door-closed",
	"fas-door-open": "fas fa-door-open",
	"far-door-open": "far fa-door-open",
	"fal-door-open": "fal fa-door-open",
	"fad-door-open": "fad fa-door-open",
	"fas-dot-circle": "fas fa-dot-circle",
	"far-dot-circle": "far fa-dot-circle",
	"fal-dot-circle": "fal fa-dot-circle",
	"fad-dot-circle": "fad fa-dot-circle",
	"fas-dove": "fas fa-dove",
	"far-dove": "far fa-dove",
	"fal-dove": "fal fa-dove",
	"fad-dove": "fad fa-dove",
	"fas-download": "fas fa-download",
	"far-download": "far fa-download",
	"fal-download": "fal fa-download",
	"fad-download": "fad fa-download",
	"fas-drafting-compass": "fas fa-drafting-compass",
	"far-drafting-compass": "far fa-drafting-compass",
	"fal-drafting-compass": "fal fa-drafting-compass",
	"fad-drafting-compass": "fad fa-drafting-compass",
	"fas-dragon": "fas fa-dragon",
	"far-dragon": "far fa-dragon",
	"fal-dragon": "fal fa-dragon",
	"fad-dragon": "fad fa-dragon",
	"fas-draw-polygon": "fas fa-draw-polygon",
	"far-draw-polygon": "far fa-draw-polygon",
	"fal-draw-polygon": "fal fa-draw-polygon",
	"fad-draw-polygon": "fad fa-draw-polygon",
	"fas-drum": "fas fa-drum",
	"far-drum": "far fa-drum",
	"fal-drum": "fal fa-drum",
	"fad-drum": "fad fa-drum",
	"fas-drum-steelpan": "fas fa-drum-steelpan",
	"far-drum-steelpan": "far fa-drum-steelpan",
	"fal-drum-steelpan": "fal fa-drum-steelpan",
	"fad-drum-steelpan": "fad fa-drum-steelpan",
	"fas-drumstick-bite": "fas fa-drumstick-bite",
	"far-drumstick-bite": "far fa-drumstick-bite",
	"fal-drumstick-bite": "fal fa-drumstick-bite",
	"fad-drumstick-bite": "fad fa-drumstick-bite",
	"fas-dumbbell": "fas fa-dumbbell",
	"far-dumbbell": "far fa-dumbbell",
	"fal-dumbbell": "fal fa-dumbbell",
	"fad-dumbbell": "fad fa-dumbbell",
	"fas-dumpster": "fas fa-dumpster",
	"far-dumpster": "far fa-dumpster",
	"fal-dumpster": "fal fa-dumpster",
	"fad-dumpster": "fad fa-dumpster",
	"fas-dumpster-fire": "fas fa-dumpster-fire",
	"far-dumpster-fire": "far fa-dumpster-fire",
	"fal-dumpster-fire": "fal fa-dumpster-fire",
	"fad-dumpster-fire": "fad fa-dumpster-fire",
	"fas-dungeon": "fas fa-dungeon",
	"far-dungeon": "far fa-dungeon",
	"fal-dungeon": "fal fa-dungeon",
	"fad-dungeon": "fad fa-dungeon",
	"fas-edit": "fas fa-edit",
	"far-edit": "far fa-edit",
	"fal-edit": "fal fa-edit",
	"fad-edit": "fad fa-edit",
	"fas-egg": "fas fa-egg",
	"far-egg": "far fa-egg",
	"fal-egg": "fal fa-egg",
	"fad-egg": "fad fa-egg",
	"fas-eject": "fas fa-eject",
	"far-eject": "far fa-eject",
	"fal-eject": "fal fa-eject",
	"fad-eject": "fad fa-eject",
	"fas-ellipsis-h": "fas fa-ellipsis-h",
	"far-ellipsis-h": "far fa-ellipsis-h",
	"fal-ellipsis-h": "fal fa-ellipsis-h",
	"fad-ellipsis-h": "fad fa-ellipsis-h",
	"fas-ellipsis-v": "fas fa-ellipsis-v",
	"far-ellipsis-v": "far fa-ellipsis-v",
	"fal-ellipsis-v": "fal fa-ellipsis-v",
	"fad-ellipsis-v": "fad fa-ellipsis-v",
	"fas-envelope": "fas fa-envelope",
	"far-envelope": "far fa-envelope",
	"fal-envelope": "fal fa-envelope",
	"fad-envelope": "fad fa-envelope",
	"fas-envelope-open": "fas fa-envelope-open",
	"far-envelope-open": "far fa-envelope-open",
	"fal-envelope-open": "fal fa-envelope-open",
	"fad-envelope-open": "fad fa-envelope-open",
	"fas-envelope-open-text": "fas fa-envelope-open-text",
	"far-envelope-open-text": "far fa-envelope-open-text",
	"fal-envelope-open-text": "fal fa-envelope-open-text",
	"fad-envelope-open-text": "fad fa-envelope-open-text",
	"fas-envelope-square": "fas fa-envelope-square",
	"far-envelope-square": "far fa-envelope-square",
	"fal-envelope-square": "fal fa-envelope-square",
	"fad-envelope-square": "fad fa-envelope-square",
	"fas-equals": "fas fa-equals",
	"far-equals": "far fa-equals",
	"fal-equals": "fal fa-equals",
	"fad-equals": "fad fa-equals",
	"fas-eraser": "fas fa-eraser",
	"far-eraser": "far fa-eraser",
	"fal-eraser": "fal fa-eraser",
	"fad-eraser": "fad fa-eraser",
	"fas-ethernet": "fas fa-ethernet",
	"far-ethernet": "far fa-ethernet",
	"fal-ethernet": "fal fa-ethernet",
	"fad-ethernet": "fad fa-ethernet",
	"fas-euro-sign": "fas fa-euro-sign",
	"far-euro-sign": "far fa-euro-sign",
	"fal-euro-sign": "fal fa-euro-sign",
	"fad-euro-sign": "fad fa-euro-sign",
	"fas-exchange-alt": "fas fa-exchange-alt",
	"far-exchange-alt": "far fa-exchange-alt",
	"fal-exchange-alt": "fal fa-exchange-alt",
	"fad-exchange-alt": "fad fa-exchange-alt",
	"fas-exclamation": "fas fa-exclamation",
	"far-exclamation": "far fa-exclamation",
	"fal-exclamation": "fal fa-exclamation",
	"fad-exclamation": "fad fa-exclamation",
	"fas-exclamation-circle": "fas fa-exclamation-circle",
	"far-exclamation-circle": "far fa-exclamation-circle",
	"fal-exclamation-circle": "fal fa-exclamation-circle",
	"fad-exclamation-circle": "fad fa-exclamation-circle",
	"fas-exclamation-triangle": "fas fa-exclamation-triangle",
	"far-exclamation-triangle": "far fa-exclamation-triangle",
	"fal-exclamation-triangle": "fal fa-exclamation-triangle",
	"fad-exclamation-triangle": "fad fa-exclamation-triangle",
	"fas-expand": "fas fa-expand",
	"far-expand": "far fa-expand",
	"fal-expand": "fal fa-expand",
	"fad-expand": "fad fa-expand",
	"fas-expand-alt": "fas fa-expand-alt",
	"far-expand-alt": "far fa-expand-alt",
	"fal-expand-alt": "fal fa-expand-alt",
	"fad-expand-alt": "fad fa-expand-alt",
	"fas-expand-arrows-alt": "fas fa-expand-arrows-alt",
	"far-expand-arrows-alt": "far fa-expand-arrows-alt",
	"fal-expand-arrows-alt": "fal fa-expand-arrows-alt",
	"fad-expand-arrows-alt": "fad fa-expand-arrows-alt",
	"fas-external-link-alt": "fas fa-external-link-alt",
	"far-external-link-alt": "far fa-external-link-alt",
	"fal-external-link-alt": "fal fa-external-link-alt",
	"fad-external-link-alt": "fad fa-external-link-alt",
	"fas-external-link-square-alt": "fas fa-external-link-square-alt",
	"far-external-link-square-alt": "far fa-external-link-square-alt",
	"fal-external-link-square-alt": "fal fa-external-link-square-alt",
	"fad-external-link-square-alt": "fad fa-external-link-square-alt",
	"fas-eye": "fas fa-eye",
	"far-eye": "far fa-eye",
	"fal-eye": "fal fa-eye",
	"fad-eye": "fad fa-eye",
	"fas-eye-dropper": "fas fa-eye-dropper",
	"far-eye-dropper": "far fa-eye-dropper",
	"fal-eye-dropper": "fal fa-eye-dropper",
	"fad-eye-dropper": "fad fa-eye-dropper",
	"fas-eye-slash": "fas fa-eye-slash",
	"far-eye-slash": "far fa-eye-slash",
	"fal-eye-slash": "fal fa-eye-slash",
	"fad-eye-slash": "fad fa-eye-slash",
	"fas-fan": "fas fa-fan",
	"far-fan": "far fa-fan",
	"fal-fan": "fal fa-fan",
	"fad-fan": "fad fa-fan",
	"fas-fast-backward": "fas fa-fast-backward",
	"far-fast-backward": "far fa-fast-backward",
	"fal-fast-backward": "fal fa-fast-backward",
	"fad-fast-backward": "fad fa-fast-backward",
	"fas-fast-forward": "fas fa-fast-forward",
	"far-fast-forward": "far fa-fast-forward",
	"fal-fast-forward": "fal fa-fast-forward",
	"fad-fast-forward": "fad fa-fast-forward",
	"fas-faucet": "fas fa-faucet",
	"far-faucet": "far fa-faucet",
	"fal-faucet": "fal fa-faucet",
	"fad-faucet": "fad fa-faucet",
	"fas-fax": "fas fa-fax",
	"far-fax": "far fa-fax",
	"fal-fax": "fal fa-fax",
	"fad-fax": "fad fa-fax",
	"fas-feather": "fas fa-feather",
	"far-feather": "far fa-feather",
	"fal-feather": "fal fa-feather",
	"fad-feather": "fad fa-feather",
	"fas-feather-alt": "fas fa-feather-alt",
	"far-feather-alt": "far fa-feather-alt",
	"fal-feather-alt": "fal fa-feather-alt",
	"fad-feather-alt": "fad fa-feather-alt",
	"fas-female": "fas fa-female",
	"far-female": "far fa-female",
	"fal-female": "fal fa-female",
	"fad-female": "fad fa-female",
	"fas-fighter-jet": "fas fa-fighter-jet",
	"far-fighter-jet": "far fa-fighter-jet",
	"fal-fighter-jet": "fal fa-fighter-jet",
	"fad-fighter-jet": "fad fa-fighter-jet",
	"fas-file": "fas fa-file",
	"far-file": "far fa-file",
	"fal-file": "fal fa-file",
	"fad-file": "fad fa-file",
	"fas-file-alt": "fas fa-file-alt",
	"far-file-alt": "far fa-file-alt",
	"fal-file-alt": "fal fa-file-alt",
	"fad-file-alt": "fad fa-file-alt",
	"fas-file-archive": "fas fa-file-archive",
	"far-file-archive": "far fa-file-archive",
	"fal-file-archive": "fal fa-file-archive",
	"fad-file-archive": "fad fa-file-archive",
	"fas-file-audio": "fas fa-file-audio",
	"far-file-audio": "far fa-file-audio",
	"fal-file-audio": "fal fa-file-audio",
	"fad-file-audio": "fad fa-file-audio",
	"fas-file-code": "fas fa-file-code",
	"far-file-code": "far fa-file-code",
	"fal-file-code": "fal fa-file-code",
	"fad-file-code": "fad fa-file-code",
	"fas-file-contract": "fas fa-file-contract",
	"far-file-contract": "far fa-file-contract",
	"fal-file-contract": "fal fa-file-contract",
	"fad-file-contract": "fad fa-file-contract",
	"fas-file-csv": "fas fa-file-csv",
	"far-file-csv": "far fa-file-csv",
	"fal-file-csv": "fal fa-file-csv",
	"fad-file-csv": "fad fa-file-csv",
	"fas-file-download": "fas fa-file-download",
	"far-file-download": "far fa-file-download",
	"fal-file-download": "fal fa-file-download",
	"fad-file-download": "fad fa-file-download",
	"fas-file-excel": "fas fa-file-excel",
	"far-file-excel": "far fa-file-excel",
	"fal-file-excel": "fal fa-file-excel",
	"fad-file-excel": "fad fa-file-excel",
	"fas-file-export": "fas fa-file-export",
	"far-file-export": "far fa-file-export",
	"fal-file-export": "fal fa-file-export",
	"fad-file-export": "fad fa-file-export",
	"fas-file-image": "fas fa-file-image",
	"far-file-image": "far fa-file-image",
	"fal-file-image": "fal fa-file-image",
	"fad-file-image": "fad fa-file-image",
	"fas-file-import": "fas fa-file-import",
	"far-file-import": "far fa-file-import",
	"fal-file-import": "fal fa-file-import",
	"fad-file-import": "fad fa-file-import",
	"fas-file-invoice": "fas fa-file-invoice",
	"far-file-invoice": "far fa-file-invoice",
	"fal-file-invoice": "fal fa-file-invoice",
	"fad-file-invoice": "fad fa-file-invoice",
	"fas-file-invoice-dollar": "fas fa-file-invoice-dollar",
	"far-file-invoice-dollar": "far fa-file-invoice-dollar",
	"fal-file-invoice-dollar": "fal fa-file-invoice-dollar",
	"fad-file-invoice-dollar": "fad fa-file-invoice-dollar",
	"fas-file-medical": "fas fa-file-medical",
	"far-file-medical": "far fa-file-medical",
	"fal-file-medical": "fal fa-file-medical",
	"fad-file-medical": "fad fa-file-medical",
	"fas-file-medical-alt": "fas fa-file-medical-alt",
	"far-file-medical-alt": "far fa-file-medical-alt",
	"fal-file-medical-alt": "fal fa-file-medical-alt",
	"fad-file-medical-alt": "fad fa-file-medical-alt",
	"fas-file-pdf": "fas fa-file-pdf",
	"far-file-pdf": "far fa-file-pdf",
	"fal-file-pdf": "fal fa-file-pdf",
	"fad-file-pdf": "fad fa-file-pdf",
	"fas-file-powerpoint": "fas fa-file-powerpoint",
	"far-file-powerpoint": "far fa-file-powerpoint",
	"fal-file-powerpoint": "fal fa-file-powerpoint",
	"fad-file-powerpoint": "fad fa-file-powerpoint",
	"fas-file-prescription": "fas fa-file-prescription",
	"far-file-prescription": "far fa-file-prescription",
	"fal-file-prescription": "fal fa-file-prescription",
	"fad-file-prescription": "fad fa-file-prescription",
	"fas-file-signature": "fas fa-file-signature",
	"far-file-signature": "far fa-file-signature",
	"fal-file-signature": "fal fa-file-signature",
	"fad-file-signature": "fad fa-file-signature",
	"fas-file-upload": "fas fa-file-upload",
	"far-file-upload": "far fa-file-upload",
	"fal-file-upload": "fal fa-file-upload",
	"fad-file-upload": "fad fa-file-upload",
	"fas-file-video": "fas fa-file-video",
	"far-file-video": "far fa-file-video",
	"fal-file-video": "fal fa-file-video",
	"fad-file-video": "fad fa-file-video",
	"fas-file-word": "fas fa-file-word",
	"far-file-word": "far fa-file-word",
	"fal-file-word": "fal fa-file-word",
	"fad-file-word": "fad fa-file-word",
	"fas-fill": "fas fa-fill",
	"far-fill": "far fa-fill",
	"fal-fill": "fal fa-fill",
	"fad-fill": "fad fa-fill",
	"fas-fill-drip": "fas fa-fill-drip",
	"far-fill-drip": "far fa-fill-drip",
	"fal-fill-drip": "fal fa-fill-drip",
	"fad-fill-drip": "fad fa-fill-drip",
	"fas-film": "fas fa-film",
	"far-film": "far fa-film",
	"fal-film": "fal fa-film",
	"fad-film": "fad fa-film",
	"fas-filter": "fas fa-filter",
	"far-filter": "far fa-filter",
	"fal-filter": "fal fa-filter",
	"fad-filter": "fad fa-filter",
	"fas-fingerprint": "fas fa-fingerprint",
	"far-fingerprint": "far fa-fingerprint",
	"fal-fingerprint": "fal fa-fingerprint",
	"fad-fingerprint": "fad fa-fingerprint",
	"fas-fire": "fas fa-fire",
	"far-fire": "far fa-fire",
	"fal-fire": "fal fa-fire",
	"fad-fire": "fad fa-fire",
	"fas-fire-alt": "fas fa-fire-alt",
	"far-fire-alt": "far fa-fire-alt",
	"fal-fire-alt": "fal fa-fire-alt",
	"fad-fire-alt": "fad fa-fire-alt",
	"fas-fire-extinguisher": "fas fa-fire-extinguisher",
	"far-fire-extinguisher": "far fa-fire-extinguisher",
	"fal-fire-extinguisher": "fal fa-fire-extinguisher",
	"fad-fire-extinguisher": "fad fa-fire-extinguisher",
	"fas-first-aid": "fas fa-first-aid",
	"far-first-aid": "far fa-first-aid",
	"fal-first-aid": "fal fa-first-aid",
	"fad-first-aid": "fad fa-first-aid",
	"fas-fish": "fas fa-fish",
	"far-fish": "far fa-fish",
	"fal-fish": "fal fa-fish",
	"fad-fish": "fad fa-fish",
	"fas-fist-raised": "fas fa-fist-raised",
	"far-fist-raised": "far fa-fist-raised",
	"fal-fist-raised": "fal fa-fist-raised",
	"fad-fist-raised": "fad fa-fist-raised",
	"fas-flag": "fas fa-flag",
	"far-flag": "far fa-flag",
	"fal-flag": "fal fa-flag",
	"fad-flag": "fad fa-flag",
	"fas-flag-checkered": "fas fa-flag-checkered",
	"far-flag-checkered": "far fa-flag-checkered",
	"fal-flag-checkered": "fal fa-flag-checkered",
	"fad-flag-checkered": "fad fa-flag-checkered",
	"fas-flag-usa": "fas fa-flag-usa",
	"far-flag-usa": "far fa-flag-usa",
	"fal-flag-usa": "fal fa-flag-usa",
	"fad-flag-usa": "fad fa-flag-usa",
	"fas-flask": "fas fa-flask",
	"far-flask": "far fa-flask",
	"fal-flask": "fal fa-flask",
	"fad-flask": "fad fa-flask",
	"fas-flushed": "fas fa-flushed",
	"far-flushed": "far fa-flushed",
	"fal-flushed": "fal fa-flushed",
	"fad-flushed": "fad fa-flushed",
	"fas-folder": "fas fa-folder",
	"far-folder": "far fa-folder",
	"fal-folder": "fal fa-folder",
	"fad-folder": "fad fa-folder",
	"fas-folder-minus": "fas fa-folder-minus",
	"far-folder-minus": "far fa-folder-minus",
	"fal-folder-minus": "fal fa-folder-minus",
	"fad-folder-minus": "fad fa-folder-minus",
	"fas-folder-open": "fas fa-folder-open",
	"far-folder-open": "far fa-folder-open",
	"fal-folder-open": "fal fa-folder-open",
	"fad-folder-open": "fad fa-folder-open",
	"fas-folder-plus": "fas fa-folder-plus",
	"far-folder-plus": "far fa-folder-plus",
	"fal-folder-plus": "fal fa-folder-plus",
	"fad-folder-plus": "fad fa-folder-plus",
	"fas-font": "fas fa-font",
	"far-font": "far fa-font",
	"fal-font": "fal fa-font",
	"fad-font": "fad fa-font",
	"fas-football-ball": "fas fa-football-ball",
	"far-football-ball": "far fa-football-ball",
	"fal-football-ball": "fal fa-football-ball",
	"fad-football-ball": "fad fa-football-ball",
	"fas-forward": "fas fa-forward",
	"far-forward": "far fa-forward",
	"fal-forward": "fal fa-forward",
	"fad-forward": "fad fa-forward",
	"fas-frog": "fas fa-frog",
	"far-frog": "far fa-frog",
	"fal-frog": "fal fa-frog",
	"fad-frog": "fad fa-frog",
	"fas-frown": "fas fa-frown",
	"far-frown": "far fa-frown",
	"fal-frown": "fal fa-frown",
	"fad-frown": "fad fa-frown",
	"fas-frown-open": "fas fa-frown-open",
	"far-frown-open": "far fa-frown-open",
	"fal-frown-open": "fal fa-frown-open",
	"fad-frown-open": "fad fa-frown-open",
	"fas-funnel-dollar": "fas fa-funnel-dollar",
	"far-funnel-dollar": "far fa-funnel-dollar",
	"fal-funnel-dollar": "fal fa-funnel-dollar",
	"fad-funnel-dollar": "fad fa-funnel-dollar",
	"fas-futbol": "fas fa-futbol",
	"far-futbol": "far fa-futbol",
	"fal-futbol": "fal fa-futbol",
	"fad-futbol": "fad fa-futbol",
	"fas-gamepad": "fas fa-gamepad",
	"far-gamepad": "far fa-gamepad",
	"fal-gamepad": "fal fa-gamepad",
	"fad-gamepad": "fad fa-gamepad",
	"fas-gas-pump": "fas fa-gas-pump",
	"far-gas-pump": "far fa-gas-pump",
	"fal-gas-pump": "fal fa-gas-pump",
	"fad-gas-pump": "fad fa-gas-pump",
	"fas-gavel": "fas fa-gavel",
	"far-gavel": "far fa-gavel",
	"fal-gavel": "fal fa-gavel",
	"fad-gavel": "fad fa-gavel",
	"fas-gem": "fas fa-gem",
	"far-gem": "far fa-gem",
	"fal-gem": "fal fa-gem",
	"fad-gem": "fad fa-gem",
	"fas-genderless": "fas fa-genderless",
	"far-genderless": "far fa-genderless",
	"fal-genderless": "fal fa-genderless",
	"fad-genderless": "fad fa-genderless",
	"fas-ghost": "fas fa-ghost",
	"far-ghost": "far fa-ghost",
	"fal-ghost": "fal fa-ghost",
	"fad-ghost": "fad fa-ghost",
	"fas-gift": "fas fa-gift",
	"far-gift": "far fa-gift",
	"fal-gift": "fal fa-gift",
	"fad-gift": "fad fa-gift",
	"fas-gifts": "fas fa-gifts",
	"far-gifts": "far fa-gifts",
	"fal-gifts": "fal fa-gifts",
	"fad-gifts": "fad fa-gifts",
	"fas-glass-cheers": "fas fa-glass-cheers",
	"far-glass-cheers": "far fa-glass-cheers",
	"fal-glass-cheers": "fal fa-glass-cheers",
	"fad-glass-cheers": "fad fa-glass-cheers",
	"fas-glass-martini": "fas fa-glass-martini",
	"far-glass-martini": "far fa-glass-martini",
	"fal-glass-martini": "fal fa-glass-martini",
	"fad-glass-martini": "fad fa-glass-martini",
	"fas-glass-martini-alt": "fas fa-glass-martini-alt",
	"far-glass-martini-alt": "far fa-glass-martini-alt",
	"fal-glass-martini-alt": "fal fa-glass-martini-alt",
	"fad-glass-martini-alt": "fad fa-glass-martini-alt",
	"fas-glass-whiskey": "fas fa-glass-whiskey",
	"far-glass-whiskey": "far fa-glass-whiskey",
	"fal-glass-whiskey": "fal fa-glass-whiskey",
	"fad-glass-whiskey": "fad fa-glass-whiskey",
	"fas-glasses": "fas fa-glasses",
	"far-glasses": "far fa-glasses",
	"fal-glasses": "fal fa-glasses",
	"fad-glasses": "fad fa-glasses",
	"fas-globe": "fas fa-globe",
	"far-globe": "far fa-globe",
	"fal-globe": "fal fa-globe",
	"fad-globe": "fad fa-globe",
	"fas-globe-africa": "fas fa-globe-africa",
	"far-globe-africa": "far fa-globe-africa",
	"fal-globe-africa": "fal fa-globe-africa",
	"fad-globe-africa": "fad fa-globe-africa",
	"fas-globe-americas": "fas fa-globe-americas",
	"far-globe-americas": "far fa-globe-americas",
	"fal-globe-americas": "fal fa-globe-americas",
	"fad-globe-americas": "fad fa-globe-americas",
	"fas-globe-asia": "fas fa-globe-asia",
	"far-globe-asia": "far fa-globe-asia",
	"fal-globe-asia": "fal fa-globe-asia",
	"fad-globe-asia": "fad fa-globe-asia",
	"fas-globe-europe": "fas fa-globe-europe",
	"far-globe-europe": "far fa-globe-europe",
	"fal-globe-europe": "fal fa-globe-europe",
	"fad-globe-europe": "fad fa-globe-europe",
	"fas-golf-ball": "fas fa-golf-ball",
	"far-golf-ball": "far fa-golf-ball",
	"fal-golf-ball": "fal fa-golf-ball",
	"fad-golf-ball": "fad fa-golf-ball",
	"fas-gopuram": "fas fa-gopuram",
	"far-gopuram": "far fa-gopuram",
	"fal-gopuram": "fal fa-gopuram",
	"fad-gopuram": "fad fa-gopuram",
	"fas-graduation-cap": "fas fa-graduation-cap",
	"far-graduation-cap": "far fa-graduation-cap",
	"fal-graduation-cap": "fal fa-graduation-cap",
	"fad-graduation-cap": "fad fa-graduation-cap",
	"fas-greater-than": "fas fa-greater-than",
	"far-greater-than": "far fa-greater-than",
	"fal-greater-than": "fal fa-greater-than",
	"fad-greater-than": "fad fa-greater-than",
	"fas-greater-than-equal": "fas fa-greater-than-equal",
	"far-greater-than-equal": "far fa-greater-than-equal",
	"fal-greater-than-equal": "fal fa-greater-than-equal",
	"fad-greater-than-equal": "fad fa-greater-than-equal",
	"fas-grimace": "fas fa-grimace",
	"far-grimace": "far fa-grimace",
	"fal-grimace": "fal fa-grimace",
	"fad-grimace": "fad fa-grimace",
	"fas-grin": "fas fa-grin",
	"far-grin": "far fa-grin",
	"fal-grin": "fal fa-grin",
	"fad-grin": "fad fa-grin",
	"fas-grin-alt": "fas fa-grin-alt",
	"far-grin-alt": "far fa-grin-alt",
	"fal-grin-alt": "fal fa-grin-alt",
	"fad-grin-alt": "fad fa-grin-alt",
	"fas-grin-beam": "fas fa-grin-beam",
	"far-grin-beam": "far fa-grin-beam",
	"fal-grin-beam": "fal fa-grin-beam",
	"fad-grin-beam": "fad fa-grin-beam",
	"fas-grin-beam-sweat": "fas fa-grin-beam-sweat",
	"far-grin-beam-sweat": "far fa-grin-beam-sweat",
	"fal-grin-beam-sweat": "fal fa-grin-beam-sweat",
	"fad-grin-beam-sweat": "fad fa-grin-beam-sweat",
	"fas-grin-hearts": "fas fa-grin-hearts",
	"far-grin-hearts": "far fa-grin-hearts",
	"fal-grin-hearts": "fal fa-grin-hearts",
	"fad-grin-hearts": "fad fa-grin-hearts",
	"fas-grin-squint": "fas fa-grin-squint",
	"far-grin-squint": "far fa-grin-squint",
	"fal-grin-squint": "fal fa-grin-squint",
	"fad-grin-squint": "fad fa-grin-squint",
	"fas-grin-squint-tears": "fas fa-grin-squint-tears",
	"far-grin-squint-tears": "far fa-grin-squint-tears",
	"fal-grin-squint-tears": "fal fa-grin-squint-tears",
	"fad-grin-squint-tears": "fad fa-grin-squint-tears",
	"fas-grin-stars": "fas fa-grin-stars",
	"far-grin-stars": "far fa-grin-stars",
	"fal-grin-stars": "fal fa-grin-stars",
	"fad-grin-stars": "fad fa-grin-stars",
	"fas-grin-tears": "fas fa-grin-tears",
	"far-grin-tears": "far fa-grin-tears",
	"fal-grin-tears": "fal fa-grin-tears",
	"fad-grin-tears": "fad fa-grin-tears",
	"fas-grin-tongue": "fas fa-grin-tongue",
	"far-grin-tongue": "far fa-grin-tongue",
	"fal-grin-tongue": "fal fa-grin-tongue",
	"fad-grin-tongue": "fad fa-grin-tongue",
	"fas-grin-tongue-squint": "fas fa-grin-tongue-squint",
	"far-grin-tongue-squint": "far fa-grin-tongue-squint",
	"fal-grin-tongue-squint": "fal fa-grin-tongue-squint",
	"fad-grin-tongue-squint": "fad fa-grin-tongue-squint",
	"fas-grin-tongue-wink": "fas fa-grin-tongue-wink",
	"far-grin-tongue-wink": "far fa-grin-tongue-wink",
	"fal-grin-tongue-wink": "fal fa-grin-tongue-wink",
	"fad-grin-tongue-wink": "fad fa-grin-tongue-wink",
	"fas-grin-wink": "fas fa-grin-wink",
	"far-grin-wink": "far fa-grin-wink",
	"fal-grin-wink": "fal fa-grin-wink",
	"fad-grin-wink": "fad fa-grin-wink",
	"fas-grip-horizontal": "fas fa-grip-horizontal",
	"far-grip-horizontal": "far fa-grip-horizontal",
	"fal-grip-horizontal": "fal fa-grip-horizontal",
	"fad-grip-horizontal": "fad fa-grip-horizontal",
	"fas-grip-lines": "fas fa-grip-lines",
	"far-grip-lines": "far fa-grip-lines",
	"fal-grip-lines": "fal fa-grip-lines",
	"fad-grip-lines": "fad fa-grip-lines",
	"fas-grip-lines-vertical": "fas fa-grip-lines-vertical",
	"far-grip-lines-vertical": "far fa-grip-lines-vertical",
	"fal-grip-lines-vertical": "fal fa-grip-lines-vertical",
	"fad-grip-lines-vertical": "fad fa-grip-lines-vertical",
	"fas-grip-vertical": "fas fa-grip-vertical",
	"far-grip-vertical": "far fa-grip-vertical",
	"fal-grip-vertical": "fal fa-grip-vertical",
	"fad-grip-vertical": "fad fa-grip-vertical",
	"fas-guitar": "fas fa-guitar",
	"far-guitar": "far fa-guitar",
	"fal-guitar": "fal fa-guitar",
	"fad-guitar": "fad fa-guitar",
	"fas-h-square": "fas fa-h-square",
	"far-h-square": "far fa-h-square",
	"fal-h-square": "fal fa-h-square",
	"fad-h-square": "fad fa-h-square",
	"fas-hamburger": "fas fa-hamburger",
	"far-hamburger": "far fa-hamburger",
	"fal-hamburger": "fal fa-hamburger",
	"fad-hamburger": "fad fa-hamburger",
	"fas-hammer": "fas fa-hammer",
	"far-hammer": "far fa-hammer",
	"fal-hammer": "fal fa-hammer",
	"fad-hammer": "fad fa-hammer",
	"fas-hamsa": "fas fa-hamsa",
	"far-hamsa": "far fa-hamsa",
	"fal-hamsa": "fal fa-hamsa",
	"fad-hamsa": "fad fa-hamsa",
	"fas-hand-holding": "fas fa-hand-holding",
	"far-hand-holding": "far fa-hand-holding",
	"fal-hand-holding": "fal fa-hand-holding",
	"fad-hand-holding": "fad fa-hand-holding",
	"fas-hand-holding-heart": "fas fa-hand-holding-heart",
	"far-hand-holding-heart": "far fa-hand-holding-heart",
	"fal-hand-holding-heart": "fal fa-hand-holding-heart",
	"fad-hand-holding-heart": "fad fa-hand-holding-heart",
	"fas-hand-holding-medical": "fas fa-hand-holding-medical",
	"far-hand-holding-medical": "far fa-hand-holding-medical",
	"fal-hand-holding-medical": "fal fa-hand-holding-medical",
	"fad-hand-holding-medical": "fad fa-hand-holding-medical",
	"fas-hand-holding-usd": "fas fa-hand-holding-usd",
	"far-hand-holding-usd": "far fa-hand-holding-usd",
	"fal-hand-holding-usd": "fal fa-hand-holding-usd",
	"fad-hand-holding-usd": "fad fa-hand-holding-usd",
	"fas-hand-holding-water": "fas fa-hand-holding-water",
	"far-hand-holding-water": "far fa-hand-holding-water",
	"fal-hand-holding-water": "fal fa-hand-holding-water",
	"fad-hand-holding-water": "fad fa-hand-holding-water",
	"fas-hand-lizard": "fas fa-hand-lizard",
	"far-hand-lizard": "far fa-hand-lizard",
	"fal-hand-lizard": "fal fa-hand-lizard",
	"fad-hand-lizard": "fad fa-hand-lizard",
	"fas-hand-middle-finger": "fas fa-hand-middle-finger",
	"far-hand-middle-finger": "far fa-hand-middle-finger",
	"fal-hand-middle-finger": "fal fa-hand-middle-finger",
	"fad-hand-middle-finger": "fad fa-hand-middle-finger",
	"fas-hand-paper": "fas fa-hand-paper",
	"far-hand-paper": "far fa-hand-paper",
	"fal-hand-paper": "fal fa-hand-paper",
	"fad-hand-paper": "fad fa-hand-paper",
	"fas-hand-peace": "fas fa-hand-peace",
	"far-hand-peace": "far fa-hand-peace",
	"fal-hand-peace": "fal fa-hand-peace",
	"fad-hand-peace": "fad fa-hand-peace",
	"fas-hand-point-down": "fas fa-hand-point-down",
	"far-hand-point-down": "far fa-hand-point-down",
	"fal-hand-point-down": "fal fa-hand-point-down",
	"fad-hand-point-down": "fad fa-hand-point-down",
	"fas-hand-point-left": "fas fa-hand-point-left",
	"far-hand-point-left": "far fa-hand-point-left",
	"fal-hand-point-left": "fal fa-hand-point-left",
	"fad-hand-point-left": "fad fa-hand-point-left",
	"fas-hand-point-right": "fas fa-hand-point-right",
	"far-hand-point-right": "far fa-hand-point-right",
	"fal-hand-point-right": "fal fa-hand-point-right",
	"fad-hand-point-right": "fad fa-hand-point-right",
	"fas-hand-point-up": "fas fa-hand-point-up",
	"far-hand-point-up": "far fa-hand-point-up",
	"fal-hand-point-up": "fal fa-hand-point-up",
	"fad-hand-point-up": "fad fa-hand-point-up",
	"fas-hand-pointer": "fas fa-hand-pointer",
	"far-hand-pointer": "far fa-hand-pointer",
	"fal-hand-pointer": "fal fa-hand-pointer",
	"fad-hand-pointer": "fad fa-hand-pointer",
	"fas-hand-rock": "fas fa-hand-rock",
	"far-hand-rock": "far fa-hand-rock",
	"fal-hand-rock": "fal fa-hand-rock",
	"fad-hand-rock": "fad fa-hand-rock",
	"fas-hand-scissors": "fas fa-hand-scissors",
	"far-hand-scissors": "far fa-hand-scissors",
	"fal-hand-scissors": "fal fa-hand-scissors",
	"fad-hand-scissors": "fad fa-hand-scissors",
	"fas-hand-sparkles": "fas fa-hand-sparkles",
	"far-hand-sparkles": "far fa-hand-sparkles",
	"fal-hand-sparkles": "fal fa-hand-sparkles",
	"fad-hand-sparkles": "fad fa-hand-sparkles",
	"fas-hand-spock": "fas fa-hand-spock",
	"far-hand-spock": "far fa-hand-spock",
	"fal-hand-spock": "fal fa-hand-spock",
	"fad-hand-spock": "fad fa-hand-spock",
	"fas-hands": "fas fa-hands",
	"far-hands": "far fa-hands",
	"fal-hands": "fal fa-hands",
	"fad-hands": "fad fa-hands",
	"fas-hands-helping": "fas fa-hands-helping",
	"far-hands-helping": "far fa-hands-helping",
	"fal-hands-helping": "fal fa-hands-helping",
	"fad-hands-helping": "fad fa-hands-helping",
	"fas-hands-wash": "fas fa-hands-wash",
	"far-hands-wash": "far fa-hands-wash",
	"fal-hands-wash": "fal fa-hands-wash",
	"fad-hands-wash": "fad fa-hands-wash",
	"fas-handshake": "fas fa-handshake",
	"far-handshake": "far fa-handshake",
	"fal-handshake": "fal fa-handshake",
	"fad-handshake": "fad fa-handshake",
	"fas-handshake-alt-slash": "fas fa-handshake-alt-slash",
	"far-handshake-alt-slash": "far fa-handshake-alt-slash",
	"fal-handshake-alt-slash": "fal fa-handshake-alt-slash",
	"fad-handshake-alt-slash": "fad fa-handshake-alt-slash",
	"fas-handshake-slash": "fas fa-handshake-slash",
	"far-handshake-slash": "far fa-handshake-slash",
	"fal-handshake-slash": "fal fa-handshake-slash",
	"fad-handshake-slash": "fad fa-handshake-slash",
	"fas-hanukiah": "fas fa-hanukiah",
	"far-hanukiah": "far fa-hanukiah",
	"fal-hanukiah": "fal fa-hanukiah",
	"fad-hanukiah": "fad fa-hanukiah",
	"fas-hard-hat": "fas fa-hard-hat",
	"far-hard-hat": "far fa-hard-hat",
	"fal-hard-hat": "fal fa-hard-hat",
	"fad-hard-hat": "fad fa-hard-hat",
	"fas-hashtag": "fas fa-hashtag",
	"far-hashtag": "far fa-hashtag",
	"fal-hashtag": "fal fa-hashtag",
	"fad-hashtag": "fad fa-hashtag",
	"fas-hat-cowboy": "fas fa-hat-cowboy",
	"far-hat-cowboy": "far fa-hat-cowboy",
	"fal-hat-cowboy": "fal fa-hat-cowboy",
	"fad-hat-cowboy": "fad fa-hat-cowboy",
	"fas-hat-cowboy-side": "fas fa-hat-cowboy-side",
	"far-hat-cowboy-side": "far fa-hat-cowboy-side",
	"fal-hat-cowboy-side": "fal fa-hat-cowboy-side",
	"fad-hat-cowboy-side": "fad fa-hat-cowboy-side",
	"fas-hat-wizard": "fas fa-hat-wizard",
	"far-hat-wizard": "far fa-hat-wizard",
	"fal-hat-wizard": "fal fa-hat-wizard",
	"fad-hat-wizard": "fad fa-hat-wizard",
	"fas-hdd": "fas fa-hdd",
	"far-hdd": "far fa-hdd",
	"fal-hdd": "fal fa-hdd",
	"fad-hdd": "fad fa-hdd",
	"fas-head-side-cough": "fas fa-head-side-cough",
	"far-head-side-cough": "far fa-head-side-cough",
	"fal-head-side-cough": "fal fa-head-side-cough",
	"fad-head-side-cough": "fad fa-head-side-cough",
	"fas-head-side-cough-slash": "fas fa-head-side-cough-slash",
	"far-head-side-cough-slash": "far fa-head-side-cough-slash",
	"fal-head-side-cough-slash": "fal fa-head-side-cough-slash",
	"fad-head-side-cough-slash": "fad fa-head-side-cough-slash",
	"fas-head-side-mask": "fas fa-head-side-mask",
	"far-head-side-mask": "far fa-head-side-mask",
	"fal-head-side-mask": "fal fa-head-side-mask",
	"fad-head-side-mask": "fad fa-head-side-mask",
	"fas-head-side-virus": "fas fa-head-side-virus",
	"far-head-side-virus": "far fa-head-side-virus",
	"fal-head-side-virus": "fal fa-head-side-virus",
	"fad-head-side-virus": "fad fa-head-side-virus",
	"fas-heading": "fas fa-heading",
	"far-heading": "far fa-heading",
	"fal-heading": "fal fa-heading",
	"fad-heading": "fad fa-heading",
	"fas-headphones": "fas fa-headphones",
	"far-headphones": "far fa-headphones",
	"fal-headphones": "fal fa-headphones",
	"fad-headphones": "fad fa-headphones",
	"fas-headphones-alt": "fas fa-headphones-alt",
	"far-headphones-alt": "far fa-headphones-alt",
	"fal-headphones-alt": "fal fa-headphones-alt",
	"fad-headphones-alt": "fad fa-headphones-alt",
	"fas-headset": "fas fa-headset",
	"far-headset": "far fa-headset",
	"fal-headset": "fal fa-headset",
	"fad-headset": "fad fa-headset",
	"fas-heart": "fas fa-heart",
	"far-heart": "far fa-heart",
	"fal-heart": "fal fa-heart",
	"fad-heart": "fad fa-heart",
	"fas-heart-broken": "fas fa-heart-broken",
	"far-heart-broken": "far fa-heart-broken",
	"fal-heart-broken": "fal fa-heart-broken",
	"fad-heart-broken": "fad fa-heart-broken",
	"fas-heartbeat": "fas fa-heartbeat",
	"far-heartbeat": "far fa-heartbeat",
	"fal-heartbeat": "fal fa-heartbeat",
	"fad-heartbeat": "fad fa-heartbeat",
	"fas-helicopter": "fas fa-helicopter",
	"far-helicopter": "far fa-helicopter",
	"fal-helicopter": "fal fa-helicopter",
	"fad-helicopter": "fad fa-helicopter",
	"fas-highlighter": "fas fa-highlighter",
	"far-highlighter": "far fa-highlighter",
	"fal-highlighter": "fal fa-highlighter",
	"fad-highlighter": "fad fa-highlighter",
	"fas-hiking": "fas fa-hiking",
	"far-hiking": "far fa-hiking",
	"fal-hiking": "fal fa-hiking",
	"fad-hiking": "fad fa-hiking",
	"fas-hippo": "fas fa-hippo",
	"far-hippo": "far fa-hippo",
	"fal-hippo": "fal fa-hippo",
	"fad-hippo": "fad fa-hippo",
	"fas-history": "fas fa-history",
	"far-history": "far fa-history",
	"fal-history": "fal fa-history",
	"fad-history": "fad fa-history",
	"fas-hockey-puck": "fas fa-hockey-puck",
	"far-hockey-puck": "far fa-hockey-puck",
	"fal-hockey-puck": "fal fa-hockey-puck",
	"fad-hockey-puck": "fad fa-hockey-puck",
	"fas-holly-berry": "fas fa-holly-berry",
	"far-holly-berry": "far fa-holly-berry",
	"fal-holly-berry": "fal fa-holly-berry",
	"fad-holly-berry": "fad fa-holly-berry",
	"fas-home": "fas fa-home",
	"far-home": "far fa-home",
	"fal-home": "fal fa-home",
	"fad-home": "fad fa-home",
	"fas-horse": "fas fa-horse",
	"far-horse": "far fa-horse",
	"fal-horse": "fal fa-horse",
	"fad-horse": "fad fa-horse",
	"fas-horse-head": "fas fa-horse-head",
	"far-horse-head": "far fa-horse-head",
	"fal-horse-head": "fal fa-horse-head",
	"fad-horse-head": "fad fa-horse-head",
	"fas-hospital": "fas fa-hospital",
	"far-hospital": "far fa-hospital",
	"fal-hospital": "fal fa-hospital",
	"fad-hospital": "fad fa-hospital",
	"fas-hospital-alt": "fas fa-hospital-alt",
	"far-hospital-alt": "far fa-hospital-alt",
	"fal-hospital-alt": "fal fa-hospital-alt",
	"fad-hospital-alt": "fad fa-hospital-alt",
	"fas-hospital-symbol": "fas fa-hospital-symbol",
	"far-hospital-symbol": "far fa-hospital-symbol",
	"fal-hospital-symbol": "fal fa-hospital-symbol",
	"fad-hospital-symbol": "fad fa-hospital-symbol",
	"fas-hospital-user": "fas fa-hospital-user",
	"far-hospital-user": "far fa-hospital-user",
	"fal-hospital-user": "fal fa-hospital-user",
	"fad-hospital-user": "fad fa-hospital-user",
	"fas-hot-tub": "fas fa-hot-tub",
	"far-hot-tub": "far fa-hot-tub",
	"fal-hot-tub": "fal fa-hot-tub",
	"fad-hot-tub": "fad fa-hot-tub",
	"fas-hotdog": "fas fa-hotdog",
	"far-hotdog": "far fa-hotdog",
	"fal-hotdog": "fal fa-hotdog",
	"fad-hotdog": "fad fa-hotdog",
	"fas-hotel": "fas fa-hotel",
	"far-hotel": "far fa-hotel",
	"fal-hotel": "fal fa-hotel",
	"fad-hotel": "fad fa-hotel",
	"fas-hourglass": "fas fa-hourglass",
	"far-hourglass": "far fa-hourglass",
	"fal-hourglass": "fal fa-hourglass",
	"fad-hourglass": "fad fa-hourglass",
	"fas-hourglass-end": "fas fa-hourglass-end",
	"far-hourglass-end": "far fa-hourglass-end",
	"fal-hourglass-end": "fal fa-hourglass-end",
	"fad-hourglass-end": "fad fa-hourglass-end",
	"fas-hourglass-half": "fas fa-hourglass-half",
	"far-hourglass-half": "far fa-hourglass-half",
	"fal-hourglass-half": "fal fa-hourglass-half",
	"fad-hourglass-half": "fad fa-hourglass-half",
	"fas-hourglass-start": "fas fa-hourglass-start",
	"far-hourglass-start": "far fa-hourglass-start",
	"fal-hourglass-start": "fal fa-hourglass-start",
	"fad-hourglass-start": "fad fa-hourglass-start",
	"fas-house-damage": "fas fa-house-damage",
	"far-house-damage": "far fa-house-damage",
	"fal-house-damage": "fal fa-house-damage",
	"fad-house-damage": "fad fa-house-damage",
	"fas-house-user": "fas fa-house-user",
	"far-house-user": "far fa-house-user",
	"fal-house-user": "fal fa-house-user",
	"fad-house-user": "fad fa-house-user",
	"fas-hryvnia": "fas fa-hryvnia",
	"far-hryvnia": "far fa-hryvnia",
	"fal-hryvnia": "fal fa-hryvnia",
	"fad-hryvnia": "fad fa-hryvnia",
	"fas-i-cursor": "fas fa-i-cursor",
	"far-i-cursor": "far fa-i-cursor",
	"fal-i-cursor": "fal fa-i-cursor",
	"fad-i-cursor": "fad fa-i-cursor",
	"fas-ice-cream": "fas fa-ice-cream",
	"far-ice-cream": "far fa-ice-cream",
	"fal-ice-cream": "fal fa-ice-cream",
	"fad-ice-cream": "fad fa-ice-cream",
	"fas-icicles": "fas fa-icicles",
	"far-icicles": "far fa-icicles",
	"fal-icicles": "fal fa-icicles",
	"fad-icicles": "fad fa-icicles",
	"fas-icons": "fas fa-icons",
	"far-icons": "far fa-icons",
	"fal-icons": "fal fa-icons",
	"fad-icons": "fad fa-icons",
	"fas-id-badge": "fas fa-id-badge",
	"far-id-badge": "far fa-id-badge",
	"fal-id-badge": "fal fa-id-badge",
	"fad-id-badge": "fad fa-id-badge",
	"fas-id-card": "fas fa-id-card",
	"far-id-card": "far fa-id-card",
	"fal-id-card": "fal fa-id-card",
	"fad-id-card": "fad fa-id-card",
	"fas-id-card-alt": "fas fa-id-card-alt",
	"far-id-card-alt": "far fa-id-card-alt",
	"fal-id-card-alt": "fal fa-id-card-alt",
	"fad-id-card-alt": "fad fa-id-card-alt",
	"fas-igloo": "fas fa-igloo",
	"far-igloo": "far fa-igloo",
	"fal-igloo": "fal fa-igloo",
	"fad-igloo": "fad fa-igloo",
	"fas-image": "fas fa-image",
	"far-image": "far fa-image",
	"fal-image": "fal fa-image",
	"fad-image": "fad fa-image",
	"fas-images": "fas fa-images",
	"far-images": "far fa-images",
	"fal-images": "fal fa-images",
	"fad-images": "fad fa-images",
	"fas-inbox": "fas fa-inbox",
	"far-inbox": "far fa-inbox",
	"fal-inbox": "fal fa-inbox",
	"fad-inbox": "fad fa-inbox",
	"fas-indent": "fas fa-indent",
	"far-indent": "far fa-indent",
	"fal-indent": "fal fa-indent",
	"fad-indent": "fad fa-indent",
	"fas-industry": "fas fa-industry",
	"far-industry": "far fa-industry",
	"fal-industry": "fal fa-industry",
	"fad-industry": "fad fa-industry",
	"fas-infinity": "fas fa-infinity",
	"far-infinity": "far fa-infinity",
	"fal-infinity": "fal fa-infinity",
	"fad-infinity": "fad fa-infinity",
	"fas-info": "fas fa-info",
	"far-info": "far fa-info",
	"fal-info": "fal fa-info",
	"fad-info": "fad fa-info",
	"fas-info-circle": "fas fa-info-circle",
	"far-info-circle": "far fa-info-circle",
	"fal-info-circle": "fal fa-info-circle",
	"fad-info-circle": "fad fa-info-circle",
	"fas-italic": "fas fa-italic",
	"far-italic": "far fa-italic",
	"fal-italic": "fal fa-italic",
	"fad-italic": "fad fa-italic",
	"fas-jedi": "fas fa-jedi",
	"far-jedi": "far fa-jedi",
	"fal-jedi": "fal fa-jedi",
	"fad-jedi": "fad fa-jedi",
	"fas-joint": "fas fa-joint",
	"far-joint": "far fa-joint",
	"fal-joint": "fal fa-joint",
	"fad-joint": "fad fa-joint",
	"fas-journal-whills": "fas fa-journal-whills",
	"far-journal-whills": "far fa-journal-whills",
	"fal-journal-whills": "fal fa-journal-whills",
	"fad-journal-whills": "fad fa-journal-whills",
	"fas-kaaba": "fas fa-kaaba",
	"far-kaaba": "far fa-kaaba",
	"fal-kaaba": "fal fa-kaaba",
	"fad-kaaba": "fad fa-kaaba",
	"fas-key": "fas fa-key",
	"far-key": "far fa-key",
	"fal-key": "fal fa-key",
	"fad-key": "fad fa-key",
	"fas-keyboard": "fas fa-keyboard",
	"far-keyboard": "far fa-keyboard",
	"fal-keyboard": "fal fa-keyboard",
	"fad-keyboard": "fad fa-keyboard",
	"fas-khanda": "fas fa-khanda",
	"far-khanda": "far fa-khanda",
	"fal-khanda": "fal fa-khanda",
	"fad-khanda": "fad fa-khanda",
	"fas-kiss": "fas fa-kiss",
	"far-kiss": "far fa-kiss",
	"fal-kiss": "fal fa-kiss",
	"fad-kiss": "fad fa-kiss",
	"fas-kiss-beam": "fas fa-kiss-beam",
	"far-kiss-beam": "far fa-kiss-beam",
	"fal-kiss-beam": "fal fa-kiss-beam",
	"fad-kiss-beam": "fad fa-kiss-beam",
	"fas-kiss-wink-heart": "fas fa-kiss-wink-heart",
	"far-kiss-wink-heart": "far fa-kiss-wink-heart",
	"fal-kiss-wink-heart": "fal fa-kiss-wink-heart",
	"fad-kiss-wink-heart": "fad fa-kiss-wink-heart",
	"fas-kiwi-bird": "fas fa-kiwi-bird",
	"far-kiwi-bird": "far fa-kiwi-bird",
	"fal-kiwi-bird": "fal fa-kiwi-bird",
	"fad-kiwi-bird": "fad fa-kiwi-bird",
	"fas-landmark": "fas fa-landmark",
	"far-landmark": "far fa-landmark",
	"fal-landmark": "fal fa-landmark",
	"fad-landmark": "fad fa-landmark",
	"fas-language": "fas fa-language",
	"far-language": "far fa-language",
	"fal-language": "fal fa-language",
	"fad-language": "fad fa-language",
	"fas-laptop": "fas fa-laptop",
	"far-laptop": "far fa-laptop",
	"fal-laptop": "fal fa-laptop",
	"fad-laptop": "fad fa-laptop",
	"fas-laptop-code": "fas fa-laptop-code",
	"far-laptop-code": "far fa-laptop-code",
	"fal-laptop-code": "fal fa-laptop-code",
	"fad-laptop-code": "fad fa-laptop-code",
	"fas-laptop-house": "fas fa-laptop-house",
	"far-laptop-house": "far fa-laptop-house",
	"fal-laptop-house": "fal fa-laptop-house",
	"fad-laptop-house": "fad fa-laptop-house",
	"fas-laptop-medical": "fas fa-laptop-medical",
	"far-laptop-medical": "far fa-laptop-medical",
	"fal-laptop-medical": "fal fa-laptop-medical",
	"fad-laptop-medical": "fad fa-laptop-medical",
	"fas-laugh": "fas fa-laugh",
	"far-laugh": "far fa-laugh",
	"fal-laugh": "fal fa-laugh",
	"fad-laugh": "fad fa-laugh",
	"fas-laugh-beam": "fas fa-laugh-beam",
	"far-laugh-beam": "far fa-laugh-beam",
	"fal-laugh-beam": "fal fa-laugh-beam",
	"fad-laugh-beam": "fad fa-laugh-beam",
	"fas-laugh-squint": "fas fa-laugh-squint",
	"far-laugh-squint": "far fa-laugh-squint",
	"fal-laugh-squint": "fal fa-laugh-squint",
	"fad-laugh-squint": "fad fa-laugh-squint",
	"fas-laugh-wink": "fas fa-laugh-wink",
	"far-laugh-wink": "far fa-laugh-wink",
	"fal-laugh-wink": "fal fa-laugh-wink",
	"fad-laugh-wink": "fad fa-laugh-wink",
	"fas-layer-group": "fas fa-layer-group",
	"far-layer-group": "far fa-layer-group",
	"fal-layer-group": "fal fa-layer-group",
	"fad-layer-group": "fad fa-layer-group",
	"fas-leaf": "fas fa-leaf",
	"far-leaf": "far fa-leaf",
	"fal-leaf": "fal fa-leaf",
	"fad-leaf": "fad fa-leaf",
	"fas-lemon": "fas fa-lemon",
	"far-lemon": "far fa-lemon",
	"fal-lemon": "fal fa-lemon",
	"fad-lemon": "fad fa-lemon",
	"fas-less-than": "fas fa-less-than",
	"far-less-than": "far fa-less-than",
	"fal-less-than": "fal fa-less-than",
	"fad-less-than": "fad fa-less-than",
	"fas-less-than-equal": "fas fa-less-than-equal",
	"far-less-than-equal": "far fa-less-than-equal",
	"fal-less-than-equal": "fal fa-less-than-equal",
	"fad-less-than-equal": "fad fa-less-than-equal",
	"fas-level-down-alt": "fas fa-level-down-alt",
	"far-level-down-alt": "far fa-level-down-alt",
	"fal-level-down-alt": "fal fa-level-down-alt",
	"fad-level-down-alt": "fad fa-level-down-alt",
	"fas-level-up-alt": "fas fa-level-up-alt",
	"far-level-up-alt": "far fa-level-up-alt",
	"fal-level-up-alt": "fal fa-level-up-alt",
	"fad-level-up-alt": "fad fa-level-up-alt",
	"fas-life-ring": "fas fa-life-ring",
	"far-life-ring": "far fa-life-ring",
	"fal-life-ring": "fal fa-life-ring",
	"fad-life-ring": "fad fa-life-ring",
	"fas-lightbulb": "fas fa-lightbulb",
	"far-lightbulb": "far fa-lightbulb",
	"fal-lightbulb": "fal fa-lightbulb",
	"fad-lightbulb": "fad fa-lightbulb",
	"fas-link": "fas fa-link",
	"far-link": "far fa-link",
	"fal-link": "fal fa-link",
	"fad-link": "fad fa-link",
	"fas-lira-sign": "fas fa-lira-sign",
	"far-lira-sign": "far fa-lira-sign",
	"fal-lira-sign": "fal fa-lira-sign",
	"fad-lira-sign": "fad fa-lira-sign",
	"fas-list": "fas fa-list",
	"far-list": "far fa-list",
	"fal-list": "fal fa-list",
	"fad-list": "fad fa-list",
	"fas-list-alt": "fas fa-list-alt",
	"far-list-alt": "far fa-list-alt",
	"fal-list-alt": "fal fa-list-alt",
	"fad-list-alt": "fad fa-list-alt",
	"fas-list-ol": "fas fa-list-ol",
	"far-list-ol": "far fa-list-ol",
	"fal-list-ol": "fal fa-list-ol",
	"fad-list-ol": "fad fa-list-ol",
	"fas-list-ul": "fas fa-list-ul",
	"far-list-ul": "far fa-list-ul",
	"fal-list-ul": "fal fa-list-ul",
	"fad-list-ul": "fad fa-list-ul",
	"fas-location-arrow": "fas fa-location-arrow",
	"far-location-arrow": "far fa-location-arrow",
	"fal-location-arrow": "fal fa-location-arrow",
	"fad-location-arrow": "fad fa-location-arrow",
	"fas-lock": "fas fa-lock",
	"far-lock": "far fa-lock",
	"fal-lock": "fal fa-lock",
	"fad-lock": "fad fa-lock",
	"fas-lock-open": "fas fa-lock-open",
	"far-lock-open": "far fa-lock-open",
	"fal-lock-open": "fal fa-lock-open",
	"fad-lock-open": "fad fa-lock-open",
	"fas-long-arrow-alt-down": "fas fa-long-arrow-alt-down",
	"far-long-arrow-alt-down": "far fa-long-arrow-alt-down",
	"fal-long-arrow-alt-down": "fal fa-long-arrow-alt-down",
	"fad-long-arrow-alt-down": "fad fa-long-arrow-alt-down",
	"fas-long-arrow-alt-left": "fas fa-long-arrow-alt-left",
	"far-long-arrow-alt-left": "far fa-long-arrow-alt-left",
	"fal-long-arrow-alt-left": "fal fa-long-arrow-alt-left",
	"fad-long-arrow-alt-left": "fad fa-long-arrow-alt-left",
	"fas-long-arrow-alt-right": "fas fa-long-arrow-alt-right",
	"far-long-arrow-alt-right": "far fa-long-arrow-alt-right",
	"fal-long-arrow-alt-right": "fal fa-long-arrow-alt-right",
	"fad-long-arrow-alt-right": "fad fa-long-arrow-alt-right",
	"fas-long-arrow-alt-up": "fas fa-long-arrow-alt-up",
	"far-long-arrow-alt-up": "far fa-long-arrow-alt-up",
	"fal-long-arrow-alt-up": "fal fa-long-arrow-alt-up",
	"fad-long-arrow-alt-up": "fad fa-long-arrow-alt-up",
	"fas-low-vision": "fas fa-low-vision",
	"far-low-vision": "far fa-low-vision",
	"fal-low-vision": "fal fa-low-vision",
	"fad-low-vision": "fad fa-low-vision",
	"fas-luggage-cart": "fas fa-luggage-cart",
	"far-luggage-cart": "far fa-luggage-cart",
	"fal-luggage-cart": "fal fa-luggage-cart",
	"fad-luggage-cart": "fad fa-luggage-cart",
	"fas-lungs": "fas fa-lungs",
	"far-lungs": "far fa-lungs",
	"fal-lungs": "fal fa-lungs",
	"fad-lungs": "fad fa-lungs",
	"fas-lungs-virus": "fas fa-lungs-virus",
	"far-lungs-virus": "far fa-lungs-virus",
	"fal-lungs-virus": "fal fa-lungs-virus",
	"fad-lungs-virus": "fad fa-lungs-virus",
	"fas-magic": "fas fa-magic",
	"far-magic": "far fa-magic",
	"fal-magic": "fal fa-magic",
	"fad-magic": "fad fa-magic",
	"fas-magnet": "fas fa-magnet",
	"far-magnet": "far fa-magnet",
	"fal-magnet": "fal fa-magnet",
	"fad-magnet": "fad fa-magnet",
	"fas-mail-bulk": "fas fa-mail-bulk",
	"far-mail-bulk": "far fa-mail-bulk",
	"fal-mail-bulk": "fal fa-mail-bulk",
	"fad-mail-bulk": "fad fa-mail-bulk",
	"fas-male": "fas fa-male",
	"far-male": "far fa-male",
	"fal-male": "fal fa-male",
	"fad-male": "fad fa-male",
	"fas-map": "fas fa-map",
	"far-map": "far fa-map",
	"fal-map": "fal fa-map",
	"fad-map": "fad fa-map",
	"fas-map-marked": "fas fa-map-marked",
	"far-map-marked": "far fa-map-marked",
	"fal-map-marked": "fal fa-map-marked",
	"fad-map-marked": "fad fa-map-marked",
	"fas-map-marked-alt": "fas fa-map-marked-alt",
	"far-map-marked-alt": "far fa-map-marked-alt",
	"fal-map-marked-alt": "fal fa-map-marked-alt",
	"fad-map-marked-alt": "fad fa-map-marked-alt",
	"fas-map-marker": "fas fa-map-marker",
	"far-map-marker": "far fa-map-marker",
	"fal-map-marker": "fal fa-map-marker",
	"fad-map-marker": "fad fa-map-marker",
	"fas-map-marker-alt": "fas fa-map-marker-alt",
	"far-map-marker-alt": "far fa-map-marker-alt",
	"fal-map-marker-alt": "fal fa-map-marker-alt",
	"fad-map-marker-alt": "fad fa-map-marker-alt",
	"fas-map-pin": "fas fa-map-pin",
	"far-map-pin": "far fa-map-pin",
	"fal-map-pin": "fal fa-map-pin",
	"fad-map-pin": "fad fa-map-pin",
	"fas-map-signs": "fas fa-map-signs",
	"far-map-signs": "far fa-map-signs",
	"fal-map-signs": "fal fa-map-signs",
	"fad-map-signs": "fad fa-map-signs",
	"fas-marker": "fas fa-marker",
	"far-marker": "far fa-marker",
	"fal-marker": "fal fa-marker",
	"fad-marker": "fad fa-marker",
	"fas-mars": "fas fa-mars",
	"far-mars": "far fa-mars",
	"fal-mars": "fal fa-mars",
	"fad-mars": "fad fa-mars",
	"fas-mars-double": "fas fa-mars-double",
	"far-mars-double": "far fa-mars-double",
	"fal-mars-double": "fal fa-mars-double",
	"fad-mars-double": "fad fa-mars-double",
	"fas-mars-stroke": "fas fa-mars-stroke",
	"far-mars-stroke": "far fa-mars-stroke",
	"fal-mars-stroke": "fal fa-mars-stroke",
	"fad-mars-stroke": "fad fa-mars-stroke",
	"fas-mars-stroke-h": "fas fa-mars-stroke-h",
	"far-mars-stroke-h": "far fa-mars-stroke-h",
	"fal-mars-stroke-h": "fal fa-mars-stroke-h",
	"fad-mars-stroke-h": "fad fa-mars-stroke-h",
	"fas-mars-stroke-v": "fas fa-mars-stroke-v",
	"far-mars-stroke-v": "far fa-mars-stroke-v",
	"fal-mars-stroke-v": "fal fa-mars-stroke-v",
	"fad-mars-stroke-v": "fad fa-mars-stroke-v",
	"fas-mask": "fas fa-mask",
	"far-mask": "far fa-mask",
	"fal-mask": "fal fa-mask",
	"fad-mask": "fad fa-mask",
	"fas-medal": "fas fa-medal",
	"far-medal": "far fa-medal",
	"fal-medal": "fal fa-medal",
	"fad-medal": "fad fa-medal",
	"fas-medkit": "fas fa-medkit",
	"far-medkit": "far fa-medkit",
	"fal-medkit": "fal fa-medkit",
	"fad-medkit": "fad fa-medkit",
	"fas-meh": "fas fa-meh",
	"far-meh": "far fa-meh",
	"fal-meh": "fal fa-meh",
	"fad-meh": "fad fa-meh",
	"fas-meh-blank": "fas fa-meh-blank",
	"far-meh-blank": "far fa-meh-blank",
	"fal-meh-blank": "fal fa-meh-blank",
	"fad-meh-blank": "fad fa-meh-blank",
	"fas-meh-rolling-eyes": "fas fa-meh-rolling-eyes",
	"far-meh-rolling-eyes": "far fa-meh-rolling-eyes",
	"fal-meh-rolling-eyes": "fal fa-meh-rolling-eyes",
	"fad-meh-rolling-eyes": "fad fa-meh-rolling-eyes",
	"fas-memory": "fas fa-memory",
	"far-memory": "far fa-memory",
	"fal-memory": "fal fa-memory",
	"fad-memory": "fad fa-memory",
	"fas-menorah": "fas fa-menorah",
	"far-menorah": "far fa-menorah",
	"fal-menorah": "fal fa-menorah",
	"fad-menorah": "fad fa-menorah",
	"fas-mercury": "fas fa-mercury",
	"far-mercury": "far fa-mercury",
	"fal-mercury": "fal fa-mercury",
	"fad-mercury": "fad fa-mercury",
	"fas-meteor": "fas fa-meteor",
	"far-meteor": "far fa-meteor",
	"fal-meteor": "fal fa-meteor",
	"fad-meteor": "fad fa-meteor",
	"fas-microchip": "fas fa-microchip",
	"far-microchip": "far fa-microchip",
	"fal-microchip": "fal fa-microchip",
	"fad-microchip": "fad fa-microchip",
	"fas-microphone": "fas fa-microphone",
	"far-microphone": "far fa-microphone",
	"fal-microphone": "fal fa-microphone",
	"fad-microphone": "fad fa-microphone",
	"fas-microphone-alt": "fas fa-microphone-alt",
	"far-microphone-alt": "far fa-microphone-alt",
	"fal-microphone-alt": "fal fa-microphone-alt",
	"fad-microphone-alt": "fad fa-microphone-alt",
	"fas-microphone-alt-slash": "fas fa-microphone-alt-slash",
	"far-microphone-alt-slash": "far fa-microphone-alt-slash",
	"fal-microphone-alt-slash": "fal fa-microphone-alt-slash",
	"fad-microphone-alt-slash": "fad fa-microphone-alt-slash",
	"fas-microphone-slash": "fas fa-microphone-slash",
	"far-microphone-slash": "far fa-microphone-slash",
	"fal-microphone-slash": "fal fa-microphone-slash",
	"fad-microphone-slash": "fad fa-microphone-slash",
	"fas-microscope": "fas fa-microscope",
	"far-microscope": "far fa-microscope",
	"fal-microscope": "fal fa-microscope",
	"fad-microscope": "fad fa-microscope",
	"fas-minus": "fas fa-minus",
	"far-minus": "far fa-minus",
	"fal-minus": "fal fa-minus",
	"fad-minus": "fad fa-minus",
	"fas-minus-circle": "fas fa-minus-circle",
	"far-minus-circle": "far fa-minus-circle",
	"fal-minus-circle": "fal fa-minus-circle",
	"fad-minus-circle": "fad fa-minus-circle",
	"fas-minus-square": "fas fa-minus-square",
	"far-minus-square": "far fa-minus-square",
	"fal-minus-square": "fal fa-minus-square",
	"fad-minus-square": "fad fa-minus-square",
	"fas-mitten": "fas fa-mitten",
	"far-mitten": "far fa-mitten",
	"fal-mitten": "fal fa-mitten",
	"fad-mitten": "fad fa-mitten",
	"fas-mobile": "fas fa-mobile",
	"far-mobile": "far fa-mobile",
	"fal-mobile": "fal fa-mobile",
	"fad-mobile": "fad fa-mobile",
	"fas-mobile-alt": "fas fa-mobile-alt",
	"far-mobile-alt": "far fa-mobile-alt",
	"fal-mobile-alt": "fal fa-mobile-alt",
	"fad-mobile-alt": "fad fa-mobile-alt",
	"fas-money-bill": "fas fa-money-bill",
	"far-money-bill": "far fa-money-bill",
	"fal-money-bill": "fal fa-money-bill",
	"fad-money-bill": "fad fa-money-bill",
	"fas-money-bill-alt": "fas fa-money-bill-alt",
	"far-money-bill-alt": "far fa-money-bill-alt",
	"fal-money-bill-alt": "fal fa-money-bill-alt",
	"fad-money-bill-alt": "fad fa-money-bill-alt",
	"fas-money-bill-wave": "fas fa-money-bill-wave",
	"far-money-bill-wave": "far fa-money-bill-wave",
	"fal-money-bill-wave": "fal fa-money-bill-wave",
	"fad-money-bill-wave": "fad fa-money-bill-wave",
	"fas-money-bill-wave-alt": "fas fa-money-bill-wave-alt",
	"far-money-bill-wave-alt": "far fa-money-bill-wave-alt",
	"fal-money-bill-wave-alt": "fal fa-money-bill-wave-alt",
	"fad-money-bill-wave-alt": "fad fa-money-bill-wave-alt",
	"fas-money-check": "fas fa-money-check",
	"far-money-check": "far fa-money-check",
	"fal-money-check": "fal fa-money-check",
	"fad-money-check": "fad fa-money-check",
	"fas-money-check-alt": "fas fa-money-check-alt",
	"far-money-check-alt": "far fa-money-check-alt",
	"fal-money-check-alt": "fal fa-money-check-alt",
	"fad-money-check-alt": "fad fa-money-check-alt",
	"fas-monument": "fas fa-monument",
	"far-monument": "far fa-monument",
	"fal-monument": "fal fa-monument",
	"fad-monument": "fad fa-monument",
	"fas-moon": "fas fa-moon",
	"far-moon": "far fa-moon",
	"fal-moon": "fal fa-moon",
	"fad-moon": "fad fa-moon",
	"fas-mortar-pestle": "fas fa-mortar-pestle",
	"far-mortar-pestle": "far fa-mortar-pestle",
	"fal-mortar-pestle": "fal fa-mortar-pestle",
	"fad-mortar-pestle": "fad fa-mortar-pestle",
	"fas-mosque": "fas fa-mosque",
	"far-mosque": "far fa-mosque",
	"fal-mosque": "fal fa-mosque",
	"fad-mosque": "fad fa-mosque",
	"fas-motorcycle": "fas fa-motorcycle",
	"far-motorcycle": "far fa-motorcycle",
	"fal-motorcycle": "fal fa-motorcycle",
	"fad-motorcycle": "fad fa-motorcycle",
	"fas-mountain": "fas fa-mountain",
	"far-mountain": "far fa-mountain",
	"fal-mountain": "fal fa-mountain",
	"fad-mountain": "fad fa-mountain",
	"fas-mouse": "fas fa-mouse",
	"far-mouse": "far fa-mouse",
	"fal-mouse": "fal fa-mouse",
	"fad-mouse": "fad fa-mouse",
	"fas-mouse-pointer": "fas fa-mouse-pointer",
	"far-mouse-pointer": "far fa-mouse-pointer",
	"fal-mouse-pointer": "fal fa-mouse-pointer",
	"fad-mouse-pointer": "fad fa-mouse-pointer",
	"fas-mug-hot": "fas fa-mug-hot",
	"far-mug-hot": "far fa-mug-hot",
	"fal-mug-hot": "fal fa-mug-hot",
	"fad-mug-hot": "fad fa-mug-hot",
	"fas-music": "fas fa-music",
	"far-music": "far fa-music",
	"fal-music": "fal fa-music",
	"fad-music": "fad fa-music",
	"fas-network-wired": "fas fa-network-wired",
	"far-network-wired": "far fa-network-wired",
	"fal-network-wired": "fal fa-network-wired",
	"fad-network-wired": "fad fa-network-wired",
	"fas-neuter": "fas fa-neuter",
	"far-neuter": "far fa-neuter",
	"fal-neuter": "fal fa-neuter",
	"fad-neuter": "fad fa-neuter",
	"fas-newspaper": "fas fa-newspaper",
	"far-newspaper": "far fa-newspaper",
	"fal-newspaper": "fal fa-newspaper",
	"fad-newspaper": "fad fa-newspaper",
	"fas-not-equal": "fas fa-not-equal",
	"far-not-equal": "far fa-not-equal",
	"fal-not-equal": "fal fa-not-equal",
	"fad-not-equal": "fad fa-not-equal",
	"fas-notes-medical": "fas fa-notes-medical",
	"far-notes-medical": "far fa-notes-medical",
	"fal-notes-medical": "fal fa-notes-medical",
	"fad-notes-medical": "fad fa-notes-medical",
	"fas-object-group": "fas fa-object-group",
	"far-object-group": "far fa-object-group",
	"fal-object-group": "fal fa-object-group",
	"fad-object-group": "fad fa-object-group",
	"fas-object-ungroup": "fas fa-object-ungroup",
	"far-object-ungroup": "far fa-object-ungroup",
	"fal-object-ungroup": "fal fa-object-ungroup",
	"fad-object-ungroup": "fad fa-object-ungroup",
	"fas-oil-can": "fas fa-oil-can",
	"far-oil-can": "far fa-oil-can",
	"fal-oil-can": "fal fa-oil-can",
	"fad-oil-can": "fad fa-oil-can",
	"fas-om": "fas fa-om",
	"far-om": "far fa-om",
	"fal-om": "fal fa-om",
	"fad-om": "fad fa-om",
	"fas-otter": "fas fa-otter",
	"far-otter": "far fa-otter",
	"fal-otter": "fal fa-otter",
	"fad-otter": "fad fa-otter",
	"fas-outdent": "fas fa-outdent",
	"far-outdent": "far fa-outdent",
	"fal-outdent": "fal fa-outdent",
	"fad-outdent": "fad fa-outdent",
	"fas-pager": "fas fa-pager",
	"far-pager": "far fa-pager",
	"fal-pager": "fal fa-pager",
	"fad-pager": "fad fa-pager",
	"fas-paint-brush": "fas fa-paint-brush",
	"far-paint-brush": "far fa-paint-brush",
	"fal-paint-brush": "fal fa-paint-brush",
	"fad-paint-brush": "fad fa-paint-brush",
	"fas-paint-roller": "fas fa-paint-roller",
	"far-paint-roller": "far fa-paint-roller",
	"fal-paint-roller": "fal fa-paint-roller",
	"fad-paint-roller": "fad fa-paint-roller",
	"fas-palette": "fas fa-palette",
	"far-palette": "far fa-palette",
	"fal-palette": "fal fa-palette",
	"fad-palette": "fad fa-palette",
	"fas-pallet": "fas fa-pallet",
	"far-pallet": "far fa-pallet",
	"fal-pallet": "fal fa-pallet",
	"fad-pallet": "fad fa-pallet",
	"fas-paper-plane": "fas fa-paper-plane",
	"far-paper-plane": "far fa-paper-plane",
	"fal-paper-plane": "fal fa-paper-plane",
	"fad-paper-plane": "fad fa-paper-plane",
	"fas-paperclip": "fas fa-paperclip",
	"far-paperclip": "far fa-paperclip",
	"fal-paperclip": "fal fa-paperclip",
	"fad-paperclip": "fad fa-paperclip",
	"fas-parachute-box": "fas fa-parachute-box",
	"far-parachute-box": "far fa-parachute-box",
	"fal-parachute-box": "fal fa-parachute-box",
	"fad-parachute-box": "fad fa-parachute-box",
	"fas-paragraph": "fas fa-paragraph",
	"far-paragraph": "far fa-paragraph",
	"fal-paragraph": "fal fa-paragraph",
	"fad-paragraph": "fad fa-paragraph",
	"fas-parking": "fas fa-parking",
	"far-parking": "far fa-parking",
	"fal-parking": "fal fa-parking",
	"fad-parking": "fad fa-parking",
	"fas-passport": "fas fa-passport",
	"far-passport": "far fa-passport",
	"fal-passport": "fal fa-passport",
	"fad-passport": "fad fa-passport",
	"fas-pastafarianism": "fas fa-pastafarianism",
	"far-pastafarianism": "far fa-pastafarianism",
	"fal-pastafarianism": "fal fa-pastafarianism",
	"fad-pastafarianism": "fad fa-pastafarianism",
	"fas-paste": "fas fa-paste",
	"far-paste": "far fa-paste",
	"fal-paste": "fal fa-paste",
	"fad-paste": "fad fa-paste",
	"fas-pause": "fas fa-pause",
	"far-pause": "far fa-pause",
	"fal-pause": "fal fa-pause",
	"fad-pause": "fad fa-pause",
	"fas-pause-circle": "fas fa-pause-circle",
	"far-pause-circle": "far fa-pause-circle",
	"fal-pause-circle": "fal fa-pause-circle",
	"fad-pause-circle": "fad fa-pause-circle",
	"fas-paw": "fas fa-paw",
	"far-paw": "far fa-paw",
	"fal-paw": "fal fa-paw",
	"fad-paw": "fad fa-paw",
	"fas-peace": "fas fa-peace",
	"far-peace": "far fa-peace",
	"fal-peace": "fal fa-peace",
	"fad-peace": "fad fa-peace",
	"fas-pen": "fas fa-pen",
	"far-pen": "far fa-pen",
	"fal-pen": "fal fa-pen",
	"fad-pen": "fad fa-pen",
	"fas-pen-alt": "fas fa-pen-alt",
	"far-pen-alt": "far fa-pen-alt",
	"fal-pen-alt": "fal fa-pen-alt",
	"fad-pen-alt": "fad fa-pen-alt",
	"fas-pen-fancy": "fas fa-pen-fancy",
	"far-pen-fancy": "far fa-pen-fancy",
	"fal-pen-fancy": "fal fa-pen-fancy",
	"fad-pen-fancy": "fad fa-pen-fancy",
	"fas-pen-nib": "fas fa-pen-nib",
	"far-pen-nib": "far fa-pen-nib",
	"fal-pen-nib": "fal fa-pen-nib",
	"fad-pen-nib": "fad fa-pen-nib",
	"fas-pen-square": "fas fa-pen-square",
	"far-pen-square": "far fa-pen-square",
	"fal-pen-square": "fal fa-pen-square",
	"fad-pen-square": "fad fa-pen-square",
	"fas-pencil-alt": "fas fa-pencil-alt",
	"far-pencil-alt": "far fa-pencil-alt",
	"fal-pencil-alt": "fal fa-pencil-alt",
	"fad-pencil-alt": "fad fa-pencil-alt",
	"fas-pencil-ruler": "fas fa-pencil-ruler",
	"far-pencil-ruler": "far fa-pencil-ruler",
	"fal-pencil-ruler": "fal fa-pencil-ruler",
	"fad-pencil-ruler": "fad fa-pencil-ruler",
	"fas-people-arrows": "fas fa-people-arrows",
	"far-people-arrows": "far fa-people-arrows",
	"fal-people-arrows": "fal fa-people-arrows",
	"fad-people-arrows": "fad fa-people-arrows",
	"fas-people-carry": "fas fa-people-carry",
	"far-people-carry": "far fa-people-carry",
	"fal-people-carry": "fal fa-people-carry",
	"fad-people-carry": "fad fa-people-carry",
	"fas-pepper-hot": "fas fa-pepper-hot",
	"far-pepper-hot": "far fa-pepper-hot",
	"fal-pepper-hot": "fal fa-pepper-hot",
	"fad-pepper-hot": "fad fa-pepper-hot",
	"fas-percent": "fas fa-percent",
	"far-percent": "far fa-percent",
	"fal-percent": "fal fa-percent",
	"fad-percent": "fad fa-percent",
	"fas-percentage": "fas fa-percentage",
	"far-percentage": "far fa-percentage",
	"fal-percentage": "fal fa-percentage",
	"fad-percentage": "fad fa-percentage",
	"fas-person-booth": "fas fa-person-booth",
	"far-person-booth": "far fa-person-booth",
	"fal-person-booth": "fal fa-person-booth",
	"fad-person-booth": "fad fa-person-booth",
	"fas-phone": "fas fa-phone",
	"far-phone": "far fa-phone",
	"fal-phone": "fal fa-phone",
	"fad-phone": "fad fa-phone",
	"fas-phone-alt": "fas fa-phone-alt",
	"far-phone-alt": "far fa-phone-alt",
	"fal-phone-alt": "fal fa-phone-alt",
	"fad-phone-alt": "fad fa-phone-alt",
	"fas-phone-slash": "fas fa-phone-slash",
	"far-phone-slash": "far fa-phone-slash",
	"fal-phone-slash": "fal fa-phone-slash",
	"fad-phone-slash": "fad fa-phone-slash",
	"fas-phone-square": "fas fa-phone-square",
	"far-phone-square": "far fa-phone-square",
	"fal-phone-square": "fal fa-phone-square",
	"fad-phone-square": "fad fa-phone-square",
	"fas-phone-square-alt": "fas fa-phone-square-alt",
	"far-phone-square-alt": "far fa-phone-square-alt",
	"fal-phone-square-alt": "fal fa-phone-square-alt",
	"fad-phone-square-alt": "fad fa-phone-square-alt",
	"fas-phone-volume": "fas fa-phone-volume",
	"far-phone-volume": "far fa-phone-volume",
	"fal-phone-volume": "fal fa-phone-volume",
	"fad-phone-volume": "fad fa-phone-volume",
	"fas-photo-video": "fas fa-photo-video",
	"far-photo-video": "far fa-photo-video",
	"fal-photo-video": "fal fa-photo-video",
	"fad-photo-video": "fad fa-photo-video",
	"fas-piggy-bank": "fas fa-piggy-bank",
	"far-piggy-bank": "far fa-piggy-bank",
	"fal-piggy-bank": "fal fa-piggy-bank",
	"fad-piggy-bank": "fad fa-piggy-bank",
	"fas-pills": "fas fa-pills",
	"far-pills": "far fa-pills",
	"fal-pills": "fal fa-pills",
	"fad-pills": "fad fa-pills",
	"fas-pizza-slice": "fas fa-pizza-slice",
	"far-pizza-slice": "far fa-pizza-slice",
	"fal-pizza-slice": "fal fa-pizza-slice",
	"fad-pizza-slice": "fad fa-pizza-slice",
	"fas-place-of-worship": "fas fa-place-of-worship",
	"far-place-of-worship": "far fa-place-of-worship",
	"fal-place-of-worship": "fal fa-place-of-worship",
	"fad-place-of-worship": "fad fa-place-of-worship",
	"fas-plane": "fas fa-plane",
	"far-plane": "far fa-plane",
	"fal-plane": "fal fa-plane",
	"fad-plane": "fad fa-plane",
	"fas-plane-arrival": "fas fa-plane-arrival",
	"far-plane-arrival": "far fa-plane-arrival",
	"fal-plane-arrival": "fal fa-plane-arrival",
	"fad-plane-arrival": "fad fa-plane-arrival",
	"fas-plane-departure": "fas fa-plane-departure",
	"far-plane-departure": "far fa-plane-departure",
	"fal-plane-departure": "fal fa-plane-departure",
	"fad-plane-departure": "fad fa-plane-departure",
	"fas-plane-slash": "fas fa-plane-slash",
	"far-plane-slash": "far fa-plane-slash",
	"fal-plane-slash": "fal fa-plane-slash",
	"fad-plane-slash": "fad fa-plane-slash",
	"fas-play": "fas fa-play",
	"far-play": "far fa-play",
	"fal-play": "fal fa-play",
	"fad-play": "fad fa-play",
	"fas-play-circle": "fas fa-play-circle",
	"far-play-circle": "far fa-play-circle",
	"fal-play-circle": "fal fa-play-circle",
	"fad-play-circle": "fad fa-play-circle",
	"fas-plug": "fas fa-plug",
	"far-plug": "far fa-plug",
	"fal-plug": "fal fa-plug",
	"fad-plug": "fad fa-plug",
	"fas-plus": "fas fa-plus",
	"far-plus": "far fa-plus",
	"fal-plus": "fal fa-plus",
	"fad-plus": "fad fa-plus",
	"fas-plus-circle": "fas fa-plus-circle",
	"far-plus-circle": "far fa-plus-circle",
	"fal-plus-circle": "fal fa-plus-circle",
	"fad-plus-circle": "fad fa-plus-circle",
	"fas-plus-square": "fas fa-plus-square",
	"far-plus-square": "far fa-plus-square",
	"fal-plus-square": "fal fa-plus-square",
	"fad-plus-square": "fad fa-plus-square",
	"fas-podcast": "fas fa-podcast",
	"far-podcast": "far fa-podcast",
	"fal-podcast": "fal fa-podcast",
	"fad-podcast": "fad fa-podcast",
	"fas-poll": "fas fa-poll",
	"far-poll": "far fa-poll",
	"fal-poll": "fal fa-poll",
	"fad-poll": "fad fa-poll",
	"fas-poll-h": "fas fa-poll-h",
	"far-poll-h": "far fa-poll-h",
	"fal-poll-h": "fal fa-poll-h",
	"fad-poll-h": "fad fa-poll-h",
	"fas-poo": "fas fa-poo",
	"far-poo": "far fa-poo",
	"fal-poo": "fal fa-poo",
	"fad-poo": "fad fa-poo",
	"fas-poo-storm": "fas fa-poo-storm",
	"far-poo-storm": "far fa-poo-storm",
	"fal-poo-storm": "fal fa-poo-storm",
	"fad-poo-storm": "fad fa-poo-storm",
	"fas-poop": "fas fa-poop",
	"far-poop": "far fa-poop",
	"fal-poop": "fal fa-poop",
	"fad-poop": "fad fa-poop",
	"fas-portrait": "fas fa-portrait",
	"far-portrait": "far fa-portrait",
	"fal-portrait": "fal fa-portrait",
	"fad-portrait": "fad fa-portrait",
	"fas-pound-sign": "fas fa-pound-sign",
	"far-pound-sign": "far fa-pound-sign",
	"fal-pound-sign": "fal fa-pound-sign",
	"fad-pound-sign": "fad fa-pound-sign",
	"fas-power-off": "fas fa-power-off",
	"far-power-off": "far fa-power-off",
	"fal-power-off": "fal fa-power-off",
	"fad-power-off": "fad fa-power-off",
	"fas-pray": "fas fa-pray",
	"far-pray": "far fa-pray",
	"fal-pray": "fal fa-pray",
	"fad-pray": "fad fa-pray",
	"fas-praying-hands": "fas fa-praying-hands",
	"far-praying-hands": "far fa-praying-hands",
	"fal-praying-hands": "fal fa-praying-hands",
	"fad-praying-hands": "fad fa-praying-hands",
	"fas-prescription": "fas fa-prescription",
	"far-prescription": "far fa-prescription",
	"fal-prescription": "fal fa-prescription",
	"fad-prescription": "fad fa-prescription",
	"fas-prescription-bottle": "fas fa-prescription-bottle",
	"far-prescription-bottle": "far fa-prescription-bottle",
	"fal-prescription-bottle": "fal fa-prescription-bottle",
	"fad-prescription-bottle": "fad fa-prescription-bottle",
	"fas-prescription-bottle-alt": "fas fa-prescription-bottle-alt",
	"far-prescription-bottle-alt": "far fa-prescription-bottle-alt",
	"fal-prescription-bottle-alt": "fal fa-prescription-bottle-alt",
	"fad-prescription-bottle-alt": "fad fa-prescription-bottle-alt",
	"fas-print": "fas fa-print",
	"far-print": "far fa-print",
	"fal-print": "fal fa-print",
	"fad-print": "fad fa-print",
	"fas-procedures": "fas fa-procedures",
	"far-procedures": "far fa-procedures",
	"fal-procedures": "fal fa-procedures",
	"fad-procedures": "fad fa-procedures",
	"fas-project-diagram": "fas fa-project-diagram",
	"far-project-diagram": "far fa-project-diagram",
	"fal-project-diagram": "fal fa-project-diagram",
	"fad-project-diagram": "fad fa-project-diagram",
	"fas-pump-medical": "fas fa-pump-medical",
	"far-pump-medical": "far fa-pump-medical",
	"fal-pump-medical": "fal fa-pump-medical",
	"fad-pump-medical": "fad fa-pump-medical",
	"fas-pump-soap": "fas fa-pump-soap",
	"far-pump-soap": "far fa-pump-soap",
	"fal-pump-soap": "fal fa-pump-soap",
	"fad-pump-soap": "fad fa-pump-soap",
	"fas-puzzle-piece": "fas fa-puzzle-piece",
	"far-puzzle-piece": "far fa-puzzle-piece",
	"fal-puzzle-piece": "fal fa-puzzle-piece",
	"fad-puzzle-piece": "fad fa-puzzle-piece",
	"fas-qrcode": "fas fa-qrcode",
	"far-qrcode": "far fa-qrcode",
	"fal-qrcode": "fal fa-qrcode",
	"fad-qrcode": "fad fa-qrcode",
	"fas-question": "fas fa-question",
	"far-question": "far fa-question",
	"fal-question": "fal fa-question",
	"fad-question": "fad fa-question",
	"fas-question-circle": "fas fa-question-circle",
	"far-question-circle": "far fa-question-circle",
	"fal-question-circle": "fal fa-question-circle",
	"fad-question-circle": "fad fa-question-circle",
	"fas-quidditch": "fas fa-quidditch",
	"far-quidditch": "far fa-quidditch",
	"fal-quidditch": "fal fa-quidditch",
	"fad-quidditch": "fad fa-quidditch",
	"fas-quote-left": "fas fa-quote-left",
	"far-quote-left": "far fa-quote-left",
	"fal-quote-left": "fal fa-quote-left",
	"fad-quote-left": "fad fa-quote-left",
	"fas-quote-right": "fas fa-quote-right",
	"far-quote-right": "far fa-quote-right",
	"fal-quote-right": "fal fa-quote-right",
	"fad-quote-right": "fad fa-quote-right",
	"fas-quran": "fas fa-quran",
	"far-quran": "far fa-quran",
	"fal-quran": "fal fa-quran",
	"fad-quran": "fad fa-quran",
	"fas-radiation": "fas fa-radiation",
	"far-radiation": "far fa-radiation",
	"fal-radiation": "fal fa-radiation",
	"fad-radiation": "fad fa-radiation",
	"fas-radiation-alt": "fas fa-radiation-alt",
	"far-radiation-alt": "far fa-radiation-alt",
	"fal-radiation-alt": "fal fa-radiation-alt",
	"fad-radiation-alt": "fad fa-radiation-alt",
	"fas-rainbow": "fas fa-rainbow",
	"far-rainbow": "far fa-rainbow",
	"fal-rainbow": "fal fa-rainbow",
	"fad-rainbow": "fad fa-rainbow",
	"fas-random": "fas fa-random",
	"far-random": "far fa-random",
	"fal-random": "fal fa-random",
	"fad-random": "fad fa-random",
	"fas-receipt": "fas fa-receipt",
	"far-receipt": "far fa-receipt",
	"fal-receipt": "fal fa-receipt",
	"fad-receipt": "fad fa-receipt",
	"fas-record-vinyl": "fas fa-record-vinyl",
	"far-record-vinyl": "far fa-record-vinyl",
	"fal-record-vinyl": "fal fa-record-vinyl",
	"fad-record-vinyl": "fad fa-record-vinyl",
	"fas-recycle": "fas fa-recycle",
	"far-recycle": "far fa-recycle",
	"fal-recycle": "fal fa-recycle",
	"fad-recycle": "fad fa-recycle",
	"fas-redo": "fas fa-redo",
	"far-redo": "far fa-redo",
	"fal-redo": "fal fa-redo",
	"fad-redo": "fad fa-redo",
	"fas-redo-alt": "fas fa-redo-alt",
	"far-redo-alt": "far fa-redo-alt",
	"fal-redo-alt": "fal fa-redo-alt",
	"fad-redo-alt": "fad fa-redo-alt",
	"fas-registered": "fas fa-registered",
	"far-registered": "far fa-registered",
	"fal-registered": "fal fa-registered",
	"fad-registered": "fad fa-registered",
	"fas-remove-format": "fas fa-remove-format",
	"far-remove-format": "far fa-remove-format",
	"fal-remove-format": "fal fa-remove-format",
	"fad-remove-format": "fad fa-remove-format",
	"fas-reply": "fas fa-reply",
	"far-reply": "far fa-reply",
	"fal-reply": "fal fa-reply",
	"fad-reply": "fad fa-reply",
	"fas-reply-all": "fas fa-reply-all",
	"far-reply-all": "far fa-reply-all",
	"fal-reply-all": "fal fa-reply-all",
	"fad-reply-all": "fad fa-reply-all",
	"fas-republican": "fas fa-republican",
	"far-republican": "far fa-republican",
	"fal-republican": "fal fa-republican",
	"fad-republican": "fad fa-republican",
	"fas-restroom": "fas fa-restroom",
	"far-restroom": "far fa-restroom",
	"fal-restroom": "fal fa-restroom",
	"fad-restroom": "fad fa-restroom",
	"fas-retweet": "fas fa-retweet",
	"far-retweet": "far fa-retweet",
	"fal-retweet": "fal fa-retweet",
	"fad-retweet": "fad fa-retweet",
	"fas-ribbon": "fas fa-ribbon",
	"far-ribbon": "far fa-ribbon",
	"fal-ribbon": "fal fa-ribbon",
	"fad-ribbon": "fad fa-ribbon",
	"fas-ring": "fas fa-ring",
	"far-ring": "far fa-ring",
	"fal-ring": "fal fa-ring",
	"fad-ring": "fad fa-ring",
	"fas-road": "fas fa-road",
	"far-road": "far fa-road",
	"fal-road": "fal fa-road",
	"fad-road": "fad fa-road",
	"fas-robot": "fas fa-robot",
	"far-robot": "far fa-robot",
	"fal-robot": "fal fa-robot",
	"fad-robot": "fad fa-robot",
	"fas-rocket": "fas fa-rocket",
	"far-rocket": "far fa-rocket",
	"fal-rocket": "fal fa-rocket",
	"fad-rocket": "fad fa-rocket",
	"fas-route": "fas fa-route",
	"far-route": "far fa-route",
	"fal-route": "fal fa-route",
	"fad-route": "fad fa-route",
	"fas-rss": "fas fa-rss",
	"far-rss": "far fa-rss",
	"fal-rss": "fal fa-rss",
	"fad-rss": "fad fa-rss",
	"fas-rss-square": "fas fa-rss-square",
	"far-rss-square": "far fa-rss-square",
	"fal-rss-square": "fal fa-rss-square",
	"fad-rss-square": "fad fa-rss-square",
	"fas-ruble-sign": "fas fa-ruble-sign",
	"far-ruble-sign": "far fa-ruble-sign",
	"fal-ruble-sign": "fal fa-ruble-sign",
	"fad-ruble-sign": "fad fa-ruble-sign",
	"fas-ruler": "fas fa-ruler",
	"far-ruler": "far fa-ruler",
	"fal-ruler": "fal fa-ruler",
	"fad-ruler": "fad fa-ruler",
	"fas-ruler-combined": "fas fa-ruler-combined",
	"far-ruler-combined": "far fa-ruler-combined",
	"fal-ruler-combined": "fal fa-ruler-combined",
	"fad-ruler-combined": "fad fa-ruler-combined",
	"fas-ruler-horizontal": "fas fa-ruler-horizontal",
	"far-ruler-horizontal": "far fa-ruler-horizontal",
	"fal-ruler-horizontal": "fal fa-ruler-horizontal",
	"fad-ruler-horizontal": "fad fa-ruler-horizontal",
	"fas-ruler-vertical": "fas fa-ruler-vertical",
	"far-ruler-vertical": "far fa-ruler-vertical",
	"fal-ruler-vertical": "fal fa-ruler-vertical",
	"fad-ruler-vertical": "fad fa-ruler-vertical",
	"fas-running": "fas fa-running",
	"far-running": "far fa-running",
	"fal-running": "fal fa-running",
	"fad-running": "fad fa-running",
	"fas-rupee-sign": "fas fa-rupee-sign",
	"far-rupee-sign": "far fa-rupee-sign",
	"fal-rupee-sign": "fal fa-rupee-sign",
	"fad-rupee-sign": "fad fa-rupee-sign",
	"fas-sad-cry": "fas fa-sad-cry",
	"far-sad-cry": "far fa-sad-cry",
	"fal-sad-cry": "fal fa-sad-cry",
	"fad-sad-cry": "fad fa-sad-cry",
	"fas-sad-tear": "fas fa-sad-tear",
	"far-sad-tear": "far fa-sad-tear",
	"fal-sad-tear": "fal fa-sad-tear",
	"fad-sad-tear": "fad fa-sad-tear",
	"fas-satellite": "fas fa-satellite",
	"far-satellite": "far fa-satellite",
	"fal-satellite": "fal fa-satellite",
	"fad-satellite": "fad fa-satellite",
	"fas-satellite-dish": "fas fa-satellite-dish",
	"far-satellite-dish": "far fa-satellite-dish",
	"fal-satellite-dish": "fal fa-satellite-dish",
	"fad-satellite-dish": "fad fa-satellite-dish",
	"fas-save": "fas fa-save",
	"far-save": "far fa-save",
	"fal-save": "fal fa-save",
	"fad-save": "fad fa-save",
	"fas-school": "fas fa-school",
	"far-school": "far fa-school",
	"fal-school": "fal fa-school",
	"fad-school": "fad fa-school",
	"fas-screwdriver": "fas fa-screwdriver",
	"far-screwdriver": "far fa-screwdriver",
	"fal-screwdriver": "fal fa-screwdriver",
	"fad-screwdriver": "fad fa-screwdriver",
	"fas-scroll": "fas fa-scroll",
	"far-scroll": "far fa-scroll",
	"fal-scroll": "fal fa-scroll",
	"fad-scroll": "fad fa-scroll",
	"fas-sd-card": "fas fa-sd-card",
	"far-sd-card": "far fa-sd-card",
	"fal-sd-card": "fal fa-sd-card",
	"fad-sd-card": "fad fa-sd-card",
	"fas-search": "fas fa-search",
	"far-search": "far fa-search",
	"fal-search": "fal fa-search",
	"fad-search": "fad fa-search",
	"fas-search-dollar": "fas fa-search-dollar",
	"far-search-dollar": "far fa-search-dollar",
	"fal-search-dollar": "fal fa-search-dollar",
	"fad-search-dollar": "fad fa-search-dollar",
	"fas-search-location": "fas fa-search-location",
	"far-search-location": "far fa-search-location",
	"fal-search-location": "fal fa-search-location",
	"fad-search-location": "fad fa-search-location",
	"fas-search-minus": "fas fa-search-minus",
	"far-search-minus": "far fa-search-minus",
	"fal-search-minus": "fal fa-search-minus",
	"fad-search-minus": "fad fa-search-minus",
	"fas-search-plus": "fas fa-search-plus",
	"far-search-plus": "far fa-search-plus",
	"fal-search-plus": "fal fa-search-plus",
	"fad-search-plus": "fad fa-search-plus",
	"fas-seedling": "fas fa-seedling",
	"far-seedling": "far fa-seedling",
	"fal-seedling": "fal fa-seedling",
	"fad-seedling": "fad fa-seedling",
	"fas-server": "fas fa-server",
	"far-server": "far fa-server",
	"fal-server": "fal fa-server",
	"fad-server": "fad fa-server",
	"fas-shapes": "fas fa-shapes",
	"far-shapes": "far fa-shapes",
	"fal-shapes": "fal fa-shapes",
	"fad-shapes": "fad fa-shapes",
	"fas-share": "fas fa-share",
	"far-share": "far fa-share",
	"fal-share": "fal fa-share",
	"fad-share": "fad fa-share",
	"fas-share-alt": "fas fa-share-alt",
	"far-share-alt": "far fa-share-alt",
	"fal-share-alt": "fal fa-share-alt",
	"fad-share-alt": "fad fa-share-alt",
	"fas-share-alt-square": "fas fa-share-alt-square",
	"far-share-alt-square": "far fa-share-alt-square",
	"fal-share-alt-square": "fal fa-share-alt-square",
	"fad-share-alt-square": "fad fa-share-alt-square",
	"fas-share-square": "fas fa-share-square",
	"far-share-square": "far fa-share-square",
	"fal-share-square": "fal fa-share-square",
	"fad-share-square": "fad fa-share-square",
	"fas-shekel-sign": "fas fa-shekel-sign",
	"far-shekel-sign": "far fa-shekel-sign",
	"fal-shekel-sign": "fal fa-shekel-sign",
	"fad-shekel-sign": "fad fa-shekel-sign",
	"fas-shield-alt": "fas fa-shield-alt",
	"far-shield-alt": "far fa-shield-alt",
	"fal-shield-alt": "fal fa-shield-alt",
	"fad-shield-alt": "fad fa-shield-alt",
	"fas-shield-virus": "fas fa-shield-virus",
	"far-shield-virus": "far fa-shield-virus",
	"fal-shield-virus": "fal fa-shield-virus",
	"fad-shield-virus": "fad fa-shield-virus",
	"fas-ship": "fas fa-ship",
	"far-ship": "far fa-ship",
	"fal-ship": "fal fa-ship",
	"fad-ship": "fad fa-ship",
	"fas-shipping-fast": "fas fa-shipping-fast",
	"far-shipping-fast": "far fa-shipping-fast",
	"fal-shipping-fast": "fal fa-shipping-fast",
	"fad-shipping-fast": "fad fa-shipping-fast",
	"fas-shoe-prints": "fas fa-shoe-prints",
	"far-shoe-prints": "far fa-shoe-prints",
	"fal-shoe-prints": "fal fa-shoe-prints",
	"fad-shoe-prints": "fad fa-shoe-prints",
	"fas-shopping-bag": "fas fa-shopping-bag",
	"far-shopping-bag": "far fa-shopping-bag",
	"fal-shopping-bag": "fal fa-shopping-bag",
	"fad-shopping-bag": "fad fa-shopping-bag",
	"fas-shopping-basket": "fas fa-shopping-basket",
	"far-shopping-basket": "far fa-shopping-basket",
	"fal-shopping-basket": "fal fa-shopping-basket",
	"fad-shopping-basket": "fad fa-shopping-basket",
	"fas-shopping-cart": "fas fa-shopping-cart",
	"far-shopping-cart": "far fa-shopping-cart",
	"fal-shopping-cart": "fal fa-shopping-cart",
	"fad-shopping-cart": "fad fa-shopping-cart",
	"fas-shower": "fas fa-shower",
	"far-shower": "far fa-shower",
	"fal-shower": "fal fa-shower",
	"fad-shower": "fad fa-shower",
	"fas-shuttle-van": "fas fa-shuttle-van",
	"far-shuttle-van": "far fa-shuttle-van",
	"fal-shuttle-van": "fal fa-shuttle-van",
	"fad-shuttle-van": "fad fa-shuttle-van",
	"fas-sign": "fas fa-sign",
	"far-sign": "far fa-sign",
	"fal-sign": "fal fa-sign",
	"fad-sign": "fad fa-sign",
	"fas-sign-in-alt": "fas fa-sign-in-alt",
	"far-sign-in-alt": "far fa-sign-in-alt",
	"fal-sign-in-alt": "fal fa-sign-in-alt",
	"fad-sign-in-alt": "fad fa-sign-in-alt",
	"fas-sign-language": "fas fa-sign-language",
	"far-sign-language": "far fa-sign-language",
	"fal-sign-language": "fal fa-sign-language",
	"fad-sign-language": "fad fa-sign-language",
	"fas-sign-out-alt": "fas fa-sign-out-alt",
	"far-sign-out-alt": "far fa-sign-out-alt",
	"fal-sign-out-alt": "fal fa-sign-out-alt",
	"fad-sign-out-alt": "fad fa-sign-out-alt",
	"fas-signal": "fas fa-signal",
	"far-signal": "far fa-signal",
	"fal-signal": "fal fa-signal",
	"fad-signal": "fad fa-signal",
	"fas-signature": "fas fa-signature",
	"far-signature": "far fa-signature",
	"fal-signature": "fal fa-signature",
	"fad-signature": "fad fa-signature",
	"fas-sim-card": "fas fa-sim-card",
	"far-sim-card": "far fa-sim-card",
	"fal-sim-card": "fal fa-sim-card",
	"fad-sim-card": "fad fa-sim-card",
	"fas-sink": "fas fa-sink",
	"far-sink": "far fa-sink",
	"fal-sink": "fal fa-sink",
	"fad-sink": "fad fa-sink",
	"fas-sitemap": "fas fa-sitemap",
	"far-sitemap": "far fa-sitemap",
	"fal-sitemap": "fal fa-sitemap",
	"fad-sitemap": "fad fa-sitemap",
	"fas-skating": "fas fa-skating",
	"far-skating": "far fa-skating",
	"fal-skating": "fal fa-skating",
	"fad-skating": "fad fa-skating",
	"fas-skiing": "fas fa-skiing",
	"far-skiing": "far fa-skiing",
	"fal-skiing": "fal fa-skiing",
	"fad-skiing": "fad fa-skiing",
	"fas-skiing-nordic": "fas fa-skiing-nordic",
	"far-skiing-nordic": "far fa-skiing-nordic",
	"fal-skiing-nordic": "fal fa-skiing-nordic",
	"fad-skiing-nordic": "fad fa-skiing-nordic",
	"fas-skull": "fas fa-skull",
	"far-skull": "far fa-skull",
	"fal-skull": "fal fa-skull",
	"fad-skull": "fad fa-skull",
	"fas-skull-crossbones": "fas fa-skull-crossbones",
	"far-skull-crossbones": "far fa-skull-crossbones",
	"fal-skull-crossbones": "fal fa-skull-crossbones",
	"fad-skull-crossbones": "fad fa-skull-crossbones",
	"fas-slash": "fas fa-slash",
	"far-slash": "far fa-slash",
	"fal-slash": "fal fa-slash",
	"fad-slash": "fad fa-slash",
	"fas-sleigh": "fas fa-sleigh",
	"far-sleigh": "far fa-sleigh",
	"fal-sleigh": "fal fa-sleigh",
	"fad-sleigh": "fad fa-sleigh",
	"fas-sliders-h": "fas fa-sliders-h",
	"far-sliders-h": "far fa-sliders-h",
	"fal-sliders-h": "fal fa-sliders-h",
	"fad-sliders-h": "fad fa-sliders-h",
	"fas-smile": "fas fa-smile",
	"far-smile": "far fa-smile",
	"fal-smile": "fal fa-smile",
	"fad-smile": "fad fa-smile",
	"fas-smile-beam": "fas fa-smile-beam",
	"far-smile-beam": "far fa-smile-beam",
	"fal-smile-beam": "fal fa-smile-beam",
	"fad-smile-beam": "fad fa-smile-beam",
	"fas-smile-wink": "fas fa-smile-wink",
	"far-smile-wink": "far fa-smile-wink",
	"fal-smile-wink": "fal fa-smile-wink",
	"fad-smile-wink": "fad fa-smile-wink",
	"fas-smog": "fas fa-smog",
	"far-smog": "far fa-smog",
	"fal-smog": "fal fa-smog",
	"fad-smog": "fad fa-smog",
	"fas-smoking": "fas fa-smoking",
	"far-smoking": "far fa-smoking",
	"fal-smoking": "fal fa-smoking",
	"fad-smoking": "fad fa-smoking",
	"fas-smoking-ban": "fas fa-smoking-ban",
	"far-smoking-ban": "far fa-smoking-ban",
	"fal-smoking-ban": "fal fa-smoking-ban",
	"fad-smoking-ban": "fad fa-smoking-ban",
	"fas-sms": "fas fa-sms",
	"far-sms": "far fa-sms",
	"fal-sms": "fal fa-sms",
	"fad-sms": "fad fa-sms",
	"fas-snowboarding": "fas fa-snowboarding",
	"far-snowboarding": "far fa-snowboarding",
	"fal-snowboarding": "fal fa-snowboarding",
	"fad-snowboarding": "fad fa-snowboarding",
	"fas-snowflake": "fas fa-snowflake",
	"far-snowflake": "far fa-snowflake",
	"fal-snowflake": "fal fa-snowflake",
	"fad-snowflake": "fad fa-snowflake",
	"fas-snowman": "fas fa-snowman",
	"far-snowman": "far fa-snowman",
	"fal-snowman": "fal fa-snowman",
	"fad-snowman": "fad fa-snowman",
	"fas-snowplow": "fas fa-snowplow",
	"far-snowplow": "far fa-snowplow",
	"fal-snowplow": "fal fa-snowplow",
	"fad-snowplow": "fad fa-snowplow",
	"fas-soap": "fas fa-soap",
	"far-soap": "far fa-soap",
	"fal-soap": "fal fa-soap",
	"fad-soap": "fad fa-soap",
	"fas-socks": "fas fa-socks",
	"far-socks": "far fa-socks",
	"fal-socks": "fal fa-socks",
	"fad-socks": "fad fa-socks",
	"fas-solar-panel": "fas fa-solar-panel",
	"far-solar-panel": "far fa-solar-panel",
	"fal-solar-panel": "fal fa-solar-panel",
	"fad-solar-panel": "fad fa-solar-panel",
	"fas-sort": "fas fa-sort",
	"far-sort": "far fa-sort",
	"fal-sort": "fal fa-sort",
	"fad-sort": "fad fa-sort",
	"fas-sort-alpha-down": "fas fa-sort-alpha-down",
	"far-sort-alpha-down": "far fa-sort-alpha-down",
	"fal-sort-alpha-down": "fal fa-sort-alpha-down",
	"fad-sort-alpha-down": "fad fa-sort-alpha-down",
	"fas-sort-alpha-down-alt": "fas fa-sort-alpha-down-alt",
	"far-sort-alpha-down-alt": "far fa-sort-alpha-down-alt",
	"fal-sort-alpha-down-alt": "fal fa-sort-alpha-down-alt",
	"fad-sort-alpha-down-alt": "fad fa-sort-alpha-down-alt",
	"fas-sort-alpha-up": "fas fa-sort-alpha-up",
	"far-sort-alpha-up": "far fa-sort-alpha-up",
	"fal-sort-alpha-up": "fal fa-sort-alpha-up",
	"fad-sort-alpha-up": "fad fa-sort-alpha-up",
	"fas-sort-alpha-up-alt": "fas fa-sort-alpha-up-alt",
	"far-sort-alpha-up-alt": "far fa-sort-alpha-up-alt",
	"fal-sort-alpha-up-alt": "fal fa-sort-alpha-up-alt",
	"fad-sort-alpha-up-alt": "fad fa-sort-alpha-up-alt",
	"fas-sort-amount-down": "fas fa-sort-amount-down",
	"far-sort-amount-down": "far fa-sort-amount-down",
	"fal-sort-amount-down": "fal fa-sort-amount-down",
	"fad-sort-amount-down": "fad fa-sort-amount-down",
	"fas-sort-amount-down-alt": "fas fa-sort-amount-down-alt",
	"far-sort-amount-down-alt": "far fa-sort-amount-down-alt",
	"fal-sort-amount-down-alt": "fal fa-sort-amount-down-alt",
	"fad-sort-amount-down-alt": "fad fa-sort-amount-down-alt",
	"fas-sort-amount-up": "fas fa-sort-amount-up",
	"far-sort-amount-up": "far fa-sort-amount-up",
	"fal-sort-amount-up": "fal fa-sort-amount-up",
	"fad-sort-amount-up": "fad fa-sort-amount-up",
	"fas-sort-amount-up-alt": "fas fa-sort-amount-up-alt",
	"far-sort-amount-up-alt": "far fa-sort-amount-up-alt",
	"fal-sort-amount-up-alt": "fal fa-sort-amount-up-alt",
	"fad-sort-amount-up-alt": "fad fa-sort-amount-up-alt",
	"fas-sort-down": "fas fa-sort-down",
	"far-sort-down": "far fa-sort-down",
	"fal-sort-down": "fal fa-sort-down",
	"fad-sort-down": "fad fa-sort-down",
	"fas-sort-numeric-down": "fas fa-sort-numeric-down",
	"far-sort-numeric-down": "far fa-sort-numeric-down",
	"fal-sort-numeric-down": "fal fa-sort-numeric-down",
	"fad-sort-numeric-down": "fad fa-sort-numeric-down",
	"fas-sort-numeric-down-alt": "fas fa-sort-numeric-down-alt",
	"far-sort-numeric-down-alt": "far fa-sort-numeric-down-alt",
	"fal-sort-numeric-down-alt": "fal fa-sort-numeric-down-alt",
	"fad-sort-numeric-down-alt": "fad fa-sort-numeric-down-alt",
	"fas-sort-numeric-up": "fas fa-sort-numeric-up",
	"far-sort-numeric-up": "far fa-sort-numeric-up",
	"fal-sort-numeric-up": "fal fa-sort-numeric-up",
	"fad-sort-numeric-up": "fad fa-sort-numeric-up",
	"fas-sort-numeric-up-alt": "fas fa-sort-numeric-up-alt",
	"far-sort-numeric-up-alt": "far fa-sort-numeric-up-alt",
	"fal-sort-numeric-up-alt": "fal fa-sort-numeric-up-alt",
	"fad-sort-numeric-up-alt": "fad fa-sort-numeric-up-alt",
	"fas-sort-up": "fas fa-sort-up",
	"far-sort-up": "far fa-sort-up",
	"fal-sort-up": "fal fa-sort-up",
	"fad-sort-up": "fad fa-sort-up",
	"fas-spa": "fas fa-spa",
	"far-spa": "far fa-spa",
	"fal-spa": "fal fa-spa",
	"fad-spa": "fad fa-spa",
	"fas-space-shuttle": "fas fa-space-shuttle",
	"far-space-shuttle": "far fa-space-shuttle",
	"fal-space-shuttle": "fal fa-space-shuttle",
	"fad-space-shuttle": "fad fa-space-shuttle",
	"fas-spell-check": "fas fa-spell-check",
	"far-spell-check": "far fa-spell-check",
	"fal-spell-check": "fal fa-spell-check",
	"fad-spell-check": "fad fa-spell-check",
	"fas-spider": "fas fa-spider",
	"far-spider": "far fa-spider",
	"fal-spider": "fal fa-spider",
	"fad-spider": "fad fa-spider",
	"fas-spinner": "fas fa-spinner",
	"far-spinner": "far fa-spinner",
	"fal-spinner": "fal fa-spinner",
	"fad-spinner": "fad fa-spinner",
	"fas-splotch": "fas fa-splotch",
	"far-splotch": "far fa-splotch",
	"fal-splotch": "fal fa-splotch",
	"fad-splotch": "fad fa-splotch",
	"fas-spray-can": "fas fa-spray-can",
	"far-spray-can": "far fa-spray-can",
	"fal-spray-can": "fal fa-spray-can",
	"fad-spray-can": "fad fa-spray-can",
	"fas-square": "fas fa-square",
	"far-square": "far fa-square",
	"fal-square": "fal fa-square",
	"fad-square": "fad fa-square",
	"fas-square-full": "fas fa-square-full",
	"far-square-full": "far fa-square-full",
	"fal-square-full": "fal fa-square-full",
	"fad-square-full": "fad fa-square-full",
	"fas-square-root-alt": "fas fa-square-root-alt",
	"far-square-root-alt": "far fa-square-root-alt",
	"fal-square-root-alt": "fal fa-square-root-alt",
	"fad-square-root-alt": "fad fa-square-root-alt",
	"fas-stamp": "fas fa-stamp",
	"far-stamp": "far fa-stamp",
	"fal-stamp": "fal fa-stamp",
	"fad-stamp": "fad fa-stamp",
	"fas-star": "fas fa-star",
	"far-star": "far fa-star",
	"fal-star": "fal fa-star",
	"fad-star": "fad fa-star",
	"fas-star-and-crescent": "fas fa-star-and-crescent",
	"far-star-and-crescent": "far fa-star-and-crescent",
	"fal-star-and-crescent": "fal fa-star-and-crescent",
	"fad-star-and-crescent": "fad fa-star-and-crescent",
	"fas-star-half": "fas fa-star-half",
	"far-star-half": "far fa-star-half",
	"fal-star-half": "fal fa-star-half",
	"fad-star-half": "fad fa-star-half",
	"fas-star-half-alt": "fas fa-star-half-alt",
	"far-star-half-alt": "far fa-star-half-alt",
	"fal-star-half-alt": "fal fa-star-half-alt",
	"fad-star-half-alt": "fad fa-star-half-alt",
	"fas-star-of-david": "fas fa-star-of-david",
	"far-star-of-david": "far fa-star-of-david",
	"fal-star-of-david": "fal fa-star-of-david",
	"fad-star-of-david": "fad fa-star-of-david",
	"fas-star-of-life": "fas fa-star-of-life",
	"far-star-of-life": "far fa-star-of-life",
	"fal-star-of-life": "fal fa-star-of-life",
	"fad-star-of-life": "fad fa-star-of-life",
	"fas-step-backward": "fas fa-step-backward",
	"far-step-backward": "far fa-step-backward",
	"fal-step-backward": "fal fa-step-backward",
	"fad-step-backward": "fad fa-step-backward",
	"fas-step-forward": "fas fa-step-forward",
	"far-step-forward": "far fa-step-forward",
	"fal-step-forward": "fal fa-step-forward",
	"fad-step-forward": "fad fa-step-forward",
	"fas-stethoscope": "fas fa-stethoscope",
	"far-stethoscope": "far fa-stethoscope",
	"fal-stethoscope": "fal fa-stethoscope",
	"fad-stethoscope": "fad fa-stethoscope",
	"fas-sticky-note": "fas fa-sticky-note",
	"far-sticky-note": "far fa-sticky-note",
	"fal-sticky-note": "fal fa-sticky-note",
	"fad-sticky-note": "fad fa-sticky-note",
	"fas-stop": "fas fa-stop",
	"far-stop": "far fa-stop",
	"fal-stop": "fal fa-stop",
	"fad-stop": "fad fa-stop",
	"fas-stop-circle": "fas fa-stop-circle",
	"far-stop-circle": "far fa-stop-circle",
	"fal-stop-circle": "fal fa-stop-circle",
	"fad-stop-circle": "fad fa-stop-circle",
	"fas-stopwatch": "fas fa-stopwatch",
	"far-stopwatch": "far fa-stopwatch",
	"fal-stopwatch": "fal fa-stopwatch",
	"fad-stopwatch": "fad fa-stopwatch",
	"fas-stopwatch-20": "fas fa-stopwatch-20",
	"far-stopwatch-20": "far fa-stopwatch-20",
	"fal-stopwatch-20": "fal fa-stopwatch-20",
	"fad-stopwatch-20": "fad fa-stopwatch-20",
	"fas-store": "fas fa-store",
	"far-store": "far fa-store",
	"fal-store": "fal fa-store",
	"fad-store": "fad fa-store",
	"fas-store-alt": "fas fa-store-alt",
	"far-store-alt": "far fa-store-alt",
	"fal-store-alt": "fal fa-store-alt",
	"fad-store-alt": "fad fa-store-alt",
	"fas-store-alt-slash": "fas fa-store-alt-slash",
	"far-store-alt-slash": "far fa-store-alt-slash",
	"fal-store-alt-slash": "fal fa-store-alt-slash",
	"fad-store-alt-slash": "fad fa-store-alt-slash",
	"fas-store-slash": "fas fa-store-slash",
	"far-store-slash": "far fa-store-slash",
	"fal-store-slash": "fal fa-store-slash",
	"fad-store-slash": "fad fa-store-slash",
	"fas-stream": "fas fa-stream",
	"far-stream": "far fa-stream",
	"fal-stream": "fal fa-stream",
	"fad-stream": "fad fa-stream",
	"fas-street-view": "fas fa-street-view",
	"far-street-view": "far fa-street-view",
	"fal-street-view": "fal fa-street-view",
	"fad-street-view": "fad fa-street-view",
	"fas-strikethrough": "fas fa-strikethrough",
	"far-strikethrough": "far fa-strikethrough",
	"fal-strikethrough": "fal fa-strikethrough",
	"fad-strikethrough": "fad fa-strikethrough",
	"fas-stroopwafel": "fas fa-stroopwafel",
	"far-stroopwafel": "far fa-stroopwafel",
	"fal-stroopwafel": "fal fa-stroopwafel",
	"fad-stroopwafel": "fad fa-stroopwafel",
	"fas-subscript": "fas fa-subscript",
	"far-subscript": "far fa-subscript",
	"fal-subscript": "fal fa-subscript",
	"fad-subscript": "fad fa-subscript",
	"fas-subway": "fas fa-subway",
	"far-subway": "far fa-subway",
	"fal-subway": "fal fa-subway",
	"fad-subway": "fad fa-subway",
	"fas-suitcase": "fas fa-suitcase",
	"far-suitcase": "far fa-suitcase",
	"fal-suitcase": "fal fa-suitcase",
	"fad-suitcase": "fad fa-suitcase",
	"fas-suitcase-rolling": "fas fa-suitcase-rolling",
	"far-suitcase-rolling": "far fa-suitcase-rolling",
	"fal-suitcase-rolling": "fal fa-suitcase-rolling",
	"fad-suitcase-rolling": "fad fa-suitcase-rolling",
	"fas-sun": "fas fa-sun",
	"far-sun": "far fa-sun",
	"fal-sun": "fal fa-sun",
	"fad-sun": "fad fa-sun",
	"fas-superscript": "fas fa-superscript",
	"far-superscript": "far fa-superscript",
	"fal-superscript": "fal fa-superscript",
	"fad-superscript": "fad fa-superscript",
	"fas-surprise": "fas fa-surprise",
	"far-surprise": "far fa-surprise",
	"fal-surprise": "fal fa-surprise",
	"fad-surprise": "fad fa-surprise",
	"fas-swatchbook": "fas fa-swatchbook",
	"far-swatchbook": "far fa-swatchbook",
	"fal-swatchbook": "fal fa-swatchbook",
	"fad-swatchbook": "fad fa-swatchbook",
	"fas-swimmer": "fas fa-swimmer",
	"far-swimmer": "far fa-swimmer",
	"fal-swimmer": "fal fa-swimmer",
	"fad-swimmer": "fad fa-swimmer",
	"fas-swimming-pool": "fas fa-swimming-pool",
	"far-swimming-pool": "far fa-swimming-pool",
	"fal-swimming-pool": "fal fa-swimming-pool",
	"fad-swimming-pool": "fad fa-swimming-pool",
	"fas-synagogue": "fas fa-synagogue",
	"far-synagogue": "far fa-synagogue",
	"fal-synagogue": "fal fa-synagogue",
	"fad-synagogue": "fad fa-synagogue",
	"fas-sync": "fas fa-sync",
	"far-sync": "far fa-sync",
	"fal-sync": "fal fa-sync",
	"fad-sync": "fad fa-sync",
	"fas-sync-alt": "fas fa-sync-alt",
	"far-sync-alt": "far fa-sync-alt",
	"fal-sync-alt": "fal fa-sync-alt",
	"fad-sync-alt": "fad fa-sync-alt",
	"fas-syringe": "fas fa-syringe",
	"far-syringe": "far fa-syringe",
	"fal-syringe": "fal fa-syringe",
	"fad-syringe": "fad fa-syringe",
	"fas-table": "fas fa-table",
	"far-table": "far fa-table",
	"fal-table": "fal fa-table",
	"fad-table": "fad fa-table",
	"fas-table-tennis": "fas fa-table-tennis",
	"far-table-tennis": "far fa-table-tennis",
	"fal-table-tennis": "fal fa-table-tennis",
	"fad-table-tennis": "fad fa-table-tennis",
	"fas-tablet": "fas fa-tablet",
	"far-tablet": "far fa-tablet",
	"fal-tablet": "fal fa-tablet",
	"fad-tablet": "fad fa-tablet",
	"fas-tablet-alt": "fas fa-tablet-alt",
	"far-tablet-alt": "far fa-tablet-alt",
	"fal-tablet-alt": "fal fa-tablet-alt",
	"fad-tablet-alt": "fad fa-tablet-alt",
	"fas-tablets": "fas fa-tablets",
	"far-tablets": "far fa-tablets",
	"fal-tablets": "fal fa-tablets",
	"fad-tablets": "fad fa-tablets",
	"fas-tachometer-alt": "fas fa-tachometer-alt",
	"far-tachometer-alt": "far fa-tachometer-alt",
	"fal-tachometer-alt": "fal fa-tachometer-alt",
	"fad-tachometer-alt": "fad fa-tachometer-alt",
	"fas-tag": "fas fa-tag",
	"far-tag": "far fa-tag",
	"fal-tag": "fal fa-tag",
	"fad-tag": "fad fa-tag",
	"fas-tags": "fas fa-tags",
	"far-tags": "far fa-tags",
	"fal-tags": "fal fa-tags",
	"fad-tags": "fad fa-tags",
	"fas-tape": "fas fa-tape",
	"far-tape": "far fa-tape",
	"fal-tape": "fal fa-tape",
	"fad-tape": "fad fa-tape",
	"fas-tasks": "fas fa-tasks",
	"far-tasks": "far fa-tasks",
	"fal-tasks": "fal fa-tasks",
	"fad-tasks": "fad fa-tasks",
	"fas-taxi": "fas fa-taxi",
	"far-taxi": "far fa-taxi",
	"fal-taxi": "fal fa-taxi",
	"fad-taxi": "fad fa-taxi",
	"fas-teeth": "fas fa-teeth",
	"far-teeth": "far fa-teeth",
	"fal-teeth": "fal fa-teeth",
	"fad-teeth": "fad fa-teeth",
	"fas-teeth-open": "fas fa-teeth-open",
	"far-teeth-open": "far fa-teeth-open",
	"fal-teeth-open": "fal fa-teeth-open",
	"fad-teeth-open": "fad fa-teeth-open",
	"fas-temperature-high": "fas fa-temperature-high",
	"far-temperature-high": "far fa-temperature-high",
	"fal-temperature-high": "fal fa-temperature-high",
	"fad-temperature-high": "fad fa-temperature-high",
	"fas-temperature-low": "fas fa-temperature-low",
	"far-temperature-low": "far fa-temperature-low",
	"fal-temperature-low": "fal fa-temperature-low",
	"fad-temperature-low": "fad fa-temperature-low",
	"fas-tenge": "fas fa-tenge",
	"far-tenge": "far fa-tenge",
	"fal-tenge": "fal fa-tenge",
	"fad-tenge": "fad fa-tenge",
	"fas-terminal": "fas fa-terminal",
	"far-terminal": "far fa-terminal",
	"fal-terminal": "fal fa-terminal",
	"fad-terminal": "fad fa-terminal",
	"fas-text-height": "fas fa-text-height",
	"far-text-height": "far fa-text-height",
	"fal-text-height": "fal fa-text-height",
	"fad-text-height": "fad fa-text-height",
	"fas-text-width": "fas fa-text-width",
	"far-text-width": "far fa-text-width",
	"fal-text-width": "fal fa-text-width",
	"fad-text-width": "fad fa-text-width",
	"fas-th": "fas fa-th",
	"far-th": "far fa-th",
	"fal-th": "fal fa-th",
	"fad-th": "fad fa-th",
	"fas-th-large": "fas fa-th-large",
	"far-th-large": "far fa-th-large",
	"fal-th-large": "fal fa-th-large",
	"fad-th-large": "fad fa-th-large",
	"fas-th-list": "fas fa-th-list",
	"far-th-list": "far fa-th-list",
	"fal-th-list": "fal fa-th-list",
	"fad-th-list": "fad fa-th-list",
	"fas-theater-masks": "fas fa-theater-masks",
	"far-theater-masks": "far fa-theater-masks",
	"fal-theater-masks": "fal fa-theater-masks",
	"fad-theater-masks": "fad fa-theater-masks",
	"fas-thermometer": "fas fa-thermometer",
	"far-thermometer": "far fa-thermometer",
	"fal-thermometer": "fal fa-thermometer",
	"fad-thermometer": "fad fa-thermometer",
	"fas-thermometer-empty": "fas fa-thermometer-empty",
	"far-thermometer-empty": "far fa-thermometer-empty",
	"fal-thermometer-empty": "fal fa-thermometer-empty",
	"fad-thermometer-empty": "fad fa-thermometer-empty",
	"fas-thermometer-full": "fas fa-thermometer-full",
	"far-thermometer-full": "far fa-thermometer-full",
	"fal-thermometer-full": "fal fa-thermometer-full",
	"fad-thermometer-full": "fad fa-thermometer-full",
	"fas-thermometer-half": "fas fa-thermometer-half",
	"far-thermometer-half": "far fa-thermometer-half",
	"fal-thermometer-half": "fal fa-thermometer-half",
	"fad-thermometer-half": "fad fa-thermometer-half",
	"fas-thermometer-quarter": "fas fa-thermometer-quarter",
	"far-thermometer-quarter": "far fa-thermometer-quarter",
	"fal-thermometer-quarter": "fal fa-thermometer-quarter",
	"fad-thermometer-quarter": "fad fa-thermometer-quarter",
	"fas-thermometer-three-quarters": "fas fa-thermometer-three-quarters",
	"far-thermometer-three-quarters": "far fa-thermometer-three-quarters",
	"fal-thermometer-three-quarters": "fal fa-thermometer-three-quarters",
	"fad-thermometer-three-quarters": "fad fa-thermometer-three-quarters",
	"fas-thumbs-down": "fas fa-thumbs-down",
	"far-thumbs-down": "far fa-thumbs-down",
	"fal-thumbs-down": "fal fa-thumbs-down",
	"fad-thumbs-down": "fad fa-thumbs-down",
	"fas-thumbs-up": "fas fa-thumbs-up",
	"far-thumbs-up": "far fa-thumbs-up",
	"fal-thumbs-up": "fal fa-thumbs-up",
	"fad-thumbs-up": "fad fa-thumbs-up",
	"fas-thumbtack": "fas fa-thumbtack",
	"far-thumbtack": "far fa-thumbtack",
	"fal-thumbtack": "fal fa-thumbtack",
	"fad-thumbtack": "fad fa-thumbtack",
	"fas-ticket-alt": "fas fa-ticket-alt",
	"far-ticket-alt": "far fa-ticket-alt",
	"fal-ticket-alt": "fal fa-ticket-alt",
	"fad-ticket-alt": "fad fa-ticket-alt",
	"fas-times": "fas fa-times",
	"far-times": "far fa-times",
	"fal-times": "fal fa-times",
	"fad-times": "fad fa-times",
	"fas-times-circle": "fas fa-times-circle",
	"far-times-circle": "far fa-times-circle",
	"fal-times-circle": "fal fa-times-circle",
	"fad-times-circle": "fad fa-times-circle",
	"fas-tint": "fas fa-tint",
	"far-tint": "far fa-tint",
	"fal-tint": "fal fa-tint",
	"fad-tint": "fad fa-tint",
	"fas-tint-slash": "fas fa-tint-slash",
	"far-tint-slash": "far fa-tint-slash",
	"fal-tint-slash": "fal fa-tint-slash",
	"fad-tint-slash": "fad fa-tint-slash",
	"fas-tired": "fas fa-tired",
	"far-tired": "far fa-tired",
	"fal-tired": "fal fa-tired",
	"fad-tired": "fad fa-tired",
	"fas-toggle-off": "fas fa-toggle-off",
	"far-toggle-off": "far fa-toggle-off",
	"fal-toggle-off": "fal fa-toggle-off",
	"fad-toggle-off": "fad fa-toggle-off",
	"fas-toggle-on": "fas fa-toggle-on",
	"far-toggle-on": "far fa-toggle-on",
	"fal-toggle-on": "fal fa-toggle-on",
	"fad-toggle-on": "fad fa-toggle-on",
	"fas-toilet": "fas fa-toilet",
	"far-toilet": "far fa-toilet",
	"fal-toilet": "fal fa-toilet",
	"fad-toilet": "fad fa-toilet",
	"fas-toilet-paper": "fas fa-toilet-paper",
	"far-toilet-paper": "far fa-toilet-paper",
	"fal-toilet-paper": "fal fa-toilet-paper",
	"fad-toilet-paper": "fad fa-toilet-paper",
	"fas-toilet-paper-slash": "fas fa-toilet-paper-slash",
	"far-toilet-paper-slash": "far fa-toilet-paper-slash",
	"fal-toilet-paper-slash": "fal fa-toilet-paper-slash",
	"fad-toilet-paper-slash": "fad fa-toilet-paper-slash",
	"fas-toolbox": "fas fa-toolbox",
	"far-toolbox": "far fa-toolbox",
	"fal-toolbox": "fal fa-toolbox",
	"fad-toolbox": "fad fa-toolbox",
	"fas-tools": "fas fa-tools",
	"far-tools": "far fa-tools",
	"fal-tools": "fal fa-tools",
	"fad-tools": "fad fa-tools",
	"fas-tooth": "fas fa-tooth",
	"far-tooth": "far fa-tooth",
	"fal-tooth": "fal fa-tooth",
	"fad-tooth": "fad fa-tooth",
	"fas-torah": "fas fa-torah",
	"far-torah": "far fa-torah",
	"fal-torah": "fal fa-torah",
	"fad-torah": "fad fa-torah",
	"fas-torii-gate": "fas fa-torii-gate",
	"far-torii-gate": "far fa-torii-gate",
	"fal-torii-gate": "fal fa-torii-gate",
	"fad-torii-gate": "fad fa-torii-gate",
	"fas-tractor": "fas fa-tractor",
	"far-tractor": "far fa-tractor",
	"fal-tractor": "fal fa-tractor",
	"fad-tractor": "fad fa-tractor",
	"fas-trademark": "fas fa-trademark",
	"far-trademark": "far fa-trademark",
	"fal-trademark": "fal fa-trademark",
	"fad-trademark": "fad fa-trademark",
	"fas-traffic-light": "fas fa-traffic-light",
	"far-traffic-light": "far fa-traffic-light",
	"fal-traffic-light": "fal fa-traffic-light",
	"fad-traffic-light": "fad fa-traffic-light",
	"fas-trailer": "fas fa-trailer",
	"far-trailer": "far fa-trailer",
	"fal-trailer": "fal fa-trailer",
	"fad-trailer": "fad fa-trailer",
	"fas-train": "fas fa-train",
	"far-train": "far fa-train",
	"fal-train": "fal fa-train",
	"fad-train": "fad fa-train",
	"fas-tram": "fas fa-tram",
	"far-tram": "far fa-tram",
	"fal-tram": "fal fa-tram",
	"fad-tram": "fad fa-tram",
	"fas-transgender": "fas fa-transgender",
	"far-transgender": "far fa-transgender",
	"fal-transgender": "fal fa-transgender",
	"fad-transgender": "fad fa-transgender",
	"fas-transgender-alt": "fas fa-transgender-alt",
	"far-transgender-alt": "far fa-transgender-alt",
	"fal-transgender-alt": "fal fa-transgender-alt",
	"fad-transgender-alt": "fad fa-transgender-alt",
	"fas-trash": "fas fa-trash",
	"far-trash": "far fa-trash",
	"fal-trash": "fal fa-trash",
	"fad-trash": "fad fa-trash",
	"fas-trash-alt": "fas fa-trash-alt",
	"far-trash-alt": "far fa-trash-alt",
	"fal-trash-alt": "fal fa-trash-alt",
	"fad-trash-alt": "fad fa-trash-alt",
	"fas-trash-restore": "fas fa-trash-restore",
	"far-trash-restore": "far fa-trash-restore",
	"fal-trash-restore": "fal fa-trash-restore",
	"fad-trash-restore": "fad fa-trash-restore",
	"fas-trash-restore-alt": "fas fa-trash-restore-alt",
	"far-trash-restore-alt": "far fa-trash-restore-alt",
	"fal-trash-restore-alt": "fal fa-trash-restore-alt",
	"fad-trash-restore-alt": "fad fa-trash-restore-alt",
	"fas-tree": "fas fa-tree",
	"far-tree": "far fa-tree",
	"fal-tree": "fal fa-tree",
	"fad-tree": "fad fa-tree",
	"fas-trophy": "fas fa-trophy",
	"far-trophy": "far fa-trophy",
	"fal-trophy": "fal fa-trophy",
	"fad-trophy": "fad fa-trophy",
	"fas-truck": "fas fa-truck",
	"far-truck": "far fa-truck",
	"fal-truck": "fal fa-truck",
	"fad-truck": "fad fa-truck",
	"fas-truck-loading": "fas fa-truck-loading",
	"far-truck-loading": "far fa-truck-loading",
	"fal-truck-loading": "fal fa-truck-loading",
	"fad-truck-loading": "fad fa-truck-loading",
	"fas-truck-monster": "fas fa-truck-monster",
	"far-truck-monster": "far fa-truck-monster",
	"fal-truck-monster": "fal fa-truck-monster",
	"fad-truck-monster": "fad fa-truck-monster",
	"fas-truck-moving": "fas fa-truck-moving",
	"far-truck-moving": "far fa-truck-moving",
	"fal-truck-moving": "fal fa-truck-moving",
	"fad-truck-moving": "fad fa-truck-moving",
	"fas-truck-pickup": "fas fa-truck-pickup",
	"far-truck-pickup": "far fa-truck-pickup",
	"fal-truck-pickup": "fal fa-truck-pickup",
	"fad-truck-pickup": "fad fa-truck-pickup",
	"fas-tshirt": "fas fa-tshirt",
	"far-tshirt": "far fa-tshirt",
	"fal-tshirt": "fal fa-tshirt",
	"fad-tshirt": "fad fa-tshirt",
	"fas-tty": "fas fa-tty",
	"far-tty": "far fa-tty",
	"fal-tty": "fal fa-tty",
	"fad-tty": "fad fa-tty",
	"fas-tv": "fas fa-tv",
	"far-tv": "far fa-tv",
	"fal-tv": "fal fa-tv",
	"fad-tv": "fad fa-tv",
	"fas-umbrella": "fas fa-umbrella",
	"far-umbrella": "far fa-umbrella",
	"fal-umbrella": "fal fa-umbrella",
	"fad-umbrella": "fad fa-umbrella",
	"fas-umbrella-beach": "fas fa-umbrella-beach",
	"far-umbrella-beach": "far fa-umbrella-beach",
	"fal-umbrella-beach": "fal fa-umbrella-beach",
	"fad-umbrella-beach": "fad fa-umbrella-beach",
	"fas-underline": "fas fa-underline",
	"far-underline": "far fa-underline",
	"fal-underline": "fal fa-underline",
	"fad-underline": "fad fa-underline",
	"fas-undo": "fas fa-undo",
	"far-undo": "far fa-undo",
	"fal-undo": "fal fa-undo",
	"fad-undo": "fad fa-undo",
	"fas-undo-alt": "fas fa-undo-alt",
	"far-undo-alt": "far fa-undo-alt",
	"fal-undo-alt": "fal fa-undo-alt",
	"fad-undo-alt": "fad fa-undo-alt",
	"fas-universal-access": "fas fa-universal-access",
	"far-universal-access": "far fa-universal-access",
	"fal-universal-access": "fal fa-universal-access",
	"fad-universal-access": "fad fa-universal-access",
	"fas-university": "fas fa-university",
	"far-university": "far fa-university",
	"fal-university": "fal fa-university",
	"fad-university": "fad fa-university",
	"fas-unlink": "fas fa-unlink",
	"far-unlink": "far fa-unlink",
	"fal-unlink": "fal fa-unlink",
	"fad-unlink": "fad fa-unlink",
	"fas-unlock": "fas fa-unlock",
	"far-unlock": "far fa-unlock",
	"fal-unlock": "fal fa-unlock",
	"fad-unlock": "fad fa-unlock",
	"fas-unlock-alt": "fas fa-unlock-alt",
	"far-unlock-alt": "far fa-unlock-alt",
	"fal-unlock-alt": "fal fa-unlock-alt",
	"fad-unlock-alt": "fad fa-unlock-alt",
	"fas-upload": "fas fa-upload",
	"far-upload": "far fa-upload",
	"fal-upload": "fal fa-upload",
	"fad-upload": "fad fa-upload",
	"fas-user": "fas fa-user",
	"far-user": "far fa-user",
	"fal-user": "fal fa-user",
	"fad-user": "fad fa-user",
	"fas-user-alt": "fas fa-user-alt",
	"far-user-alt": "far fa-user-alt",
	"fal-user-alt": "fal fa-user-alt",
	"fad-user-alt": "fad fa-user-alt",
	"fas-user-alt-slash": "fas fa-user-alt-slash",
	"far-user-alt-slash": "far fa-user-alt-slash",
	"fal-user-alt-slash": "fal fa-user-alt-slash",
	"fad-user-alt-slash": "fad fa-user-alt-slash",
	"fas-user-astronaut": "fas fa-user-astronaut",
	"far-user-astronaut": "far fa-user-astronaut",
	"fal-user-astronaut": "fal fa-user-astronaut",
	"fad-user-astronaut": "fad fa-user-astronaut",
	"fas-user-check": "fas fa-user-check",
	"far-user-check": "far fa-user-check",
	"fal-user-check": "fal fa-user-check",
	"fad-user-check": "fad fa-user-check",
	"fas-user-circle": "fas fa-user-circle",
	"far-user-circle": "far fa-user-circle",
	"fal-user-circle": "fal fa-user-circle",
	"fad-user-circle": "fad fa-user-circle",
	"fas-user-clock": "fas fa-user-clock",
	"far-user-clock": "far fa-user-clock",
	"fal-user-clock": "fal fa-user-clock",
	"fad-user-clock": "fad fa-user-clock",
	"fas-user-cog": "fas fa-user-cog",
	"far-user-cog": "far fa-user-cog",
	"fal-user-cog": "fal fa-user-cog",
	"fad-user-cog": "fad fa-user-cog",
	"fas-user-edit": "fas fa-user-edit",
	"far-user-edit": "far fa-user-edit",
	"fal-user-edit": "fal fa-user-edit",
	"fad-user-edit": "fad fa-user-edit",
	"fas-user-friends": "fas fa-user-friends",
	"far-user-friends": "far fa-user-friends",
	"fal-user-friends": "fal fa-user-friends",
	"fad-user-friends": "fad fa-user-friends",
	"fas-user-graduate": "fas fa-user-graduate",
	"far-user-graduate": "far fa-user-graduate",
	"fal-user-graduate": "fal fa-user-graduate",
	"fad-user-graduate": "fad fa-user-graduate",
	"fas-user-injured": "fas fa-user-injured",
	"far-user-injured": "far fa-user-injured",
	"fal-user-injured": "fal fa-user-injured",
	"fad-user-injured": "fad fa-user-injured",
	"fas-user-lock": "fas fa-user-lock",
	"far-user-lock": "far fa-user-lock",
	"fal-user-lock": "fal fa-user-lock",
	"fad-user-lock": "fad fa-user-lock",
	"fas-user-md": "fas fa-user-md",
	"far-user-md": "far fa-user-md",
	"fal-user-md": "fal fa-user-md",
	"fad-user-md": "fad fa-user-md",
	"fas-user-minus": "fas fa-user-minus",
	"far-user-minus": "far fa-user-minus",
	"fal-user-minus": "fal fa-user-minus",
	"fad-user-minus": "fad fa-user-minus",
	"fas-user-ninja": "fas fa-user-ninja",
	"far-user-ninja": "far fa-user-ninja",
	"fal-user-ninja": "fal fa-user-ninja",
	"fad-user-ninja": "fad fa-user-ninja",
	"fas-user-nurse": "fas fa-user-nurse",
	"far-user-nurse": "far fa-user-nurse",
	"fal-user-nurse": "fal fa-user-nurse",
	"fad-user-nurse": "fad fa-user-nurse",
	"fas-user-plus": "fas fa-user-plus",
	"far-user-plus": "far fa-user-plus",
	"fal-user-plus": "fal fa-user-plus",
	"fad-user-plus": "fad fa-user-plus",
	"fas-user-secret": "fas fa-user-secret",
	"far-user-secret": "far fa-user-secret",
	"fal-user-secret": "fal fa-user-secret",
	"fad-user-secret": "fad fa-user-secret",
	"fas-user-shield": "fas fa-user-shield",
	"far-user-shield": "far fa-user-shield",
	"fal-user-shield": "fal fa-user-shield",
	"fad-user-shield": "fad fa-user-shield",
	"fas-user-slash": "fas fa-user-slash",
	"far-user-slash": "far fa-user-slash",
	"fal-user-slash": "fal fa-user-slash",
	"fad-user-slash": "fad fa-user-slash",
	"fas-user-tag": "fas fa-user-tag",
	"far-user-tag": "far fa-user-tag",
	"fal-user-tag": "fal fa-user-tag",
	"fad-user-tag": "fad fa-user-tag",
	"fas-user-tie": "fas fa-user-tie",
	"far-user-tie": "far fa-user-tie",
	"fal-user-tie": "fal fa-user-tie",
	"fad-user-tie": "fad fa-user-tie",
	"fas-user-times": "fas fa-user-times",
	"far-user-times": "far fa-user-times",
	"fal-user-times": "fal fa-user-times",
	"fad-user-times": "fad fa-user-times",
	"fas-users": "fas fa-users",
	"far-users": "far fa-users",
	"fal-users": "fal fa-users",
	"fad-users": "fad fa-users",
	"fas-users-cog": "fas fa-users-cog",
	"far-users-cog": "far fa-users-cog",
	"fal-users-cog": "fal fa-users-cog",
	"fad-users-cog": "fad fa-users-cog",
	"fas-users-slash": "fas fa-users-slash",
	"far-users-slash": "far fa-users-slash",
	"fal-users-slash": "fal fa-users-slash",
	"fad-users-slash": "fad fa-users-slash",
	"fas-utensil-spoon": "fas fa-utensil-spoon",
	"far-utensil-spoon": "far fa-utensil-spoon",
	"fal-utensil-spoon": "fal fa-utensil-spoon",
	"fad-utensil-spoon": "fad fa-utensil-spoon",
	"fas-utensils": "fas fa-utensils",
	"far-utensils": "far fa-utensils",
	"fal-utensils": "fal fa-utensils",
	"fad-utensils": "fad fa-utensils",
	"fas-vector-square": "fas fa-vector-square",
	"far-vector-square": "far fa-vector-square",
	"fal-vector-square": "fal fa-vector-square",
	"fad-vector-square": "fad fa-vector-square",
	"fas-venus": "fas fa-venus",
	"far-venus": "far fa-venus",
	"fal-venus": "fal fa-venus",
	"fad-venus": "fad fa-venus",
	"fas-venus-double": "fas fa-venus-double",
	"far-venus-double": "far fa-venus-double",
	"fal-venus-double": "fal fa-venus-double",
	"fad-venus-double": "fad fa-venus-double",
	"fas-venus-mars": "fas fa-venus-mars",
	"far-venus-mars": "far fa-venus-mars",
	"fal-venus-mars": "fal fa-venus-mars",
	"fad-venus-mars": "fad fa-venus-mars",
	"fas-vest": "fas fa-vest",
	"far-vest": "far fa-vest",
	"fal-vest": "fal fa-vest",
	"fad-vest": "fad fa-vest",
	"fas-vest-patches": "fas fa-vest-patches",
	"far-vest-patches": "far fa-vest-patches",
	"fal-vest-patches": "fal fa-vest-patches",
	"fad-vest-patches": "fad fa-vest-patches",
	"fas-vial": "fas fa-vial",
	"far-vial": "far fa-vial",
	"fal-vial": "fal fa-vial",
	"fad-vial": "fad fa-vial",
	"fas-vials": "fas fa-vials",
	"far-vials": "far fa-vials",
	"fal-vials": "fal fa-vials",
	"fad-vials": "fad fa-vials",
	"fas-video": "fas fa-video",
	"far-video": "far fa-video",
	"fal-video": "fal fa-video",
	"fad-video": "fad fa-video",
	"fas-video-slash": "fas fa-video-slash",
	"far-video-slash": "far fa-video-slash",
	"fal-video-slash": "fal fa-video-slash",
	"fad-video-slash": "fad fa-video-slash",
	"fas-vihara": "fas fa-vihara",
	"far-vihara": "far fa-vihara",
	"fal-vihara": "fal fa-vihara",
	"fad-vihara": "fad fa-vihara",
	"fas-virus": "fas fa-virus",
	"far-virus": "far fa-virus",
	"fal-virus": "fal fa-virus",
	"fad-virus": "fad fa-virus",
	"fas-virus-slash": "fas fa-virus-slash",
	"far-virus-slash": "far fa-virus-slash",
	"fal-virus-slash": "fal fa-virus-slash",
	"fad-virus-slash": "fad fa-virus-slash",
	"fas-viruses": "fas fa-viruses",
	"far-viruses": "far fa-viruses",
	"fal-viruses": "fal fa-viruses",
	"fad-viruses": "fad fa-viruses",
	"fas-voicemail": "fas fa-voicemail",
	"far-voicemail": "far fa-voicemail",
	"fal-voicemail": "fal fa-voicemail",
	"fad-voicemail": "fad fa-voicemail",
	"fas-volleyball-ball": "fas fa-volleyball-ball",
	"far-volleyball-ball": "far fa-volleyball-ball",
	"fal-volleyball-ball": "fal fa-volleyball-ball",
	"fad-volleyball-ball": "fad fa-volleyball-ball",
	"fas-volume-down": "fas fa-volume-down",
	"far-volume-down": "far fa-volume-down",
	"fal-volume-down": "fal fa-volume-down",
	"fad-volume-down": "fad fa-volume-down",
	"fas-volume-mute": "fas fa-volume-mute",
	"far-volume-mute": "far fa-volume-mute",
	"fal-volume-mute": "fal fa-volume-mute",
	"fad-volume-mute": "fad fa-volume-mute",
	"fas-volume-off": "fas fa-volume-off",
	"far-volume-off": "far fa-volume-off",
	"fal-volume-off": "fal fa-volume-off",
	"fad-volume-off": "fad fa-volume-off",
	"fas-volume-up": "fas fa-volume-up",
	"far-volume-up": "far fa-volume-up",
	"fal-volume-up": "fal fa-volume-up",
	"fad-volume-up": "fad fa-volume-up",
	"fas-vote-yea": "fas fa-vote-yea",
	"far-vote-yea": "far fa-vote-yea",
	"fal-vote-yea": "fal fa-vote-yea",
	"fad-vote-yea": "fad fa-vote-yea",
	"fas-vr-cardboard": "fas fa-vr-cardboard",
	"far-vr-cardboard": "far fa-vr-cardboard",
	"fal-vr-cardboard": "fal fa-vr-cardboard",
	"fad-vr-cardboard": "fad fa-vr-cardboard",
	"fas-walking": "fas fa-walking",
	"far-walking": "far fa-walking",
	"fal-walking": "fal fa-walking",
	"fad-walking": "fad fa-walking",
	"fas-wallet": "fas fa-wallet",
	"far-wallet": "far fa-wallet",
	"fal-wallet": "fal fa-wallet",
	"fad-wallet": "fad fa-wallet",
	"fas-warehouse": "fas fa-warehouse",
	"far-warehouse": "far fa-warehouse",
	"fal-warehouse": "fal fa-warehouse",
	"fad-warehouse": "fad fa-warehouse",
	"fas-water": "fas fa-water",
	"far-water": "far fa-water",
	"fal-water": "fal fa-water",
	"fad-water": "fad fa-water",
	"fas-wave-square": "fas fa-wave-square",
	"far-wave-square": "far fa-wave-square",
	"fal-wave-square": "fal fa-wave-square",
	"fad-wave-square": "fad fa-wave-square",
	"fas-weight": "fas fa-weight",
	"far-weight": "far fa-weight",
	"fal-weight": "fal fa-weight",
	"fad-weight": "fad fa-weight",
	"fas-weight-hanging": "fas fa-weight-hanging",
	"far-weight-hanging": "far fa-weight-hanging",
	"fal-weight-hanging": "fal fa-weight-hanging",
	"fad-weight-hanging": "fad fa-weight-hanging",
	"fas-wheelchair": "fas fa-wheelchair",
	"far-wheelchair": "far fa-wheelchair",
	"fal-wheelchair": "fal fa-wheelchair",
	"fad-wheelchair": "fad fa-wheelchair",
	"fas-wifi": "fas fa-wifi",
	"far-wifi": "far fa-wifi",
	"fal-wifi": "fal fa-wifi",
	"fad-wifi": "fad fa-wifi",
	"fas-wind": "fas fa-wind",
	"far-wind": "far fa-wind",
	"fal-wind": "fal fa-wind",
	"fad-wind": "fad fa-wind",
	"fas-window-close": "fas fa-window-close",
	"far-window-close": "far fa-window-close",
	"fal-window-close": "fal fa-window-close",
	"fad-window-close": "fad fa-window-close",
	"fas-window-maximize": "fas fa-window-maximize",
	"far-window-maximize": "far fa-window-maximize",
	"fal-window-maximize": "fal fa-window-maximize",
	"fad-window-maximize": "fad fa-window-maximize",
	"fas-window-minimize": "fas fa-window-minimize",
	"far-window-minimize": "far fa-window-minimize",
	"fal-window-minimize": "fal fa-window-minimize",
	"fad-window-minimize": "fad fa-window-minimize",
	"fas-window-restore": "fas fa-window-restore",
	"far-window-restore": "far fa-window-restore",
	"fal-window-restore": "fal fa-window-restore",
	"fad-window-restore": "fad fa-window-restore",
	"fas-wine-bottle": "fas fa-wine-bottle",
	"far-wine-bottle": "far fa-wine-bottle",
	"fal-wine-bottle": "fal fa-wine-bottle",
	"fad-wine-bottle": "fad fa-wine-bottle",
	"fas-wine-glass": "fas fa-wine-glass",
	"far-wine-glass": "far fa-wine-glass",
	"fal-wine-glass": "fal fa-wine-glass",
	"fad-wine-glass": "fad fa-wine-glass",
	"fas-wine-glass-alt": "fas fa-wine-glass-alt",
	"far-wine-glass-alt": "far fa-wine-glass-alt",
	"fal-wine-glass-alt": "fal fa-wine-glass-alt",
	"fad-wine-glass-alt": "fad fa-wine-glass-alt",
	"fas-won-sign": "fas fa-won-sign",
	"far-won-sign": "far fa-won-sign",
	"fal-won-sign": "fal fa-won-sign",
	"fad-won-sign": "fad fa-won-sign",
	"fas-wrench": "fas fa-wrench",
	"far-wrench": "far fa-wrench",
	"fal-wrench": "fal fa-wrench",
	"fad-wrench": "fad fa-wrench",
	"fas-x-ray": "fas fa-x-ray",
	"far-x-ray": "far fa-x-ray",
	"fal-x-ray": "fal fa-x-ray",
	"fad-x-ray": "fad fa-x-ray",
	"fas-yen-sign": "fas fa-yen-sign",
	"far-yen-sign": "far fa-yen-sign",
	"fal-yen-sign": "fal fa-yen-sign",
	"fad-yen-sign": "fad fa-yen-sign",
	"fas-yin-yang": "fas fa-yin-yang",
	"far-yin-yang": "far fa-yin-yang",
	"fal-yin-yang": "fal fa-yin-yang",
	"fad-yin-yang": "fad fa-yin-yang"
};
