<template>
	<div class="p-d-flex p-flex-column" style="width: 100%;height: 100%">
		<div class="p-col content" :class="{'overhidden': transition}">
			<router-view v-slot="{ Component, route }">
				<transition :name="'slide-'+way" mode="out-in" @before-leave="transition=true" @after-enter="transition=false">
					<slot :selected="menu" :navigate="navigate" :route="route" :Component="Component" :includes="includes">
						<BigMenu v-if="menuIsOpen" @home="menu='home'"/>
						<Dashboard v-else-if="menu==='home'" @home="menu='home'"/>
						<component v-else :is="Component" :key="route.path"></component>
					</slot>
				</transition>
			</router-view>
		</div>
		
		<div class="p-d-flex p-justify-around p-align-center p-flex-nowrap headerm">
			<div @click="displayDashboard" class="menuitem" :class="{active:menu==='home'}">
				<i class="pi pi-home"></i>
			</div>
      <div @click="switchLocale" class="menuitem">
        {{ $t('menu_lang') }}
      </div>
			<div @click="displayMenu" class="menuitem" :class="{active:menu==='menu'}">
				<i class="pi pi-bars"></i>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import Dashboard from "@/components/Dashboard/Dashboard";
import BigMenu from "@/components/Menu/BigMenu";
import {useI18n} from "vue-i18n";

export default {
	name: "OrientationLayout",
	components: {BigMenu, Dashboard},
	setup() {
		const menus = ['home', 'menu'];
		const menu = ref('home');
		const way = ref('left');
    const menuIsOpen = ref(false);
		
		const router = useRouter();
		const route = useRoute();

    const currentRoute = computed(() => {
      console.log('currentRoute : '+route.name);
      return route.name;
    });

    if(currentRoute==='dashboard'){
      way.value = 'right';
    }

		
		const guard = router.beforeEach((to, from, next) => {
			switch (to.name) {
				default:
          const toDepth = to.path.split('/').length;
          const fromDepth = from.path.split('/').length;
          way.value = toDepth < fromDepth ? 'right' : 'left';
          console.log('default guard : '+way.value);

          menu.value = 'home';
          menuIsOpen.value = false;
          next();
			}
		});
		
		watch(menu, (val, old) => {
			way.value = menus.indexOf(val) < menus.indexOf(old) ? "right" : "left";
      console.log('watch menu');
      console.log(way);
		});

    const displayMenu = () => {
      console.log('menuIsOpen : '+menuIsOpen.value);
      if(menuIsOpen.value===false){
        menu.value = 'menu';
        //way.value = '';
      }else if(menuIsOpen.value===true){
        menu.value = '';
      }
      menuIsOpen.value = !menuIsOpen.value;
    }

    const displayDashboard = () => {
      if(menuIsOpen.value===true){
        displayMenu();
      }
      menu.value = 'home';
      router.push({name:'dashboard'});
    }
		
		
		return {
      transition: ref(false),
			menu,
      menuIsOpen,
      currentRoute,
			way,
			navigate($event) {
        console.log('navigate : '+$event);
				menu.value = $event;
        menuIsOpen.value = false;
			},
      displayMenu,
      displayDashboard,
			includes: computed(() => {
				const base = ["BigMenu", "Dashboard"];
				return [...base, route.name]
			})
		};
	},
  methods:{
    switchLocale(){
      console.log('switchLocale from '+this.$root.$i18n.locale);
      if(this.$root.$i18n.locale==='fr'){
        this.$root.$i18n.locale = 'en';
      }else if(this.$root.$i18n.locale==='en'){
        this.$root.$i18n.locale = 'fr';
      }
    }
  }
}
</script>

<style scoped>

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-leave-active,
.slide-right-enter-active {
	transition: .2s;
}


.slide-left-enter-from, .slide-right-leave-active {
	opacity: 0;
	-webkit-transform: translate(50vw, 0);
	transform: translate(50vw, 0);
}

.slide-left-leave-active, .slide-right-enter-from {
	opacity: 0;
	-webkit-transform: translate(-50vw, 0);
	transform: translate(-50vw, 0);
}

.content {
	overflow: auto;
}

.overhidden {
	overflow-x: hidden;
}

.headerm {
	width: 100%;
	height: 3rem;
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.2);
	z-index: 999;
}

.menuitem {
	width: 3em;
	text-align: center;
	position: relative;
	cursor: pointer;
}

.menuitem:hover:after {
	width: 100%;
	content: "";
	display: block;
	position: absolute;
	bottom: -8px;
	background-color: #ccc;
	border-radius: 8px;
	height: 4px;
}

.menuitem.active:after {
	width: 100%;
	content: "";
	display: block;
	position: absolute;
	bottom: -8px;
	background-color: #077DAF;
	border-radius: 8px;
	height: 4px;
}

i.pi:hover {

}
</style>
