<template>
  <TableLayout ref="table" service="product" single-route-name="products.edit" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :enabled_actions="enabled_actions">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('product',2)}}</h1>
		</template>
		<template #toolbar-right="{open}">
			<Button :label="$t('new')" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.add()"/>
      <Button :label="$tc('option',2)" icon="pi pi-cog" class="p-button-success p-mr-2" @click="$router.push({name:'products.options.list'})" v-if="$store.permissions.has('products.options.list')"/>
      <Button :label="$tc('category',2)" icon="pi pi-cog" class="p-button-success p-mr-2" @click="$router.push({name:'products.categories.list'})" v-if="$store.permissions.has('products.categories.list')"/>
		</template>
		
		<template #default="{open}">
      <Column field="image" sortable>
        <template #header>
          {{ $tc('image',1) }}
        </template>
        <template #body="{data, index}">
          <img :src="imageLink(data)" style="max-width: 100px;"/>
        </template>
      </Column>
      <Column field="name" sortable>
        <template #header>
          {{ $t('nom') }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
        </template>
      </Column>
			<Column field="skus">
        <template #header>
          {{ $t('sku') }}
        </template>
				<template #body="{data}">
					<select>
						<option v-for="o in data.skus">{{ o }}</option>
					</select>
				</template>
			</Column>
      <Column field="productCategories" sortable>
        <template #header>
          {{ $tc('categorie',2) }}
        </template>
        <template #filter="{filterModel,filterCallback}">
          <Dropdown type="text" v-model="filterModel.value" :options="productCategories" optionLabel="name" optionValue="_id" :showClear="true" @change="filterCallback()"></Dropdown>
        </template>
        <template #body="{data}">
          {{ categories(data) }}
        </template>
      </Column>
      <Column field="status">
        <template #header>
          {{ $tc('statut',1) }}
        </template>
        <template #body="{data}">
          {{ statusMap[data.status] }}
        </template>
      </Column>
		</template>
	</TableLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import FormRadium from "@/components/FormPrime/FormRadium";
import {ref,computed} from "vue";
import {CrudRepository} from "@radiumjs/client";
import {useStore} from "@/store";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
	name: 'Products',
	components: {
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
    _GET
	},
  data(){
    return {
      column_filters: {
        name: 'string',
        categories: 'string'
      },
      productCategories:[]
    };
  },
	setup(props, {emit}) {
    const i18n = useI18n();
		const radium = useStore().radium;
		const toast = useToast();
		const repo = new CrudRepository(radium, 'product');
    const enabled_actions = [];
    const actions = [];

    _GET.setProvider(radium);

    let filters = {
      'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'productCategories': {value: null, matchMode: FilterMatchMode.IN}
    };

    const statusOptions = ref([
      {
        name: i18n.t('available'),
        code: 2
      },
      {
        name: i18n.t('available_admin'),
        code: 1
      },
      {
        name: i18n.t('unavailable'),
        code: -1
      }
    ]);
		
		
		const table = ref();
		return {
      enabled_actions,
      filters,
			table,
			tabs: {
				info: "Infos",//custom
				notable: true,//preMade
				timeData: true,//preMade
			},
			actions: [
				{
					label: i18n.t('duplicate'),
					icon: "pi pi-clone",
					async command(data) {
						const result = await repo.clone(data._id);
						if (result) {
							toast.add({
								severity: 'success',
								life: 5000,
								summary: i18n.t('duplicated_product'),
								detail: ""
							});
							
							await table.value.datatable.refresh();
						}
					}
				}
			],
      statusOptions,
      statusMap: computed(() => {
        return statusOptions.value.reduce((a, v) => {
          a[v.code] = v.name;
          return a
        }, {});
      }),
		};
	},
  methods:{
    imageLink(data){
      const type = data.image_type ? data.image_type : '';
      if(type==='image'&&data.image?.file?.name){
        return (process.env.VUE_APP_UPLOAD_SERVER?process.env.VUE_APP_UPLOAD_SERVER:'')+'/storages/'+file.dir+'/'+file.name;
      }else if(type==='url'&&data.image){
        return data.image;
      }
      return '';
    },
    categories(data){
      let output = [];
      if(data.productCategories){
        this.productCategories.forEach((value,index) => {
            if(data.productCategories.includes(value._id)){
              output.push(value.name);
            }
        });
      }
      return output.join(', ');
    },
    async getCategories(){
      await _GET.all('productCategories').then(response => {
        this.productCategories = response;
      });
    },
  },
  mounted() {
    this.getCategories();
  }
}
</script>
