import {Module, ModuleView} from "@/helpers/modules/module";
import {computed, defineComponent, DefineComponent} from "vue";
import {SkuHelper} from "@/helpers/product";

interface ConditionItem {
    partIndex: number,
    optionIndex: number,
    optionName: string,
}

interface ConditionConfig {
    conditions: ConditionItem[],
    active?: boolean
}

export class Condition extends Module<ConditionConfig> {
    
    defaultConfig(): ConditionConfig {
        return {
            conditions: []
        };
    }
    
    get info() {
        return {
            label: "Condition"
        };
    }
    
    view(helper: SkuHelper, config: ConditionConfig): ModuleView<ConditionConfig> {
        return new ConditionView(helper, config);
    }
    
    optionator(helper, optionData): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    const conditions = computed<ConditionItem[]>({
                        get() {
                            return optionData.value.conditions
                        },
                        set(val) {
                            optionData.value = {
                                ...optionData.value,
                                conditions: val
                            };
                        }
                    });
                    
                    const SkuCondition = require('./SkuConditions.vue').default
                    return () => (<SkuCondition modelValue={conditions.value} onUpdate:modelValue={e => conditions.value = e}/>)
                }
            })
        };
    }
    
}

class ConditionView extends ModuleView<ConditionConfig> {
    testSKU(sku: { sku: string; options: number[] }): boolean {
        const prod = this.helper.product;
        
        return !sku.options.some((o, i) => {
            return prod.sku.parts[i].options[o].conditions?.some(c => {
                return sku.options[c.part] === c.option;
            })
        });
    }
}
