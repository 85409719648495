<template>
	<Button class="p-d-block p-text-center" style="width: 100%" @click="$router.push(path);$emit('closeDash')">
		<i :class="icon" class="p-d-block p-mx-auto" style="font-size: 5vw"></i>
		<span class="p-d-block p-mx-auto" style="font-size: 3vw">{{ label }}</span>
	</Button>
</template>

<script>

export const meta = {
	path: "menu",
	name: "Button",
	sizes: [{w: 2, h: 2}],
	settings: require("./MenuButtonSettings.vue").default
};

export default {
	name: "MenuButton",
	components: {
		Button: require('primevue/button').default
	},
	emits: ["closeDash"],
	props: {
		icon: String,
		label: String,
		path: String
	}
}
</script>

<style scoped>
.p-button {
	padding: 0;
}
</style>
