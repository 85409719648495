

import {useConfirm} from "primevue/useconfirm";
import {computed, ComputedRef, inject, ref} from "vue";
import {useI18n} from "vue-i18n";


export default {
	name: 'EditProductSousOption',
	components: {
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
    Checkbox: require('primevue/checkbox').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
  props:{
    modelValue: Object,
    parent: Object,
    index: Number
  },
  emits: ["update:modelValue", "destroy"],
	setup(props, {emit}) {
    const i18n = useI18n();
    const confirm = useConfirm();
		return {
      // sku
      sku: computed({
        get() {
          return props.modelValue.sku
        },
        set(sku) {
          emit('update:modelValue', {
            ...props.modelValue,
            sku
          });
        }
      }),//sku
      // description_fr
      description_fr: computed({
        get() {
          return props.modelValue.description_fr
        },
        set(description_fr) {
          emit('update:modelValue', {
            ...props.modelValue,
            description_fr
          });
        }
      }),//description_fr
      // description_en
      description_en: computed({
        get() {
          return props.modelValue.description_en
        },
        set(description_en) {
          emit('update:modelValue', {
            ...props.modelValue,
            description_en
          });
        }
      }),//description_en
      // msrp_lineaire
      msrp_lineaire: computed({
        get() {
          return props.modelValue.msrp_lineaire
        },
        set(msrp_lineaire) {
          emit('update:modelValue', {
            ...props.modelValue,
            msrp_lineaire
          });
        }
      }),//msrp_lineaire
      // msrp
      msrp: computed({
        get() {
          return props.modelValue.msrp
        },
        set(msrp) {
          emit('update:modelValue', {
            ...props.modelValue,
            msrp
          });
        }
      }),//msrp
      // prix_lineaire
      prix_lineaire: computed({
        get() {
          return props.modelValue.prix_lineaire
        },
        set(prix_lineaire) {
          emit('update:modelValue', {
            ...props.modelValue,
            prix_lineaire
          });
        }
      }),//prix_lineaire
      // prix
      prix: computed({
        get() {
          return props.modelValue.prix
        },
        set(prix) {
          emit('update:modelValue', {
            ...props.modelValue,
            prix
          });
        }
      }),//prix
      // extremite
      extremite: computed({
        get() {
          return props.modelValue.extremite
        },
        set(extremite) {
          emit('update:modelValue', {
            ...props.modelValue,
            extremite
          });
        }
      }),//extremite
      // cote
      cote: computed({
        get() {
          return props.modelValue.cote
        },
        set(cote) {
          emit('update:modelValue', {
            ...props.modelValue,
            cote
          });
        }
      }),//cote
      // coin
      coin: computed({
        get() {
          return props.modelValue.coin
        },
        set(coin) {
          emit('update:modelValue', {
            ...props.modelValue,
            coin
          });
        }
      }),//coin
      destroy($event) {
        confirm.require({
          target: $event.currentTarget,
          message: i18n.t('confirm_remove_item'),
          icon: 'pi pi-exclamation-triangle',
          acceptClass: 'p-button-danger',
          accept: () => {
            emit('destroy');
            //
          },
          reject: () => {
          }
        });
      }
    };
	}
}
