<template>
	<Calendar v-model="value"/>
</template>

<script>
import {computed} from "vue";
import * as moment from "moment";

export default {
	name: "CalendarField",
	components: {
		Calendar: require('primevue/calendar').default
	},
	props: {
		modelValue: String
	},
	setup(props, {emit}) {
		return {
			value: computed({
				get() {
					return props.modelValue && moment.utc(props.modelValue).local().toDate();
				},
				set(val) {
					const value = val && moment(val).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
					emit('update:modelValue', value);
					emit('change', value);
				}
			})
		};
	}
}
</script>

<style scoped>
::v-deep(.p-datepicker-inline) {
	flex-direction: column;
}
</style>
