<template>
	<SingleLayout service="event" :schema="schema" :return-on-save="false">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('event',1)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$t('information')}}</span>
				</template>
        <template #file-header>
          <i class="pi pi-info p-pl-1"></i>
          <span>{{$tc('file',2)}}</span>
        </template>
				
				<template #info>
					<div class="p-field">
						<FormInput class="p-mt-3" :errors="errors.status" v-slot="classes">
						<span class="p-float-label">
							<Dropdown v-model="item.status"
							          @change="update('$set','status',$event.value);"
							          :options="statusOptions"
							          optionLabel="name"
							          optionValue="code"
							/>
							<label>{{$tc('statut',1)}}</label>
						</span>
						</FormInput>
					</div>
					
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.title" v-slot="classes">
							<span class="p-float-label">
								<InputText type="text"
								           v-model="item.title"
								           @change="update('$set','title',$event.target.value)"
								           :class="classes"/>
								<label>{{$t('titre')}}</label>
							</span>
							</FormInput>
						</div>
					</div>
					
					<FormInput class="p-mt-3" :errors="errors.description" v-slot="classes">
						<h4 class="p-text-normal p-mt-0 p-mb-2">{{$t('description')}}</h4>
						<Editor v-model="item.description"
						        @text-change="update('$set','description',$event.htmlValue)"
						        editorStyle="height: 320px"/>
					</FormInput>
					
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.start_date" v-slot="classes">
								<div class="p-field-checkbox">
									<label class="p-pr-2 p-pb-1">{{$t('date')}}</label>
								</div>
								<div>
									<CalendarField :showTime="true"
									               :inline="true"
									               v-model="item.start_date"
									               @change="update('$set','start_date',$event)"
									               :class="classes"
									/>
								</div>
							</FormInput>
						</div>
						<div class="p-field p-col" hidden>
							<FormInput class="p-mt-3" :errors="errors.end_date" v-slot="classes">
								<div class="p-field-checkbox">
									<label class="p-pr-2">{{$t('date_end')}}</label>
									<Checkbox :modelValue="!!item.end_date"
									          @update:modelValue="$event ? update('$set','end_date',item.end_date = new Date().toISOString()) : update('$set','end_date',item.end_date = false)"
									          :binary="true" label="test"/>
								</div>
								<CalendarField :showTime="true"
								               :inline="true"
								               v-model="item.end_date"
								               @change="update('$set','end_date',$event)"
								               :class="classes"
								               :showButtonBar="true"
								               v-if="item.end_date"
								/>
							</FormInput>
						</div>
					</div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" v-slot="classes">
                <span class="p-float-label p-d-flex">
                  <InputText type="text"
                             v-model="item.rappel"
                             @change="update('$set','rappel',$event.target.value)"
                             class="p-d-flex w-auto p-mr-2" style="width:50px;" placeholder="Nombre"/>
                  <Dropdown v-model="item.rappel_unit"
                            @change="update('$set','rappel_unit',$event.value);"
                            :options="rappelOptions"
                            optionLabel="name"
                            optionValue="code"
                            class="p-d-flex"
                  />
                  <label>{{$t('reminder')}}</label>
                </span>
              </FormInput>
            </div>
          </div>
				
				</template>
        <template #file>
          <div class="p-field" v-if="item.files">
            <div class="p-row p-inputtext">
              <div class="p-col-12 p-row">
                <h2>{{ $tc('file',2) }}</h2>
              </div>
              <div v-for="(file, key) in item.files" class="p-col-12 p-row p-align-center p-border-b">
                <div class="p-field p-col-3">
                  <span class="p-float-label">
                    {{ file.realname }}
                    <label>{{ $tc('file',2) }} : </label>
                   </span>
                </div>
                <div class="p-field p-col-3">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.files[key]['titre_fr']" placeholder="Titre (fr)" @change="updateFiles(item, update)"/>
                        <InputText type="text" v-model="item.files[key]['titre_en']" placeholder="Titre (en)" @change="updateFiles(item, update)"/>
                        <label>{{ $t('nom') }} : </label>
                      </span>
                </div>
                <div class="p-field p-col-3">
                  <span class="p-button p-button-danger p-d-inline" @click="removeFile( key, item, update)"><i class="pi pi-trash"></i></span>
                </div>
              </div>
            </div>
          </div>

          <Upload upload_dir="events" @update:modelValue="addFiles(item,update,$event)"></Upload>
        </template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import Upload from "@/components/Upload/Upload.vue";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
	name: 'EditEvent',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Checkbox: require('primevue/checkbox').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
    Upload
	},
	setup(props, {emit}) {
    const i18n = useI18n();
		const statusOptions = ref([
			{
				name: i18n.t('awaiting'),
				code: -1
			},
			{
				name: i18n.t('priority'),
				code: 2
			},
			{
				name: i18n.t('started'),
				code: 1
			},
			{
				name:  i18n.t('done'),
				code: 0
			}
		]);
    const rappelOptions = ref([
      {
        name: i18n.t('minutes'),
        code: 'minutes'
      },
      {
        name: i18n.t('hours'),
        code: 'hours'
      },
      {
        name: i18n.t('days'),
        code: 'days'
      },
      {
        name:  i18n.t('weeks'),
        code: 'weeks'
      },
      {
        name:  i18n.t('months'),
        code: 'months'
      }
    ]);
		return {
			schema: {
				status: {type: "number"},
				title: {type: "string", min: 3, max: 255},
				description: {type: "string", optional: true},
				//client_id: {type: "string", optional: true, default: useRoute().params.client_id},
				//assigned_users: {type: "array", optional: true},
				due_date: {type: 'date', optional: true},
        start_date: {type: 'date', optional: true},
			},
			tabs: {
				info: "Infos",//custom
        file:true,
				notable: true,//preMade
			},
			statusOptions,
      rappelOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = v.name;
					return a
				}, {});
			}),
		};
	},
  methods:{
    addFiles(item, update, event){
      if(!item.files){
        item.files = [];
      }
      for(let i=0;i<event.length;i++){
        let v = event[i];
        item.files.push(v);
      }
      update("$set",'files',item.files);
    },
    updateFiles(item, update){
      update("$set",'files',item.files);
    },
    removeFile( key, item, update){
      const c = confirm(this.$t('confirm_remove_file'));
      if(!c){
        return false;
      }
      item.files.splice( key, 1 );
      update("$set",'files',item.files);
    }
  }
}
</script>
