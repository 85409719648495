
import {computed, defineComponent, h, ref} from 'vue';
import FormRadium from "../FormPrime/FormRadium.vue";
import {Product, SKU, SkuHelper, SkuPart} from "@/helpers/product";
import {useRadium} from "@radiumjs/vue";
import {CrudRepository} from "@radiumjs/client";

export default defineComponent({
	name: "SkuSelectorProductOption",
	components: {
		FormRadium,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Listbox: require('primevue/listbox').default,
		InputText: require('primevue/inputtext').default,
		Dropdown: require('primevue/dropdown').default,
    Checkbox: require('primevue/checkbox').default,
    RadioButton: require('primevue/radiobutton').default
	},
	props: {
    options: Object,
    parent: Object,
    type: String,
    index: String,
    singleOption: Object
	},
  data() {
    return {
      selected:{
        id: this.$props.parent._id,
        sku: null,
        contour: [],
        unique: null,
        coin:[],
        info: {}
      },
      option:{}
    }
  },
	setup(props, {emit}) {
    console.log('setup');
    console.log(props);
    return { }
	},
  methods:{
    updateSingleOption(){
      this.option = this.$props.singleOption;
      this.selected.contour = [];
      this.selected.coin = [];
      this.selected.info = this.option;
      this.$emit('update',this.$props.index,this.selected);
      this.updateOption();
    },
    updateOption(){
      console.log(this.selected);
      console.log('index : '+this.$props.index);
      this.$emit('update',this.$props.index,this.selected);
    },
    resetOption(){
      console.log(this.selected.sku);
      const selectedOption = this.$props.options.filter(item => item.sku === this.selected.sku);
      this.option = selectedOption[0];
      console.log('option');
      console.log(this.option);
      this.selected.contour = [];
      this.selected.unique = null;
      this.selected.coin = [];
      this.selected.info = this.option;
      this.updateOption();
    }
  },
  mounted() {},
  watch:{
    'selected.sku': function(val){
      if(this.$props.type==='contour'){
        //@ts-ignore
        if(this.option&&this.option.extremite==2&&this.option.cote==2){
          this.selected.contour = ['t','r','l','b'];
        //@ts-ignore
        }else if(this.option&&this.option.extremite==2){
          this.selected.contour = ['r','l'];
        //@ts-ignore
        }else if(this.option&&this.option.cote==2){
          this.selected.contour = ['t','b'];
        }
      }
    }
  }
});
