import {SKU, SkuHelper, SkuPartOption} from "@/helpers/product";
import {Module, ModuleView} from "@/helpers/modules/module";
import {defineComponent, DefineComponent, ref, watch, watchEffect, WritableComputedRef} from "vue";
import {default as InputField} from "primevue/inputnumber";

export class Prices extends Module<{}, PriceModuleView> {
    
    defaultConfig() {
        return {};
    }
    
    get info() {
        return {
            label: "Prix"
        };
    }
    
    optionator(helper, optionData: WritableComputedRef<SkuPartOption>): { component: DefineComponent } | undefined {
        return {
            component: defineComponent({
                setup() {
                    const used = ref(null);
                    const price = ref(null);
                    const priceLinear = ref(null);
                    
                    watchEffect(() => {
                        used.value = optionData.value?.vars?.price?.type === "static";
                        price.value = parseFloat(optionData.value?.vars?.price?.type === "static" ? optionData.value?.vars?.price?.value : '0');
                        priceLinear.value = parseFloat(optionData.value?.vars?.priceLinear?.type === "static" ? optionData.value?.vars?.priceLinear?.value : '0');
                    })
                    
                    watch([used, price, priceLinear], () => {
                        if (used.value) {
                            optionData.value = {
                                ...optionData.value,
                                vars: {
                                    ...optionData.value.vars,
                                    price: {type: "static", value: price.value},
                                    priceLinear: {type: "static", value: priceLinear.value},
                                }
                            };
                        } else {
                            const vars = {
                                ...optionData.value.vars
                            };
                            delete vars.price;
                            delete vars.priceLinear;
                            optionData.value = {
                                ...optionData.value,
                                vars
                            };
                        }
                    });
                    
                    const InputField = require("primevue/inputnumber").default
                    const Checkbox = require("primevue/checkbox").default
                    return () => {
                        let sections = [];
                        
                        if (used.value) {
                            sections.push(
                                <div className="p-field p-d-flex p-align-center p-mt-3">
                                    <label className="p-m-0 p-r-2">Price</label>
                                    <InputField modelValue={price.value} onUpdate:modelValue={e => price.value = e} mode="decimal" minFractionDigits={2}/>
                                </div>
                            );
                            if (helper.value.views.sizes.isActive) {
                                sections.push(
                                    <div className="p-field p-d-flex p-align-center">
                                        <label className="p-m-0 p-r-2">Price Linear</label>
                                        <InputField modelValue={priceLinear.value} onUpdate:modelValue={e => priceLinear.value = e} mode="decimal" minFractionDigits={2}/>
                                    </div>
                                );
                            }
                        }
                        
                        return (
                            <div>
                                <div className="p-field-checkbox p-m-0">
                                    <Checkbox modelValue={used.value} onUpdate:modelValue={e => used.value = e} binary={true}/>
                                    <label>{used.value == null ? 'N/A' : used.value ? "Active" : "Inactif"}</label>
                                </div>
                                <div className="p-m-2 p-p-1 p-shadow-3">
                                    {sections}
                                </div>
                            </div>
                        )
                    }
                }
            })
        };
    }
    
    view(helper: SkuHelper, config): PriceModuleView {
        return new PriceModuleView(helper, config);
    }
    
}

class PriceModuleView extends ModuleView<{}> {
    constructor(helper, config) {
        super(helper, config);
    }
    
    getRange(sku: SKU): [number, number] {
        const totals = this.getPriceStat(sku);
        return [totals.min, totals.max];
    }
    
    getPrice(sku: SKU): number {
        return this.getPriceStat(sku).real;
    }

    getProductOptionsPrice(sku: SKU, productOptions: []): {msrp,prix}{
        console.log('getProductOptionsPrice');
        console.log(productOptions);
        const ops = {
            msrp: [],
            prix: []
        }
        const sizes = this.helper.views.sizes.getSizes(sku);
        const length = this.helper.views.sizes.length.value;
        const width = this.helper.views.sizes.width.value;
        const length_inch = parseFloat(length);
        const length_feet = length_inch / 12;
        const width_inch = parseFloat(width);
        const width_feet = width_inch / 12;
        console.log(length);
        console.log(width);

        productOptions.forEach((item:any) => {
            const type = item.contour?.length ? 'contour' : (item.coin?.length?'coin': (item.unique!=null?'unique':'') );
            let feet = type=='contour' ? (length_feet*2)+(width_feet*2) : length_feet ;
            let msrp = 0;
            let prix = 0;
            let price = 0;
            if(item.info?.msrp_lineaire>0){
                price = parseFloat(item.info.msrp_lineaire);
                msrp = price*feet;
            }else if(item.info?.msrp>0){
                price = parseFloat(item.info.msrp);
                msrp = price;
            }
            if(item.info?.prix_lineaire>0){
                price = parseFloat(item.info.prix_lineaire);
                prix = price*feet;
            }else if(item.info?.prix>0){
                price = parseFloat(item.info.prix);
                prix = price;
            }
            ops.msrp.push(msrp);
            ops.prix.push(prix);
        });

        console.log(ops);

        const result = {
            msrp: 0,
            prix: 0
        }
        Object.keys(ops).forEach(op => {
            result[op] = ops[op].reduce((a, v) => a + v, 0);
        });
        return result;
    }
    
    getPriceStat(sku: SKU) {
        const ops = {
            min: [],
            max: [],
            real: []
        }
        
        sku.options.forEach((oi, pi) => {
            const option = this.helper.product.sku.parts[pi].options[oi];
            const price = option?.vars?.price
            const priceLinear = option?.vars?.priceLinear;

            console.log('option');
            //console.log(option);
            console.log(price);
            console.log(priceLinear);
            //console.log(this.helper.views.sizes.config.active);
            
            if (price?.type === "static") {
                ops.min.push(parseFloat(price?.value || '0'));
                ops.max.push(parseFloat(price?.value || '0'));
                ops.real.push(parseFloat(price?.value || '0'));
                console.log('price : '+price?.value);
            }
            console.log('ops');
            console.log(ops);
            if (this.helper.views.sizes.config.active && priceLinear?.type === "static") {
                const sizes = this.helper.views.sizes.getSizes(sku);
                //console.log('sizes');
                //console.log(sizes);
                
                ops.min.push(sizes.length.min / 12 * parseFloat(priceLinear?.value || '0'));
                ops.max.push(sizes.length.max / 12 * parseFloat(priceLinear?.value || '0'));
                ops.real.push(parseFloat(this.helper.views.sizes.length.value) / 12 * parseFloat(priceLinear?.value || '0'));
            }
        });

        console.log('ops');
        console.log(ops);
        
        
        const result = {
            min: 0,
            max: 0,
            real: 0
        }
        Object.keys(ops).forEach(op => {
            result[op] = ops[op].reduce((a, v) => a + v, 0);
        });
        return result;
    }
}
