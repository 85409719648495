<template>
	<div class="p-p-2 p-shadow-5 p-d-flex p-flex-column" style="min-height: 100%">
		<Toolbar class="p-mb-4">
			<template #left>
				<h1>
					{{ item.name }} -
					<span :style="{'color': statusOptions[item.status]?.color}">{{ statusOptions[item.status]?.name }}</span>
					<router-link :to="{name: 'EditReseller', params: {id: item._id}}"
					             v-slot="{ href, route, navigate, isActive, isExactActive }"
					             custom>
						<Button @click="navigate" icon="pi pi-pencil" class="p-ml-4 p-button-sm"/>
					</router-link>
				</h1>
			</template>
			
			<template #right>
				<Button :label="$t('back')"
				        @click="$router.back()"
				        icon="pi pi-arrow-left"
				        class="p-button-secondary p-mr-2"/>
			</template>
		</Toolbar>
		
		<div class="p-p-2 p-d-flex" style="height: 100%;flex-grow: 1">
			<div class="p-grid" style="flex-grow: 1">
				<div class="p-col-12 p-xl-4">
					<Card class="p-mb-4">
						<template #title>
              {{$t('coordonnees')}}
						</template>
						<template #content>
							<div class="p-d-flex p-ai-center p-mb-2">
								<i class="fal fa-phone p-mr-2"></i>
								<a :href="'tel:'+item.main_phone">{{ item.main_phone }}</a>
							</div>
							<div class="p-d-flex p-ai-center p-mb-2">
								<i class="fal fa-envelope p-mr-2"></i>
								<a :href="'mailto:'+item.main_email">{{ item.main_email }}</a>
							</div>
						
						</template>
					</Card>
					<Card>
						<template #title>
              {{$t('billing_address')}}
						</template>
						<template #content>
							<div class="p-d-flex p-mb-2">
								<i class="fal fa-map-marker-alt p-mr-2 p-mt-1"></i>
								<address>
									{{ item.address_line_1 }}<br>
									{{ item.address_line_2 }}<br v-if="item.address_line_2">
									{{ item.city }}, {{ mappedProvinces[item.province] }}<br>
									{{ item.postal_code }}, Canada
								</address>
							</div>
							<div class="p-d-flex p-ai-center p-mb-2">
								<i class="fal fa-envelope p-mr-2"></i>
								<a :href="'mailto:'+item.billing_email">{{ item.billing_email }}</a>
							</div>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-xl-8">
					<TabView>
						<TabPanel>
							<template #header>
								<span>{{$tc('note',2)}}</span>
							</template>
							<div>
								<lm-notes v-model="item.lm_notes" @update:modelValue="updateNotes($event)"></lm-notes>
							</div>
						</TabPanel>
						<TabPanel>
							<template #header>
								<span>{{$tc('document',2)}}</span>
							</template>
							<div>

							</div>
						</TabPanel>
					</TabView>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/*<div class="row">
	<div class="col-xl-4">
		<lm-card>
			<template #header>
			<h2>{{ client.organization_name }}</h2>
		</template>
		<div class="info mb-4">
			<model-get v-for="(id, index) in client.in_charge" :key="index"
			 model="v1.user.get"
			:class="{'mb-3': index === client.in_charge.length-1}"
			:id="id"
		>
			<template #default="{data}">
			<div>
				Responsable #{{index+1}}: {{data.first_name}} {{data.last_name}}
			</div>
		</template>
	</model-get>
	<div v-if="client.main_phone">
		<i class="fas fa-phone"></i>
		<a :href="'tel:'+client.main_phone">
		{{ client.main_phone }}
	</a>
</div>
<div v-if="client.main_email">
	<i class="fas fa-envelope"></i>
	<a :href="'mailto:'+client.main_email">
	{{ client.main_email }}
</a>
</div>
<div v-if="client.website">
	<i class="fas fa-globe-americas"></i>
	<a target="_blank" :href="client.website">
	{{ client.website }}
</a>
</div>
</div>
<div>
	<h5>Adresse de facturation</h5>
	<address>
		<p v-if="client.address_line_1">{{ client.address_line_1 }}</p>
		<p v-if="client.address_line_2">{{ client.address_line_2 }}</p>
		<p>
			<span v-if="client.city">{{ client.city }}</span>
			<span v-if="client.city && client.state_province">, </span>
			<span v-if="client.state_province">{{ client.state_province }}</span>
			<span>&nbsp;</span>
			<span v-if="client.zip_code">{{ client.zip_code }}</span>
		</p>
	</address>
</div>

</lm-card>
</div>
<div class="col-xl-8">
	<lm-card>
		<lm-tabs v-model="active">
			<tab title="Contacts">
				<crud-contact
				:client_id="id">

			</crud-contact>
		</tab>
		<tab title="Notes">
			<lm-notes :id="id"
			 service="v1.client"
		></lm-notes>
	</tab>
	<tab title="Leads">
		<crud-leads
		:client_id="id">

	</crud-leads>
</tab>
<tab title="Documents">
	<upload-drag></upload-drag>
</tab>
</lm-tabs>
</lm-card>
</div>
</div>*/

import {useStore} from "@/store";
import {CrudRepository} from "@radiumjs/client";
import {useRoute} from "vue-router";

import {provinces} from "@/helpers/provinces";
import {computed, onActivated} from "vue";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";

export default {
	name: "SingleReseller",
	components: {
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Card: require('primevue/card').default,
		TabView: require('primevue/tabview').default,
		TabPanel: require('primevue/tabpanel').default,
		LmNotes: require('@/components/Lm-Notes/Lm-Notes').default,
	},
	setup(props) {
		const radium = useStore().radium;
		const route = useRoute();
		const toast = useToast();
		const repo = new CrudRepository(radium, 'client');
    const i18n = useI18n();
		
		const {item, id, loading, save: iSave, refresh: objRefresh, update} = radium.objectReactive(repo, route.params.id);
		objRefresh();
		
		onActivated(() => {
			objRefresh()
		});
		
		const statusOptions = {
			"1": {
				name: "Actif",
				code: 1,
				color: '#198754'
			},
			"-1": {
				name: "Inactif",
				code: -1,
				color: '#d20000',
			}
		}
		
		return {
			item,
			async updateNotes(notes) {
				update('$set', 'lm_notes', notes);
        try {
          await iSave();
          toast.add({severity: 'success', summary: i18n.t('success'), detail: i18n.t('message_success_edit'), life: 3000});
        } catch (e) {
          toast.add({severity: 'error', summary: i18n.t('error'), detail: e, life: 20000});
        }
				
			},
			statusOptions,
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
		};
	},
}
</script>
<style scoped>
.info i {
	margin-right: 1rem;
}

address p {
	margin-bottom: 4px;
}
</style>
