<template>
	<SingleLayout service="userCategories" :schema="schema"  :return-on-save="true">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('user_categorie',2)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{$t('information')}}</span>
				</template>
        <template #permission-header>
        <span>{{$t('permission')}}</span>
      </template>
				<template #info>
					<div class="p-field p-mt-4 p-col-12">
						<div class="p-float-label">
							<InputText id="name" type="text" v-model="item.name_fr" @change="update('$set','name_fr',$event.target.value)" placeholder="(fr)"/>
              <InputText id="name" type="text" v-model="item.name_en" @change="update('$set','name_en',$event.target.value)" class="mt-2" placeholder="(en)"/>
							<label>{{$t('nom')}}</label>
						</div>
					</div>
				</template>
        <template #permission>
          <UserPermissions :selected="item.permissions" @update:model-value="update('$set','permissions',$event)"></UserPermissions>
      </template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import UserPermissions from "./UserPermissions";
import {Permissions} from "../../helpers/permissions";


export default {
	name: 'EditUserCategorie',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
    UserPermissions,
    Permissions,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
	setup(props, {emit}) {
		return {
			tabs: {
				info: true,
        permission: true
			}
		};
	}
}
</script>
