<template>
	<div class="p-p-2 p-d-flex p-flex-column p-justify-between" style="height: 100%">
		<div class="container" :class="{'edit-mode': edit}">
			<!--			<div class="grid-bg">-->
			<!--				<div v-for="i in (12*12)"></div>-->
			<!--			</div>-->
			
			<div class="grid-fg" ref="dashRef" @contextmenu="openMenu">
				<template v-for="(cell,i) in cells" :key="cell.index">
					<Widget
						class="widget"
						:edit="edit"
						v-if="cell.data"
						:cell="cells[i]"
						:meta="widgetsType[cell.data.type].meta"
						:style="{'grid-area':cell.gridArea}"
						@dragged="dragged($event,cell)"
						@resize="resize($event,cell)"
						@update:args="upArgs($event,cell)"
						@delete="remove($event,cell);"
					>
						<component :is="widgetsType[cell.data.type].default" v-bind="cell.data.args" @closeDash="$emit('home')">
						</component>
					</Widget>
					<div class="gap" v-else :style="{'grid-area':cell.gridArea}">
						<span>{{ cell.rect.x + "," + cell.rect.y }}<br>{{ cell.index }}</span>
					</div>
				</template>
			
			</div>
		</div>
		<div class="tools p-d-flex p-justify-between">
			<div></div>
			<div>
				<Button icon="pi pi-save" v-if="edit" @click="save"/>
				<ToggleButton v-model="edit" onIcon="pi pi-pencil" offIcon="pi pi-pencil"/>
			</div>
		</div>
		<ContextMenu ref="menuRef" :model="menuItems"/>
	</div>


</template>

<style scope lang="scss">

.container {
	position: relative;
	//max-height: calc(80vh - 110px);
	overflow: auto;
	
	&.edit-mode {
		.grid-fg {
			> div {
				//background-color: #077DAF;
				//border-radius: 12%;
			}
			
			> div.gap {
				span {
					position: absolute;
					//display: block;
					top: 0;
					left: 25%;
					max-width: 80%;
				}
				
				position: relative;
				background-color: #ccc;
				border-radius: 12%;
			}
		}
		
		/*.tools {
			position: relative;
			left: unset;
			transform: unset;
		}*/
	}
}

.grid-fg {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 10px;
	
	> div.gap {
		span {
			display: none;
		}
		
		padding-top: 100%;
	}
}

.tools {

}

</style>

<script>

import {computed, ref, watch} from "vue";
import {useStore} from "@/store";

const grid_w = 12;

export default {
	name: 'Dashboard',
	components: {
		ContextMenu: require('primevue/contextmenu').default,
		Widget: require('./Widget').default,
		ToggleButton: require("primevue/togglebutton").default,
		Button: require("primevue/button").default,
	},
	emits: ["home"],
	setup() {
		const store = useStore();
		const grid = store.dashboard.grid;
		const widgetsType = store.dashboard.widgetsType;
		
		const cells = ref([]);
		const edit = ref(false);
		
		const refresh = () => {
			cells.value = grid.cells.map((v, i) => {
				if (!v) return undefined;
				v.index = i;
				return v;
			}).filter(v => v);
		}
		refresh();
		watch(() => edit.value, () => {
			if (!edit.value)
				save();
			grid.defrag();
			refresh()
		});
		
		const save = () => {
			store.dashboard.saveDash();
		};
		
		const dashRef = ref();
		const menuRef = ref();
		return {
			test: console.log,
			cells,
			dashRef,
			menuRef,
			widgetsType,
			edit,
			openMenu($event) {
				if (!edit.value)
					return;
				menuRef.value.show($event);
			},
			menuItems: computed(() => {
				const menu = [];
				for (const widgetKey in widgetsType) {
					const widget = widgetsType[widgetKey];
					menu.push({
						label: widget.meta?.name || "n/a",
						icon: widget.meta?.icon,
						command($event) {
							const div = dashRef.value;
							const rect = div.getBoundingClientRect();
							
							const size = rect.width / 12;
							
							const x = Math.abs(Math.floor(($event.originalEvent.clientX - rect.left) / size) + 1);
							const y = Math.abs(Math.floor(($event.originalEvent.clientY - rect.top) / size) + 1);
							const {w, h} = widget.meta.sizes[0];
							
							grid.add({
								type: widgetKey,
								args: {}
							}, {x, y, w, h});
							
							refresh();
						}
					});
				}
				return menu;
			}),
			resize({w, h}, cell) {
				const {x, y} = grid.cells[cell.index].rect;
				if (grid.move(cell.index, {x, y, w, h})) {
					refresh();
				}
			},
			remove(_, cell) {
				grid.remove(cell.index)
				refresh();
			},
			upArgs(args, cell) {
				grid.cells[cell.index].data.args = args;
				refresh();
			},
			dragged(pos, cell) {
				/**
				 * @type {HTMLDivElement}
				 */
				const div = dashRef.value;
				const rect = div.getBoundingClientRect();
				
				const size = rect.width / 12;
				
				const x = Math.abs(Math.floor((pos.nx - rect.left) / size) + 1);
				const y = Math.abs(Math.floor((pos.ny - rect.top) / size) + 1);
				const {w, h} = grid.cells[cell.index].rect;
				if (grid.move(cell.index, {x, y, w, h})) {
					refresh();
				}
			}
		}
	},
	methods: {
		showMenu(event) {
			this.$refs.menu.show(event);
		}
	}
}
</script>
