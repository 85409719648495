
import {computed, ComputedRef, defineComponent, inject, ref} from "vue";
import {useConfirm} from "primevue/useconfirm";
import SkuPartOptions from "./SkuPartOptions.vue";
import SkuPartOption from "@/components/Sku/SkuPartOption.vue";
import {SkuHelper, SkuPart} from "@/helpers/product";

export default defineComponent({
	name: "SkuPart",
	components: {
		SkuPartOption,
		SkuPartOptions,
		SkuHelper,
		Button: require('primevue/button').default,
		SplitButton: require('primevue/splitbutton').default,
		Toolbar: require('primevue/toolbar').default,
		Listbox: require('primevue/listbox').default,
		Panel: require('primevue/panel').default,
		InputText: require('primevue/inputtext').default,
		Dropdown: require('primevue/dropdown').default,
		Sidebar: require('primevue/sidebar').default,
		OrderList: require('primevue/orderlist').default,
	},
	props: {
		modelValue: Object,
	},
	emits: ["update:modelValue", "destroy", "right", "left"],
	setup(props, {emit}) {
		const confirm = useConfirm();
		const helper = inject("productHelper") as ComputedRef<SkuHelper>;
		
		const partData = computed<SkuPart>({
			get() {
				return props.modelValue as SkuPart
			},
			set(data) {
				emit('update:modelValue', data);
			}
		});
		
		const modules = Object.entries(SkuHelper.modules).map((v) => {
			return {
				module: v[1],
				ui: v[1].partator(helper, partData),
				actif: computed(() => helper.value?.views[v[0]]?.isActive)
			};
		}).filter(v => v?.ui);
		
		
		const edit = ref();
		
		const options = computed<any[]>({
			get() {
				return props.modelValue.options || []
			},
			set(options) {
				emit('update:modelValue', {
					...props.modelValue,
					options
				});
			}
		});
		
		return {
			modules,
			edit,
			optionEditing: ref(),
			label: computed({
				get() {
					return props.modelValue.label
				},
				set(label) {
					emit('update:modelValue', {
						...props.modelValue,
						label
					});
				}
			}),
			options,
			addOption() {
				options.value = [...options.value, {}]
			},
			updateOption(newOpt, i) {
        console.log('updateOption');
        console.log(newOpt);
				const newOpts = [...options.value];
				newOpts[i] = newOpt;
				options.value = newOpts;
			},
			destroyOption(i) {
				const newOpts = [...options.value];
				newOpts.splice(i, 1);
				options.value = newOpts;
			},
			destroy($event) {
				confirm.require({
					target: $event.currentTarget,
					message: 'Êtes-vous sure de vouloir supprimer cette partie?',
					icon: 'pi pi-exclamation-triangle',
					acceptClass: 'p-button-danger',
					accept: () => {
						emit('destroy');
						edit.value = false;
					},
					reject: () => {
					}
				});
			}
		}
	},
  methods: {
    newDatas(): SkuPart{
      return SkuHelper.SkuPart;
    }
  }
});
