<template>
  <div class="p-p-2 p-shadow-5 p-d-flex p-flex-column" style="min-height: 100%">
    <Toolbar class="p-mb-4">
      <template #left>
        <h1 style="margin: auto">{{$tc('setting',2)}}</h1>
      </template>
    </Toolbar>
    <div class="p-p-2 p-d-flex" style="height: 100%;flex-grow: 1">
      <div style="flex-grow: 1;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import {reactive} from "vue";
import {useRouter} from "vue-router";


export default {
  name:"Settings",
  components: {
    Toolbar: require('primevue/toolbar').default,
  },
  data() {
    return {}
  },
  setup(){
    return {};
  },
  methods: {
  }
}
</script>
