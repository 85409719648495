<template>
	<Dialog v-model:visible="show" :style="{width: '90vh'}" :header="header" :modal="true" class="p-fluid">
		<ProgressSpinner v-if="loading"/>
		
		<div v-if="!error">
		
		</div>
		
		<template v-if="!loading && !error">
			<TabView>
				<TabPanel v-for="(tabName,tabKey) in tabs" :key="tabKey">
					<template #header>
						<slot :name="tabKey+'-header'" :item="item" :errors="errors">
							<template v-if="tabKey==='notable'">
								<i class="pi pi-book p-pr-1"></i>
								<span>Note</span>
							</template>
							<template v-else-if="tabKey==='timeData'">
								<i class="pi pi-clock p-pr-1"></i>
								<span>Horodatage</span>
							</template>
							<span v-else>{{ tabName }}</span>
						</slot>
					</template>
					<slot :name="tabKey" :item="item" :errors="errors">
						<lm-notes v-model="item.notes" v-if="tabKey==='notable'"/>
						<TimeData :item="item" v-if="tabKey==='timeData'"/>
					</slot>
				</TabPanel>
			</TabView>
		</template>
		
		<template #footer v-if="!loading && !error" :item="item" :errors="errors">
			<hr/>
			<Button class="p-px-3" icon="pi pi-check" @click="save" autofocus/>
		</template>
	</Dialog>
</template>

<script>
import {computed, ref, triggerRef, watch} from "vue";
import Validator from "fastest-validator";
import {CrudRepository} from "@radiumjs/client";
import TimeData from "@/components/FormPrime/TimeData";
import {useStore} from "@/store/index.ts";
import LmNotes from "@/components/Lm-Notes/Lm-Notes";


export default {
	name: "FormDialog",
	props: {
		id: [String, Boolean],
		header: String,
		visible: Boolean,
		service: String,
		schema: Object,
		tabs: Object,
	},
	components: {
		LmNotes,
		TimeData,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		TabView: require('primevue/tabview').default,
		TabPanel: require('primevue/tabpanel').default,
		ProgressSpinner: require('primevue/progressspinner').default,
	},
	setup(props, {emit, slots}) {
		const radium = useStore().radium;
		
		const errors = ref({});
		const error = ref(false);//TODO handle 404/500 and other
		
		const validate = v.compile(props.schema);
		const repo = new CrudRepository(radium, props.service);
		
		const {item, id, loading, save: iSave, refresh} = radium.objectReactive(repo, props.id);
		
		watch(() => props.id, () => {
			console.log(props.id);
			id.value = props.id;
			refresh();
		});
		
		////
		
		/*const tabs = ref([]);

		const slotsKey = Object.keys(slots);
		slotsKey.filter(v=>!(v in ["footer"]));

		console.log(slots);*/
		
		////
		
		const save = () => {
			errors.value = {};
			let errs = validate(item.value);
			console.log('error validate', errs);
			if (!Array.isArray(errs)) {
				iSave();
			} else {
				errs.forEach(v => {
					errors.value[v.field] = errors.value[v.field] || [];
					errors.value[v.field].push(v);
				});
				triggerRef(errors);
			}
		}
		
		return {
			error,
			errors,
			item,
			save,
			loading,
			show: computed({
				get: () => {
					return props.visible
				},
				set: (val) => {
					emit('update:visible', val)
				}
			})
		};
	}
}
</script>

<style scoped>

</style>
