import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a44c601"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-row p-inputtext p-inputtext-row" }
const _hoisted_2 = {
  key: 0,
  class: "p-d-flex p-col-12"
}
const _hoisted_3 = { class: "p-row p-col-11 p-md-3" }
const _hoisted_4 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_5 = { class: "p-float-label" }
const _hoisted_6 = { for: "sku" }
const _hoisted_7 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_8 = { class: "p-float-label" }
const _hoisted_9 = { for: "description_fr" }
const _hoisted_10 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_11 = { class: "p-float-label" }
const _hoisted_12 = { for: "description_en" }
const _hoisted_13 = { class: "p-row p-col-6 p-md-3" }
const _hoisted_14 = { class: "p-field p-my-2 p-col-6" }
const _hoisted_15 = { class: "p-float-label" }
const _hoisted_16 = { for: "prix" }
const _hoisted_17 = { class: "p-field p-my-2 p-col-6" }
const _hoisted_18 = { class: "p-float-label" }
const _hoisted_19 = { for: "msrp" }
const _hoisted_20 = { class: "p-field p-my-2 p-col-6" }
const _hoisted_21 = { class: "p-float-label" }
const _hoisted_22 = { for: "prix_lineaire" }
const _hoisted_23 = { class: "p-field p-my-2 p-col-6" }
const _hoisted_24 = { class: "p-float-label" }
const _hoisted_25 = { for: "msrp_lineaire" }
const _hoisted_26 = {
  key: 1,
  class: "p-row p-col-6 p-md-3"
}
const _hoisted_27 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_28 = { class: "p-float-label" }
const _hoisted_29 = { for: "cote" }
const _hoisted_30 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_31 = { class: "p-float-label" }
const _hoisted_32 = { for: "extremite" }
const _hoisted_33 = {
  key: 2,
  class: "p-row p-col-6 p-md-3"
}
const _hoisted_34 = { class: "p-field p-my-2 p-col-12" }
const _hoisted_35 = { class: "p-float-label" }
const _hoisted_36 = { for: "coin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.index>-1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: "",
            icon: "pi pi-times",
            class: "p-button-danger p-mr-2",
            onClick: $setup.destroy
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_InputText, {
            id: "sku",
            type: "text",
            modelValue: $setup.sku,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.sku) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('sku')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_InputText, {
            id: "description_fr",
            type: "text",
            modelValue: $setup.description_fr,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.description_fr) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('description')) + " - " + _toDisplayString(_ctx.$t('french')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_InputText, {
            id: "description_en",
            type: "text",
            modelValue: $setup.description_en,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.description_en) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('description')) + " - " + _toDisplayString(_ctx.$t('english')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_InputNumber, {
            id: "prix",
            modelValue: $setup.prix,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.prix) = $event)),
            mode: "decimal",
            minFractionDigits: 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('unit_price')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_InputNumber, {
            id: "msrp",
            modelValue: $setup.msrp,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.msrp) = $event)),
            mode: "decimal",
            minFractionDigits: 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('msrp_price')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_InputNumber, {
            id: "prix_lineaire",
            modelValue: $setup.prix_lineaire,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.prix_lineaire) = $event)),
            mode: "decimal",
            minFractionDigits: 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('linear_price')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_InputNumber, {
            id: "msrp_lineaire",
            modelValue: $setup.msrp_lineaire,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.msrp_lineaire) = $event)),
            mode: "decimal",
            minFractionDigits: 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('msrp_linear_price')), 1)
        ])
      ])
    ]),
    ($props.parent.optionType&&$props.parent.optionType.code=='contour')
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_InputNumber, {
                id: "cote",
                modelValue: $setup.cote,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.cote) = $event)),
                mode: "decimal",
                minFractionDigits: 0,
                max: 2
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('many_side')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_InputNumber, {
                id: "extremite",
                modelValue: $setup.extremite,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.extremite) = $event)),
                mode: "decimal",
                minFractionDigits: 0,
                max: 2
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('many_tip')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($props.parent.optionType&&$props.parent.optionType.code=='coin')
      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createVNode(_component_InputNumber, {
                id: "coin",
                modelValue: $setup.coin,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.coin) = $event)),
                mode: "decimal",
                minFractionDigits: 0,
                max: 4
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('many_corner')), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}