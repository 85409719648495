<template>
	<SingleLayout service="resellerShipping" :schema="schema" :return-on-save="true">
		<template #toolbar-left="{id}">
			<h1 style="margin: auto">{{$tc('adresses',2)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$t('information')}}</span>
				</template>
				
				<template #info>
					<div class="p-field">
						<FormInput class="p-mt-3" :errors="errors.status" v-slot="classes">
							<span class="p-float-label">
								<Dropdown v-model="item.primary_shipto_flag"
								          @change="update('$set','primary_shipto_flag',$event.value);"
								          :options="statusOptions"
								          optionLabel="name"
								          optionValue="code"
								/>
								<label>{{$tc('statut',1)}}</label>
							</span>
						</FormInput>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_location_name" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_location_name" @change="update('$set','shipto_location_name',$event.target.value)" :class="classes"/>
									<label>{{$t('nom')}}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_email" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="fal fa-envelope"></i>
									<InputText type="email" v-model="item.shipto_email" @change="update('$set','shipto_email',$event.target.value)" :class="classes"/>
									<label>{{$t('main_email')}}</label>
								</span>
							</FormInput>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_telephone" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.shipto_telephone" @change="update('$set','shipto_telephone',$event.target.value)" :class="classes"/>
									<label>{{$t('telephone')}}</label>
								</span>
							</FormInput>
						</div>
					</div>
					
					<h3 class="p-mt-4">{{$t('shipping')}}</h3>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_address_1" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_address_1" @change="update('$set','shipto_address_1',$event.target.value)" :class="classes"/>
									<label>{{$t('adresse_ligne_1')}}</label>
								</span>
							</FormInput>
						</div>
					</div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipto_address_2" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_address_2" @change="update('$set','shipto_address_2',$event.target.value)" :class="classes"/>
									<label>{{$t('adresse_ligne_2')}}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipto_address_3" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_address_3" @change="update('$set','shipto_address_3',$event.target.value)" :class="classes"/>
									<label>{{$t('adresse_ligne_3')}}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipto_address_4" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_address_4" @change="update('$set','shipto_address_4',$event.target.value)" :class="classes"/>
									<label>{{$t('adresse_ligne_4')}}</label>
								</span>
              </FormInput>
            </div>
          </div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_city" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipto_city" @change="update('$set','shipto_city',$event.target.value)" :class="classes"/>
									<label>{{$t('city')}}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_province" v-slot="classes">
								<span class="p-float-label">
									<Dropdown v-model="item.shipto_province"
									          @change="update('$set','shipto_province',$event.value)"
									          :options="provinces"
									          optionLabel="label"
									          optionValue="value"
									          :class="classes"/>
									<label>{{$tc('province',1)}}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.shipto_postal_code" v-slot="classes">
								<span class="p-float-label">
									<InputMask mask="a9a 9a9" v-model="item.shipto_postal_code" @change="update('$set','shipto_postal_code',$event.target.value.toUpperCase())" :class="classes"/>
									<label>{{$t('code_postal')}}</label>
								</span>
							</FormInput>
						</div>
					</div>
				
				</template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {provinces} from "@/helpers/provinces";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

export default {
	name: 'EditResellerShipping',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
		InputMask: require('primevue/inputmask').default,
	},
	setup(props, {emit}) {
    const i18n = useI18n();
		const route = useRoute();
    const statusOptions = ref([
      {
        name: "Primaire",
        code: 'Primary',
        css: 'badge status-active'
      },
      {
        name: "Secondaire",
        code: 'Not Primary',
        css: 'badge status-info'
      }
    ]);
		return {
			provinces,
			schema: {
				status: {type: "string", convert: true},
        shipto_location_name: {type: "string", min: 3, max: 255},
        shipto_telephone: {type: "string", optional: true},
        shipto_fax: {type: "string", optional: true},
        shipto_email: {type: "email", optional: true},
        shipto_address_1: {type: "string", optional: true},
        shipto_address_2: {type: "string", optional: true},
        shipto_address_3: {type: "string", optional: true},
        shipto_address_4: {type: "string", optional: true},
        shipto_city: {type: "string", optional: true},
        shipto_province: {type: "number", optional: true},
        shipto_postal_code: {type: "string", optional: true},
				parent: {type: "string", default: route.params.reseller}
			},
			tabs: {
				info: "Infos",
				notable: true,
				timeData: true,
			},
			statusOptions,
			statusMap: computed(() => {
				return statusOptions.value.reduce((a, v) => {
					a[v.code] = {name: v.name, css: v.css};
					return a
				}, {});
			}),
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
		}
	},
}
</script>
