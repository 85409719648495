<template>
	<Login v-if="!loggedIn"></Login>
	<OrientationLayout v-else>
		<template #default="{navigate,Component,selected,route,includes}">
			<keep-alive :include="includes">
				<BigMenu v-if="selected==='menu'" @home="navigate($route.name==='Dashboard' ? 'dash' : 'home')"/>
        <!--<MyProfile v-else-if="selected==='profile'"/>-->
        <!--<Dashboard v-else-if="selected==='dash'" @home="navigate('home')"/>-->
				<component v-else :is="Component" :key="route.path"></component>
			</keep-alive>
		</template>
	</OrientationLayout>
	<ConfirmPopup></ConfirmPopup>
	<Toast/>
</template>

<script>
import Header from "@/components/Layout/Header";
import {useStore} from "@/store";
import OrientationLayout from "@/components/Layout/OrientationLayout";
import Login from "./views/Auth/Login";
import MyProfile from "./views/Auth/MyProfile";
import BigMenu from "@/components/Menu/BigMenu";
import Dashboard from "@/components/Dashboard/Dashboard";
import { useI18n } from "vue-i18n";

export default {
	components: {
		MyProfile,
		Login,
		OrientationLayout,
		Header,
		Toast: require("primevue/toast").default,
		ConfirmPopup: require("primevue/confirmpopup").default,
		BigMenu,
		Dashboard
	},
	setup() {
    const i18n = useI18n();
		const store = useStore();
		
		return {
			loggedIn: store.auth.loggedIn
		};
	}
}
</script>
<style lang="scss">
//@import "~bootstrap/scss/bootstrap";

body {
	margin: 0;
	height: 100vh;
}

#app {
	height: 100%;
}

</style>
