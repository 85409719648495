
import {computed, ref} from "vue";
import {useStore} from "@/store";

export default {
	name: "ActionsButton",
	components: {
    Menu: require('primevue/menu').default,
    Button: require('primevue/button').default
	},
	props: {
		data: Object,
		actions: Array,
    disabled_actions: Array
	},
	setup(props, {emit}) {
    const store = useStore();

		const cmd = () => {
			emit("cmd:edit", props.data?._id);
		}

    const menu = ref();
    const toggle = (event) => {
      menu.value.toggle(event);
    };

		const items = computed(() => {
      const disabled_actions = props.disabled_actions || [];

      if(!store.permissions.edit()){
        disabled_actions.push('edit');
      }
      if(!store.permissions.delete()){
        disabled_actions.push('delete');
      }



			const items = [];
      if(!disabled_actions.includes('edit')) {
        items.push({
          label: 'Modifier',
          icon: 'pi pi-pencil',
          command: () => {
            emit("cmd:edit", props.data?._id);
          }
        });
      }

			if (props.actions) {
        for (const action of props.actions) {
          items.push({
            ...(action as any),
            command() {
              (action as any).command(props.data);
            }
          })
        }
      }

      if(!disabled_actions.includes('delete')) {
        items.push({
          label: 'Supprimer',
          icon: 'pi pi-times',
          command: () => {
            emit("cmd:delete", props.data?._id);
          }
        });
      }

			return items;
		});

		return {
      items,
      menu,
      toggle
    };
	}
}
