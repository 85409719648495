<template>
	<SingleLayout service="lead" :schema="schema" :return-on-save="false">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('lead',1)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{$t('information')}}</span>
				</template>
				<template #info>
					<FormInput class="p-mt-3" :errors="errors?.status" v-slot="classes">
						<label for="statusUserDropdown">{{$tc('statut',1)}}</label>
						<span class="">
							<Dropdown v-model="item.status"
							          :options="statusOptions"
							          optionLabel="name"
							          optionValue="code"
							          id="statusUserDropdown"
							          @update:modelValue="update('$set','name',$event.value)"
							/>
						</span>
					</FormInput>
					<div class="p-field p-mt-4">
						<div class="p-float-label">
							<InputText id="name" type="text" v-model="item.name" @change="update('$set','name',$event.target.value)"/>
							<label for="name">{{$t('nom')}}</label>
						</div>
					</div>
					<div class="p-field p-mt-4">
						<div class="p-float-label">
							<InputText id="contact" type="text" v-model="item.contact" @change="update('$set','contact',$event.target.value)"/>
							<label for="contact">{{$tc('contact',1)}}</label>
						</div>
					</div>
					<div class="p-field p-mt-4">
						<div class="p-float-label">
							<InputText id="raison" type="text" v-model="item.raison" @change="update('$set','raison',$event.target.value)"/>
							<label for="raison">{{$tc('reason',1)}}</label>
						</div>
					</div>
				
				</template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {useI18n} from "vue-i18n";


export default {
	name: 'EditLead',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
	setup(props, {emit}) {
		const i18n = useI18n();
		return {
			statusOptions: [
				{
					name: i18n.t("actif"),
					code: 0,
					color: '#198754'
				},
				{
					name: i18n.t("good"),
					code: 1,
					color: '#d20000',
				},
				{
					name: i18n.t("bad"),
					code: -1,
					color: '#d20000',
				}
			],
			schema: {},
			tabs: {
				info: true,
				notable: true,
				timeData: true,
			}
		};
	}
}
</script>
