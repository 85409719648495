
import '@fullcalendar/core';
import frLocale from '@fullcalendar/core/locales/fr';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {CalendarOptions} from "@fullcalendar/core";
import {Events} from "../helpers/event";
import {useStore} from "@/store";
import {useRouter} from "vue-router";

export default {
	name: "Home",
	components: {
		Fieldset: require("primevue/fieldset").default,
		Timeline: require("primevue/timeline").default,
		Card: require("primevue/card").default,
		FullCalendar
	},
	setup() {
		const router = useRouter();
		
		const options: CalendarOptions = {
			locales: [frLocale],
			locale: 'fr',
			plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
			//initialDate: '2017-02-01',
			initialView: 'dayGridWeek',
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
				right: 'dayGridWeek,listWeek'
			},
			
			height: "auto",
			
			editable: true,
			selectable: true,
			selectMirror: true,
			dayMaxEvents: true,
			
			events: new Events(useStore().radium).fetch(),
			
			
			eventClick: function (info) {
				info.jsEvent.preventDefault(); // don't let the browser navigate
				
				if (info.event.url) {
					router.push(info.event.url);
				}
			}
		};
		
		return {
			changelogs: [
				{
					title: 'Module soumission', date: '25/06/2021', icon: 'pi pi-dot', color: '#222',
					content: "Cette version contient le module de soumssion ainsi que la création de produits avec leurs SKUs, le système de connexion et d'enregistrement pour les administrateurs, toute la base du CRM qui permettera de gerer vos client et distributeur."
				}
			],
			options
		};
	}
}
