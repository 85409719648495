import {createRouter, createWebHistory} from 'vue-router'
import EditProduct from "../views/Products/EditProduct";
import Products from "../views/Products/Products";
import SoumissionRapide from "../views/Submission/SoumissionRapide";
import Submission from "../views/Submission/Submission";
import Resellers from "../views/Resellers/Resellers";
import EditReseller from "../views/Resellers/EditReseller";
import SingleReseller from "../views/Resellers/SingleReseller";
import Leads from "../views/Leads/Leads";
import EditLead from "../views/Leads/EditLead";
import Events from "../views/Event/Events";
import EditEvent from "../views/Event/EditEvent";
import EditResellerContact from "../views/Resellers/EditResellerContact";
import ProductsLayout from "../views/Products/ProductsLayout";



const routes = [
	{
		path: "/",
		component: require("../views/Home").default,
		name: 'dashboard',
		i18n: 'menu_home',
		show: true
	},

	//Reseller
	{
		path: "/resellers",
		component: () => import("../views/Layout"),
		i18n: 'menu_distributeur',
		show: true,
		children: [
			{
				path: "",
				component: Resellers,
				name: 'resellers.list',
				i18n: 'menu_list',
				meta:{
					permission: 'resellers.list'
				}
			},
			{
				path: "edit/:id?",
				name: "resellers.edit",
				component: EditReseller,
				i18n: 'menu_edit',
				meta:{
					permission: 'resellers.edit'
				}
			},
			{
				path: "edit/:reseller/contact/:id?",
				name: "resellers.contact.edit",
				component: EditResellerContact,
				i18n: 'menu_distributeur_contact_edit',
				meta:{
					permission: 'resellers.contact.edit'
				}
			},
			{
				path: "edit/contactCategories",
				name: "resellers.contact.categories.list",
				component: require("../views/Resellers/ContactCategories").default,
				i18n: 'menu_distributeur_contact_category_list',
				meta:{
					permission: 'resellers.contact.categories.list'
				}
			},
			{
				path: "edit/contactCategories/:id?",
				name: "resellers.contact.categories.edit",
				component: require("../views/Resellers/EditContactCategorie").default,
				i18n: 'menu_distributeur_contact_category_edit',
				meta:{
					permission: 'resellers.contact.categories.edit'
				}
			},
			{
				path: "edit/:reseller/shipping/:id?",
				name: "resellers.shipping.edit",
				component: require("../views/Resellers/EditResellerShipping").default,
				i18n: 'menu_distributeur_shipping_edit',
				meta:{
					permission: 'resellers.shipping.edit'
				}
			},
			{
				path: ":id",
				name: "resellers.show",
				component: SingleReseller,
				meta:{
					permission: 'resellers.show'
				}
			}
		]
	},

	//Clients
	{
		path: "/clients",
		component: () => import("../views/Layout"),
		i18n: 'menu_enduser',
		show: true,
		children: [
			{
				path: "",
				component: () => import("../views/Clients/Clients"),
				name: 'clients.list',
				i18n: 'menu_list',
				meta:{
					permission: 'clients.list'
				}
			},
			{
				path: "edit/:id?",
				name: "clients.edit",
				component: () => import("../views/Clients/EditClient"),
				i18n: 'menu_edit',
				meta:{
					permission: 'clients.edit'
				}
			}
		]
	},

	//PRODUCTS
	{
		path: "/products",
		component: () => import("../views/Layout"),
		i18n: 'menu_product',
		show: true,
		children: [
			{
				path: "",
				component: Products,
				name: 'products.list',
				i18n: 'menu_product',
				meta:{
					permission: 'products.list'
				}
			},
			{
				path: "edit/:id?",
				component: EditProduct,
				name: 'products.edit',
				i18n: 'menu_product_edit',
				meta:{
					permission: 'products.edit'
				}
			},
			{
				name: 'products.options.list',
				path: 'options',
				component: require("../views/Products/ProductOptions").default,
				i18n: 'menu_product_options',
				meta:{
					permission: 'products.options.list'
				}
			},
			{
				name: 'products.options.edit',
				path: 'options/edit/:id?',
				component: require("../views/Products/EditProductOption").default,
				i18n: 'menu_product_option_edit',
				meta:{
					permission: 'products.options.edit'
				}
			},
			{
				name: 'products.categories.list',
				path: 'categories',
				component: require("../views/Products/ProductCategories").default,
				i18n: 'menu_product_categories',
				meta:{
					permission: 'products.categories.list'
				}
			},
			{
				name: 'products.categories.edit',
				path: 'categories/edit/:id?',
				component: require("../views/Products/EditProductCategorie").default,
				i18n: 'menu_product_category_edit',
				meta:{
					permission: 'products.categories.edit'
				}
			}

		]
	},

	//LEADS
	{
		path: "/leads",
		component: () => import("../views/Layout"),
		i18n: 'menu_lead',
		show: true,
		children: [
			{
				path: "",
				component: Leads,
				name: 'leads.list',
				i18n: 'menu_lead',
				meta:{
					permission: 'leads.list'
				}
			},
			{
				path: "edit/:id?",
				component: EditLead,
				name: 'leads.edit',
				i18n: 'menu_lead_edit',
				meta:{
					permission: 'leads.edit'
				}
			}
		]
	},

	//Event
	{
		path: "/events",
		component: () => import("../views/Layout"),
		i18n: 'menu_event',
		show: true,
		children: [
			{
				path: "",
				component: Events,
				name: 'events.list',
				i18n: 'menu_event',
				meta:{
					permission: 'events.list'
				}
			},
			{
				path: "edit/:id?",
				component: EditEvent,
				name: 'events.edit',
				i18n: 'menu_event_edit',
				meta:{
					permission: 'events.edit'
				}
			}
		]
	},

	//Submission
	{
		path: "/submissions",
		i18n: 'menu_submission',
		component: () => import("../views/Layout"),
		show: true,
		children:[
			{
				path: "",
				component: Submission,
				name: 'submissions.list',
				i18n: 'menu_submission',
				meta:{
					permission: 'submissions.list'
				}
			},
			{
				path: "edit/client/:client",
				component: SoumissionRapide,
			},
			{
				path: "edit/:id?",
				component: SoumissionRapide,
				name: 'submissions.edit',
				i18n: 'menu_submission_edit',
				meta:{
					permission: 'submissions.edit'
				}
			}
		]
	},

	//Users
	{
		path: "/users",
		component: () => import("../views/Layout"),
		i18n: 'menu_user',
		show: true,
		children: [
			{
				name: 'users.list',
				path: "",
				component: require("../views/Users/Users").default,
				i18n: 'menu_user',
				meta:{
					permission: 'users.list'
				}
			},
			{
				path: "edit/:id?",
				name: "users.edit",
				component: require("../views/Users/EditUser").default,
				i18n: 'menu_user_edit',
				meta:{
					permission: 'users.edit'
				}
			},
			{
				name: 'users.categories.list',
				path: 'categories',
				component: require("../views/Users/UserCategories").default,
				i18n: 'menu_user_categorie',
				meta:{
					permission: 'users.categories.list'
				}
			},
			{
				name: 'users.categories.edit',
				path: 'categories/edit/:id?',
				component: require("../views/Users/EditUserCategorie").default,
				i18n: 'menu_user_categorie_edit',
				meta:{
					permission: 'users.categories.edit'
				}
			},
			{
				name: 'users.logs.list',
				path: 'logs',
				component: require("../views/Users/UserLogs").default,
				i18n: 'user_log',
				meta:{
					permission: 'users.logs.list'
				}
			}

		]
	},

	//PROFILE
	{
		path: '/mon-profil',
		name: 'profile',
		component: require("../views/Auth/MyProfile").default,
		i18n: 'menu_profile',
		show: true,
		meta:{
			permission: 'profile'
		}
	},

	//Mailchimp
	/*{
		path: '/mailchimp',
		name: 'mailchimp',
		component: require("../views/Mailchimp/Mailchimp").default,
		i18n: 'menu_mailchimp',
		show: true,
		children: [
			{
				name: 'mailchimp.lists',
				path: "lists",
				component: require("../views/Mailchimp/Lists").default,
				i18n: 'menu_mailchimp_lists',
				meta:{
					permission: 'mailchimp.lists'
				}
			}
		]
	},*/

	//SETTINGS
	{
		path: "/config",
		component: require("../views/Settings/Settings").default,
		i18n: 'menu_setting',
		show: true,
		children: [
			{
				name: 'settings.list',
				path: "",
				component: require("../views/Settings/Default").default,
				i18n: 'menu_setting_default',
				show: false,
				meta:{
					permission: 'settings.list'
				}
			},
			{
				name: 'settings.quotetypes',
				path: "quotetypes",
				component: require("../views/Settings/QuoteTypes").default,
				i18n: 'types_soumission',
				show: false,
				meta:{
					permission: 'settings.quotetypes'
				}
			},
			{
				name: 'settings.quotestatus',
				path: "quotestatus",
				component: require("../views/Settings/QuoteStatus").default,
				i18n: 'statuts_soumission',
				show: false,
				meta:{
					permission: 'settings.quotestatus'
				}
			},
			{
				name: 'settings.termes',
				path: "termes",
				component: require("../views/Settings/Termes").default,
				i18n: 'termes_conditions',
				show: false,
				meta:{
					permission: 'settings.termes'
				}
			},
			{
				name: 'settings.invoicemessages',
				path: "invoicemessages",
				component: require("../views/Settings/Messages").default,
				i18n: 'menu_setting_invoicemessages',
				show: false,
				meta:{
					permission: 'settings.invoicemessages'
				}
			},
			{
				name: 'settings.taxes.edit',
				path: 'taxes',
				i18n: 'menu_taxe',
				show: false,
				component: require("../views/Settings/Taxes").default,
				meta:{
					permission: 'settings.taxes.edit'
				}
			},
			{
				name: 'settings.shippingaddons.edit',
				path: 'shippingaddons',
				i18n: 'menu_shipping_addons',
				show: false,
				component: require("../views/Settings/ShippingAddons").default,
				meta:{
					permission: 'settings.shippingaddons.edit'
				}
			},
			{
				name: 'settings.shipping.edit',
				path: 'shipping',
				i18n: 'menu_shipping_carrier',
				show: false,
				component: require("../views/Settings/Shipping").default,
				meta:{
					permission: 'settings.shipping.edit'
				}
			},
			{
				name: 'settings.sync',
				path: 'import',
				i18n: 'menu_import',
				show: false,
				component: require("../views/Settings/Sync").default,
				meta:{
					permission: 'settings.sync'
				}
			},
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
