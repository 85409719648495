<template>
	<SingleLayout service="resellerContactCategories" :schema="schema" return-on-save="resellers.contact.categories.list">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('departement',2)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{$t('information')}}</span>
				</template>
				<template #info>
					<div class="p-field p-mt-4 p-col-12">
						<div class="p-float-label">
							<InputText id="name" type="text" v-model="item.name_fr" @change="update('$set','name_fr',$event.target.value)" placeholder="(fr)"/>
              <InputText id="name" type="text" v-model="item.name_en" @change="update('$set','name_en',$event.target.value)" class="mt-2" placeholder="(en)"/>
							<label>{{$t('nom')}}</label>
						</div>
					</div>
				</template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import SingleLayout from "../Layouts/SingleLayout";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";


export default {
	name: 'EditContactCategorie',
	components: {
		SingleLayout,
		FormRadium,
		FormInput,
		FormDialog,
		CalendarField,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
	setup(props, {emit}) {
		return {
			tabs: {
				info: true
			}
		};
	}
}
</script>
