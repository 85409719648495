<template>
  <PanelMenu :model="itemsComputed" />
</template>

<script>
import PanelMenu from 'primevue/panelmenu';
import {computed, reactive, ref} from 'vue';
import {useRouter} from "vue-router";
import {useStore} from "@/store";
import { useI18n } from "vue-i18n";
import {translatedFieldName} from "../../i18n";

const icons = require('@/helpers/icons').icons;

export default {
	name: "BigMenu",
	components: {
		PanelMenu
	},
  data(){
    return {
      items: []
    }
  },
	emits: ["home"],
	setup(props, {emit}) {
    return {};
	},
  methods:{
    createMenu(){
      const router = useRouter();
      const store = useStore();
      let menuItems = [];
      console.log(store.auth.user_info);

      router.options.routes.forEach(route => {
        const m = this._menu(route);
        if(m.label){
          menuItems.push(m);
        }
      });

      menuItems.push({});

      menuItems.push({
        label: this.$t("logout"),//i18n key
        icon: '',
        async command() {
          await store.radium.logout();
          store.auth.loggedIn.value = false;
          router.push({path:'/'});
        }
      });

      this.items = menuItems;

      console.log(menuItems);
      return menuItems;
    },
    _menu(item){//item = route
      const store = useStore();
      let hide = false;
      if(item.i18n&&item.show) {
        let args = {
          label: this.$t(item.i18n),//i18n key
          key: item.i18n,
          icon: item.meta?.icon,
          hide: false
        };
        if (item.path) {
          args.to = item;
        }

        if(item.meta?.permission){
          if(!store.permissions.has(item.meta.permission)){
            args.to = false;
            args.hide = true;
          }
        }

        if(item.children) {
          let children = [];
          item.children.forEach((child,child_i)=> {
            let m = this._menu(child);
            if(child.meta?.permission){
              if(!store.permissions.has(child.meta?.permission)){
                if(child.meta?.permission.match(/\.list/g)&&child_i===0){
                  console.log('no permission to access '+child.meta?.permission);
                  args.hide = true;
                }
                m = {};
              }
            }
            if(m.label){
              children.push(m);
            }
          });
          if(children.length){
            args.items = children;
            args.to = false;
          }
        }

        if((!args.items&&!args.to)||args.hide){
          return {};
        }

        return args;
      }
      return {};
    }
  },
  computed: {
    itemsComputed() {
      return this.items
    }
  },
  mounted(){
    this.createMenu();
  }
}
</script>

<style scoped>

</style>
