<template>
    <Toolbar class="p-mb-4">
      <template #left>
        <h1 style="margin: auto">Taxes</h1>
      </template>
      <template #right>
        <Button class="p-button-success p-mr-2" :label="$t('save')" icon="pi pi-save" @click="save" autofocus/>
        <Button class="p-button-secondary p-mr-2" :label="$t('back')" icon="pi pi-arrow-left" @click="$router.push({name:'settings.list'})" autofocus/>
      </template>
    </Toolbar>
  <DataTable :value="items" key="value">
    <Column field="value" header="ID">
      <template #body="{data}">
        <InputText id="value" type="hidden" v-model="data.value"/>
        {{ data.value }}
      </template>
    </Column>
    <Column field="label" header="Province">
      <template #body="{data}">
        <InputText id="label" type="hidden" v-model="data.label"/>
        {{ data.label }}
      </template>
    </Column>
    <Column field="tps" :header="$t('tps')+'%'">
      <template #body="{data}">
        <InputText id="tps" type="text" v-model="data.tps"/>
      </template>
    </Column>
    <Column field="tvq" :header="$t('tvq')+'%'">
      <template #body="{data}">
        <InputText id="tvq" type="text" v-model="data.tvq"/>
      </template>
    </Column>
    <Column field="tvh" :header="$t('tvh')+'%'">
      <template #body="{data}">
        <InputText id="tvh" type="text" v-model="data.tvh"/>
      </template>
    </Column>
  </DataTable>
  <Toolbar class="p-mt-4">
    <template #left>
      <slot name="toolbar-left"></slot>
    </template>

    <template #right>
      <Button class="p-px-3" :label="$t('save')" icon="pi pi-check" @click="save" autofocus/>
    </template>
  </Toolbar>
</template>

<script>

import DataTable from 'primevue/datatable';
import $router from "core-js/internals/internal-state";
import {CrudRepository} from "@radiumjs/client";
import {computed, defineComponent, reactive, ref, unref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useRadium} from "@radiumjs/vue";
import {provinces} from "../../helpers/provinces";

const db_key = 'taxes';
let db_id = '';


export default {
  name:"Taxes",
  emits: ["save", "update"],
  components: {
    DataTable,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
  },
  data() {
    return {
      items:[]
    }
  },
  setup(props, {emit, slots}){
    return {};
  },

  methods:{
    repo(){
      if(!this.radium){
        this.radium = useRadium();
      }
      return new CrudRepository(this.radium, 'setting');
    },
    async getTaxes(){
      const result = await this.repo().list({},{rows: 50,first: 0});
      const _db_items = result.items;
      const id = 'taxes';
      let items = [];
      for (let i = 0; i < _db_items.length; i++) {
        if(_db_items[i][id]){
          items = _db_items[i][id];
          db_id = _db_items[i]['_id'];
          break;
        }
      }
      if(items.length>0){
        this.items = items;
      }else{
        this.items = this.provinces();
      }
    },
    provinces(){
      return provinces;
    },
    save(){
      const id = db_key;
      let obj = {};
      obj[id] = this.items;
      if(db_id!==''){
        this.repo().update(db_id,{$set:obj});
      }else{
        const response = this.repo().create(obj);
        if(response.insertedId){
          db_id = response.insertedId;
        }
      }
    }
  },
  mounted() {
    this.getTaxes();
  }
}
</script>
