<template>
	<SingleLayout ref="form" service="submission" :schema="schema" backButtonRoute="submissions.list" @itemUpdated="itemUpdated" :after-save="afterSave">
		<template #toolbar-left="{item}">
			<h1 style="margin: auto">{{ $tc('submission',2) }} {{ quoteTitle() }}</h1>
		</template>
		<template #default="{save,item,errors,update}">
      <div class="alert alert-success" role="alert" v-if="item.sent">
        {{ $t('sent') }} {{ $filters.date(item.sent) }}
      </div>
      <div class="alert alert-success" role="alert" v-if="item.notify_sent">
      {{ $t('notification_sent_on') }} {{ $filters.date(item.notify_sent) }}
      </div>
      <div class="alert alert-danger" role="alert" v-else-if="item.notify_error">
        {{ $t('notification_error') }} {{ $filters.date(item.notify_error) }}
      </div>
      <div class="alert alert-info" role="alert" v-else-if="item.notify_date">
        {{ $t('notification_will_be_send_on') }} {{ item.notify_date }}
      </div>
			<form-radium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<span>{{ $t('client') }}</span>
				</template>
        <template #billing-header>
          <span>{{ $t('billing') }}</span>
        </template>
				<template #skus-header>
					<span>{{ $tc('product',2) }}</span>
				</template>
        <template #shipping-header>
          <span>{{ $t('shipping') }}</span>
        </template>
        <template #file-header>
          <span>{{ $tc('sketch',2) }}</span>
        </template>
        <template #submission-header>
          <span>{{ $tc('submission',1) }}</span>
        </template>
				
				<template #info>
          <!--<div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $t('information') }}</h2>
              </div>
              <div class="p-col-12">
                <div class="p-row">
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                        <Calendar :model-value="_DATE.format(item.created_at, true)" disabled dateFormat="yy-mm-dd" @date-select="updateDate(item, 'created_at', update, $event)"  />
                        <label>{{ $t('created_at') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                    <div class="p-row">
                      <div class="p-col-10">
                        <span class="p-float-label">
                          <InputText v-model="item.qt_nb" readonly @change="update('$set','qt_nb',$event.target.value)"/>
                          <label>{{ $t('soumission_nb') }}</label>
                        </span>
                      </div>
                      <div class="p-col-2">
                        <Button @click="updateSoumissionNumber(item,update,true)" icon="pi pi-refresh" />
                      </div>
                    </div>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                        <Calendar v-model="item.expired_at" _DATE.format="yy-mm-dd" @date-select="updateDate(item, 'expired_at', update, $event)"  />
                        <label>{{ $t('expired_at') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.nom_cs" @change="update('$set','nom_cs',$event.target.value);updateSoumissionNumber(item, update)"/>
                        <label>{{ $t('nom_cs') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.nom_rep" @change="update('$set','nom_rep',$event.target.value)"/>
                        <label>{{ $t('nom_rep') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.nom_projet" @change="update('$set','nom_projet',$event.target.value)"/>
                        <label>{{ $t('nom_projet') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.delai" @change="update('$set','delai',$event.target.value)"  />
                        <label>{{ $t('delai_production') }} : </label>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                      <Dropdown type="text" v-model="item.quotestatus" :options="quotestatus" optionLabel="description_fr" optionValue="sku" :showClear="true" @change="update('$set','quotestatus',$event.value);"></Dropdown>
                      <label>{{ $t('statut_soumission') }}</label>
                      </span>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
              <div class="p-row">
                <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <Dropdown type="text" v-model="item.for" :options="forOptions" optionLabel="name" optionValue="code" :showClear="true" @change="update('$set','for',$event.value);setFor()" ></Dropdown>
                        <label>{{ $t('submission_for') }}</label>
                      </span>
                </div>
              </div>
          </div>
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row" v-if="$store.permissions.isAdmin()&&item.for">
            <div class="p-row" v-if="item.for==='reseller'">
              <div class="p-col-12">
                <h2>{{ $tc('distributeur',1) }}</h2>
              </div>
              <div class="p-col-12 p-mt-5">
                  <span class="p-float-label">
                    <Dropdown type="text" v-model="item.reseller_id" :options="resellers" optionLabel="customer_name" optionValue="_id" :filter="true" :filterPlaceholder="$t('search')" :showClear="true" @change="selectReseller($event.value,item,update)"></Dropdown>
                    <label>{{ $tc('distributeur',1) }}</label>
                  </span>
              </div>
              <div class="p-row" v-if="item.reseller_id">
                <div class="p-col-12 p-mt-5">
                  <span class="p-float-label">
                      <Dropdown type="text" v-model="item.shipping_id" :options="shippings" optionLabel="shipto_city" optionValue="_id" :filter="true" :filterPlaceholder="$t('search')" :showClear="true" @change="selectShipping($event.value,item,update)">
                        <template #option="slotProps">
                          {{ slotProps.option.shipto_location_name }} - {{ slotProps.option.shipto_city }}
                        </template>
                      </Dropdown>
                      <label>{{ $tc('succursale',1) }}</label>
                  </span>
                </div>
                <div class="p-col-12 p-mt-5">
                    <span class="p-float-label">
                          <InputText type="text" v-model="item.nom_contact" @change="update('$set','nom_contact',$event.target.value)"/>
                          <label>{{ $t('nom_contact') }} : </label>
                        </span>
                </div>
              </div>
            </div>
            <div :class="'p-row'+(item.for==='reseller'?' mt-5':'')" v-if="item.for!=='custom'">
              <div class="p-col-12">
                <h2>{{ $tc('enduser',1) }}</h2>
              </div>
              <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <Dropdown type="text" v-model="item.client_id" :options="clients" optionLabel="name" optionValue="_id" :filter="true" :filterPlaceholder="$t('search')" :showClear="true" @filter="searchClient($event)" @change="selectClient($event.value,item,update)" ></Dropdown>
                        <label>{{ $tc('enduser',1) }}</label>
                      </span>
              </div>
              <div class="p-col-12 p-mt-5 field-checkbox" v-if="client_id">
                <Checkbox :binary="true" :value="1" v-model="item.billed_to" :checked="billed_to" class="p-mr-2" @update:model-value="updateBilledTo($event)" /> {{$t('billing_to')}}
              </div>
            </div>
            <div class="p-row" v-if="item.for==='custom'">
              <div class="p-col-12">
                <h2>{{ $tc('client',1) }}</h2>
              </div>
              <div class="p-col-12 p-mt-5">
                        <span class="p-float-label">
                          <Dropdown type="text" v-model="item.client_type" :options="clientTypes" optionLabel="name" optionValue="code" :showClear="true" @change="update('$set','client_type',$event.value);" ></Dropdown>
                          <label>{{ $t('client_type') }}</label>
                        </span>
              </div>
              <div class="p-col-12 p-mt-5">
                  <span class="p-float-label">
                    <InputText type="text" :model-value="item.client?.contact" @change="updateClient('contact',$event.target.value)"/>
                    <label>{{ $t('nom') }}</label>
                  </span>
              </div>
              <div class="p-col-12 p-mt-5">
                  <span class="p-float-label">
                    <InputText type="text" :model-value="item.client?.name" @change="updateClient('name',$event.target.value)"/>
                    <label>{{ $t('entreprise') }}</label>
                  </span>
              </div>
              <div class="p-col-12 p-mt-5">
                  <span class="p-float-label">
                    <InputText type="text" :model-value="item.client?.email" @change="updateClient('email',$event.target.value)"/>
                    <label>{{ $t('email') }}</label>
                  </span>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row" v-if="$store.permissions.isAdmin()&&item.for">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $t('account') }}</h2>
              </div>
              <FormInput class="p-col-12 p-md-6 p-mt-5" :errors="errors.account_nb" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.account_nb" @update:modelValue="update('$set','account_nb',$event)" :class="classes"/>
                      <label>{{ $t('account_nb') }}</label>
                    </span>
              </FormInput>
              <FormInput class="p-col-12 p-md-6 p-mt-5" :errors="errors.discount" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.discount" @update:modelValue="update('$set','discount',$event)" :class="classes"/>
                      <label>{{ $tc('escompte',1) }}</label>
                    </span>
              </FormInput>
            </div>
          </div>
				</template>

        <template #billing>

          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $tc('billing',1) }}</h2>
              </div>
              <div class="p-col-12" v-if="item.for">
                <div class="p-row">
                  <div class="p-col-12 p-mt-5">
                        <span class="p-float-label">
                        <InputText type="text" :model-value="item.billing?.address_1" @change="update('$set','billing.address_1',$event.target.value)"/>
                        <label>{{ $t('adresse_ligne_1') }}</label>
                        </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                        <span class="p-float-label">
                        <InputText type="text" :model-value="item.billing?.address_2" @change="update('$set','billing.address_2',$event.target.value)"/>
                        <label>{{ $t('adresse_ligne_2') }}</label>
                        </span>
                  </div>
                </div>
                <div class="p-row">
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.billing?.city" @change="update('$set','billing.city',$event.target.value)"/>
                      <label>{{ $t('ville') }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <Dropdown type="text" :model-value="item.billing?.province" :options="provinces" optionLabel="label" optionValue="value" @change="update('$set','billing.province',$event.value)"></Dropdown>
                      <label>{{ $tc('province',1) }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.billing?.postal_code" @change="update('$set','billing.postal_code',$event.target.value)"/>
                      <label>{{ $t('code_postal') }}</label>
                      </span>
                  </div>
                </div>
                <div class="p-row">
                  <div class="p-col-12 p-mt-5">
                    <span class="p-float-label">
                        <InputText type="text" :model-value="item.billing?.telephone" @change="update('$set','billing.telephone',$event.target.value)"/>
                        <label>{{ $t('telephone') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                    <span class="p-float-label">
                        <InputText type="text" :model-value="item.billing?.email" @change="update('$set','billing.email',$event.target.value)"/>
                        <label>{{ $t('email') }} : </label>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
				
				<template #skus>
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                        <Calendar :model-value="dateFormat(item.expired_at)" dateFormat="yy-mm-dd" @date-select="updateDate('expired_at', $event)"  />
                        <label>{{ $t('expired_at') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                          <span class="p-float-label">
                            <Calendar :model-value="dateFormat(item.created_at)" disabled dateFormat="yy-mm-dd" @date-select="updateDate( 'created_at', $event)"  />
                            <label>{{ $t('created_at') }} : </label>
                          </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                      <Dropdown type="text" v-model="item.quotetype" :options="quotetypes" optionLabel="description_fr" optionValue="sku" :showClear="true" @change="update('$set','quotetype',$event.value);updateRappel();"></Dropdown>
                      <label>{{ $t('type_soumission') }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                          <span class="p-float-label">
                            <InputText v-model="item.qt_nb" readonly @change="update('$set','qt_nb',$event.target.value)"/>
                            <label>{{ $t('soumission_nb') }}</label>
                          </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <!--<InputText type="text" v-model="item.nom_cs" @change="update('$set','nom_cs',$event.target.value);updateSoumissionNumber(item, update)"/>-->
                        <Dropdown type="text" v-model="item.cs_id" :options="users" optionLabel="username" optionValue="_id" :showClear="true" @change="update('$set','cs_id',$event.value);updateCS($event.value)"></Dropdown>
                        <label>{{ $t('nom_cs') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                          <span class="p-float-label">
                            <!--<InputText type="text" v-model="item.nom_cs" @change="update('$set','nom_cs',$event.target.value);updateSoumissionNumber(item, update)"/>-->
                            <Dropdown type="text" v-model="item.nom_rep" :options="users" optionLabel="username" optionValue="_id" :showClear="true" @change="update('$set','nom_rep',$event.value);updateRep($event.value)"></Dropdown>
                            <label>{{ $t('nom_rep') }} : </label>
                          </span>
                  </div>
                  <!--<div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.nom_rep" @change="update('$set','nom_rep',$event.target.value)"/>
                        <label>{{ $t('nom_rep') }} : </label>
                      </span>
                  </div>-->
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.nom_projet" @change="update('$set','nom_projet',$event.target.value)"/>
                        <label>{{ $t('nom_projet') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-mt-5">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.delai" @change="update('$set','delai',$event.target.value)"  />
                        <label>{{ $t('delai_production_jour') }} : </label>
                      </span>
                  </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $tc('product',2) }}</h2>
              </div>
              <div class="p-col-12">
					    <SoumissionRapideProduits :item="item" v-model="item.products" @update:model-value="updateProducts(update,$event)" @update:item="updateItem(update,$event)"></SoumissionRapideProduits>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $t('summary') }}</h2>
              </div>
              <div class="p-col-12">
					      <Sommaire :model-value="item" :taxes="taxes" v-if="taxes"></Sommaire>
              </div>
            </div>
          </div>
				</template>

        <template #shipping>
          <div class="p-fluid p-formgrid p-grid p-mb-5 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $tc('shipping',1) }}</h2>
              </div>
              <!--<div class="p-inputtext">
                <div class="p-row">
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                        <Dropdown type="text" v-model="item.shipping_type" :options="addressTypes" optionLabel="name" optionValue="code" :filterPlaceholder="$t('search')" :showClear="true" @change="update('$set','shipping_type',$event.value);selectShippingType(item, update)" ></Dropdown>
                        <label>{{ $t('shipped_to') }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5" v-if="item.shipping_type==='client'&&!client">
                    <div class="alert alert-danger">{{ $t('message_need_client') }}</div>
                  </div>
                  <div class="p-col-12 p-mt-5" v-if="item.shipping_type==='pos'&&!shipping">
                    <div class="alert alert-danger">{{ $t('message_need_pos') }}</div>
                  </div>
                  <div class="p-col-12 p-mt-5" v-if="item.shipping_type==='reseller'&&!reseller">
                    <div class="alert alert-danger">{{ $t('message_need_reseller') }}</div>
                  </div>
                </div>
              </div>-->
              <div class="p-col-12" v-if="item.for">
                <div class="p-row">
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.shipping?.address_1" @change="_update('shipping.address_1',$event.target.value,true)"/>
                      <label>{{ $t('adresse_ligne_1') }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.shipping?.address_2" @change="update('$set','shipping.address_2',$event.target.value)"/>
                      <label>{{ $t('adresse_ligne_2') }}</label>
                      </span>
                  </div>
                </div>
                <div class="p-row">
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.shipping?.city" @change="update('$set','shipping.city',$event.target.value)"/>
                      <label>{{ $t('ville') }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <Dropdown type="text" :model-value="item.shipping?.province" :options="provinces" optionLabel="label" optionValue="value" @change="update('$set','shipping.province',$event.value)"></Dropdown>
                      <label>{{ $tc('province',1) }}</label>
                      </span>
                  </div>
                  <div class="p-col-12 p-md-6 p-lg-4 p-mt-5">
                      <span class="p-float-label">
                      <InputText type="text" :model-value="item.shipping?.postal_code" @change="update('$set','shipping.postal_code',$event.target.value)"/>
                      <label>{{ $t('code_postal') }}</label>
                      </span>
                  </div>
                </div>
                <div class="p-row">
                  <div class="p-col-12 p-mt-5">
                    <span class="p-float-label">
                        <InputText type="text" :model-value="item.shipping?.email" @change="update('$set','shipping.email',$event.target.value)"/>
                        <label>{{ $t('email') }} : </label>
                      </span>
                  </div>
                  <div class="p-col-12 p-mt-5">
                    <span class="p-float-label">
                        <InputText type="text" :model-value="item.shipping?.telephone" @change="update('$set','shipping.telephone',$event.target.value)"/>
                        <label>{{ $t('telephone') }} : </label>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="carriers" class="p-col-12 p-inputtext p-inputtext-row">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $t('shipping') }} - {{ $tc('shipping_carrier',1) }}</h2>
              </div>
              <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-mt-5">
                      <span class="p-field p-float-label">
                      <Dropdown type="text" :model-value="item.carrier_id" :options="carriers" optionLabel="description_fr" optionValue="sku" :showClear="true" @change="selectCarrier($event.value,item)"></Dropdown>
                      <label>{{ $t('shipping') }} - {{ $tc('shipping_carrier',1) }}</label>
                      </span>
              </div>
              <div class="p-col-12 p-md-6 p-lg-4 p-xl-3 p-mt-5 p-mr-auto">
                      <span class="p-field p-float-label">
                      <InputText type="text" :model-value="item.carrier?.price" @change="_update('carrier.price',$event.target.value,true)"/>
                      <label>{{ $t('price') }}</label>
                      </span>
              </div>
            </div>
          </div>
          <div v-if="shippingAddons" class="p-col-12 p-inputtext p-inputtext-row p-mt-3">
            <div class="p-row" v-if="item.shippingaddons">
              <div class="p-col-12">
                <h2>{{ $t('shipping') }} - {{ $tc('shipping_addons',2) }}</h2>
              </div>
            </div>
            <div class="p-row" v-if="item.shippingaddons">
              <DataTable :value="item.shippingaddons" class="w-100 p-mb-3">
                <Column :header="$tc('shipping_addons',1)">
                  <template #body="{data,index}">
                    <Dropdown type="text" :model-value="data.sku" :options="shippingAddons" :optionLabel="translatedFieldName('description')" optionValue="sku" @change="updateShippingAddons($event.value,index,item,update)" class="w-100"></Dropdown>
                    <span v-if="data.contact&&(!item.nom_contact||!item.main_phone)">
                      <small class="text-danger">{{ $t('message_need_contact') }}</small>
                    </span>
                  </template>
                </Column>
                <Column :header="$t('price')">
                  <template #body="{data}">
                    {{ $money(data.prix) }}
                  </template>
                </Column>
                <Column header="">
                  <template #body="{data,index}">
                    <span class="p-button p-button-danger p-d-inline" @click="destroyShippingAddons(index,item)"><i class="pi pi-trash"></i></span>
                  </template>
                </Column>
              </DataTable>
            </div>
            <div class="p-row p-inputtext ">
              <div class="p-row">
                <div class="p-col-auto">
                  <Button @click="addShippingAddons(item)" class="p-button p-component p-button-secondary">{{ $t('add')}} - {{ $tc('shipping_addons',1) }}</Button>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <Sommaire :model-value="item" :taxes="taxes" v-if="taxes"></Sommaire>
        </template>

        <template #file>
          <div class="p-field" v-if="item.files">
            <div class="p-row p-inputtext p-inputtext-row">
              <div class="p-col-12 p-row">
                <h2>{{ $tc('sketch',2) }}</h2>
              </div>
              <div v-for="(file, key) in item.files" class="p-col-12 p-row p-align-center p-border-b">
                <div class="p-field p-col-3">
                  <span class="p-float-label">
                    {{ file.realname }}
                    <label>{{ $tc('file',2) }} : </label>
                   </span>
                </div>
                <div class="p-field p-col-3">
                      <span class="p-float-label">
                        <InputText type="text" v-model="item.files[key]['titre_fr']" placeholder="Titre (fr)" @change="updateFiles(item, update)"/>
                        <InputText type="text" v-model="item.files[key]['titre_en']" placeholder="Titre (en)" @change="updateFiles(item, update)"/>
                        <label>{{ $t('nom') }} : </label>
                      </span>
                </div>
                <div class="p-field p-col-3">
                  <span class="p-button p-button-danger p-d-inline" @click="removeFile( key, item, update)"><i class="pi pi-trash"></i></span>
                </div>
              </div>
            </div>
          </div>

          <Upload upload_dir="soumissions" @update:modelValue="addFiles(item,update,$event)"></Upload>
        </template>
        <template #submission>
            <SoumissionInvoice :modelValue="item" :taxes="taxes" :shippingAddons="shippingAddons" @sent="_update('sent',$event)"></SoumissionInvoice>
        </template>
        <!--<template #tag>
          <div class="p-col-12 p-inputtext p-mt-3">
            <div class="p-row">
              <div class="p-col-12">
                <h2>{{ $tc('tag',2) }}</h2>
              </div>
            </div>
            <div class="p-row" v-if="item.tags">
              <DataTable :value="item.tags" class="w-100 p-mb-3">
                <Column>
                  <template #header="{data}">
                    {{ $tc('tag',1) }}
                  </template>
                  <template #body="{data,index}">
                    <InputText type="text" v-model="item.tags[index]" @change="updateTag($event.target.value,index,item,update)"/>
                  </template>
                </Column>
                <Column header="">
                  <template #body="{data,index}">
                    <span class="p-button p-button-danger p-d-inline" @click="destroyTag(index,item,update)"><i class="pi pi-trash"></i></span>
                  </template>
                </Column>
              </DataTable>
            </div>
            <div class="p-row p-inputtext ">
              <div class="p-row">
                <div class="p-col-auto">
                  <Button @click="addTag(item)" class="p-button p-component p-button-secondary">{{ $t('add')}}</Button>
                </div>
              </div>
            </div>
          </div>
        </template>-->
			</form-radium>
		</template>
	</SingleLayout>
</template>

<script>
import SkuSelector from "@/components/Sku/SkuSelector";
import {useStore} from "@/store";
import {useConfirm} from "primevue/useconfirm";
import {useRadium} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import SoumissionInvoice from "./SoumissionInvoice";
import SoumissionRapideAdd from "./SoumissionRapideAdd";
import SoumissionRapideProduits from "./SoumissionRapideProduits";
import FormInput from "@/components/FormPrime/FormInput";
import Sommaire from "@/components/SoumissionRapide/Sommaire";
import {CrudRepository} from "@radiumjs/client";
import {provinces} from "../../helpers/provinces";
import {_DATE,dateFormat} from "../../helpers/dateLocal";
import Calendar from 'primevue/calendar';
import moment from 'moment';
import {default_taxes} from "../../helpers/taxes";
import Upload from "@/components/Upload/Upload.vue";
import {computed,reactive,ref,onMounted} from "vue";
import {_GET} from "../../helpers/get";
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";

import SingleLayout from "../Layouts/SingleLayout";

export default {
	name: "SoumissionRapide",
	components: {
    Sommaire,
		FormInput,
		SoumissionRapideProduits,
		SoumissionRapideAdd,
    SoumissionInvoice,
		SkuSelector,
		SingleLayout,
		FormRadium,
    moment,
    Upload,
		DataTable: require("primevue/datatable").default,
		DataView: require("primevue/dataview").default,
		Galleria: require("primevue/galleria").default,
		DataViewLayoutOptions: require("primevue/dataviewlayoutoptions").default,
		Column: require("primevue/column").default,
		Steps: require("primevue/steps").default,
    Dropdown: require("primevue/dropdown").default,
		Toolbar: require("primevue/toolbar").default,
		Rating: require("primevue/rating").default,
		Button: require("primevue/button").default,
		Sidebar: require("primevue/sidebar").default,
		InputText: require("primevue/inputtext").default,
    Checkbox: require('primevue/checkbox').default,
    Calendar,
    _DATE,
    _GET
	},
  data() {
    return {
      settings:[],
      resellers:[],
      shippings:[],
      clients:[],
      users:[],
      provinces:provinces,
      taxes:{
        value:'QC',
      },
      carriers:[],
      shippingAddons:[],
      selectedShippingAddons:[],
      selectedShippingAddress:null,
      quotetypes: [],
      quotestatus: [],

      reseller: {},
      shipping: {},
      client: {},
      cs: {},
      cs_id: null,
      rep: {},
      rep_id: null,
      carrier: {},
      carrier_id: null,
      isInit: false
    }
  },
	setup() {
    const i18n = useI18n();
    const store = useStore();
		const radium = store.radium;
		const confirm = useConfirm();
    const route = useRoute();
    const message_discount = computed(() => {
      return $t('message_discount');
    });

    const toUpdate = route.params.id || false;
    const client_id = route.params.client || false;
    const reseller_id = store.permissions.isReseller() ? store.auth.user_info.value.id : null ;
    const shipping_id = null ;

    console.log('client_id : '+client_id);

    _GET.setProvider(radium);

    const single = ref({});

    const addressTypes = ref([
      {
        name: i18n.t('distributeur'),
        code: 'reseller',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('succursale'),
        code: 'pos',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('enduser'),
        code: 'client',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('custom'),
        code: 'custom',
        css: 'badge badge-info'
      }
    ]);

    const forOptions = ref([
      {
        name: i18n.t('distributeur'),
        code: 'reseller',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('enduser'),
        code: 'client',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('custom'),
        code: 'custom',
        css: 'badge badge-info'
      }
    ]);

    const clientTypes = ref([
      {
        name: i18n.t('distributeur'),
        code: 'reseller',
        css: 'badge badge-info'
      },
      {
        name: i18n.t('enduser'),
        code: 'client',
        css: 'badge badge-info'
      }
    ]);

		return {
      toUpdate,
      client_id,
      reseller_id,
      shipping_id,
      single,
      addressTypes,
      forOptions,
      clientTypes,
      billed_to:true,
			tabs: {
				info: true,//custom
        billing: true,
				skus: true,//custom
        shipping: true,
        file: true,//custom
        submission: true,//custom
				notable: true,//preMade
				timeData: true,//preMade
			},
			schema: {
				discount: {
					type: "custom", check(value, errors, schema) {
						if (typeof value !== "string" || value.length <= 0)
							errors.push({type: "discount"});
						else {
							const values = value.split("/");
							for (const v of values) {
								const i = parseFloat(v);
								if (i.toString(10) !== v || i < 0 || i > 100)
									errors.push({type: "discount"});
							}
						}
						return value;
					},
					messages: {discount: message_discount},
          optional: true
				},
        billing_type:{type:"string",optional: true,default:'reseller'},
        shipping_type:{type:"string",optional: true,default:'reseller'}
			}
		};
	},
  methods:{
    init(){
      if(this.single.value.client_id){
        this.client_id = this.single.value.client_id;
        if(!this.single.value.client){
          this.getClient();
        }else{
          this.client = this.single.value.client;
        }
      }
      if(this.single.value.shipping_id){
        this.shipping_id = this.single.value.shipping_id;
        if(!this.single.value.shipping){
          this.getShipping();
        }else{
          this.shipping = this.single.value.shipping;
        }
      }
      if(this.single.value.reseller_id){
        this.reseller_id = this.single.value.reseller_id;
        if(!this.single.value.reseller){
          this.getReseller();
        }else{
          this.reseller = this.single.value.reseller;
        }
        this.getShippings(this.single.value.reseller_id);
      }

      this.getClients();

      if(Object.keys(this.single.value).includes('billed_to')){
         this.billed_to = this.single.value.billed_to;
      }
      console.log('billed_to', this.billed_to);
      this.isInit = true;
    },
    async afterSave(event){
      console.log('afterSave');
      console.log(event);
      if(event.id){
        if(!this.single?.value?._id){
          this.single.value._id = event.id;
        }
        console.log(this.single);
        await this.quoteNumber();
      }
      return true;
    },
    async quoteNumber(){
      if(this.single.value?.qt_nb){
        return;
      }
      await _GET.all('setting').then(response => {
        const settings = response;

        let qt = 0;

        const setting = settings.filter(item => Object.keys(item).includes('quotenumber'));
        if(setting.length==1){
          let setting_id = setting[0]['_id'];
          qt = parseInt(setting[0]['quotenumber']);
          if(isNaN(qt)){
            qt = 0;
          }
          qt++;
          let nb = ('000000')+qt;
          nb = nb.substring(qt.toString().length);
          let qt_nb = 'QT'+nb;
          console.log(qt_nb);
          this._update('qt_nb', qt_nb,true);

          _GET.update('submission',this.single.value?._id,'qt_nb',qt_nb);
          _GET.update('setting',setting_id,'quotenumber',qt);
        }else{
          qt++;
          let nb = ('000000')+qt;
          nb = nb.substring(qt.toString().length);
          let qt_nb = 'QT'+nb;
          console.log(qt_nb);
          this._update('qt_nb', qt_nb,true);
          _GET.create('setting','quotenumber',qt);
        }
      });
    },
    billing_name(){
      if(this.single.value?.for){
        const type = this.single.value.for;

        if(this.billed_to&&this.client){
          return this.client.name;
        }

        if(type==='reseller'&&this.reseller){
          return this.reseller.customer_name;
        }else if((type==='pos'||type==='reseller')&&this.shipping){
          return this.shipping.shipto_location_name;
        }else if(type==='client'&&this.client){
          return this.client.name;
        }else if(type==='custom'&&this.client){
          return this.client.name;
        }
      }
      return '';
    },
    shipping_name(){
      if(this.single.value?.for){
        const type = this.single.value.for;
        if((type==='pos'||type==='reseller')&&this.shipping){
          return this.shipping.shipto_location_name;
        }else if(type==='reseller'&&this.reseller){
          return this.reseller.customer_name;
        }else if(type==='client'&&this.client){
          return this.client.name;
        }else if(type==='custom'&&this.client){
          return this.client.name;
        }
      }
      return '';
    },
    itemUpdated(item){
      console.log(item);
      this.single.value = item;

      if(!this.isInit){
        this.init();
      }
    },
    updateItem(update, event){
      console.log('event');
      console.log(event);
      //this._update(event.key,event.val,true);
    },
    updateProducts(update, event){
      console.log('event');
      console.log(event);
      update('$set','products',event);
      update('$set','unit', localStorage.unit);
    },
    updateInvoice(update, event = []){
      console.log('updateInvoice');
      console.log(event);
      event.forEach(el => {
        update('$set',el.key,el.value);
      });
    },
    repo(service){
      if(!this.radium){
        this.radium = useRadium();
      }
      return new CrudRepository(this.radium, service);
    },

    setFor(){
      let type = this.single?.value?.for;
      if(type==='custom'){
        this.resetReseller();
        this.resetClient();
      }else if(type==='reseller'){
        this.resetClient();
        this.clients = [];
      }else if(type==='client'){
        this.resetReseller();
        this.getClients();
      }
      this.resetShipping();
      this.resetBilling();
    },



    /* reseller */
    resetReseller(){
      if(this.$store.permissions.isAdmin()){
        this.reseller = {};
        this.reseller_id = null;
        this._update('reseller',this.reseller,true);
        this._update('reseller_id',this.reseller_id,true);
      }
    },
    async getResellers(){
      await _GET.all('reseller').then(response => {
        this.resellers = response;
      });
    },
    async getReseller(){
      console.log('getReseller '+this.reseller_id+' ('+this.resellers.length+')');
      const f = this.resellers.filter(item => {
        return item._id===this.reseller_id;
      });
      if(f.length===1){
        this.reseller = f[0];
        this._update('reseller',this.reseller,true);
      }else if(f.length===0){
        await _GET.get('reseller',this.reseller_id).then(response => {
            this.reseller = response;
            this._update('reseller',this.reseller,true);
        });
      }
      this.setBilling();
      return {};
    },
    async selectReseller(val, item, update){
      this.resetShipping();
      this.reseller_id = item.reseller_id;
      this._update('reseller_id',this.reseller_id,true);
      this.getReseller();
      this.searchClient({});
      this.getShippings();
      this.setBilling();
      //this._updateBillingFromReseller(datas, item, update);
      //this._updateFieldsFromReseller(datas, item, update);
    },
    
    //shipping
    resetShipping(){
      this.shipping = {};
      this.shipping_id = null;
      this._update('shipping', this.shipping,true);
      this._update('shipping_id', this.shipping_id,true);
    },
    async getShippings(){
      this.shippings = [];
      if(this.reseller_id){
        await _GET.all('resellerShipping',{},{parent:{value: this.reseller_id, matchMode: FilterMatchMode.EQUALS}}).then(response => {
          this.shippings = response;
        });
      }
    },
    async getShipping(){
      console.log('getShipping '+this.shipping_id+' ('+this.shippings.length+')');
      const f = this.shippings.filter(item => {
        return item._id===this.shipping_id;
      });
      if(f.length===1){
        this.shipping = f[0];
        this._update('shipping',this.shipping,true);
      }else if(f.length===0){
        await _GET.get('shipping',this.shipping_id).then(response => {
          this.shipping = response;
          this._update('shipping',this.shipping,true);
        });
      }
      this.setShipping();
      return {};
    },
    async selectShipping(val, item, update){
      this.resetShipping();
      this.shipping_id = val;
      this._update('shipping_id',this.shipping_id,true);

      this.getShipping();
      this.searchClient({});
    },

    // client / enduser
    resetClient(){
      this.client = {};
      this.client_id = null;
      this.billed_to = false;
      this._update('client', this.client,true);
      this._update('client_id', this.client_id,true);
      this._update('billed_to', this.billed_to,true);
    },
    async getClients(){
      this.searchClient({});
    },
    async getClient(){
      console.log('getClient');
      const f = this.clients.filter(item => {
        return item._id===this.client_id;
      });
      if(f.length===1){
        this.client = f[0];
        this._update('client',this.client,true);
      }else if(f.length===0){
        await _GET.get('client',this.client_id).then(response => {
          this.client = response;
          this._update('client',this.client,true);
        });
      }
      this.setBilling();
      return {};
    },
    async searchClient(event){
      this.clients = [];
      const f = {};

      if(this.$store.permissions.isAdmin()&&!this.reseller_id){
          this.searchClientAll(event);
      }else{
        if(event.value&&event.value.length>2){
          f['name'] = {value: event.value, matchMode: FilterMatchMode.CONTAINS};
        }
        if(this.$store.permissions.isReseller()){
          f['author.parent'] = {value: this.$store.auth.user_info.value.id, matchMode: FilterMatchMode.EQUALS};
        }else if(this.single.value?.reseller_id){
          f['author.parent'] = {value: this.single.value.reseller_id, matchMode: FilterMatchMode.EQUALS};
        }
        if(this.single.value?.shipping_id){
          f['author.id'] = {value: this.single.value.shipping_id, matchMode: FilterMatchMode.EQUALS};
        }
        if(f){
          await _GET.all('client',{},f).then(response => {
            this.clients = response;
          });
        }
      }
    },
    async searchClientAll(event){
      await _GET.all('client').then(response => {
        this.clients = response;
      });
    },
    async selectClient(val, item, update){
      this.resetClient();
      this.client_id = val;
      await this.getClient();
      this._update('client_id',this.client_id,true);
    },
    updateClient(key, val){
      this.client[key] = val;
      this._update('client',this.client,true);
    },
    updateBilledTo(event){
      console.log(event);
      this.billed_to = event;
      this._update('billed_to',this.billed_to,true);

      this.setBilling();
      //this.resetShipping();
      this.setShipping();
    },
    
    
    updateBillingFromReseller(response,item,update) {
      //reset
      item.billing = {};
      const toupdate = ['address_1','address_2','address_3','address_4','city','province','postal_code'];
      for(let i in toupdate){
        if(typeof(this.reseller['customer_billing_'+toupdate[i]])!="undefined"){
          item.billing[toupdate[i]] = this.reseller['customer_billing_'+toupdate[i]];
        }
      }
      this._update('billing',item.billing);
      this._updateProvince(item,update);
    },
    updateFieldsFromReseller(response,item,update) {
      const toupdate = [
        {from:'discount',to:'discount'},
        {from:'account_nb',to:'account_nb'},
        {from:'customer_invoice_email',to:'main_email'},
        {from:'customer_billing_telephone',to:'main_phone'}
      ];
      toupdate.forEach(row => {
        if(typeof(response[row.from])!="undefined"){
          item[row.to] = response[row.from];
          update('$set',row.to,response[row.from]);
        }
      });
      /*for(let i in toupdate){
        if(typeof(response[toupdate[i]])!="undefined"){
          item[toupdate[i]] = response[toupdate[i]];
          update('$set',toupdate[i],response[toupdate[i]]);
        }
      }*/
    },
    updateProvince(item,update) {
     /* console.log('updateProvince');
      console.log(item.billing);
      if(item.billing&&item.billing.province&&this.taxes){
        const taxe = this.taxes.filter(i => i.value===item.billing.province);
        if(taxe&&taxe[0]){
          update('$set','taxes',taxe[0]);
        }
      }else{
        update('$set','taxes', {});
      }*/
    },
    updateBilling(key,val,item,update) {
      if(!item.billing){
        item.billing = {};
      }
      item.billing[key] = val;
      update('$set','billing',item.billing);
      this._updateProvince(item,update);
    },
    resetBilling(){
      this._update('billing', {},true);
    },
    setBilling(){
      //const type = this.single.value.billing_type;
      const type = this.single.value.for;
      console.log('setBilling '+type);

      const billing = {};
      if(type!=='custom'){
        let from = {};
        if(type==='reseller'&&this.reseller){
          from = this.reseller;
        }else if(type==='pos'&&this.shipping){
          from = this.shipping;
        }else if(type==='client'&&this.client){
          from = this.client;
        }

        //force client
        if(this.billed_to){
          from = this.client;
        }

        const toupdate = ['address_1','address_2','address_3','address_4','city','province','postal_code','telephone','email'];
        for(let i in toupdate){
          if(typeof(from['customer_billing_'+toupdate[i]])!="undefined"){
            billing[toupdate[i]] = from['customer_billing_'+toupdate[i]];
          }else if(typeof(from['billing_'+toupdate[i]])!="undefined"){
            billing[toupdate[i]] = from['billing_'+toupdate[i]];
          }else if(type==='pos'&&typeof(from['shipto_'+toupdate[i]])!="undefined"){
            billing[toupdate[i]] = from['shipto_'+toupdate[i]];
          }else if(typeof(from[toupdate[i]])!="undefined"){
            billing[toupdate[i]] = from[toupdate[i]];
          }
        }

        if(billing){
          billing.name = this.billing_name();
        }
      }
      this._update('billing',billing,true);
    },
    selectBillingType(){
        this.setBilling();
    },
    setShipping(){
      //const type = this.single.value.shipping_type;
      const type = this.single.value.for;
      console.log('setShipping '+type);

      const shipping = {};
      if(type!=='custom'){
        let from = {};
        if((type==='pos'||type==='reseller')&&this.shipping){
          from = this.shipping;
        }else if(type==='reseller'&&this.reseller){
          from = this.reseller;
        }else if(type==='client'&&this.client){
          from = this.client;
        }

        //force client
        if(this.billed_to){
          from = this.client;
        }

        const toupdate = ['address_1','address_2','address_3','address_4','city','province','postal_code','telephone','email'];
        for(let i in toupdate){
          if(typeof(from['customer_billing_'+toupdate[i]])!="undefined"){
            shipping[toupdate[i]] = from['customer_billing_'+toupdate[i]];
          }else if(typeof(from['billing_'+toupdate[i]])!="undefined"){
            shipping[toupdate[i]] = from['billing_'+toupdate[i]];
          }else if(typeof(from['shipping_'+toupdate[i]])!="undefined"){
            shipping[toupdate[i]] = from['shipping_'+toupdate[i]];
          }else if((type==='pos'||type==='reseller')&&typeof(from['shipto_'+toupdate[i]])!="undefined"){
            shipping[toupdate[i]] = from['shipto_'+toupdate[i]];
          }else if(typeof(from[toupdate[i]])!="undefined"){
            shipping[toupdate[i]] = from[toupdate[i]];
          }
        }
        if(shipping){
          shipping.name = this.shipping_name();
        }
      }
      this._update('shipping',shipping,true);
    },
    selectShippingType(){
      this.setShipping();
    },
    getCSInititals(item){
      if(!item.cs){
        return '';
      }
      let names = item.cs.username.split(' ');
      let initials = [];
      for(let i in names){
        initials.push(names[i].charAt(0).toUpperCase());
      }
      return initials.join('');
    },
    async getSoumissions(){
      return await this.repo('submission').list({},{rows: 50,first: 0});
    },
    updateSoumissionNumber(item,update,force=false) {
      if(item.qt_nb&&!force){
        return item.qt_nb;
      }
      let self = this;
      this.getSoumissions().then(function(response){
        let max_seq = 6;
        const soumissions = response.items;
        const soumissions_count = String(soumissions.length);
        console.log(soumissions_count.length);
        const inititals = self.getCSInititals(item);
        console.log('initials : '+inititals);
        max_seq = max_seq - soumissions_count.length;
        let sequence = soumissions_count;
        for(let i=0;i<max_seq;i++){
          sequence = '0'+sequence;
        }
        console.log('sequence : '+sequence);

        const final_number = 'QT'+inititals+sequence;
        item.qt_nb = final_number;
        update('$set','qt_nb',final_number);
        return response;
      });
    },
    updateExpiredAt(item, update, event){
      item.expired_at = _DATE.format(event);
      update('$set','expired_at',item.expired_at);
    },
    updateDelai(item, update, event){
      item.delai = _DATE.format(event);
      update('$set','delai',item.delai);
    },
    updateDate(key, event){
      console.log(event);
      let d = _DATE.format(event);
      this._update( key, d,true);

      if(key=='expired_at'){
        this.setEvent();
      }
    },
    updateRappel(){
      if(this.single.value.quotetype){
        let qs = this.quotetypes.filter((i) => i.sku==this.single.value.quotetype);
        if(qs.length>0){
          qs = qs[0];
          let days = parseInt(qs.notify);
          if(isNaN(days)){
            days = 0;
          }
          let from = this.single.value.created_at || moment();
          let notify_date = moment(String(from)).add(days, 'days');
          let d = _DATE.format(notify_date);
          this._update( 'notify_date', d,true);
          return d;
        }
      }
      return '';
    },
    async setEvent(){

      if(!this.single.value.event_id){
        const event_id = await _GET.create('event',{
          soumission_id:this.single.value._id,
          start_date:this.single.value.expired_at,
          title:"Soumission #"+this.single.value.qt_nb,
          description:""
        });
        console.log('event_id : '+event_id);
        if(event_id){
          this._update( 'event_id', event_id,true);
        }
      }
    },
    dateFormat(date,today=false){
      return _DATE.format(date,today);
    },
    async getSettings(){
      const radium = useRadium();
      const crude = new CrudRepository(radium, 'setting');
      const settings = await crude.list({},{rows: 50,first: 0}).then(function (response) {
        return response.items;
      });

      this.settings = settings;
      this.getTaxes();
      this.getCarriers();
      this.getShippingAddons();
      this.getQuoteTypes();
      this.getQuoteStatus();
    },
    getQuoteTypes(){
      const quotetypes = this.settings.filter(item => Object.keys(item).includes('quotetypes'));
      console.log(quotetypes);
      if(quotetypes.length==1){
        this.quotetypes = quotetypes[0]['quotetypes'];
      }
    },
    getQuoteStatus(){
      const quotestatus = this.settings.filter(item => Object.keys(item).includes('quotestatus'));
      console.log(quotestatus);
      if(quotestatus.length==1){
        this.quotestatus = quotestatus[0]['quotestatus'];
      }
    },
    getTaxes(){
      const taxes = this.settings.filter(item => Object.keys(item).includes('taxes'));
      if(taxes){
        this.taxes = taxes[0]['taxes'];
      }else{
        this.taxes = default_taxes;
      }
    },
    getCarriers(){
      const shipping = this.settings.filter(item => Object.keys(item).includes('shipping'));
      console.log(shipping);
      if(shipping.length==1){
        this.carriers = shipping[0]['shipping'];
      }
    },
    async getCarrier(){
      console.log('getCarrier '+this.carrier_id+' ('+this.carriers.length+')');
      const f = this.carriers.filter(item => {
        return item.sku===this.carrier_id;
      });
      if(f.length===1){
        this.carrier = {...this.carrier,...f[0]};
        this._update('carrier',this.carrier,true);
      }
      return {};
    },
    async selectCarrier(val,item){
      //update('$set','reseller_id',val);
      this._update('carrier_id',val,true);
      this.carrier_id = item.carrier_id;
      this.getCarrier();
    },
    getShippingAddons(){
      const shipping = this.settings.filter(item => Object.keys(item).includes('shippingaddons'));
      console.log(shipping);
      if(shipping.length==1){
        this.shippingAddons = shipping[0]['shippingaddons'];
      }
    },
    updateShipping(key,value,item,update){
      let shipping = {};
      if(key=='carrier'){
        if(value){
          const carrier = this.carriers.filter(i => i.sku==value);
          if(carrier.length){
            shipping.sku = carrier[0].sku;
            shipping.name =  carrier[0].description_fr;
            shipping.price = 0;
          }
        }
      }else if(key=='price'){
        if(item.shipping){
          shipping = item.shipping;
        }
        let v = parseFloat(value);
        v = isNaN(v) ? 0 : v;
        shipping.price = v;
      }

      if(!item.shipping){
        item.shipping = {};
      }

      console.log(shipping);

      item.shipping = shipping;
      update("$set",'shipping',item.shipping);
    },
    addTag(item) {
      if(!item.tags){
        item.tags = [];
      }
      item.tags.push('');
    },
    updateTag(value,index,item,update){
      item.tags[index] = value;
      update("$set",'tags',item.tags);
    },
    destroyTag(index,item,update){
      console.log('destroy '+index);
      const c = confirm(this.$t('confirm_remove_item'));
      if(!c){
        return false;
      }
      item.tags.splice( index, 1 );
      if(item.tags){
        item.tags = [];
      }
      update("$set",'tags',item.tags);
    },
    addShippingAddons(item) {
      if(!item.shippingaddons){
        item.shippingaddons = [];
      }
      item.shippingaddons.push({});
    },
    updateShippingAddons(value,index,item,update){
      console.log(value);
      console.log(this.shippingAddons);
      if(value){
        const shippingaddon = this.shippingAddons.filter(i => i.sku==value);
        console.log('shippingaddon');
        console.log(shippingaddon);
        if(shippingaddon){
          item.shippingaddons[index] = shippingaddon[0];
        }
      }
      console.log(item.shippingaddons);
      update("$set",'shippingaddons',item.shippingaddons);
    },
    destroyShippingAddons(index,item){
      console.log('destroy '+index);
      const c = confirm(this.$t('confirm_remove_item'));
      if(!c){
        return false;
      }
      item.shippingaddons.splice( index, 1 );
      if(item.shippingaddons){
        item.shippingaddons = [];
      }
      update("$set",'shippingaddons',item.shippingaddons);
    },
    addFiles(item, update, event){
      console.log(event);
      if(!item.files){
        item.files = [];
      }

      for(let i=0;i<event.length;i++){
        let v = event[i];
        item.files.push(v);
      }
      console.log(item.files);
      update("$set",'files',item.files);
    },
    updateFiles(item, update){
      console.log(item.files);
      update("$set",'files',item.files);
    },
    removeFile( key, item, update){
      const c = confirm(this.$t('confirm_remove_file'));
      if(!c){
        return false;
      }
      item.files.splice( key, 1 );
      update("$set",'files',item.files);
    },


    log(data){
      console.log(data);
    },
    _update(k,v,set = true){
      let key = k;
      let val = v;
      const keys = key.split('.');
      if(typeof(keys)==='object'){
        key = keys[0];
        if(!Object.keys(this.single.value).includes(key)&&keys.length>1){
          this.single.value[key] = {};
          this.single.value[key][keys[1]] = val;
          val = this.single.value[key]
        }
      }
      this.$refs.form.update('$set',key,val,set);
    },
    async getUsers(){
        await _GET.all('admin').then(response => {
          this.users = response;
        });
    },
    updateCS(event){
      this.cs_id = event;
      this._update('cs_id',this.cs_id,true);
      const f = this.users.filter(item => {
        return item._id===this.cs_id;
      });
      if(f.length===1){
        this.cs = f[0];
        this.cs.password = '';
        this._update('cs',this.cs,true);
      }
    },
    updateRep(event){
      this.rep_id = event;
      this._update('rep_id',this.rep_id,true);
      const f = this.users.filter(item => {
        return item._id===this.rep_id;
      });
      if(f.length===1){
        this.rep = f[0];
        this.rep.password = '';
        this._update('rep',this.rep,true);
      }
    },

    quoteTitle(){
      let o = [];
      let name = this.billing_name();
      let qt = this.single?.value?.qt_nb;
      if(name){
        o.push(name);
      }
      if(qt){
        o.push(qt);
      }
      if(o.length>0){
        o.unshift('');
      }
      return o.join(' - ');
    }
  },
  mounted() {
    this.getResellers();
    this.getSettings();
    this.getUsers();
  }
}
</script>

<style lang="scss" scoped>

</style>

