<template>
	<SingleLayout service="reseller" :schema="schema" :return-on-save="true">
		<template #toolbar-left="{id}">
			<h1 style="margin: auto">{{ $tc('distributeur',1) }}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
				<template #info-header>
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$t('information')}}</span>
				</template>
        <template #shipping-header v-if="$store.permissions.has('resellers.shipping.list')">
          <i class="pi pi-info p-pl-1"></i>
          <span>{{$tc('adresses',2)}}</span>
        </template>
        <template #contact-header v-if="$store.permissions.has('resellers.contact.list')">
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$tc('contact',2)}}</span>
				</template>
        <template #users-header>
          <i class="pi pi-info p-pl-1"></i>
          <span>{{$tc('user',2)}}</span>
        </template>
				
				<template #info>
					<div class="p-field">
						<FormInput class="p-mt-3" :errors="errors.status" v-slot="classes">
							<span class="p-float-label">
								<Dropdown v-model="item.active_flag"
								          @change="update('$set','active_flag',$event.value);"
								          :options="statusOptions"
								          optionLabel="name"
								          optionValue="code"
								/>
								<label>{{ $tc('statut',1) }}</label>
							</span>
						</FormInput>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_name" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_name" @change="update('$set','customer_name',$event.target.value)" :class="classes"/>
									<label>{{ $t('nom') }}</label>
								</span>
							</FormInput>
						</div>
					</div>

					<div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_confirmation_email" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.customer_confirmation_email" @update:modelValue="update('$set','customer_confirmation_email',$event)" :class="classes"/>
                      <label>{{ $t('confirmation_email') }}</label>
                    </span>
              </FormInput>
            </div>
            <div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_website" v-slot="classes">
									<span class="p-float-label p-input-icon-left">
										<i class="fal fa-globe"></i>
										<InputText type="text" v-model="item.customer_billing_website" @change="update('$set','customer_billing_website',$event.target.value)" :class="classes"/>
										<label>{{ $t('website') }}</label>
									</span>
							</FormInput>
						</div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.discount" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.discount" @update:modelValue="update('$set','discount',$event)" :class="classes"/>
                      <label>{{ $tc('escompte',1) }}</label>
                    </span>
              </FormInput>
            </div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.account_nb" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.account_nb" @update:modelValue="update('$set','account_nb',$event)" :class="classes"/>
                      <label>{{ $t('account') }}</label>
                    </span>
              </FormInput>
            </div>

					</div>
					
					<h3 class="p-mt-4">{{$t('billing')}}</h3>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_address_1" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_billing_address_1" @change="update('$set','customer_billing_address_1',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_1') }}</label>
								</span>
							</FormInput>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_address_2" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_billing_address_2" @change="update('$set','customer_billing_address_2',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_2') }}</label>
								</span>
							</FormInput>
						</div>
					</div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_billing_address_3" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_billing_address_3" @change="update('$set','customer_billing_address_3',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_3') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_billing_address_4" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_billing_address_4" @change="update('$set','customer_billing_address_4',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_4') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_city" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.customer_billing_city" @change="update('$set','customer_billing_city',$event.target.value)" :class="classes"/>
									<label>{{ $tc('customer_billing_city',1) }}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_province" v-slot="classes">
								<span class="p-float-label">
									<Dropdown v-model="item.customer_billing_province"
									          @change="update('$set','customer_billing_province',$event.value)"
									          :options="provinces"
									          optionLabel="label"
									          optionValue="value"
									          :class="classes"/>
									<label>{{ $tc('province',1) }}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.customer_billing_postal_code" v-slot="classes">
								<span class="p-float-label">
									<InputMask mask="a9a 9a9" v-model="item.customer_billing_postal_code" @change="update('$set','customer_billing_postal_code',$event.target.value.toUpperCase())" :class="classes"/>
									<label>{{ $t('code_postal') }}</label>
								</span>
							</FormInput>
						</div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_invoice_email" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.customer_invoice_email" @update:modelValue="update('$set','customer_invoice_email',$event)" :class="classes"/>
                      <label>{{ $t('invoice_email') }}</label>
                    </span>
              </FormInput>
            </div>
					</div>

          <h3 class="p-mt-4">{{$tc('contact',1)}}</h3>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_billing_telephone" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.customer_billing_telephone" @change="update('$set','customer_billing_telephone',$event.target.value)" :class="classes"/>
									<label>{{ $t('telephone') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_billing_fax" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.customer_billing_fax" @change="update('$set','customer_billing_fax',$event.target.value)" :class="classes"/>
									<label>{{ $t('fax') }}</label>
								</span>
              </FormInput>
            </div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.customer_billing_email" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="fal fa-envelope"></i>
									<InputText type="email" v-model="item.customer_billing_email" @change="update('$set','customer_billing_email',$event.target.value)" :class="classes"/>
									<label>{{ $t('billing_email') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
				</template>
        <template #shipping v-if="$store.permissions.has('resellers.shipping.list')">
          <table-layout service="resellerShipping" single-route-name="resellers.shipping.edit" :actions="shipping.actions" :filters="shipping.filters" :parent="item._id" :open-params="{reseller: item._id}">
            <template #toolbar-right="{open}">
              <Button label="Nouveau" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.has('resellers.shipping.add')"/>
            </template>

            <template #default="{open}">
              <Column>
                <template #header>
                  {{ $t('nom') }}
                </template>
                <template #body="{data}">
                  <router-link :to="{name: 'resellers.shipping.edit', params: {reseller:item._id,id: data._id}}"
                               v-slot="{ href, route, navigate, isActive, isExactActive }"
                               custom>
                    <Button @click="navigate" :label="data.shipto_location_name" class="p-button-text"/>
                  </router-link>
                </template>
              </Column>
              <Column>
                <template #body="{data}">
                  <span :class="statusShippingMap[data.primary_shipto_flag]?.css">{{ statusShippingMap[data.primary_shipto_flag]?.name }}</span>
                </template>
              </Column>
              <Column field="shipto_email">
                <template #header>
                  {{ $t('email') }}
                </template>
              </Column>
              <Column field="shipto_telephone">
                <template #header>
                  {{ $t('telephone') }}
                </template>
              </Column>
              <Column >
                <template #header>
                  {{ $t('adresse') }}
                </template>
                <template #body="{data}">
                  {{ data.shipto_address_1 }}
                  <br v-if="data.shipto_address_2">
                  {{ data.shipto_address_2 }}
                  <br v-if="data.shipto_address_3">
                  {{ data.shipto_address_3 }}
                  <br v-if="data.shipto_address_4">
                  {{ data.shipto_address_4 }}
                  <br>
                  {{ data.shipto_city }}, {{ mappedProvinces[data.shipto_province] }}
                  <br v-if="data.shipto_postal_code">
                  {{ data.shipto_postal_code }}
                </template>
              </Column>
            </template>

          </table-layout>
        </template>
        <template #contact v-if="$store.permissions.has('resellers.contact.list')">
					<table-layout service="resellerContact" single-route-name="resellers.contact.edit" :actions="contact.actions" :filters="contact.filters" :parent="item._id" :open-params="{reseller: item._id}">
						<template #toolbar-right="{open}">
							<Button label="Nouveau" icon="pi pi-plus" class="p-button-success p-mr-2" @click="open(false)" v-if="$store.permissions.has('resellers.contact.add')"/>
						</template>
						
						<template #default="{open}">
							<Column>
                <template #header>
                  {{ $t('nom') }}
                </template>
								<template #body="{data}">
									<router-link :to="{name: 'resellers.contact.edit', params: {reseller:item._id,id: data._id}}"
									             v-slot="{ href, route, navigate, isActive, isExactActive }"
									             custom>
										<Button @click="navigate" :label="data.name" class="p-button-text"/>
									</router-link>
								</template>
							</Column>
              <Column field="main_email">
                <template #header>
                  {{ $t('email') }}
                </template>
              </Column>
							<Column field="main_phone">
                <template #header>
                  {{ $t('telephone') }}
                </template>
              </Column>
							<Column >
                <template #header>
                  {{ $t('adresse') }}
                </template>
                <template #body="{data}">
                  {{ getResellerShipping(data.shipping) }}
                </template>
							</Column>
              <Column >
                <template #header>
                  {{ $tc('departement',1) }}
                </template>
                <template #body="{data}">
                {{ getContactCategorieName(data.categorie) }}
              </template>
            </Column>
              <Column field="status">
                <template #header="{data}">
                  {{$t('access')}}
                </template>
                <template #body="{data}">
                  <span :class="statusContactMap[data.status]?.css">{{ statusContactMap[data.status]?.name }}</span>
                </template>
              </Column>
						</template>
					
					</table-layout>
				</template>
        <template #users>
          <CheckboxGroup response="array" :model-value="users" :selected="item.users" name="name" value="_id" group="users" @update:model-value="updateUsers($event,item,update)"/>
        </template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {provinces} from "@/helpers/provinces";
import {useStore} from "@/store";
import ShippingAddons from "../Settings/ShippingAddons";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";
import {translatedFieldName, translatedString} from "../../i18n";

export default {
	name: 'EditReseller',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
    ShippingAddons,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
		InputMask: require('primevue/inputmask').default,
	},
  data(){
    return {
      users: [],
      contactCategories: []
    };
  },
	setup(props, {emit}) {
    const i18n = useI18n();
    const store = useStore();
		const radium = store.radium;
    const statusOptions = ref([
      {
        name: i18n.t('actif'),
        code: 'Active',
        css: 'badge badge-success'
      },
      {
        name: i18n.t('inactif'),
        code: 'Inactive',
        css: 'badge badge-danger'
      }
    ]);
    const statusContactOptions = ref([
      {
        name: i18n.t('actif'),
        code: 'Active',
        css: 'badge badge-success'
      },
      {
        name: i18n.t('inactif'),
        code: 'Inactive',
        css: 'badge badge-danger'
      }
    ]);
    const statusShippingOptions = ref([
      {
        name: "Primaire",
        code: 'Primary',
        css: 'badge badge-primary'
      },
      {
        name: "Secondaire",
        code: 'Not Primary',
        css: 'badge badge-secondary'
      }
    ]);
    const contact = {};
    contact.filters = {};
    contact.actions = [];
    if(store.permissions.has('resellers.contact.resetpassword')){
      contact['actions'] = [
        {
          label: i18n.t('reset_password'),
          icon: "pi pi-envelope",
          command(data) {
            radium.api('resetPassword', {email: data.email}).then(() => {
              toast.add({
                severity: "success",
                summary: i18n.t('email_sent'),
                life: 5000
              });
            })
          }
        }
      ];
    }

    const shipping = {};
    shipping.filters = {};
    shipping.actions = [];

    const resellerShippings = [];

		return {
      resellerShippings,
			provinces,
			schema: {
        active_flag: {type: "string", convert: true},
        customer_name: {type: "string", min: 3, max: 255},
        customer_billing_telephone: {type: "string", optional: true},
        customer_billing_website: {type: "string", optional: true},
        customer_billing_email: {type: "email", optional: true},
        customer_billing_address_1: {type: "string", optional: true},
        customer_billing_address_2: {type: "string", optional: true},
        customer_billing_address_3: {type: "string", optional: true},
        customer_billing_address_4: {type: "string", optional: true},
        customer_billing_city: {type: "string", optional: true},
        customer_billing_province: {type: "string", optional: true},
        customer_billing_postal_code: {type: "string", optional: true},
        discount: {type: "string", optional: true},
        account_nb: {type: "string", optional: true}
			},
			tabs: {
				info: true,
        shipping: true,
				contact: true,
        users: true,
				notable: true,
				timeData: true,
			},
      statusContactOptions,
      statusContactMap: computed(() => {
        return statusContactOptions.value.reduce((a, v) => {
          a[v.code] = {name: v.name, css: v.css};
          return a
        }, {});
      }),
      statusShippingOptions,
			statusShippingMap: computed(() => {
				return statusShippingOptions.value.reduce((a, v) => {
					a[v.code] = {name: v.name, css: v.css};
					return a
				}, {});
			}),
      statusOptions,
      statusMap: computed(() => {
        return statusOptions.value.reduce((a, v) => {
          a[v.code] = {name: v.name, css: v.css};
          return a
        }, {});
      }),
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			}),
      contact,
      shipping
		}
	},

  methods:{
    checkEvent(event){
      console.log(event);
      console.log(event.value);
      console.log(event.target);
      console.log(event.target.value);
      this.$store.perm
    },
    updateUsers(event,item,update){
      const values = event[0];
      const clicked = [event[1]];
      if(item._id){
        console.log('clicked : '+clicked);
        update('$set','users',values);
        _GET.update_merge('admin',clicked,'clients',item._id);
      }
    },
    async getUsers(){
      this.users = await _GET.all('admin');
    },
    async getResellerShippings(){
      await _GET.all('resellerShipping',{},{
        parent: {value:this.$route.params.id,matchMode: FilterMatchMode.EQUALS}
      }).then(response => {
        response.forEach(item => {
          this.resellerShippings[item._id] = item;
        });
      });
    },
    getResellerShipping(id){
      if(this.resellerShippings){
        const str = [];
        str.push(this.resellerShippings[id].shipto_location_name);
        str.push(this.resellerShippings[id].shipto_address_1);
        str.push(this.resellerShippings[id].shipto_city);
        return str.join(', ');
      }
      return '-';
    },
    getContactCategorieName(id){
      if(id&&this.contactCategories){
        const categorie = this.contactCategories.filter(item => {
          return item._id==id;
        });
        if(categorie.length>0){
          return translatedString('name',categorie[0]);
        }
      }
    },
    async getContactCategories(){
      this.contactCategories = await _GET.all('resellerContactCategories');
    }
  },
  mounted() {
    this.getUsers();
    this.getResellerShippings();
    this.getContactCategories();
  }
}
</script>
