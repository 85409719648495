<template>
	<SingleLayout service="admin" :schema="schema" :return-on-save="false">
		<template #toolbar-left>
			<h1 style="margin: auto">{{$tc('user',1)}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)">
        <template #client-header>
          <span>{{$tc('client',2)}}</span>
        </template>
        <template #userfields>
          <div class="p-field p-col-6">
            <FormInput class="p-mt-3" :errors="errors?.type" v-slot="classes">
                <span class="p-float-label">
                  <Dropdown v-model="item.type"
                            :options="typesOptions"
                            optionLabel="name"
                            optionValue="code"
                            id="typesUserDropdown"
                            @change="updateItem('type',item.type,item,update)"
                  />
                  <label>Type</label>
                </span>
            </FormInput>
          </div>
          <div class="p-field p-col-6">
            <FormInput class="p-mt-3" :errors="errors?.categorie" v-slot="classes">
                <span class="p-float-label">
                  <Dropdown v-model="item.categorie"
                            :options="userCategories"
                            optionLabel="name_fr"
                            optionValue="_id"
                            id="userCategoriesDropdown"
                            :show-clear="true"
                            @change="update('$set','categorie',$event.value)"
                  />
                  <label>{{$tc('user_categorie',1)}}</label>
                </span>
            </FormInput>
          </div>
        </template>
        <template #client>
          <CheckboxGroup v-if="item._id" response="array" :model-value="clients" :selected="item.clients" name="name" value="_id" group="clients" @update:model-value="updateClients($event,item,update)"/>
          <div v-else>
            {{$t('message_need_saving',{type:$tc('client',2)})}}
          </div>
        </template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import {SingleLayout, TableLayout, useRadium} from "@radiumjs/vue";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {CrudRepository} from "@radiumjs/client";
import CheckboxGroup from "@/components/Form/CheckboxGroup.vue";
import {_GET} from "../../helpers/get";

export default {
	name: 'EditUser',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
    CheckboxGroup,
		DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
	},
  data(){
    return {
      clients: [],
      userCategories: []
    };
  },
	setup(props, {emit}) {

    const typesOptions = ref([
      {
        name: "Administrateur",
        code: 1
      },
      {
        name: "Vendeur",
        code: 2
      },
      {
        name: "Employé",
        code: 3
      }
    ]);
		
		return {
      typesOptions,
			schema: {
				status: {type: "number"},
				email: {type: "email"}
			},
			tabs: {
				user: true,
        client: true,
				notable: true
			}
		};
	},
  methods:{
    updateItem(key,val,item,update){
      update('$set',key,val);
    },
    afterSave(event){
      console.log('afterSave');
      console.log(event);
    },
    updateClients(event,item,update){
      const values = event[0];
      const clicked = [event[1]];
      if(item._id){
        console.log('clicked : '+clicked);
        update('$set','clients',values);
        _GET.update_merge('reseller',clicked,'users',item._id);
      }
    },
    async getClients(){
      this.clients = await _GET.all('reseller');
    },
    async getUserCategories(){
      this.userCategories = await _GET.all('userCategories');
    },
  },
  mounted() {
    this.getClients();
    this.getUserCategories();
  }
}
</script>
