
import {computed, defineComponent, ref, getCurrentInstance} from "vue";
import SoumissionRapideAdd from "./SoumissionRapideAdd.vue";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import {BuiltProduct, discountStringToNumber, SoumissionRapide} from "@/helpers/soumission";
import Note from "@/components/SoumissionRapide/Note.vue";
import {useRadium} from "@radiumjs/vue";
import {CrudRepository} from "@radiumjs/client";
import { useI18n } from "vue-i18n";
import {_DATE} from "../../helpers/dateLocal";
import {_GET} from "../../helpers/get";
import Calendar from 'primevue/calendar';


type CartItem = SoumissionRapide["products"][number];

export default defineComponent({
	name: "SoumissionRapideProduits",
	components: {
		Note,
		SoumissionRapideAdd,
		Column: require("primevue/column").default,
		DataTable: require("primevue/datatable").default,
		Button: require("primevue/button").default,
		Toolbar: require("primevue/toolbar").default,
		Sidebar: require("primevue/sidebar").default,
		InputText: require("primevue/inputtext").default,
		Textarea: require("primevue/textarea").default,
    Calendar,
    _DATE,
    _GET
	},
	props: {
		modelValue: Array,
		item: Object,//the submission
	},
	emits: ["update:modelValue"],
  data() {
    return {
      productOptions:[],
      soumission_types:[]
    }
  },
	setup(props, {emit}) {
		const toast = useToast();
		const confirm = useConfirm();
    const app = getCurrentInstance();
		
		const products = computed<CartItem[]>(() => {
			return (props.modelValue || []) as CartItem[];//TODO validate product
		});

		const adding = ref(false);
    const message_note_placeholder = useI18n().t("message_note_placeholder");
    const message_product_added = useI18n().t("message_product_added");

		return {
			adding,
      item:props.item,
      placeholder:message_note_placeholder,
			
			upNote(index, val) {
				if (index === false)
					return;
				const arr = [...props.modelValue] as CartItem[];
				arr[index].product = {
					...arr[index].product,
          note: val
				};
				emit('update:modelValue', arr);
			},
      upTags(index, val) {
        if (index === false)
          return;
        const arr = [...props.modelValue] as CartItem[];
        arr[index].product = {
          ...arr[index].product,
          tags: val
        };
        emit('update:modelValue', arr);
      },

      upDiscount(index, val) {
        if (index === false)
          return;
        const arr = [...props.modelValue] as CartItem[];
        arr[index].product = {
          ...arr[index].product,
          discount: val
        };
        console.log(arr);
        emit('update:modelValue', arr);
      },

      upPrixClient(index, val) {
        if (index === false)
          return;
        const arr = [...props.modelValue] as CartItem[];
        arr[index].product = {
          ...arr[index].product,
          prix_client: val
        };
        console.log(arr);
        emit('update:modelValue', arr);
      },
			
			discount: computed(() => discountStringToNumber(props.item.discount)),
			
			products,
			addProduct(product: BuiltProduct) {
				console.log('addProduct');
        console.log(product);
        emit("update:modelValue", [...(props.modelValue || []), {
					product,
					note: "",
          tags: "",
          unit: localStorage.unit,
					qty: 1
				} as CartItem]);
				
				toast.add({
					summary: message_product_added,
					detail: product.name,
					severity: "info",
					life: 5000
				});
				adding.value = false;
			},
			destroy(prd, $event) {
				confirm.require({
					target: $event.currentTarget,
					message: "",//TODO :$t("confirm_remove_item")
					icon: 'pi pi-exclamation-triangle',
					acceptClass: 'p-button-danger',
					accept: () => {
						const i = products.value.indexOf(prd);
						if (i === -1)
							return;
						
						if (i + 1 >= props.modelValue.length)
							emit("update:modelValue", [...props.modelValue.slice(0, i)]); else
							emit("update:modelValue", [...props.modelValue.slice(0, i), ...props.modelValue.slice(i + 1, props.modelValue.length)]);
					},
					reject: () => {
					}
				});
			},
		};
	},
  methods: {
    decimal(n){
      return n.toFixed(2);
    },
    getMeasureString(n,d){
      const unit = d.unit || 'ftin';
      if(unit == 'ftin'){
        return Math.floor(n / 12)+`' `+(n % 12)+`"`;
      }else if(unit == 'ft'){
        const _n = (n / 12);
        return _n.toFixed(2)+`'`;
      }
      return (n)+`"`;
    },
    async getGlobalOptions(){
      const radium = useRadium();
      const crude = new CrudRepository(radium, 'productOptions');
      const cats = await crude.list({},{rows: 50,first: 0}).then(function (response) {
        return response.items;
      });
      this.productOptions = cats;
    },
    getOptionName(product, optionName){
      if(product.options){
        for(let i in product.options){
          const option = product.options[i];
          if(option&&option[optionName]){
            if(option[optionName]['label_'+this.$i18n.locale]){
              return option[optionName]['label_'+this.$i18n.locale];
            }
            return option[optionName]['label'];
          }
        }
      }
      return '';
    },
    getFileTitle(file){
      if(file['titre_'+this.$i18n.locale]){
        return file['titre_'+this.$i18n.locale];
      }
      return '';
    },
    getFileLink(file){
      if(file.name){
        return (process.env.VUE_APP_UPLOAD_SERVER?process.env.VUE_APP_UPLOAD_SERVER:'')+'/storages/'+file.dir+'/'+file.name;
      }
      return '';
    },
    getWeblinkTitle(weblink){
      if(weblink['titre_'+this.$i18n.locale]){
        return weblink['titre_'+this.$i18n.locale];
      }
      return '';
    },
    getWeblinkLink(weblink){
      if(weblink['link_'+this.$i18n.locale]){
        return weblink['link_'+this.$i18n.locale];
      }
      return '';
    },
    msrp(data){
      const option_msrp = data.product.productOptions_price&&data.product.productOptions_price.msrp ? data.product.productOptions_price.msrp : 0;
      return this.decimal((Number(data.product.price)+option_msrp) * data.qty);
    },
    unit_msrp(data){
      const option_msrp = data.product.productOptions_price&&data.product.productOptions_price.msrp ? data.product.productOptions_price.msrp : 0;
      return this.decimal((Number(data.product.price)+option_msrp));
    },
    get_price(data){
      const option_msrp = data.product.productOptions_price&&data.product.productOptions_price.msrp ? data.product.productOptions_price.msrp : 0;
      let price = (Number(data.product.price)+option_msrp);
      if(this.item.billed_to||this.item.for==='custom'){
        let p = Number(data?.product?.prix_client);
        if(p){
          price = p;
        }
      }
      return price;
    },
    get_discount(data){
      let discount = this.discount;
      if(data.product.discount){
        discount = discountStringToNumber(data.product.discount);
      }
      return discount;
    },
    price(data){
      let discount = this.get_discount(data);
      const price = this.get_price(data);
      return this.decimal(price * data.qty * discount);
    },
    unit_price(data){
      let discount = this.get_discount(data);
      const price = this.get_price(data);
      return this.decimal(price  * discount);
    },
    displayProductOptionInfoDetail(productOption){
      let description = '';
      if(productOption.unique){
        description = productOption.unique;
      }
      return  description;
    },
    updateDate(item, key, update, event){
      item[key] = _DATE.format(event);
      update('$set',key,item[key]);

      if(key=='expired_at'){
        this.setEvent(item,update);
      }
    },
    async setEvent(item,update){
      if(!item.event_id){
        const event_id = await _GET.create('event',{
          soumission_id:item._id,
          start_date:item.expired_at,
          title:"Soumission #"+item.qt_nb,
          description:""
        });
        console.log('event_id : '+event_id);
        if(event_id){
          update('$set','event_id',event_id);
        }
      }
    }
  },
  mounted() {
    this.getGlobalOptions();

  }
});
