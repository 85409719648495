import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "SkuSelectorConfiguration" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (module) => {
      return (_openBlock(), _createBlock(_component_Panel, {
        header: module.module.info.label,
        toggleable: true,
        collapsed: true,
        class: "p-mt-3"
      }, {
        icons: _withCtx(() => [
          _createVNode(_component_Checkbox, {
            "model-value": module.view.value.isActive,
            "onUpdate:modelValue": ($event: any) => (module.view.value.isActive = $event),
            binary: true,
            class: "p-panel-header-icon"
          }, null, 8, ["model-value", "onUpdate:modelValue"])
        ]),
        default: _withCtx(() => [
          (module.configurator)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(module.configurator.component), { key: 0 }))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, "Pas de configuration"))
        ]),
        _: 2
      }, 1032, ["header"]))
    }), 256))
  ]))
}