
import {defineComponent, ref} from 'vue';
import FormRadium from "@/components/FormPrime/FormRadium.vue";
import SkuSelector from "@/components/Sku/SkuSelector.vue";
import SkuBuilder from "@/components/Sku/SkuBuilder.vue";
import Upload from "@/components/Upload/Upload.vue";
import SkuSelectorConfigurator from "@/components/Sku/SkuSelectorConfigurator.vue";
import {useRadium} from "@radiumjs/vue";
import SkuList from "@/components/Sku/SkuList.vue";
import {VAceEditor} from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-chrome';
import {Product, SkuHelper} from "@/helpers/product";
import {CheckerFunctionV2} from "fastest-validator";
import ProductCategories from "./ProductCategories.vue";
import {CrudRepository} from "@radiumjs/client";
import InputRepeater from "@/components/Inputs/InputRepeater.vue";
import CheckboxGroup from "@/components/Form/CheckboxGroup.vue";
import {useI18n} from "vue-i18n";
import {useStore} from "@/store";
import SingleLayout from "../Layouts/SingleLayout.vue";
import {_GET} from "../../helpers/get";

export default defineComponent({
	name: "Product",
	components: {
		SkuList,
		SingleLayout,
		SkuBuilder,
		SkuSelectorConfigurator,
		SkuSelector,
		FormRadium,
    Upload,
    InputRepeater,
    CheckboxGroup,
		Button: require('primevue/button').default,
		Toolbar: require('primevue/toolbar').default,
		Dialog: require('primevue/dialog').default,
		DataTable: require('primevue/datatable').default,
		Column: require('primevue/column').default,
		InputText: require('primevue/inputtext').default,
    Checkbox: require('primevue/checkbox').default,
		Dropdown: require('primevue/dropdown').default,
		Textarea: require('primevue/textarea').default,
		Rating: require('primevue/rating').default,
		VAceEditor,
    _GET
	},
  data() {
    return {
      productOptions:[],
      productCategories:[]
    }
  },
	setup(props, {emit: emitO, expose}) {
    const i18n = useI18n();
    const store = useStore();
    const radium = store.radium;
    _GET.setProvider(radium);

    const single = ref({});

    const statusOptions = ref([
      {
        name: i18n.t('available'),
        code: 2
      },
      {
        name: i18n.t('available_admin'),
        code: 1
      },
      {
        name: i18n.t('unavailable'),
        code: -1
      }
    ]);
    const imageTypes = ref([
      {
        name: 'Image',
        code: 'image'
      },
      {
        name: 'URL',
        code: 'url'
      }
    ]);

		return {
      isInit: false,
      single,
      statusOptions,
      imageTypes,
			priceError: ref(),
			helpPrice: ref(),
      tabs: {
				info: "Infos",
        image: true,
        option: "Option",
        category: true,
        file: true,
        link: true
			},
			schema: {
				sku: {
					type: "custom",
					custom: ((val, errors, schema, path, _, ctx) => {
						//@ts-ignore
						const helper = new SkuHelper(ctx.data as Product);
						
						helper.validate(errors);
						
						return val;
					}) as CheckerFunctionV2
				}
			}
		};
	},
  methods:{
    init(){
      this.isInit = true;
    },
    itemUpdated(item){
      console.log(item);
      this.single = item;

      if(!this.isInit){
        this.init();
      }
    },
    updateCategories(item, event, update){
      console.log('updateCategories');
      update('$set','productCategories',event[0]);
      //workaround for productCategories that include array
      item.productCategories = event[0];
    },
    currentStatus(item){
      if(!Object.keys(item).includes('status')){
        item.status = 2;
      }
      return item.status;
    },
    async getOptions(){
      await _GET.all('productOptions').then(response => {
        this.productOptions = response;
      });
    },
    async getCategories(){
      await _GET.all('productCategories').then(response => {
        this.productCategories = response;
      });
    },
    addFiles(item, update, event){
      console.log(event);
      if(!item.files){
        item.files = [];
      }

      for(let i=0;i<event.length;i++){
        let v = event[i];
        item.files.push(v);
      }
      console.log(item.files);
      update("$set",'files',item.files);
    },
    updateFiles(item, update){
      console.log(item.files);
      update("$set",'files',item.files);
    },
    removeFile( key, item, update){
      const c = confirm(this.$t('confirm_remove_file'));
      if(!c){
        return false;
      }
      item.files.splice( key, 1 );
      update("$set",'files',item.files);
    },
    addImage(item, update, event){
      console.log(event);
      const img = event[0];
      item.image = img;
      update("$set",'image',img);
    },
    updateImage(item, update){
      console.log(item.image);
      update("$set",'files',item.image);
    },
    removeImage( item, update){
      const c = confirm(this.$t('confirm_remove_file'));
      if(!c){
        return false;
      }
      update("$set",'image','');
    },
    imageLink(file){
        if(file.name){
          return (process.env.VUE_APP_UPLOAD_SERVER?process.env.VUE_APP_UPLOAD_SERVER:'')+'/storages/'+file.dir+'/'+file.name;
        }
        return '';
    },
    addWeblink(item){
      if(!item.weblinks){
        item.weblinks = [];
      }
      item.weblinks.push({});
    },
    updateWeblinks(item, update){
      console.log(item.weblinks);
      update("$set",'weblinks',item.weblinks);
    },
    removeWeblink( key, item, update){
      const c = confirm(this.$t('confirm_remove_item'));
      if(!c){
        return false;
      }
      item.weblinks.splice( key, 1 );
      update("$set",'weblinks',item.weblinks);
    },
  },
  mounted() {
    this.getOptions();
    this.getCategories();
  }
})
