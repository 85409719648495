<template>
	
	<div>
		<InputText type="text" v-model="label"/>
		<Dropdown type="text" v-model="icon" :options="icons" optionLabel="name" :filter="true" filterPlaceholder="Chercher">
			<template #value="slotProps">
				<div class="p-dropdown-car-value" v-if="slotProps.value">
					<i :class="slotProps.value.value"/>
					<span class="p-pl-2">{{ slotProps.value.name }}</span>
				</div>
				<span v-else>
		            Selectionne un icon
		        </span>
			</template>
			<template #option="slotProps">
				<div class="p-dropdown-car-option">
					<i :class="slotProps.option.value"/>
					<span class="p-pl-2">{{ slotProps.option.name }}</span>
				</div>
			</template>
		</Dropdown>
		<br>
		<Dropdown type="text" v-model="path" :options="paths" optionLabel="name" optionValue="path" :filter="true" filterPlaceholder="Chercher">
		
		</Dropdown>
		<InputText type="text" v-model="path"/>
	</div>

</template>

<script>
import {computed, ref, watch} from "vue";
import {useRouter} from "vue-router";

export default {
	name: "MenuButtonSettings",
	components: {
		InputText: require("primevue/inputtext").default,
		Dropdown: require("primevue/dropdown").default,
	},
	props: ['args'],
	setup(props, {emit}) {
		const icons = require('@/helpers/icons').icons;
		
		const label = ref(props.args?.label || "");
		const icon = ref(props.args?.icon || "");
		const path = ref(props.args?.path || "");
		
		const cur = Object.keys(icons).find(k => icons[k] === icon.value);
		if (cur)
			icon.value = {name: cur, value: icons[cur]};
		
		watch(label, up);
		watch(icon, up);
		watch(path, up);
		
		function up() {
			emit("update:args", {
				label: label.value,
				icon: icon.value?.value,
				path: path.value
			})
		}
		
		const router = useRouter();
		const paths = computed(() => {
			const paths = [];
			router.getRoutes().forEach(route => {
				if (route.path.indexOf(":") === -1) {
					paths.push(route);
				} else
					console.log(route);
			});
			return paths;
		});
		
		return {
			paths,
			label,
			icon,
			path,
			icons: Object.keys(icons).map(k => {
				return {name: k, value: icons[k]};
			})
		};
	}
}
</script>

<style scoped>

</style>
