<template>
	<LoginLayout :email="email" :password="password" :password-check="passwordCheck">
		<template #default="{login,errors,goToReset}">
			<div class="container">
				<div class="p-shadow-3 p-p-3 p-d-flex">
					<div class="brand-wrapper">
						<img src="@/assets/images/logo_mattech.png" alt="logo" class="logo">
					</div>
					
					<div class="p-d-flex p-flex-column p-pl-3">
						<div class="p-field">
							<label for="email">{{$t('email')}}</label>
							<FormInput :errors="errors.email" v-slot="{classes}">
								<InputText id="email" :class="classes" type="email" v-model="email" toggleMask></InputText>
							</FormInput>
						</div>
						
						<div class="p-field">
							<label for="password">{{$t('password')}}</label>
							<FormInput :errors="errors.password">
								<Password id="password" v-model="password" toggleMask :feedback="false"></Password>
							</FormInput>
						</div>
						
						<small class="p-pb-1 clickable"><a @click="goToReset()">{{$t('forgot_password')}}</a></small>
						
						<Button @click="login()" :label="$t('connect')"/>
					</div>
				</div>
			</div>
		</template>
		<template #register="{register,errors}">
			<div class="container">
				<div class="p-shadow-3 p-p-3 p-d-flex">
					<div class="brand-wrapper">
						<img src="@/assets/images/logo_mattech.png" alt="logo" class="logo">
					</div>
					
					<div class="p-d-flex p-flex-column p-pl-2">
						<div class="p-field">
							<label for="passwordR">{{$t('new')}} {{$t('password')}}</label>
							<FormInput :errors="errors.password">
								<Password id="passwordR" v-model="password" toggleMask></Password>
							</FormInput>
						</div>
						
						<div class="p-field">
							<label for="passwordR2">{{$t('confirmation')}}</label>
							<FormInput :errors="errors.passwordCheck">
								<Password id="passwordR2" v-model="passwordCheck" toggleMask :feedback="false"></Password>
							</FormInput>
						</div>
						
						<Button @click="register()" :label="$t('register')"/>
					</div>
				</div>
			</div>
		</template>
		<template #reset="{resetPassword,errors,goToLogin}">
			<div class="container">
				<div class="p-shadow-3 p-p-3 p-d-flex">
					<div class="brand-wrapper">
						<img src="@/assets/images/logo_mattech.png" alt="logo" class="logo">
					</div>
					
					<div class="p-d-flex p-flex-column p-pl-2">
						<div class="p-field">
							<label for="emailRP">{{$t('email')}}</label>
							<FormInput :errors="errors.email" v-slot="{classes}">
								<InputText id="emailRP" :class="classes" type="email" v-model="email" toggleMask></InputText>
							</FormInput>
						</div>
						
						<small class="p-pb-1 clickable"><a @click="goToLogin()">{{$t('back')}}</a></small>
						
						<Button @click="resetPassword()" :label="$t('edit_password')"/>
					</div>
				</div>
			</div>
		</template>
	</LoginLayout>
</template>

<script>
import {ref} from "vue";
import FormInput from "@/components/FormPrime/FormInput";
import LoginLayout from "@/components/Layout/LoginLayout";

export default {
	components: {
		LoginLayout,
		FormInput,
		Password: require('primevue/password').default,
		InputText: require('primevue/inputtext').default,
		Button: require('primevue/button').default,
	},
	setup() {
		
		return {
			email: ref(),
			password: ref(),
			passwordCheck: ref()
		}
	}
}
</script>

<style scoped>

.container {
	display: grid;
	place-items: center;
	height: 100vh;
}

img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.clickable > a {
	cursor: pointer;
}

</style>
