<template>
	<div class="p-p-2 p-shadow-3">
    <TableLayout service="product" v-model:hidden-columns="hiddenColumns" :first="0" :filters="filters" :column_filters="Object.keys(column_filters)" :actions="actions" :disabled_actions="disabled_actions" :hide-actions="true">
      <template #toolbar-left>
        <Button @click="addingEmpty = true">{{ $t('add_empty_product') }}</Button>
      </template>
      <template #default="{open}">
        <Column field="image" sortable>
          <template #header>
            {{ $tc('image',1) }}
          </template>
          <template #body="{data, index}">
            <img :src="imageLink(data)" style="max-width: 100px;"/>
          </template>
        </Column>
        <Column field="name">
          <template #body="{data}">
            <div class="product-name">{{ data.name }}</div>
            <div class="product-description">{{ data.description }}</div>
          </template>
          <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" @keydown.enter="filterCallback()"/>
          </template>
        </Column>
        <Column field="productCategories" v-if="productCategories">
          <template #body="{data}">
            {{ getProductCategorie(data.productCategories) }}
          </template>
          <template #filter="{filterModel,filterCallback}">
            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="productCategories" optionLabel="name" optionValue="_id" placeholder="Tous" class="p-column-filter" :showClear="true"/>
          </template>
        </Column>
        <!--<Column>
          <template #body="{data}">
            <h5>Grattage</h5>
            <Rating :modelValue="data.scrapes" :stars="5" :readonly="true" :cancel="false"></Rating>
            <Rating :modelValue="data.scrapes-5" :stars="5" :readonly="true" :cancel="false"></Rating>
          </template>
        </Column>
        <Column >
          <template #body="{data}">
            <h5>Essuyage</h5>
            <Rating :modelValue="data.absorbs" :stars="5" :readonly="true" :cancel="false"></Rating>
            <Rating :modelValue="data.absorbs-5" :stars="5" :readonly="true" :cancel="false"></Rating>
          </template>
        </Column>
        <Column>
          <template #body="{data}">
            <h5>Résistance à l'écrasement</h5>
            <Rating :modelValue="data.resists" :stars="5" :readonly="true" :cancel="false"></Rating>
            <Rating :modelValue="data.resists-5" :stars="5" :readonly="true" :cancel="false"></Rating>
          </template>
        </Column>-->
        <Column>
          <template #body="{data}">
            <Button icon="pi pi-shopping-cart" @click="add = data"></Button>
          </template>
        </Column>
      </template>
    </TableLayout>
		
		<Sidebar v-model:visible="adding" :baseZIndex="1000" position="full" :showCloseIcon="false">
			<sku-selector :product="add" @cancel="adding = false" @add="$emit('add',$event)" class="p-mb-3"/>
		</Sidebar>
    <Sidebar v-model:visible="addingEmpty" :baseZIndex="1000" position="full" :showCloseIcon="false">
      <SoumissionRapideAddEmpty @cancel="addingEmpty = false" @add="$emit('add',$event)" class="p-mb-3"/>
    </Sidebar>
	</div>
</template>

<script>
import SkuSelector from "@/components/Sku/SkuSelector";
import SoumissionRapideAddEmpty from "@/views/Submission/SoumissionRapideAddEmpty";
import {CrudRepository} from "@radiumjs/client";
import {useStore} from "@/store";
import {computed, onActivated, ref} from "vue";
import {useRadium} from "@radiumjs/vue";

import {_GET} from "../../helpers/get";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";

export default {
	name: "SoumissionRapideAdd",
	components: {
    TableLayout,
		SkuSelector,
    SoumissionRapideAddEmpty,
		DataTable: require("primevue/datatable").default,
		DataView: require("primevue/dataview").default,
		Galleria: require("primevue/galleria").default,
		DataViewLayoutOptions: require("primevue/dataviewlayoutoptions").default,
		Column: require("primevue/column").default,
		Steps: require("primevue/steps").default,
		Toolbar: require("primevue/toolbar").default,
		Rating: require("primevue/rating").default,
		Button: require("primevue/button").default,
		Sidebar: require("primevue/sidebar").default,
    InputText: require('primevue/inputtext').default,
    Dropdown: require('primevue/dropdown').default,
	},
	emit: ['add'],
  data(){
    return {
      productCategories:[],
      products:null,
      column_filters: {
        name: 'string',
        productCategories: 'array'
      }
    };
  },
	setup() {
    const store = useStore();
    _GET.setProvider(store.radium);
    const disabled_actions = ['edit','delete'];
    const own_filters = store.permissions.filters();

    let filters = {
      'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'productCategories': {value: null, matchMode: FilterMatchMode.EQUALS}
    };
    filters = {...filters,...own_filters};

    const actions = [];
		
		onActivated(() => {
		})
		
		const add = ref();
    const addEmpty = ref();
		
		return {
      actions,
      disabled_actions,
      filters,
			add,
			adding: computed({
				get() {
					return !!add.value;
				},
				set(v) {
					if (!v)
						add.value = undefined;
				}
			}),

      addEmpty,
      addingEmpty: computed({
        get() {
          return !!addEmpty.value;
        },
        set(v) {
          console.log('addingEmpty '+v+' : '+addEmpty.value);
          addEmpty.value = v;
        }
      }),
		};
	},
  methods:{
    async getProductCategories(){
      let self = this;
      const res = await _GET.all('productCategories').then(function(response){
        return response;
      });
      this.productCategories = res;
    },
    getProductCategorie(c){
      if(this.productCategories&&c) {
        const categories = [];
        this.productCategories.forEach(i => {
          if (c.includes(i._id)) {
            categories.push(i.name);
          }
        });
        return categories.join(',');
      }
      return '';
    },
    imageLink(data){
      const type = data.image_type ? data.image_type : '';
      if(type==='image'&&data.image?.file?.name){
        return (process.env.VUE_APP_UPLOAD_SERVER?process.env.VUE_APP_UPLOAD_SERVER:'')+'/storages/'+file.dir+'/'+file.name;
      }else if(type==='url'&&data.image){
        return data.image;
      }
      return '';
    }
  },
  mounted(){
    this.getProductCategories();
  }
}
</script>

<style scoped lang="scss">
.card {
	background: #ffffff;
	padding: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
	margin-bottom: 2rem;
}

.p-dropdown {
	width: 14rem;
	font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;
	
	img {
		width: 150px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}
	
	.product-list-detail {
		flex: 1 1 0;
	}
	
	.p-rating {
		margin: 0 0 .5rem 0;
	}
	
	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}
	
	.product-list-action {
		display: flex;
		flex-direction: column;
	}
	
	.p-button {
		margin-bottom: .5rem;
	}
}

::v-deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid #dee2e6;
	
	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	img {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}
	
	.product-grid-item-content {
		text-align: center;
	}
	
	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;
		
		img {
			margin: 2rem 0;
		}
		
		.product-list-detail {
			text-align: center;
		}
		
		.product-price {
			align-self: center;
		}
		
		.product-list-action {
			display: flex;
			flex-direction: column;
		}
		
		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>
