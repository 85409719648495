<template>
	<div style="overflow: hidden; height: 100%;width: 100%">
		<apexchart
			style="position: absolute"
			width="100%"
			height="100%"
			type="bar"
			:options="chartOptions"
			:series="series"
		></apexchart>
	</div>
</template>

<script>

export const meta = {
	path: "data",
	name: "Graph",
	sizes: [
		{h: 2, w: 3},
		{h: 4, w: 4}
	]
};

export default {
	name: "Graph",
	components: {
		apexchart: require("vue3-apexcharts").default,
	},
	props: {
		icon: String,
		label: String,
	},
	data() {
		return {
			chartOptions: {
				chart: {
					id: "vuechart-example",
				},
				xaxis: {
					categories: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
				},
			},
			series: [
				{
					name: "clients",
					data: [3, 5, 1, 4, 8, 5, 10],
				},
				{
					name: "allo",
					data: [7, 6, 5, 8, 7, 6, 5],
				},
			]
		};
	}
}
</script>

<style scoped>

div {
	background-color: #aaa;
	border-radius: 3%;
}

</style>
