

import {useConfirm} from "primevue/useconfirm";
import {computed, ComputedRef, inject, ref} from "vue";
import {BuiltProduct} from "@/helpers/soumission";
import {Product, SKU, SkuHelper} from "@/helpers/product";

export default {
  name: 'SoumissionRapideAddEmpty',
  components: {
    DataTable: require('primevue/datatable').default,
    Toolbar: require('primevue/toolbar').default,
    Column: require('primevue/column').default,
    Button: require('primevue/button').default,
    Dialog: require('primevue/dialog').default,
    InputNumber: require('primevue/inputnumber').default,
    RadioButton: require('primevue/radiobutton').default,
    Dropdown: require('primevue/dropdown').default,
    InputText: require('primevue/inputtext').default,
    Checkbox: require('primevue/checkbox').default,
    SplitButton: require('primevue/splitbutton').default,
    Calendar: require('primevue/calendar').default,
    Editor: require('primevue/editor').default,
    Textarea: require("primevue/textarea").default,
  },
  props: {
    modelValue: Object
  },
  data(){
    return {
      item: {
        info:{}
      },
      note:""
    }
  },
  emits: ["add", "cancel"],
  setup(props, {emit}) {
    const helper = new SkuHelper({} as Product);

    return {
      add(item) {
        console.log('add');
        console.log(item);

        item.options = [];
        item.files = [];
        item.weblinks = [];
        item.productOptions = [];
        item.productOptions_price = 0;
        item.weight = item.info.weight;

        const product = helper.buildEmpty(item);

        console.log(product);
        emit('add', product);
      }
    };
  },
  methods:{}
}
